import { css } from '@emotion/core';
import colors from '../../../../../../styles/colors';

export const appointmentMessageCSS = css`
  &.splitGrid {
    grid-template-columns: 44px auto;
  }

  &.is-cancelled-icon {
    background-color: ${colors.red[900]};
    border-radius: 50%;
    max-width: 20px;
    max-height: 20px;
    margin-left: 16px;
  }

  &.is-adhoc {
    background-color: transparent !important;
    border-radius: 0 !important;
    max-width: unset !important;
    max-height: 20px;
    margin-left: 12px;
    border: none !important;
    box-shadow: none !important;
  }

  &.is-accepted-icon {
    background-color: ${colors.blue.default};
    border-radius: 50%;
    max-width: 20px;
    max-height: 20px;
    margin-left: 16px;
  }

  &.is-accepted-icon-for-group-event {
    background-color: ${colors.blue.default};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 14px;
  }

  &.is-cancelled-info {
    color: ${colors.red[900]};
    font-weight: 700;
    padding-top: 10px;
  }

  &.is-accepted-info {
    color: ${colors.gray[700]};
    font-weight: 400;
    padding-top: 10px;
  }

  &.appointmentDate {
    font-weight: 700;
    padding-bottom: 10px;
    color: ${colors.gray[700]};
    line-height: 21px;
  }

  &.is-pin-right {
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 0.5rem;
    border-top-right-radius: 0;
    border-color: ${colors.blue.default};
    background-color: ${colors.white};

    .chakra-ui-dark & {
      background-color: ${colors.blue.default};
    }

    &.is-stranger {
      background-color: ${colors.blue.default};

      .chakra-ui-dark & {
        background-color: ${colors.blue.default};
      }
    }
  }

  &.is-pin-left {
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-color: ${colors.white};
    background-color: ${colors.lime};

    .chakra-ui-dark & {
      background-color: ${colors.lime};
    }
  }
`;
