import React, { ReactText } from 'react';
import { Checkbox, CheckboxGroup, Divider, Stack, Tooltip, Spinner, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { Recruiter } from '../../../firebase/firestore/documents/recruiter';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { useStoreState } from '../../../models/hooks';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';

export type RecruiterListProps = {
  recruiters: Recruiter[];
  checkedRecruiters?: string[];
  handleCheckedRecruiters: (recruiterIds: ReactText[]) => void;
  getStyleForAvailabilityByUser: (currentUserProfileId: string) => string | undefined;
  searchText: string;
};

export const RecruiterList = ({
  recruiters,
  checkedRecruiters,
  handleCheckedRecruiters,
  getStyleForAvailabilityByUser,
  searchText,
}: RecruiterListProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentUserProfile } = useCurrentUserProfile();
  const recruiterMaxSelect: number = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.RECRUITER_MAX_SELECT];
  });

  return (
    <CheckboxGroup
      value={checkedRecruiters}
      onChange={(data) => (data.length <= recruiterMaxSelect ? handleCheckedRecruiters(data) : '')}
    >
      <Stack className="recruiter-stack" p={[5, 4]} spacing={[1, 2]}>
        {currentUserProfile && (
          <Tooltip
            placement="top-start"
            shouldWrapChildren
            label={
              checkedRecruiters &&
              checkedRecruiters?.length >= recruiterMaxSelect &&
              !checkedRecruiters?.includes(currentUserProfile.id)
                ? t('administration:calendar.toolTipWarningMessage', {
                    recruiterName: `${currentUserProfile.fullName}`,
                  })
                : ''
            }
          >
            <Checkbox
              data-testid={`Checkbox-${currentUserProfile.id}`}
              colorScheme={getStyleForAvailabilityByUser(currentUserProfile.id)}
              value={currentUserProfile.id}
            >
              {`${currentUserProfile.fullName}`}
            </Checkbox>
          </Tooltip>
        )}
        <Divider borderColor="gray.100" />
        {recruiters.length <= 0 ? (
          <Flex py={6} alignItems="center" justifyContent="center">
            <Spinner color="blue.500" />
          </Flex>
        ) : (
          recruiters
            .filter((r) => {
              const firstName = r.firstName?.trim() || /* istanbul ignore next */ '';
              const lastName = r.lastName?.trim() || /* istanbul ignore next */ '';

              if (r.id === currentUserProfile?.id || (!firstName && /* istanbul ignore next */ !lastName)) {
                return false;
              }

              return (
                firstName.toLowerCase().includes(searchText.toLowerCase()) ||
                lastName.toLowerCase().includes(searchText.toLowerCase())
              );
            })
            // .filter(
            //   (r) =>
            //     r.id !== currentUserProfile?.id &&
            //     (r.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
            //       r.lastName.toLocaleLowerCase().includes(searchText.toLowerCase())),
            // )
            .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
            .map((recruiter) => (
              <Tooltip
                placement="top-start"
                key={recruiter.id}
                shouldWrapChildren
                label={
                  checkedRecruiters &&
                  checkedRecruiters?.length >= recruiterMaxSelect &&
                  !checkedRecruiters?.includes(recruiter.id)
                    ? t('administration:calendar.toolTipWarningMessage', {
                        recruiterName: `${recruiter.firstName} ${recruiter.lastName}`,
                      })
                    : ''
                }
              >
                <Checkbox
                  colorScheme={getStyleForAvailabilityByUser(recruiter.id)}
                  data-testid={`Checkbox-${recruiter.id}`}
                  value={recruiter.id}
                >
                  {`${recruiter.firstName} ${recruiter.lastName}`}
                </Checkbox>
              </Tooltip>
            ))
        )}
      </Stack>
    </CheckboxGroup>
  );
};
