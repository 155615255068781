export default {
  VIDEO: 'Video',
  PHONE: 'Phone',
  INPERSON: 'In Person',
  INTEREST_REVOKED: 'Interest Revoked',
  APPOINTMENT_REQUEST_CREATED_NO_TYPE: 'Interview Requested',
  APPOINTMENT_REQUEST_CREATED: '$t({{ appointmentType }}) Interview Requested',
  APPOINTMENT_REQUEST_CREATED_plural: '{{count}} min. $t({{ appointmentType }}) Interview Requested',
  APPOINTMENT_REQUEST_CANCELLED: '$t({{ appointmentType }}) Interview Cancelled',
  APPOINTMENT_REQUEST_CANCELLED_plural: '{{count}} min. $t({{ appointmentType }}) Interview Cancelled',
  APPOINTMENT_REQUEST_DECLINED: '$t({{ appointmentType }}) Interview Request Declined',
  APPOINTMENT_REQUEST_DECLINED_plural: '{{count}} min. $t({{ appointmentType }}) Interview Request Declined',
  APPOINTMENT_REQUEST_CANCELLED_BY_DISMISS: '$t({{ appointmentType }}) Interview Cancelled',
  APPOINTMENT_REQUEST_CANCELLED_BY_DISMISS_plural: '{{count}} min. $t({{ appointmentType }}) Interview Cancelled',
  APPOINTMENT_REQUEST_ACCEPTED: '$t({{ appointmentType }}) Interview Accepted',
  APPOINTMENT_REQUEST_ACCEPTED_plural: '{{count}} min. $t({{ appointmentType }}) Interview Accepted',
  APPOINTMENT_PERIOD_CANCELLED: '$t({{ appointmentType }}) Interview period cancelled',
  APPOINTMENT_PERIOD_CANCELLED_plural: '{{count}} min. $t({{ appointmentType }}) Interview period cancelled',
  APPOINTMENT_MESSAGE: { APPOINTMENT_DATE: '{{date, dddd MMM D}} at {{date, h:mm A}}' },
  ONDEMAND_INTERVIEW_CREATED: '{{count}} min. On-Demand $t({{ appointmentType }}) Interview',
  ONDEMAND_INTERVIEW_ACCEPTED: '{{count}} min. On-Demand $t({{ appointmentType }}) Interview Accepted',
  ONDEMAND_INTERVIEW_CANCELLED: '{{count}} min. On-Demand $t({{ appointmentType }}) Interview Canceled',
};
