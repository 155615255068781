import { thunk, Thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { LanguageEvaluationController } from '../../../../controllers/languageEvaluationController';
import HttpClientWithRetry from '../../../../http/client/HttpClientWithRetry';
import { HttpStoreModel } from '../../../../http/models/httpModel';
import {
  AudioDataType,
  AudioResponseType,
  LanguageEvaluationTemplateDataType,
  LanguageEvaluationTemplateResponseType,
  TemplateList,
} from '../LanguageEvaluationTypes';

export type LanguageEvaluationStoreModel = {
  languageEvaluationTemplate: LanguageEvaluationModel;
};

export interface LanguageEvaluationModel {
  createLanguageEvaluationTemplate: Thunk<
    LanguageEvaluationModel,
    { accountId: string; templateData: LanguageEvaluationTemplateDataType },
    void,
    HttpStoreModel,
    Promise<AxiosResponse>
  >;
  getLanguageEvaluationTemplates: Thunk<
    LanguageEvaluationModel,
    { accountId: string; params: { after?: string | null; before?: string | null } },
    void,
    HttpStoreModel,
    Promise<TemplateList>
  >;
  copyLanguageEvaluationTemplate: Thunk<
    LanguageEvaluationModel,
    { templateId: string },
    void,
    HttpStoreModel,
    Promise<AxiosResponse>
  >;
  getLanguageEvaluationTemplateDetail: Thunk<
    LanguageEvaluationModel,
    { accountId: string; templateId: string },
    void,
    HttpStoreModel,
    Promise<LanguageEvaluationTemplateResponseType>
  >;
  updateLanguageEvaluationTemplate: Thunk<
    LanguageEvaluationModel,
    { templateId: string; templateData: LanguageEvaluationTemplateDataType },
    void,
    HttpStoreModel,
    Promise<AxiosResponse>
  >;
  evaluateLanguageEvaluation: Thunk<
    LanguageEvaluationModel,
    { audioData: AudioDataType },
    void,
    HttpStoreModel,
    Promise<AudioResponseType>
  >;
}

export const languageEvaluationModel: LanguageEvaluationModel = {
  createLanguageEvaluationTemplate: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new LanguageEvaluationController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.createLanguageEvaluationTemplate(payload.accountId, payload.templateData);
    return response;
  }),

  getLanguageEvaluationTemplates: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new LanguageEvaluationController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getLanguageEvaluationTemplates(payload.accountId, payload.params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response?.data?.leTemplates as TemplateList;
  }),

  copyLanguageEvaluationTemplate: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new LanguageEvaluationController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.copyLanguageEvaluationTemplate(payload.templateId);
    return response;
  }),

  getLanguageEvaluationTemplateDetail: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new LanguageEvaluationController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getLanguageEvaluationTemplateDetail(payload.accountId, payload.templateId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response?.data?.leTemplate as LanguageEvaluationTemplateResponseType;
  }),

  updateLanguageEvaluationTemplate: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new LanguageEvaluationController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updateLanguageEvaluationTemplate(payload.templateId, payload.templateData);
    return response;
  }),

  evaluateLanguageEvaluation: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new LanguageEvaluationController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.evaluateLanguageEvaluation(payload.audioData);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response?.data?.result as AudioResponseType;
  }),
};
