import { AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import {
  AudioDataType,
  LanguageEvaluationTemplateDataType,
} from '../modules/administration/languageEvaluation/LanguageEvaluationTypes';

export class LanguageEvaluationController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async createLanguageEvaluationTemplate(
    accountId: string,
    templateData: LanguageEvaluationTemplateDataType,
  ): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/addLanguageEvaluationTemplate`;
    const body = templateData;
    return this.httpClient.post(path, body);
  }

  async getLanguageEvaluationTemplates(
    accountId: string,
    params: { after?: string | null; before?: string | null },
  ): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/getLanguageEvaluationTemplates`;
    const response = await this.httpClient.get(path, { params });
    return response;
  }

  async copyLanguageEvaluationTemplate(templateId: string): Promise<AxiosResponse> {
    const path = `/api/v1/copyLanguageEvaluationTemplate/${templateId}`;
    return this.httpClient.post(path);
  }

  async getLanguageEvaluationTemplateDetail(accountId: string, templateId: string): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/getLanguageEvaluationTemplate/${templateId}`;
    return this.httpClient.get(path);
  }

  async updateLanguageEvaluationTemplate(
    templateId: string,
    templateData: LanguageEvaluationTemplateDataType,
  ): Promise<AxiosResponse> {
    const path = `/api/v1/editLanguageEvaluationtemplate/${templateId}`;
    const body = templateData;
    return this.httpClient.put(path, body);
  }

  async evaluateLanguageEvaluation(audioFile: AudioDataType): Promise<AxiosResponse> {
    const path = '/api/v1/languageEvaluationTemplate/testTemplate';
    const body = audioFile;
    return this.httpClient.post(path, body);
  }
}
