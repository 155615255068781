/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-default-export */
import React, { useRef, useState } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { Box, Flex, ScaleFade, Text, Spinner, useToast, useDisclosure } from '@chakra-ui/core';
import { css } from '@emotion/core';
import { useTable, usePagination, Cell, Column } from 'react-table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FaRegCopy } from 'react-icons/fa';
import colors from '../../styles/colors';
import { CandidateSearchDataType, CandidateStatus } from './CandidateSearchTypes';
import { CandidateMessagingViewModal } from '../../app/components/CandidateMessagingViewModal';

const TableStyle = css`
  cursor: pointer;
  &:hover, &:focus, &:active {
    background: #f7fafc;
  }
  &.activeRow {
    background: ${colors.gray[50]};
  }
  &:hover .rightArrow {
    opacity: 1;
    transform: translate(0, -50%);
  }
}
`;

export type TableProps<Data extends Record<string, unknown>> = {
  data: Data[];
  columns: Column<Data>[];
  isLoading?: boolean;
};

export default function CandidateSearchTableView<Data extends Record<string, unknown>>({
  data,
  columns,
  isLoading,
}: TableProps<Data>) {
  const { t } = useTranslation('candidateSearch');
  const toast = useToast();
  const tableContainerRef = useRef<null | HTMLTableElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentCandidateId, setCurrentCandidateId] = useState<string>('');
  const [isMessagingModal, setIsMessagingModal] = useState<boolean>(false);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    usePagination,
  );

  const onCopySeekerHireDetailId = async (hireDetailId: string) => {
    await navigator.clipboard.writeText(hireDetailId);
    toast({
      title: t('copiedHireId'),
      duration: 2000,
      isClosable: true,
    });
  };

  // istanbul ignore next
  const onCandidateModalOpen = (candidateId: string) => {
    setCurrentCandidateId(candidateId);
    setIsMessagingModal(false);
    onOpen();
  };

  // istanbul ignore next
  const getStatusLabel = (label: string) => {
    switch (label) {
      case CandidateStatus.Dismissed:
        return t('candidateStatus.DISMISSED');
      case CandidateStatus.Hired:
        return t('candidateStatus.HIRED');
      case CandidateStatus.HireInReview:
        return t('candidateStatus.HIRE_IN_REVIEW');
      case CandidateStatus.HiredOnAnotherPosition:
        return t('candidateStatus.HIRED_ON_ANOTHER_POSITION');
      case CandidateStatus.NotLookingForWork:
        return t('candidateStatus.NOT_LOOKING_FOR_WORK');
      case CandidateStatus.AppointmentRequested:
        return t('candidateStatus.APPOINTMENT_REQUESTED');
      case CandidateStatus.AppointmentAccepted:
        return t('candidateStatus.APPOINTMENT_ACCEPTED');
      case CandidateStatus.AppointmentCancelled:
        return t('candidateStatus.APPOINTMENT_CANCELLED');
      case CandidateStatus.AppointmentDeclined:
        return t('candidateStatus.APPOINTMENT_DECLINED');
      case CandidateStatus.ContactRequested:
        return t('candidateStatus.CONTACT_REQUESTED');
      case CandidateStatus.ContactRequestAccepted:
        return t('candidateStatus.CONTACT_REQUEST_ACCEPTED');
      case CandidateStatus.ContactRequestDeclined:
        return t('candidateStatus.CONTACT_REQUEST_DECLINED');
      case CandidateStatus.AppointmentReschedulingNeeded:
        return t('candidateStatus.APPOINTMENT_RESCHEDULING_NEEDED');
      case CandidateStatus.OfferMade:
        return t('candidateStatus.OFFER_MADE');
      case CandidateStatus.OfferRejected:
        return t('candidateStatus.OFFER_REJECTED');
      case CandidateStatus.OfferRevoked:
        return t('candidateStatus.OFFER_REVOKED');
      case CandidateStatus.Bookmarked:
        return t('candidateStatus.BOOKMARKED');
      case CandidateStatus.Liked:
        return t('candidateStatus.LIKED');
      case CandidateStatus.InterestRevoked:
        return t('candidateStatus.INTEREST_REVOKED');
      case CandidateStatus.Unhired:
        return t('candidateStatus.UNHIRED');
      case CandidateStatus.Blacklisted:
        return t('candidateStatus.BLACKLISTED');
      default:
        return '-';
    }
  };

  const renderCell = (cellData: Cell<Data>) => {
    const columnData = (cellData.row.original as unknown) as CandidateSearchDataType;

    switch (cellData.column.id) {
      case 'fullName':
        return (
          <Box className={cellData.column.id} data-testid={cellData.column.id} py="10px">
            <Box
              as="span"
              style={{ color: colors.linkBlue, textDecoration: 'underline' }}
              fontSize="15px"
              fontWeight={400}
              onClick={/* istanbul ignore next */ () => onCandidateModalOpen(columnData.id)}
            >
              {columnData.fullName}
            </Box>
            <Text fontSize="12px" fontWeight={400}>
              {columnData.email}
            </Text>
          </Box>
        );
      case 'location':
        return (
          <Box>
            <Text fontSize="14px" fontWeight={400}>
              {columnData.locationId}
            </Text>
            <Text fontSize="14px" fontWeight={400}>
              {columnData.businessName}
            </Text>
          </Box>
        );
      case 'phone':
        return (
          <>
            {columnData?.phone ? (
              <Box fontSize="14px" fontWeight={400} py="10px">
                {columnData.phone}
              </Box>
            ) : (
              <Text>-</Text>
            )}
          </>
        );
      case 'status':
        return (
          <Box fontSize="14px" fontWeight={400} textTransform="capitalize">
            {getStatusLabel(columnData?.statusDetails?.status)}
          </Box>
        );

      case 'lastUpdatedAt':
        return (
          <Box>
            <Text fontSize="14px">{moment(columnData?.statusDetails?.lastUpdatedAt).format('DD/MM/YYYY')}</Text>
          </Box>
        );
      case 'seekerHireDetailId':
        return (
          <>
            {columnData.seekerHireDetailId ? (
              <Flex d="inline-flex" justifyContent="flex-start" alignItems="center">
                <Box
                  fontSize="14px"
                  fontWeight={400}
                  py="5px"
                  mr={2}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                  }}
                >
                  {columnData.seekerHireDetailId}
                </Box>
                <Box>
                  <FaRegCopy
                    size={14}
                    onClick={() => onCopySeekerHireDetailId(columnData.seekerHireDetailId)}
                    data-testid={`copy-${columnData.seekerHireDetailId}`}
                  />
                </Box>
              </Flex>
            ) : (
              <Text>-</Text>
            )}
          </>
        );

      /* istanbul ignore next */
      default:
        return <Text fontSize="14px">{cellData.value !== undefined ? cellData.render('Cell') : 'N/A'}</Text>;
    }
  };

  return (
    <ScaleFade initialScale={0.7} in unmountOnExit={false}>
      <Table
        {...getTableProps()}
        className="mainTable"
        key="mainTable"
        w={{ base: '100%', md: '100%', lg: '100%' }}
        border="1px solid #E5E7EB"
        borderCollapse="collapse"
        bg="#fff"
        ref={tableContainerRef}
      >
        <Thead className="tableHeading" bg="blue.100">
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} textAlign="left" key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column) => {
                return (
                  <Th
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                    px={5}
                    py={3}
                    border="1px solid #E5E7EB"
                    borderCollapse="collapse"
                  >
                    <Box style={{ display: 'flex', maxWidth: '100%' }}>
                      <Text textTransform="none" display="inline" whiteSpace="nowrap">
                        {column.render('Header')}
                      </Text>
                    </Box>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!isLoading ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <Tr css={TableStyle} {...row.getRowProps()} key={row.getRowProps().key} cursor="pointer" width="100%">
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                      border="1px solid #E5E7EB"
                      borderCollapse="collapse"
                      px={5}
                      py={1}
                      isNumeric={cell.column.isNumeric}
                      key={cell.getCellProps().key}
                    >
                      {renderCell(cell)}
                    </Td>
                  ))}
                </Tr>
              );
            })
          ) : (
            <Td colSpan={6}>
              <Flex alignItems="center" justifyContent="center" p={5}>
                <Spinner colorScheme="blue" size="lg" />
              </Flex>
            </Td>
          )}
        </Tbody>
      </Table>

      {isOpen && (
        /* istanbul ignore next */ <CandidateMessagingViewModal
          isOpen={isOpen}
          onClose={onClose}
          currentCandidateId={currentCandidateId}
          messagingViewModal={isMessagingModal}
        />
      )}
    </ScaleFade>
  );
}
