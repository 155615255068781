import { Box, FormControl, Stack, FormLabel, Text, Switch } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../models/hooks';

interface FinalSignatureSwitchProps {
  needFinalSignature: boolean;
  setNeedFinalSignature: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FinalSignatureSwitch({ needFinalSignature, setNeedFinalSignature }: FinalSignatureSwitchProps) {
  const { t } = useTranslation('hrFormTemplate');
  const { currentTemplate } = useStoreState((state) => state.hrFormTemplate);

  React.useEffect(() => {
    if (currentTemplate) {
      setNeedFinalSignature(currentTemplate?.needFinalSignature ?? false);
    }
  }, [currentTemplate, setNeedFinalSignature]);

  return (
    <Box>
      <FormControl display="flex" alignItems="flex-start">
        <Switch
          id="hr-form-final-review-signature"
          size="sm"
          onChange={(e) => setNeedFinalSignature(e.target.checked)}
          isChecked={needFinalSignature}
        />
        <Stack spacing={0} marginLeft={4}>
          <FormLabel htmlFor="hr-form-final-review-signature" mb="0" fontSize="sm" fontWeight="bold" lineHeight={1}>
            {t('formBuilder.languageReviewModal.finalReviewSignature.label')}
          </FormLabel>
          <Text fontSize="sm" color={'gray.500'}>
            {t('formBuilder.languageReviewModal.finalReviewSignature.description')}{' '}
          </Text>
        </Stack>
      </FormControl>
    </Box>
  );
}
