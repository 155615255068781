import React, { ChangeEvent, useState, useCallback } from 'react';
import { Box, Text, Checkbox, Stack, Collapse, Button, Icon, Flex } from '@chakra-ui/core';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { EngagementTypes } from '../filters/seekersFiltersContextValueType';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';

export const CandidateEngagementView: React.FC = () => {
  const { t } = useTranslation();
  const { searchAndSortTypes, setSearchAndSortTypes, setIsFromSearchAndSort } = useSeekersFilters();

  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({
    appointment: false,
    offer: false,
    onDemandInterview: false,
  });

  const handleCheckboxChange = useCallback(
    (key: keyof EngagementTypes, checked: boolean, typeValue: string) => {
      setIsFromSearchAndSort(true);

      // istanbul ignore next line
      setSearchAndSortTypes((prev) => ({
        ...prev,
        candidateEngagement: {
          ...prev.candidateEngagement,
          [key]: checked ? typeValue : '',
        },
      }));
    },
    [setIsFromSearchAndSort, setSearchAndSortTypes],
  );

  const filterGroups = [
    {
      category: 'appointment',
      filterLabelKey: 'cadidateEngagementView.interview',
      subFilters: [
        { key: 'apointmentRequested', labelKey: 'cadidateEngagementView.appointmentRequested', type: 'APPOINTMENT_REQUESTED' },
        { key: 'appoinmentAccepted', labelKey: 'cadidateEngagementView.appoinmentAccepted', type: 'APPOINTMENT_ACCEPTED' },
        { key: 'appoinmentDeclined', labelKey: 'cadidateEngagementView.appoinmentDeclined', type: 'APPOINTMENT_DECLINED' },
        { key: 'appoinmentCancled', labelKey: 'cadidateEngagementView.appoinmentCancled', type: 'APPOINTMENT_CANCELLED' },
      ],
    },
    {
      category: 'onDemandInterview',
      filterLabelKey: 'cadidateEngagementView.onDemandInterview',
      subFilters: [
        {
          key: 'onDemandInterviewRequested',
          labelKey: 'cadidateEngagementView.onDemandInterviewRequested',
          type: 'ON_DEMAND_INTERVIEW_REQUESTED',
        },

        {
          key: 'onDemandInterviewAccepted',
          labelKey: 'cadidateEngagementView.onDemandInterviewAccepted',
          type: 'ON_DEMAND_INTERVIEW_ACCEPTED',
        },
        // {
        //   key: 'onDemandInterviewDeclined',
        //   labelKey: 'cadidateEngagementView.onDemandInterviewDeclined',
        //   type: 'ON_DEMAND_INTERVIEW_DECLINED',
        // },
        {
          key: 'onDemandInterviewCanceled',
          labelKey: 'cadidateEngagementView.onDemandInterviewCanceled',
          type: 'ON_DEMAND_INTERVIEW_CANCELED',
        },
      ],
    },
    {
      category: 'offer',
      filterLabelKey: 'cadidateEngagementView.offer',
      subFilters: [
        { key: 'offerMade', labelKey: 'cadidateEngagementView.OfferMade', type: 'OFFER_MADE' },
        { key: 'offerAccepted', labelKey: 'cadidateEngagementView.offerAccepted', type: 'OFFER_ACCEPTED' },
        { key: 'offerRejected', labelKey: 'cadidateEngagementView.offerRejected', type: 'OFFER_REJECTED' },
      ],
    },
  ];

  return (
    <Box>
      <Stack direction="column">
        <Checkbox
          data-testid="noAppointmentRequestCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.noAppointmentRequest === 'NO_APPOINTMENT_REQUEST'}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleCheckboxChange('noAppointmentRequest', e.target.checked, 'NO_APPOINTMENT_REQUEST')
          }
        >
          <Text px={3} fontSize="14px">
            {t('cadidateEngagementView.noAppointmentRequest')}
          </Text>
        </Checkbox>

        {filterGroups.map(({ category, filterLabelKey, subFilters }) => {
          const isParentChecked = subFilters.every(
            ({ key, type }) => searchAndSortTypes.candidateEngagement[key as keyof EngagementTypes] === type,
          );

          return (
            <Box key={category} data-testid={`category_${category}`}>
              <Flex justifyContent="space-between">
                <Checkbox
                  data-testid={`parentCheckbox_${category}`}
                  isChecked={isParentChecked}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    subFilters.forEach(({ key, type }) =>
                      handleCheckboxChange(key as keyof EngagementTypes, e.target.checked, type),
                    );
                    setExpandedSections((prev) => ({ ...prev, [category]: true }));
                  }}
                >
                  <Text
                    px={3}
                    fontSize="14px"
                    cursor="pointer"
                    data-testid={`categoryLabel_${category}`}
                    onClick={() => setExpandedSections((prev) => ({ ...prev, [category]: !prev[category] }))}
                  >
                    {t(filterLabelKey)}
                  </Text>
                </Checkbox>

                <Flex alignItems="center">
                  <Button
                    data-testid={`collapseButton_${category}`}
                    outline="none"
                    variant="ghost"
                    boxShadow="none"
                    _focus={{ boxShadow: 'none' }}
                    _active={{ boxShadow: 'none' }}
                    size="xs"
                    style={{ marginRight: 0 }}
                    onClick={() => setExpandedSections((prev) => ({ ...prev, [category]: !prev[category] }))}
                  >
                    <Icon as={expandedSections[category] ? ChevronUpIcon : ChevronDownIcon} />
                  </Button>
                </Flex>
              </Flex>

              <Collapse in={expandedSections[category]}>
                <Stack pl={6} pt={2}>
                  {subFilters.map(({ key, labelKey, type }) => (
                    <Checkbox
                      data-testid={`subFilter_${key}`}
                      pl={3}
                      key={key}
                      isChecked={searchAndSortTypes.candidateEngagement[key as keyof EngagementTypes] === type}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleCheckboxChange(key as keyof EngagementTypes, e.target.checked, type);
                      }}
                    >
                      <Text px={3} fontSize="14px">
                        {t(labelKey)}
                      </Text>
                    </Checkbox>
                  ))}
                </Stack>
              </Collapse>
            </Box>
          );
        })}

        <Checkbox
          data-testid="interestRevokedCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.interestRevoked === 'INTEREST_REVOKED'}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleCheckboxChange('interestRevoked', e.target.checked, 'INTEREST_REVOKED')
          }
        >
          <Text px={3} fontSize="14px">
            {t('cadidateEngagementView.interestRevoked')}
          </Text>
        </Checkbox>

        <Checkbox
          data-testid="contactRequestDeclinedCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.contactRequestDeclined === 'CONTACT_REQUEST_DECLINED'}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleCheckboxChange('contactRequestDeclined', e.target.checked, 'CONTACT_REQUEST_DECLINED')
          }
        >
          <Text px={3} fontSize="14px">
            {t('cadidateEngagementView.contactRequestDeclined')}
          </Text>
        </Checkbox>
      </Stack>
    </Box>
  );
};
