export default {
  createJobsTab: 'Create',
  createJobPositionCardHeader: 'Créez un poste',
  updateJobPositionCardHeader: 'Mettre à jour ce poste',
  createJobPositionCardHint: 'Complétez ou changez les champs restants',
  industry: 'Secteur',
  occupation: 'Occupation',
  jobType: 'Type de poste',
  jobTitle: 'Titre du poste',
  noOfOpenings: 'Nombre de postes',
  businessLocation: "Site de l'entreprise",
  businessLocationPlaceholder: 'Choissisez le site',
  salary: 'Salaire',
  per: 'Par',
  unit: 'Unité',
  experience: 'Expérience',
  jobDescription: 'Description',
  yearsOfExperience: "Années d'expérience",
  expiryDate: "Date d'expiration",
  automationCardHeader: 'Automatisation ',
  automationCardHint: 'Je peux vous aider à simplifier le recrutement en automatisant des étapes:',
  automationOptionOneLabel: 'Trouvez-moi des candidats',
  automationOptionOneHint:
    'Je vais vous trouver les meilleurs candidats, leurs poser quelques questions, mais le reste dépend de vous.',
  automationOptionTwoLabel: 'Présélectionnez les candidats',
  automationOptionTwoHint:
    'Je vais vous trouver les meilleurs candidats, les sélectionner, et vérifier leurs intérêts pour vous. Dites-moi juste combien de candidats à présélectionner.',
  automationOptionNoOfCandidateScreenLabel: 'Combien de candidats devrais-je présélectionner?',
  automationOptionThreeLabel: 'Occupez-vous de tout',
  automationOptionThreeHint:
    'Je vais vous trouver les meilleurs candidats, leurs proposer plusieurs plages horaires présélectionnées et faire votre horaire.',
  interviewCardHeader: "Détails de l'entrevue",
  interviewCardHint: "Changez les détails de l'entrevue",
  interviewType: "Type d'entrevue",
  interviewDuration: "Longueur de l'entrevue",
  interviewLocation: "Entrevue au site de l'entreprise",
  interviewOtherLocation: 'Autre endroit',
  interviewMessage: "Information de l'entrevue pour le candidat",
  videoCardHeader: 'Détails du vidéo',
  videoCardHint: 'Aperçu vidéo de la position',
  maximizeInterest: 'Télécharger la vidéo',
  interestDetail:
    "Enregistrez et téléchargez une vidéo de jusqu'à 30 secondes pour augmenter l'intérêt des candidats pour le poste. La vidéo sera visible aux candidats qui regardent le poste.",
  uploadBtn: 'Télécharger',
  removeBtn: 'Enlever',
  keepBtn: 'Garder',
  deleteBtn: 'Supprimer',
  questionCardHeader: 'Questions spécifiques',
  questionCardHint: 'Ajoutez des questions spécifiques pour les candidats',
  questionLabel: 'Ajoutez une question spécifique',
  questionInputPlaceholder: 'Écrivez la question ici (500 caractères)',
  questionExpectedAnsLabel: 'Réponse attendue',
  questionAutoDismissLabel: 'Rejeter automatiquement les candidats qui ne choisissent pas la réponse attendue',
  confirmBtn: 'Confirmer',
  freeFormQuestionCardHeader: 'Questions libres',
  freeFormQuestionCardHint: 'Ajoutez des questions libres pour les candidats',
  freeFormQuestionLabel: 'Ajoutez une question libre',
  freeFormQuestionInputPlaceholder: 'Écrivez la question ici (500 caractères)',
  workScheduleCardHeader: 'Disponibilité désirée',
  workScheduleCardHint: "Spécifiez l'horaire de travail pour ce poste. Sélectionnez des plages horaires",
  weekDay: 'Dimanche, Lundi, Mardi, Mercredi, Jeudi, Vendredi, Samedi',
  workSchedule: 'Jour, Soirée, Nuit',
  fullTimeLabel: 'Ceci est un poste à temps plein',
  advanceAccordionLabel: 'Continuer',
  skillsCardHeader: 'Compétences',
  skillsCardHint: "Indiquez l'importance de chacune des compétences relatives aux autres",
  skillsCardHintSlideText: "Traînez le curseur pour indiquer l'importance de chaque comptétence par rapport aux autres.",
  questionRequired: 'La question est requise',
  questionMinLetterRequired: 'La question doit être au minimum 10 caractères',
  interviewTypeRequired: "Le type d'entrevue est requis",
  interviewDurationRequired: "La durée d'entrevue est requise",
  interviewMessageRequired: "L'information de l'entrevue pour le candidat est requise",
  interviewLocationRequired: "L'endroit de l'entrevue est requis",
  minPriceRequired: 'La valeur minimum est requise',
  maxPriceRequired: 'La valeur maximum est requise',

  invalidNumberFormat: 'Format de chiffre invalide',
  maxValueShouldBeGreaterThanMinValue: `Le nombre doit être plus grand ou égal à {{minValue}}`,
  minValueRequired: 'La valeur minimum est requise',
  maxValueRequired: 'La valeur maximum est requise',
  minValueShouldBeLowerThanMaxValue: 'Le nombre doit être plus petit ou égal à {{maxValue}}',

  unitRequired: "L'unité est requise",
  industryRequired: 'Le secteur est requis',
  occupationRequired: "l'occupation est requise",
  jobRequired: 'Le poste est requis',
  jobTitleRequired: 'Le titre du poste est requis',
  businessLocationRequired: "Le site de l'entreprise est requis",
  noOfOpeningsRequired: "Le nombre d'ouvertures est requis",
  maxPriceMustBe: 'Le nombre doit être plus grand ou égal à',
  maxNoOfOpeningRequired: 'Le nombre doit être plus petit ou égal à 999',
  jobDescriptionRequired: 'La description du poste est requise',
  jobExpiryDateRequired: "La date d'expiration du poste est requise",
  jobCreatedSuccessMessage: 'Poste créé avec succès',
  jobUpdatedSuccessMessage: 'Ce poste a été mis à jour avec succès',
  automationConfigurationRequired: 'Le nombre de candidats est requis',
  automationConfigurationMinRequired: 'Au moins 1 est requis',
  proposedSalaryUnitOptions: {
    annual: 'Annuel',
    hourly: 'Horaire',
    custom: 'Spécial',
  },
  experienceFilterTypeOptions: {
    preferred: 'Préférée',
    maximum: 'Maximum',
    minimum: 'Minimum',
  },
  steps: {
    jobDetail: 'Détails du poste',
    additionalDetail: 'Détails additionnels',
    questions: 'Questions',
    automation: 'Automatisation',
    availability: 'Disponibilités',
    videoDetails: 'Détails du vidéo',
  },
  stepsNav: {
    back: 'Retourner',
    next: 'Suivant',
    submit: 'Créez un poste',
    update: 'Mettre à jour ce poste',
  },
  businessManagementTab: {
    business: 'Entreprise',
    availableJobs: 'Postes disponibles',
    createJob: 'Créez un poste',
    updatesJob: 'Mettre à jour ce poste',
    createMultipleJobs: 'Créez plusieurs postes',
    automation: 'Automatisation',
    businessAndPosition: 'Sites et postes',
  },
  jobDescriptionPlaceholder: 'Écrire une courte description du poste',
  interviewDescriptionPlaceholder: 'Écrire un message pour la personne interviewée',
  selectOccupationPlaceholder: "Choisir l'occupation",
  selectJobTypePlaceholder: 'Choisir le type de poste',
  to: 'à',
  video: {
    maxLengthError: 'La durée maximale permise du vidéo est {{length}} minute(s).',
    maxSizeError: 'La taille maximale permise du vidéo est {{size}} MB.',
  },
  selectFilterByType: `Choisir le type de filtre`,
  copyInfo: 'Vous pouvez modifier les autres détails du poste lorsque le poste est créé.',
  jobDetails: 'Détails du poste',
  copyPositionError: 'Le poste ne pouvait pas être copié en ce moment.',
  reviewPositionBtn: 'Réviser les détails du poste',
  wages: 'Salaire (montant)',
  salaryType: 'Salaire (unité)',
  noSpecificQuestions: `Il n'a pas de questions pour ce poste.`,
  noFreeFormQuestions: `Il n'a pas de questions pour ce poste.`,
  questionHeader: 'Questions',
  edit: 'Modifier le poste',
  copyPosition: 'Copier',
  dublicationInfo: 'Créer une copie des détails dans un nouveau poste',
  positionNamePlaceholder: 'poste',
  hireForm: "Formulaire d'embauche",
  scheduleMatch: 'Match des disponibilités',
  enabled: 'Activée',
  disabled: 'Désactivée',
  modernHire: 'Évaluation des compétences',
  modernHirePassThreshold: 'Seuil minimum de réussite',
  templateID: 'Identifiant du modèle',
  modernHireTemplateIdError: "L'identifiant ne peut pas être vide",
  modernHireThresholdValueNotEmptyError: 'Le seuil ne peut pas être vide',
  modernHireThresholdValueNotZeorError: 'Le seuil doit être supérieur à 0',
  languageThresholdValueEmptyError: 'Language evaluation pass threshold is required', // to be translated
  languageEvaluationTemplateError: "Un modèle d'évaluation de la langue est requis.",
  multipleChoiceQuestions: 'Questions à choix multiples',
  newQuestionHeader: 'Questions de préqualification',
  questionsHint: `Ajouter des questions que nous allons demander aux candidats pour soumettre leur candidature`,
  freeFormQuestions: 'Free Form Questions',
  freeform: 'Libre',
  specific: 'Oui/Non',
  multipleChoice: 'Choix multiple',
  pleaseSelect: 'Les candidats doivent choisir',
  bestOptions: 'options. Les réponses',
  of: 'de',
  done: 'Terminer',
  selectOptions: 'Choisir les options',
  hintText: `Les candidats seront demandés de choisir le bon nombre d'options.`,
  placeholderHint:
    'Si vous choisissez A et B comme options requises, toutes les réponses incluant A et B seront considérées correctes.',
  optionNotEmpty: 'Vous devez remplir toutes les options.',
  questionNotEmpty: 'Vous devez entrer une question.',
  optionsRequired: 'Vous devez choisir {{numberOfOptions}} options correctes.',
  multipleChoiceQuestion: {
    IS_EQUAL: 'doivent être exactement',
    HAS_ANSWER: 'doivent inclure',
    DOES_NOT_HAVE_ANSWER: 'ne doivent pas inclure',
    AT_LEAST: 'doivent inclure au moins',
  },
  addOption: 'Ajouter une option',
  enableLanguageEvaluation: "Activer l'évaluation de la langue",
  languageEvaluation: 'Évaluation de la langue',
  languageEvaluationPassThreshold: `Niveau minimum de réussite`,
  templateRequiredError: `Vous devez choisir au moins une phrase.`,
  languageAgentRequiredError: 'Vous devez choisir un agent anglais et un agent français.',
  selectLanguage: 'Choisir la langue',
  selectLanguageEvaluationPassThreshold: 'Seuil minimum de réussite',
  selectPassThreshold: 'Choisir le seuil minimum',
  phraseId: 'Phrase',
  selectPhrase: ' Choisir la phrase',
  english: 'Anglais',
  french: 'Français',
  enabledCertnIntegration: 'Activer Intégration de Certn',
  certnIntegration: 'Intégration de Certn',
  certnIntegrationSubtitle: 'Sélectionnez les vérifications des antécédents requis par défaut',
  virtualRecruiter: 'Virtuel Recruteur',
  noPhrasetext: 'Vous pouvez ajouter des phrases à partir de Administration/Configuration/Évaluation de la langue',
  deleteQuestionConfirmation: 'Êtes-vous sûr de vouloir supprimer la question ?',
  videoImageFormatFail: 'Votre vidéo doit être en format .mp4, .webm, .ogg ou .mov.',
  notSupportedInWebApply: 'Noter que les questions à choix multiples ne sont pas présentement supportées dans Web Apply.',
  enableDialogFlow: "Activer l'IA conversationnelle",
  selectEnglishAgent: "Choisir l'agent anglais",
  selectFrenchAgent: "Choisir l'agent français",
  dialogflow: 'IA conversationnelle',
  selectedDialogflowAgents: 'Agent(s) de IA conversationnelle sélectionné(s)',
  englishAgent: 'Agent anglais',
  frenchAgent: 'Agent français',
  videoGallery: {
    videoGallery: 'Galerie vidéo',
    videoGalleryInfo: 'Gérez les vidéos que vous avez téléchargées ici.',
    addVideo: 'Ajouter une vidéo',
    deleteVideo: 'Supprimer une vidéo',
    videoDeleteWarning: `Êtes-vous certain? Vous ne pouvez pas annuler cette action.`,
    cancel: 'Annuler',
    delete: 'Supprimer',
    videoDeleteSuccess: 'Vidéo supprimée avec succès.',
    videoDeleteErrorMessage: 'La vidéo ne pouvait pas être supprimée actuellement.',
    close: 'Fermer',
  },
  recruiterNotes: 'Note du recruteur',
  recruiterNotePlaceholder: 'Inscrivez vos notes ici.',
  resume: 'Curriculum vitae',
  next: 'Suivant',
  positionCreation: 'Création du poste',
  createBusinessPosition: 'Configurer les détails de votre poste.',
  smbEmploymentType: 'Employment Type',
  jobTypedesc: {
    fullTime: 'Temps plein',
    partTime: 'Temps partiel',
    seasonal: 'Seasonal',
  },
  errorPositionCreation: 'Erreur rencontrée lors de la création de la position',
  hireFormSelect: {
    dollaramaDefault: 'Dollarama_default',
    selectPlaceholder: 'Choisir un modèle de formulaire',
  },
  selectTemplate: 'Choisir un modèle',
  passThreshold: 'Choisir le seuil de passage',
};
