/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons/lib';
import { GiToken } from 'react-icons/gi';
import { AxiosError } from 'axios';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { confirmationModalHeaderCSS } from '../../messaging/seeker/header/actionsDropdown/ConfirmationModal.styles';
import colors from '../../../styles/colors';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { useTopCandidatesContext } from './TopCandidatesContext';

export type EngagedConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  positionId: string;
  seekerId: string;
  handleActionAfterEngage: (seekerId: string) => void;
};

export const EngagedConfirmationModal = ({
  isOpen,
  onClose,
  positionId,
  seekerId,
  handleActionAfterEngage,
}: EngagedConfirmationModalProps): JSX.Element => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { currentUserProfile } = useCurrentUserProfile();
  const { unlock } = useStoreActions((action) => action.positionManagement);
  const isModernHireEnable = useStoreState((s) => s.app.accounts?.configuration?.modernHireEnable);
  const { selectedTopCandidate } = useTopCandidatesContext();

  const handleEngageCandidate = async (pId: string, sId: string, recruiterId: string, modernHireEnabled?: boolean) => {
    try {
      setIsLoading(true);
      await unlock({
        positionId: pId,
        seekerId: sId,
        recruiterId,
        modernHireEnable: modernHireEnabled ?? false,
      });
      handleActionAfterEngage(seekerId);
      onClose();
    } catch (error) {
      let defaultMessage = t('businessManagement:topCandidate.engageErrorText');
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response?.status === 401) {
        defaultMessage = newError.response.data.message || defaultMessage;
      }
      toast({
        description: defaultMessage,
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateAppointmentRequest = async () => {
    if (!currentUserProfile) return;
    await handleEngageCandidate(positionId, seekerId, currentUserProfile.id, isModernHireEnable);
  };

  return (
    <Modal id="EngagedConfirmationModal" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader css={confirmationModalHeaderCSS} className="modal-header">
            <Flex direction="row" alignItems="center">
              <Flex alignItems="center" direction="column" justify="center" className="blue-circle-icon">
                <IconContext.Provider value={{ color: colors.white }}>
                  <GiToken size={14} />
                </IconContext.Provider>
              </Flex>
              <Box width={2} />
              {t('businessManagement:topCandidate.engageCandidateModal.header')}
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Text fontSize="xl" mb={2} mt={4}>
              {t('businessManagement:topCandidate.engageCandidateModal.bodyText', {
                name: selectedTopCandidate?.seeker.firstName,
              })}
            </Text>
            <Text fontSize="lg">{t('businessManagement:topCandidate.engageCandidateModal.useToken')}</Text>
          </ModalBody>
          <ModalFooter>
            <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={onClose} disabled={isLoading}>
              {t('messaging:actions.dismiss.confirmationModal.cancelButton')}
            </Button>
            <Button
              data-testid="ConfirmButton"
              colorScheme="blue"
              onClick={handleCreateAppointmentRequest}
              disabled={isLoading}
              isLoading={isLoading}
            >
              {t('businessManagement:topCandidate.engageCandidateModal.engage')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
