export default {
  notAuthorized: {
    notAuthorizedMsg: 'Not Authorized to view this page. Please contact your administration.',
    goToHomeBtn: ' Go Back To Home Page',
  },
  administrationSideBarMenuItems: {
    jobListingUrl: 'Job Listing URL ',
    hireFormTemplates: 'Hire Form',
    uploadCompanyLogo: 'Company Resources',
    companyManagement: 'Company Resources',
    certnIntegration: 'Certn Integration',
    positionManagement: 'Position Management',
    lowAvailability: 'Team Low Availability',
    recruiterManagement: 'Recruiter Management',
    appointmentCalendarLabel: 'Calendar',
    configuration: 'Configuration',
    unresponsivePeriod: 'Unresponsive Candidates Management',
    languageEvaluationPhrasesLabel: 'Language Evaluation',
    videoGallery: 'Video Gallery',
    webHookURL: 'Webhook Endpoint',
    imageGallery: 'Image Gallery',
    templates: 'Templates',
    fitbandConfiguration: 'Schedule Match Configuration',
  },
  calendar: {
    sidebarTitle: 'Recruiters',
    positionTitle: 'Positions',
    all: 'All',
    followed: 'Followed',
    sidebarRecruiterSearchPlaceholder: 'Search recruiters',
    sidebarPositionSearchPlaceholder: 'Search positions',
    sidebarPositionClearAll: 'Clear all',
    toolTipWarningMessage: `To view {{recruiterName}}'s interviews, you must uncheck another recruiter.`,
    toolTipWarningMessageSnackBar: `To view interviews, you must uncheck another recruiter.`,
    backBtnText: 'Back',
  },
  hireFormAPIKey: {
    label: 'API Keys',
    subDescription: 'Copy API key for hire form',
    copyKeyBtnLabel: 'Copy Key',
    generateNewAPIKey: 'Generate New API Key',
    copiedLabel: 'API Key copied',
    renewedSuccessMessage: 'New API key generated successfully',
    alertModal: {
      title: 'Generate New API Key',
      description:
        'This will generate a new API Key. This API Key will be used throughout your integration with AppyHere. Are you sure you want to proceed?',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    alertModalCopy: {
      title:
        'Copy and store this API Key in a secure location. You will no longer be able to access it after closing this window.',
      copyBtn: 'Copy API Key',
    },
  },
  modernHire: {
    sidebarTitle: 'Competency Evaluation',
    form: {
      userName: 'Username',
      password: 'Password',
      modernHireSubDomain: 'Modern Hire Sub Domain',
      submit: 'Submit',
    },
  },
  languageEvaluation: {
    cardTitle: 'Language Evaluation',
    selectLanguage: 'Select Language',
    french: 'French',
    english: 'English',
    phraseTitle: 'Phrase Title',
    phrase: 'Phrase',
    add: 'Add',
    update: 'Update',
    delete: 'Delete',
    deletePhraseTitle: 'Delete Phrase',
    deletePhraseDetail: 'This phrase will be permanently deleted. This action cannot be undone.',
    confirm: 'Confirm',
    cancel: 'Cancel',
    phraseLabelRequired: 'Phrase label is required',
    phraseDescriptionRequired: 'Phrase is required',
    template: 'Templates',
    phrases: 'Phrases',
    templateHeader: 'Language Evaluation Templates',
    showDefault: 'Show Default Templates',
    addNewTemplate: 'Create New Template',
    create: 'Create',
    duplicate: 'Duplicate',
    edit: 'Edit',
    name: 'Name',
    created: 'Created On',
    action: 'Actions',
    noData: 'No Data Found',
    systemDefault: '(Default System Template)',
    showingResult: 'Showing {{currentItems}} of {{totalItems}} templates',
    templateModal: {
      createTemplateTitle: 'Create New Language Evaluation Template',
      editTemplateTitle: 'Edit Language Evaluation Template',
      viewTemplate: 'View Language Evaluation Template',
      testSettings: 'I want to test this template',
      templateName: 'Name',
      templateNameError: 'Template Name is required.',
      enterTemplateName: 'Enter template name',
      language: 'Language',
      selectLanguage: 'Select language',
      languageError: 'Language is required.',
      phrases: 'Phrase(s)',
      selectPhrases: 'Select phrase(s)',
      note: 'Note:',
    },
    templateToastMessage: {
      nameExists: 'This Template Name already exists.',
      copyTemplateTitle: 'Template Duplicated',
      copyTemplateSuccess: 'A duplicate of {{templateName}} was created.',
      templateCreate: 'Template Created',
      templateCreateSuccess: 'The template {{templateName}} was created successfully.',
      templateEdit: 'Template Modified',
      templateEditSuccess: 'The template {{templateName}} was modified successfully.',
    },
    recording: {
      selectPhrase: 'Select Phrase',
      choosePhrase: 'Select Phrase',
      evaluateRecording: 'Test Your Template',
      startRecording: 'Start Recording',
      clickRecordButton: 'Click the button to begin recording',
      evaluate: 'Evaluate',
      allowMicrophoneAccess: 'You must allow microphone access to continue.',
      reRecord: 'Re-record',
      audioEvaluationTitle: 'Audio Evaluation',
      audioEvaluationSuccess: 'Audio Evaluation was successful.',
    },
    band: {
      expert: 'Expert',
      competent: 'Competent',
      limited: 'Limited',
      occasional: 'Minimal',
      expertTooltipText: `The candidate's speech is precise at all times. They can be effortlessly understood throughout.`,
      competentTooltipText: `The candidate is able to speak without much hesitation, repetition and/or self-correction. They can be generally understood without much effort.`,
      limitedTooltipText: `The candidate is unable to keep speaking without noticeable pauses. Their speech may be slow. Understanding them requires some effort, and there could be patches of speech that cannot be understood.`,
      occasionalText: `The candidate's speech is totally incoherent. They can produce occasional individual words and phrases that are recognizable. The majority of their speech cannot be understood.`,
    },
    bandFields: {
      accuracy: 'Accuracy',
      completeness: 'Completeness',
      confidence: 'Confidence',
      fluency: 'Fluency',
      pronunciation: 'Pronunciation',
    },
    evaluation: {
      expertText: 'This recording would be considered Expert-level per your template settings. Here was your detailed score:',
      competentText:
        'This recording would be considered Competent-level per your template settings. Here was your detailed score:',
      limitedText: 'This recording would be considered Limited-level per your template settings. Here was your detailed score:',
      occasionalText:
        'This recording would be considered Minimal-level per your template settings. Here was your detailed score:',
      defaultText: 'Here is the detailed score for this recording:',
    },
    noteLabel: {
      accuracy: "Evaluates how much the speaker's pronunciation matches a native speaker's.",
      completeness: 'Evaluates how much of the text the speaker says.',
      confidence: 'The confidence of the automated evaluation.',
      fluency: 'Evaluates how much the speaker uses silent breaks between words to match the speech of a native speaker.',
      pronunciation: "Evaluates how correct the speaker's pronunciation of words are.",
    },
  },
  fitbandConfiguration: {
    templateHeader: 'Schedule Match Templates',
    showDefaults: 'Show Default Templates',
    createNew: 'Create New Template',
    confirmChanges: 'Confirm Template Changes',
    areYouSureText:
      'Are you sure you want to save your changes? These changes will be applied to all positions using this template.',
    cancelBtn: 'Cancel',
    okBtn: 'OK',
    duplicate: 'Duplicate',
    edit: 'Edit',
    saveBtn: 'I understand',
    systemDefault: '(Default System Template)',
    noChanges: 'Unchanged',
    noDataFound: 'No Data Found',
    showingResult: 'Showing {{currentItems}} of {{totalItems}} templates',
    tableHeaders: {
      name: 'Name',
      createdOn: 'Created On',
      lastEdited: 'Last Edited',
      action: 'Actions',
    },
    fitbandModal: {
      viewFitband: 'Schedule Match Template',
      editFitband: 'Edit Schedule Match Template',
      createFitband: 'Create Schedule Match Template',
      templateName: 'Template Name',
      enterTemplateName: 'Enter template name',
      templateNameError: 'Template Name is required.',
      goodFit: 'Good',
      mediumFit: 'Medium',
      mediumFitError: 'Medium fit must be less than good fit.',
      penalty: 'Penalty Adjustments',
      requisition: 'Missing Availability',
      extraAvailability: 'Extra Availability',
      days: 'Days',
      hours: 'Hours',
      updateBtn: 'Update',
      createBtn: 'Create',
    },
    fitbandToastMessage: {
      nameExists: 'This Template Name already exists.',
      copyTemplateTitle: 'Template Duplicated',
      copyTemplateSuccess: 'A duplicate of {{templateName}} was created.',
      templateCreate: 'Template Created',
      templateCreateSuccess: '{{templateName}} was created successfully.',
      templateEdit: 'Template Modified',
      templateEditSuccess: '{{templateName}} was modified successfully.',
    },
    penaltyTooltipText:
      'You can adjust these values to determine how strictly candidates are scored based on your work availability needs.',
    requisitionText: 'Missing Availability: Penalizes candidates who are missing some of your required availability.',
    extraAvailabilityText: 'Extra Availability: Penalizes candidates who offer more availability than you need.',
  },
  webHookUrl: {
    title: 'Hired Candidate Webhook Endpoint',
    subtitle:
      "You can set the endpoint URL for the Hired Candidate API here. This endpoint is where we'll send a candidate's details once they're hired.",
    inputPlaceHolder: 'Enter URL Endpoint',
    successMsg: 'Webhook URL updated successfully.',
    errorMsg: 'Webhook URL could not be updated.',
    validUrl: 'Only valid URL formats are accepted.',
  },
  imageGallery: {
    loading: 'Please wait...',
    noImages: 'No images found.',
    uploadError: 'Image upload failed',
    imagePreviewTitle: 'Image Preview',
  },
  imageGalleryModal: {
    triggerButtonLabel: 'Upload Image',
    title: 'Image Gallery',
    subtitle: "Upload and manage this organization's images.",
    refreshButtonToolTip: 'Refresh gallery',
    uploadButtonLabel: 'Upload Image',
    uploadPreviewCancelButtonLabel: 'Cancel',
    uploadPreviewSaveButtonLabel: 'Save',
    loadFailedToastTitle: 'Unable to retrieve image gallery',
    loadFailedToastMessage: 'An error has occurred. Please try again.',
    invalidImageType: 'Invalid image type selected. Only PNG or JPG images are accepted.',
    invalidImageSize: 'Invalid image size. Only images up to 2MB in size are accepted.',
    sortAscending: 'Sort Ascending',
    sortDescending: 'Sort Descending',
    searchInputPlaceholder: 'Search',
    errorLoadingImages: 'Error loading image',
  },
  companyResources: {
    companyLogo: 'Company Logo',
    documentSettings: 'Hire Form Settings',
    disclaimerText: 'Disclaimer Text',
    disclaimerSubText:
      'This is a standard disclaimer template shown before a candidate begins their Hire Form. You may customize it to suit your needs. Ensure that all necessary legal and compliance details are included to maintain proper transparency with your candidates.',
    cancel: 'Cancel',
    update: 'Update',
    preview: 'Preview',
    devicePreview: 'Device Preview',
    successMessage: 'Settings updated successfully',
    errorMessage: 'There was an error while updating the settings. Please try again.',
    emptyDisclaimerMessage:
      'The disclaimer text cannot be empty. Ensure that a valid disclaimer text is provided before proceeding.',
    emptyConsentMessage: 'The consent text cannot be empty. Ensure that a valid consent text is provided before proceeding.',
    editorPlaceholderText: 'Add your disclaimer template text and any links here.',
    consentButtonPlaceholder: 'Confirmation Button Text',
  },
};
