import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Checkbox,
  Stack,
} from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../models/hooks';

interface LanguageReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function LanguageReviewModal({ isOpen, onClose, onConfirm }: LanguageReviewModalProps) {
  const { errors } = useStoreState((state) => state.hrFormTemplate);
  const { t } = useTranslation('hrFormTemplate');

  const [isConfirm, setIsConfirm] = React.useState(false);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      isCentered
      autoFocus={false}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent data-testid="languageReviewModal">
        <ModalHeader>{t('formBuilder.languageReviewModal.title')}</ModalHeader>
        <ModalBody>
          <Stack spacing={4}>
            <Text>{t('formBuilder.languageReviewModal.message')}</Text>
            <Checkbox
              onChange={(e) => setIsConfirm(e.target.checked)}
              checked={isConfirm}
              data-testid="language-review-modal-checkbox"
            >
              <Text fontSize="sm">{t('formBuilder.languageReviewModal.confirmText')}</Text>
            </Checkbox>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {t('formBuilder.cancel')}
          </Button>
          <Button
            colorScheme="green"
            variant="solid"
            onClick={(e) => {
              e.preventDefault();
              if (errors?.length > 0) {
                onClose();
              } else {
                onConfirm();
              }
            }}
            disabled={!isConfirm}
            data-testid="language-review-modal-confirm-button"
          >
            {t('formBuilder.languageReviewModal.confirmButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
