import { AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { SearchDataType } from '../modules/candidateSearch/CandidateSearchTypes';

export class CandidateSearchController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async searchCandidates(accountId: string, searchData: SearchDataType): Promise<AxiosResponse> {
    const path = `api/v1/account/${accountId}/candidate/lookup`;
    const body = searchData;
    return this.httpClient.post(path, body);
  }
}
