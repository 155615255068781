/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { Box, Tooltip, useToast } from '@chakra-ui/core';
import { BsBookmarkStar, BsBookmarkStarFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import some from 'lodash/some';
import { useStoreActions, useStoreState } from '../../../models/hooks';

import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { EngagedCandidate } from '../../../firebase/firestore/documents/candidate';

export type CandidateViewProps = {
  selectedCandidate: EngagedCandidate | undefined;
};

export const TopCandidateBookmark = ({ selectedCandidate }: CandidateViewProps): JSX.Element => {
  const { t } = useTranslation();
  const toast = useToast();
  const user = useStoreState((s) => s.app.user);
  const { currentUserProfile } = useCurrentUserProfile();

  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const { addSeekerToFavorite, removeSeekerFromFavorite } = useStoreActions((actions) => actions.account);

  const isUsersFavourite = some(user?.favouriteSeekers, {
    id: selectedCandidate?.seeker,
  });

  useEffect(() => {
    setIsFavorite(isUsersFavourite);
  }, [isUsersFavourite]);

  const addSeekerToFavoriteHandler = async () => {
    try {
      setDisabled(true);
      await addSeekerToFavorite({
        accountId: selectedCandidate?.account as string,
        userId: currentUserProfile?.id as string,
        seekerId: selectedCandidate?.seeker as string,
      });
      setIsFavorite(true);
    } finally {
      setDisabled(false);
      toast({
        title: t('notification:seekerHeader.actionSuccessFul'),
        description: t('notification:seekerHeader.addedToFavorite'),
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const removeSeekerFromFavoriteHandler = async () => {
    try {
      setDisabled(true);
      await removeSeekerFromFavorite({
        accountId: selectedCandidate?.account as string,
        userId: currentUserProfile?.id as string,
        seekerId: selectedCandidate?.seeker as string,
      });
    } finally {
      setDisabled(false);
      toast({
        title: t('notification:seekerHeader.actionSuccessFul'),
        description: t('notification:seekerHeader.removedFromFavorite'),
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const isUserDeactivated = selectedCandidate?.deactivated;

  return (
    <Box>
      {isFavorite ? (
        <Box
          as="button"
          disabled={isUserDeactivated || disabled}
          data-testid="UnFavoriteCandidate"
          onClick={removeSeekerFromFavoriteHandler}
        >
          <Tooltip
            hasArrow
            label={
              isUserDeactivated
                ? t('notification:seekerHeader.deactivatedFavoriteText')
                : t('notification:seekerHeader.unFavoriteTooltip')
            }
          >
            <span
              style={{
                background: '#FF6600',
                display: 'inline-block',
                padding: '4px',
                borderRadius: '50%',
                marginRight: '6px',
              }}
            >
              <BsBookmarkStarFill
                style={{ opacity: isUserDeactivated ? 0.5 : 1 }}
                color="#ffffff"
                width="1rem"
                size={14}
                cursor={isUserDeactivated || disabled ? 'no-drop' : 'pointer'}
              />
            </span>
          </Tooltip>
        </Box>
      ) : (
        <Box
          as="button"
          disabled={isUserDeactivated || disabled}
          data-testid="FavoriteCandidate"
          onClick={addSeekerToFavoriteHandler}
        >
          <Tooltip
            hasArrow
            label={
              isUserDeactivated
                ? t('notification:seekerHeader.deactivatedFavoriteText')
                : t('notification:seekerHeader.favoriteTooltip')
            }
          >
            <span
              style={{
                background: '#f3f3f3',
                display: 'inline-block',
                padding: '4px',
                borderRadius: '50%',
                marginRight: '6px',
              }}
            >
              <BsBookmarkStar
                style={{ opacity: isUserDeactivated ? 0.5 : 1 }}
                cursor={isUserDeactivated || disabled ? 'no-drop' : 'pointer'}
                width="1rem"
                size={14}
                color="#FF6600"
              />
            </span>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};
