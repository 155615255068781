import { Stack, HStack } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../styles/customTheme';
import FormBuilderSections from '../formElements/FormBuilderSections';
import TemplateActions from './TemplateActions';
import TemplateNameInput from './TemplateNameInput';
import FinalSignatureSwitch from './FinalSignatureSwitch';

export interface FormBuilderProps {}

export default function FormBuilder() {
  return (
    <Stack
      spacing={6}
      flex="1 0 50%"
      px={theme.space[4]}
      py={theme.space[6]}
      overflowY="auto"
      minHeight="0"
      height="100%"
      overflowX="hidden"
      marginLeft="0 !important"
    >
      {/* <Text fontSize="xl" fontWeight="bold">
        {t('formBuilder.title')}
      </Text> */}
      <FormBuilderActions />

      <FormBuilderSections />
    </Stack>
  );
}

function FormBuilderActions() {
  const [templateName, setTemplateName] = React.useState<string>('');
  const [needFinalSignature, setNeedFinalSignature] = React.useState<boolean>(false);

  return (
    <Stack spacing={5}>
      <HStack justify="space-between" align="center">
        <TemplateNameInput {...{ templateName, setTemplateName }} />
        <TemplateActions {...{ templateName, needFinalSignature }} />
      </HStack>
      <FinalSignatureSwitch {...{ needFinalSignature, setNeedFinalSignature }} />
    </Stack>
  );
}
