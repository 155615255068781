/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, memo, useState } from 'react';
import { Box, Flex, List, Text } from '@chakra-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ReactComponent as _PositionStateDot } from '../../../assets/img/position-state.svg';
import { getTranslatedValue } from '../../../utils/localizedString';
import { AppointmentType, AvailableBookingWithPosition } from '../../../firebase/firestore/documents/appointmentAvaiability';
import { BookingDetailView } from '../bookingDetail/BookingDetailView';

import { ReactComponent as _IconVideoCall } from '../../../assets/img/video-accepted.svg';
import { ReactComponent as _IconAudioCall } from '../../../assets/img/phone-accepted.svg';
import { ReactComponent as _IconPersonMeetCall } from '../../../assets/img/appointment-accepted.svg';
import { ReactComponent as VideocamSvg } from '../../../assets/img/videocam.svg';
import { ReactComponent as PhoneSvg } from '../../../assets/img/phone-flash.svg';
import { ReactComponent as PersonSvg } from '../../../assets/img/person.svg';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import colors from '../../../styles/colors';

const VideoImage = memo(_IconVideoCall);
const AudioImage = memo(_IconAudioCall);
const PersonMeetImage = memo(_IconPersonMeetCall);

export type ScheduleByDateProps = {
  listBooking: AvailableBookingWithPosition[];
};

export const ScheduleListView = ({ listBooking }: ScheduleByDateProps): JSX.Element => {
  const [selectedBookingDetail, setSelectedBookingDetail] = useState<AvailableBookingWithPosition>();
  const { i18n, t } = useTranslation();
  const [isBookingDetailOpen, setIsBookingDetailOpen] = useState<boolean>(false);
  const { currentUserProfile } = useCurrentUserProfile();
  const PositionStateDot = memo(_PositionStateDot);

  const showScheduleIcon = (type: string, isAdhoc?: boolean) => {
    if (isAdhoc) {
      if (type === AppointmentType.video) {
        return <VideocamSvg data-testid={`${type}-adhoc`} />;
      }
      if (type === AppointmentType.phone) {
        return <PhoneSvg data-testid={`${type}-adhoc`} />;
      }
      return <PersonSvg data-testid={`${type}-adhoc`} />;
    }

    if (type === AppointmentType.video) {
      return <VideoImage data-testid={type} />;
    }
    if (type === AppointmentType.phone) {
      return <AudioImage data-testid={type} />;
    }
    return <PersonMeetImage data-testid={type} />;
  };

  const loadBookingDetail = (bookingDetail: AvailableBookingWithPosition) => {
    setSelectedBookingDetail(bookingDetail);
    setIsBookingDetailOpen(true);
  };

  const sortedBookingList = listBooking.sort((a, b) => moment(a.booking.date).valueOf() - moment(b.booking.date).valueOf());

  return (
    <Fragment>
      <List p={4} spacing={3} className="schedule-list-wrapper" data-testid="scheduleList">
        {sortedBookingList.map((scheduleWithCandidateInformation) => {
          return (
            <Box
              className="schedule-box"
              style={{ cursor: 'context-menu' }}
              key={scheduleWithCandidateInformation.booking.id}
              data-testid={scheduleWithCandidateInformation.booking.id}
              onClick={() => loadBookingDetail(scheduleWithCandidateInformation)}
              bg="white"
              p={4}
              borderRadius={5}
              boxShadow="sm"
            >
              <Flex align="center">
                <Box className="icon-block">
                  {showScheduleIcon(
                    scheduleWithCandidateInformation.booking.type as AppointmentType,
                    scheduleWithCandidateInformation.candidate.appointment?.adhoc,
                  )}
                </Box>
                <Box ml="3">
                  <Text fontWeight="bold" fontSize="sm" mb={1} lineHeight={1}>
                    {getTranslatedValue(scheduleWithCandidateInformation.candidate.jobTitle, i18n.language)}
                  </Text>

                  <Text
                    d={currentUserProfile?.id === scheduleWithCandidateInformation.booking.recruiterId ? 'none' : 'block'}
                    className="recruiter-name"
                    color="blue.500"
                    fontSize="0.65rem"
                    fontWeight="normal"
                    mb={1}
                    lineHeight={1}
                  >
                    {scheduleWithCandidateInformation.booking.recruiterName}
                  </Text>
                  <Text
                    d={currentUserProfile?.id === scheduleWithCandidateInformation.booking.recruiterId ? 'block' : 'none'}
                    className="candidate-name"
                    color="orange.500"
                    fontSize="0.65rem"
                    fontWeight="normal"
                    mb={1}
                    lineHeight={1}
                  >
                    {`${scheduleWithCandidateInformation.candidate.firstName} ${scheduleWithCandidateInformation.candidate.lastName}`}
                  </Text>
                  <Text className="candidate-name" fontSize="0.65rem" fontWeight="normal" mb={1} lineHeight={1}>
                    {scheduleWithCandidateInformation.candidate.businessName}
                  </Text>
                  <Text fontSize="0.65rem" lineHeight={1}>
                    {
                      /* istanbul ignore next */
                      i18n.language === 'fr'
                        ? `${moment(scheduleWithCandidateInformation.booking.date).format('LT')} - ${moment(
                            scheduleWithCandidateInformation.booking.date,
                          )
                            .add(scheduleWithCandidateInformation.booking.duration, 'minutes')
                            .format('LT')}`
                        : `${moment(scheduleWithCandidateInformation.booking.date).format('hh:mm A')} - ${moment(
                            scheduleWithCandidateInformation.booking.date,
                          )
                            .add(scheduleWithCandidateInformation.booking.duration, 'minutes')
                            .format('hh:mm A')}`
                    }
                  </Text>
                  {scheduleWithCandidateInformation.candidate.dismissed && (
                    <Flex alignItems="center">
                      <PositionStateDot color={colors.red[600]} />

                      <Text
                        fontSize="xs"
                        color={colors.red[600]}
                        lineHeight="shorter"
                        pt={1}
                        maxW={{ base: '11rem', md: '12rem', lg: '18rem' }}
                      >
                        {t('candidateStatus:DISMISSED')}
                      </Text>
                    </Flex>
                  )}
                </Box>
              </Flex>
            </Box>
          );
        })}
      </List>
      {selectedBookingDetail && (
        <BookingDetailView
          isOpen={isBookingDetailOpen}
          onClose={
            // istanbul ignore next
            () => setIsBookingDetailOpen(false)
          }
          bookingDetail={selectedBookingDetail}
        />
      )}
    </Fragment>
  );
};
