import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

/**
 * Hook to show an exit confirmation prompt when leaving the current route.
 *
 * @param {boolean} initialBool - Whether the prompt should be enabled initially.
 * @param {string} customMessage - The custom message to display in the prompt.
 * @returns {[boolean, Dispatch<SetStateAction<boolean>>, () => boolean]} - State setter and manual trigger function.
 */
export default function useExitPrompt(
  initialBool: boolean,
  customMessage?: string,
): [boolean, Dispatch<SetStateAction<boolean>>, () => boolean] {
  const { t } = useTranslation('app');
  const [showExitPrompt, setShowExitPrompt] = useState<boolean>(initialBool);
  const location = useLocation(); // Get current path
  const history = useHistory(); // React Router v5 history
  const message = customMessage || t('exitPromptMessage');

  useEffect(() => {
    // Handle browser refresh/tab close
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (showExitPrompt) {
        event.preventDefault();
        return message;
      }
      return undefined;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Block navigation within the app
    const unblock = history.block((promptLocation) => {
      if (showExitPrompt && promptLocation?.location?.pathname !== location.pathname) {
        return window.confirm(message) ? undefined : false;
      }
      return undefined;
    });

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblock(); // Remove the history block
    };
  }, [showExitPrompt, location.pathname, message, history]);

  /**
   * Manually triggers the exit prompt.
   * @returns {boolean} - Whether the user confirmed the exit.
   */
  const triggerExitPrompt = (): boolean => {
    if (!showExitPrompt) return true; // If disabled, allow exit.
    return window.confirm(message);
  };

  return [showExitPrompt, setShowExitPrompt, triggerExitPrompt];
}
