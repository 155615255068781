import { Stack, theme, HStack, Button, InputGroup, Input, InputRightElement, Box, InputLeftElement } from '@chakra-ui/core';
import React from 'react';
import { IoArrowBackOutline, IoClose, IoSearch } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import ComponentTabs from './ComponentTabs';
import { useTranslation } from 'react-i18next';

interface FormComponentContainer {}

const FormComponentContainer = React.memo(() => {
  const history: any = useHistory();
  const { t } = useTranslation('hrFormTemplate');
  const [search, setSearch] = React.useState<string>('');

  return (
    <Box
      flex="0 0 25%"
      spacing={theme.space[4]}
      backgroundColor={theme.colors.white}
      pl={theme.space[4]}
      pb={theme.space[6]}
      minHeight="0"
      height="100%"
      overflowY="auto"
      overflowX="hidden"
      data-testid="form-component-container"
    >
      <Stack overflowY="auto" overflowX="hidden" minHeight="0" height="100%" pr={theme.space[4]} pt={theme.space[6]}>
        <HStack spacing={theme.space[4]}>
          <Button
            size="sm"
            variant="ghost"
            paddingX={1}
            borderRadius="50%"
            colorScheme="gray"
            onClick={() => history.goBack()}
            data-testid="back-button"
          >
            <IoArrowBackOutline fontSize={24} />
          </Button>
          <InputGroup variant="outline">
            <Input
              value={search}
              placeholder={t('formBuilder.componentSidebar.searchPlaceholder')}
              onChange={(e) => {
                const value = e.target.value;
                setSearch(value);
              }}
              data-testid="component-sidebar-search-input"
            />
            {search.length > 0 && (
              <InputRightElement
                paddingX={2}
                children={<IoClose cursor="pointer" size={16} color={theme.colors.gray[500]} onClick={() => setSearch('')} />}
              />
            )}

            <InputLeftElement pointerEvents="none" paddingX={2} children={<IoSearch size={16} color="#2D3748" />} />
          </InputGroup>
        </HStack>
        <ComponentTabs search={search} />
      </Stack>
    </Box>
  );
});

export default FormComponentContainer;
