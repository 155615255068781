import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Text,
  useClipboard,
  useToast,
} from '@chakra-ui/core';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import React, { memo, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import { css } from '@emotion/core';
import i18next from 'i18next';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
  updateObject,
} from '../../../../utils/FormErrorUtils';
import { ReactComponent as _IconCalendar } from '../../../../assets/img/icon-calendar-blue.svg';
import i18n from '../../../../locales/i18n';
import { useStoreActions } from '../../../../models/hooks';
import { reactDatePickerCSS } from '../../../calendar/scheduleSidebar/ReactDatePicker.Style';

const datePickerStyle = css`
  .react-datepicker-wrapper {
    display: block;
    width: 100%;
  }
`;
export type PositionLinkModalProps = {
  isOpen: boolean;
  onClose: () => void;
  positionId: string;
};
const IconCalendar = memo(_IconCalendar);

export type PositionLinkModal = {
  referrer?: string;
  source?: string;
  campaign?: string;
  medium?: string;
};
export const PositionLinkModal = ({ isOpen, onClose, positionId }: PositionLinkModalProps) => {
  // states
  const [expiryDate, setExpiryDate] = useState<Date | null>();
  const [initialState, setInitialState] = useState<PositionLinkModal>({
    referrer: '',
    source: '',
    campaign: '',
    medium: '',
  });
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [referralPriority, setReferralPriority] = useState<boolean>(false);
  const [deepLink, setDeepLink] = React.useState<string>('');

  // hooks
  const { t } = useTranslation('jobPosition');
  const { onCopy } = useClipboard(deepLink);
  const toast = useToast();

  useEffect(() => {
    if (deepLink !== '') {
      onCopy();
      /* istanbul ignore next */
      toast({
        title: t('jobPosition:copiedMessage'),
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deepLink]);
  const schema = Joi.object()
    .options({
      abortEarly: false,
    })
    .keys({
      loadMore: Joi.boolean().required(),
      referrer: Joi.string()
        .trim()
        .allow('')
        .optional()
        .max(50)
        .messages({
          'string.max': t('copyPositionLinkModal.referrerMaxLength'),
        }),
      campaign: Joi.string()
        .trim()
        .allow('')
        .optional()
        .max(100)
        .messages({
          'string.max': t('copyPositionLinkModal.campaignMaxLength'),
        }),
      source: Joi.string()
        .trim()
        .allow('')
        .optional()
        .max(100)
        .messages({
          'string.max': t('copyPositionLinkModal.sourceMaxLength'),
        }),

      medium: Joi.string()
        .trim()
        .allow('')
        .optional()
        .max(100)
        .messages({
          'string.max': t('copyPositionLinkModal.mediumMaxLength'),
        }),
    });
  // comment added
  const { getDeepLinkOfPosition } = useStoreActions((actions) => actions.positionManagement);
  const handleClick = () => {
    setLoadMore(!loadMore);
  };

  const handleReferalExpiryChange = () => {
    setReferralPriority(!referralPriority);
  };

  const CustomDatePickerInput = /* istanbul ignore next */ (
    props: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>,
  ) => (
    <ButtonGroup
      d="flex"
      bg="gray.150"
      className="customDatepicker"
      size="md"
      isAttached
      variant="outline"
      onClick={props.onClick}
      ref={ref}
    >
      <Button fontWeight="normal" mr="-px" borderColor="gray.150" style={{ width: '100%' }} justifyContent="flex-start">
        {props.value}
      </Button>
      <IconButton
        borderColor="gray.150"
        style={{ padding: '0.2rem' }}
        aria-label="calendar"
        icon={<IconCalendar width={20} height={20} />}
      />
    </ButtonGroup>
  );
  const formValidation = /* istanbul ignore next */ (formData: any, scheme: Joi.ObjectSchema) => {
    const { error } = scheme.validate(formData);
    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }
    setFormErrors([]);
    return true;
  };

  const handleClose = () => {
    setInitialState({
      referrer: '',
      source: '',
      campaign: '',
      medium: '',
    });
    setExpiryDate(null);
    setFormErrors([]);
    setLoadMore(false);
    setReferralPriority(false);
    onClose();
  };
  // istanbul ignore next
  const handlePositionLinkGenerator = async () => {
    const formData = { ...initialState, expiryDate, loadMore, referralPriority };
    const validate = formValidation({ ...initialState, loadMore }, schema);
    if (!validate) return;
    const response = await getDeepLinkOfPosition({
      positionId,
      priority: referralPriority,
      referrerId: formData.referrer !== '' ? formData.referrer : undefined,
      source: formData.source !== '' ? formData.source : undefined,
      medium: formData.medium !== '' ? formData.medium : undefined,
      campaign: formData.campaign !== '' ? formData.campaign : undefined,
      locale: i18next.language,
      priorityExpiry: formData.expiryDate !== null ? formData.expiryDate : undefined,
    });
    setDeepLink(response.link);
    await navigator.clipboard.writeText(response.link);
    handleClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    updateObject(initialState, event.target.id, event.target.value, false);
    setFormErrors(clearErrorMessage(formErrors, event.target.id));
  };
  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent minW="640px" pt={3}>
        <ModalBody>
          <Flex direction="column">
            <Heading as="h5" size="sm">
              {t('copyPositionLinkModal.sharePositionLink')}
            </Heading>
            <Text fontSize="14px">{t('copyPositionLinkModal.customizeLink')}</Text>
            <Heading as="h6" size="xs" mt={4}>
              {t('copyPositionLinkModal.referralLink')}
            </Heading>
            <Text fontSize="14px">{t('copyPositionLinkModal.autoGenerateOnClick')}</Text>
            <Divider mt={3} />

            <Flex data-testid="tracking-info" alignItems="center" style={{ cursor: 'pointer' }} onClick={handleClick}>
              <Heading as="h6" size="sm" mt={3}>
                {t('copyPositionLinkModal.trackingInfo')}
              </Heading>
              <Spacer />
              {loadMore ? <ChevronUpIcon mt={3} boxSize={6} /> : <ChevronDownIcon mt={3} boxSize={6} />}
            </Flex>
            {loadMore && (
              <>
                <Flex>
                  <Box width="50%" mr={6}>
                    <Flex direction="column">
                      <Checkbox mb={3} mt={3} isChecked={referralPriority} onChange={() => handleReferalExpiryChange()} mr={4}>
                        {t('copyPositionLinkModal.setReferralLink')}
                      </Checkbox>
                    </Flex>
                  </Box>
                  <Box w="50%">
                    {referralPriority && (
                      <Flex alignItems="center" fontSize="sm">
                        <FormControl isInvalid={hasErrorMessage(formErrors, 'expiryDate')}>
                          <FormLabel my="8px" fontWeight="bold">
                            <Heading as="h6" size="xs" mt={3}>
                              {t('createJobPosition:expiryDate')}
                            </Heading>
                          </FormLabel>
                          <Box css={reactDatePickerCSS} data-testid="datePicker">
                            <Box css={datePickerStyle}>
                              <DatePicker
                                placeholderText={t('managementOverview:hireOfferStat.selectDate')}
                                selected={expiryDate}
                                className="react-datepicker-input-fullwidth bold"
                                customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                                locale={i18n.language}
                                dateFormat="P"
                                minDate={moment().toDate()}
                                data-testid="expiryDate"
                                shouldCloseOnSelect
                                onChange={
                                  /* istanbul ignore next */ (date) => {
                                    setExpiryDate(date as Date);
                                    setFormErrors(clearErrorMessage(formErrors, 'expiryDate'));
                                  }
                                }
                              />
                            </Box>
                          </Box>
                          <FormErrorMessage>{getErrorMessage(formErrors, 'expiryDate')}</FormErrorMessage>
                        </FormControl>
                      </Flex>
                    )}
                  </Box>
                </Flex>
                <Flex>
                  <Box width="50%" mr={6}>
                    <FormControl isInvalid={hasErrorMessage(formErrors, 'referrer')}>
                      <FormLabel htmlFor="name">
                        <Heading as="h6" size="xs" mt={3}>
                          {t('copyPositionLinkModal.referrer')}
                        </Heading>
                      </FormLabel>
                      <Input
                        placeholder={t('copyPositionLinkModal.enterReferrer')}
                        data-testid="referrerField"
                        variant="filled"
                        id="referrer"
                        type="text"
                        value={initialState.referrer}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>{getErrorMessage(formErrors, 'referrer')}</FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box width="50%">
                    <FormControl isInvalid={hasErrorMessage(formErrors, 'source')}>
                      <FormLabel htmlFor="name">
                        <Heading as="h6" size="xs" mt={3}>
                          {t('copyPositionLinkModal.source')}
                        </Heading>
                      </FormLabel>
                      <Input
                        placeholder={t('copyPositionLinkModal.enterSource')}
                        data-testid="source"
                        variant="filled"
                        id="source"
                        type="text"
                        value={initialState.source}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>{getErrorMessage(formErrors, 'source')}</FormErrorMessage>
                    </FormControl>
                  </Box>
                </Flex>
                <Flex>
                  <Box width="50%" mr={6}>
                    <FormControl isInvalid={hasErrorMessage(formErrors, 'campaign')}>
                      <FormLabel htmlFor="name">
                        <Heading as="h6" size="xs" mt={3}>
                          {t('copyPositionLinkModal.campaign')}
                        </Heading>
                      </FormLabel>
                      <Input
                        placeholder={t('copyPositionLinkModal.enterCampaign')}
                        data-testid="campaign"
                        variant="filled"
                        id="campaign"
                        type="text"
                        value={initialState.campaign}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>{getErrorMessage(formErrors, 'campaign')}</FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box width="50%">
                    <FormControl isInvalid={hasErrorMessage(formErrors, 'medium')}>
                      <FormLabel htmlFor="name">
                        <Heading as="h6" size="xs" mt={3}>
                          {t('copyPositionLinkModal.medium')}
                        </Heading>
                      </FormLabel>
                      <Input
                        placeholder={t('copyPositionLinkModal.enterMedium')}
                        data-testid="mediumField"
                        variant="filled"
                        id="medium"
                        type="text"
                        value={initialState.medium}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>{getErrorMessage(formErrors, 'medium')}</FormErrorMessage>
                    </FormControl>
                  </Box>
                </Flex>
              </>
            )}
            <Divider mt={3} />
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="end" mb={2}>
          <Box>
            <Button colorScheme="red" mr={3} onClick={handleClose}>
              {t('openPositionModal.cancelModalButton')}
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => handlePositionLinkGenerator()}
              //   isLoading={isLoading}
              data-testid="copyPositionLinkBtn"
              alignSelf="right"
            >
              {t('copyPositionLinkModal.createAndCopy')}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
