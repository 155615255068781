import { Editable, EditablePreview, EditableInput, Input, FormControl, FormErrorMessage } from '@chakra-ui/core';
import { SchemaBuilderProps } from './formBuilderSchema';
import React from 'react';
import useErrorHandling from '../useErrorHandling';
import theme from '../../../../styles/customTheme';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
// import debounce from 'lodash/debounce';
interface LabelSchemaBuilderProps {
  value: string;
  onChange: (value: string) => void;
  path: (string | number)[];
  disabled?: boolean;
}

const LabelSchema = React.memo(
  ({ value, onChange, path, disabled = true }: LabelSchemaBuilderProps) => {
    const [inputValue, setInputValue] = React.useState(value);
    const { t } = useTranslation('hrFormTemplate');

    // const debouncedSetFormState = React.useCallback(debounce(onChange, 500), [onChange]);
    React.useEffect(() => {
      setInputValue(value);
    }, [value]);

    // React.useEffect(() => {
    //   // debouncedSetFormState(inputValue);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [inputValue]);

    const { validateError, error, hasError, inputRef } = useErrorHandling({
      path,
      valdationFor: 'label',
      onValidation: (v) => {
        setInputValue(v as string);
        return v;
      },
    });

    function handleInputChange(val: string) {
      validateError(val);
    }

    function handleInputBlur(val: string) {
      onChange(val);
    }

    const editablePreviewStyle: React.CSSProperties = {
      ...{
        fontWeight: 'normal',
        textDecoration: disabled ? 'none' : 'underline',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        cursor: disabled ? 'not-allowed' : 'text',
        opacity: disabled ? 0.6 : 1,
        color: hasError ? theme.colors.red[500] : '#4A5568',
      },
      ...(inputValue?.length && { color: hasError ? theme.colors.red[500] : theme.colors.gray[600] }),
    };

    return (
      <FormControl isInvalid={hasError}>
        <Editable
          variant="unstyled"
          value={inputValue}
          placeholder={t('formBuilder.addLabel')}
          fontSize="xs"
          width="fit-content"
          isDisabled={disabled}
          isPreviewFocusable={!disabled}
          onChange={handleInputChange}
          onSubmit={handleInputBlur}
          isTruncated
          data-testid="label-editable"
          selectAllOnFocus={false}
          size="xs"
          minW={'200px'}
          submitOnBlur
        >
          <EditablePreview width={'100%'} style={editablePreviewStyle} />
          <Input
            as={EditableInput}
            width={'100%'}
            isInvalid={hasError}
            fontSize="xs"
            variant="filled"
            size="sm"
            placeholder={t('formBuilder.addLabel')}
            style={{
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
              paddingLeft: '3px',
            }}
            ref={inputRef}
            data-testid="label-input"
            _placeholder={{
              color: theme.colors.gray[300],
            }}
          />
        </Editable>
        <FormErrorMessage mt={0} fontSize="xs">
          {t(error?.message!)}
        </FormErrorMessage>
      </FormControl>
    );
  },
  (p, n) => {
    return p.value === n.value && p.disabled === n.disabled && isEqual(p.path, n.path) && p.onChange === n.onChange;
  },
);

const LabelSchemaBuilder = React.memo(
  (props: Pick<SchemaBuilderProps, 'path' | 'language' | 'onChange' | 'defaultValue' | 'isAdvanced'>) => {
    const { defaultValue, onChange, language } = props;
    const value = defaultValue?.label?.[language];
    const path = [...props.path, 'label', language];
    const [isDisabled, setIsDisabled] = React.useState(true);

    const handleOnChange = (val: string) => {
      const updatedValue = { ...defaultValue, label: { ...defaultValue.label!, [language]: val } };

      if (language === 'en' && !Boolean(defaultValue?.label?.fr?.trim())) {
        updatedValue.label.fr = value;
      }
      if (language === 'fr' && !Boolean(defaultValue?.label?.en?.trim())) {
        updatedValue.label.en = value;
      }
      onChange(updatedValue);
    };

    React.useEffect(() => {
      const disabled = defaultValue.field.toLowerCase() !== defaultValue.inputType?.toLowerCase();
      setIsDisabled(disabled);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue.field]);

    if (props?.isAdvanced && !value) return null;

    return <LabelSchema value={value || ''} onChange={handleOnChange} path={path} disabled={props.isAdvanced || isDisabled} />;
  },
  (p, n) => {
    return (
      isEqual(p.defaultValue, n.defaultValue) && isEqual(p.path, n.path) && p.language === n.language && p.onChange === n.onChange
    );
  },
);

export default LabelSchemaBuilder;
