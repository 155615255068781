import React, { useContext } from 'react';
import { createContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FormBuilderSchema, Language, mapFormSchema, SectionSchema } from './formElements/formBuilderSchema';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { formBuilderSchemas } from './formBuilderSchemas/formBuilder.schema';
import { PATH_ADMINISTRATION_TEMPLATES, PATH_HIRE_FORM_EDIT } from '../../../routes/constants';
import useExitPrompt from '../companyManagement/useExitPrompt';

interface FormBuilderContext {
  formSchema: SectionSchema[];
  setFormSchema: React.Dispatch<React.SetStateAction<SectionSchema[]>>;
  languages: Language[];
  language: Language;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
  templateName: string;
  setTemplateName: React.Dispatch<React.SetStateAction<string>>;
  findIndexById: (id: string) => number;
  draggedElement: FormBuilderSchema | null;
  setDraggedElement: (element: FormBuilderSchema | null) => void;
  setShowExitPrompt: React.Dispatch<React.SetStateAction<boolean>>;
}

const HireFormContext = createContext<FormBuilderContext>({
  formSchema: [],
  setFormSchema: (o) => o,
  languages: ['en', 'fr'],
  language: 'en',
  setLanguage: (o) => o,
  templateName: '',
  setTemplateName: (o) => o,
  findIndexById: () => -1,
  draggedElement: null,
  setDraggedElement: (o) => o,
  setShowExitPrompt: () => {},
});

const HireFormProvider: React.FC = ({ children }) => {
  const languages: Language[] = ['en', 'fr'];
  const history = useHistory();
  const originalTemplate = React.useRef<SectionSchema[] | null>(null);
  const { getTemplateById, setCurrentTemplate, setErrors } = useStoreActions((state) => state.hrFormTemplate);
  const { currentTemplate } = useStoreState((state) => state.hrFormTemplate);
  const [formSchema, setFormSchema] = useState<SectionSchema[]>([{ section: 'section1', fields: [], id: uuidv4() }]);
  const [language, setLanguage] = useState<Language>(languages[0]);
  const [templateName, setTemplateName] = useState<string>('');
  const [draggedElement, setDraggedElement] = useState<FormBuilderSchema | null>(null);
  let templateId = useParams<{ templateId?: string }>()?.templateId ?? undefined;
  const isEdit = useRouteMatch(PATH_HIRE_FORM_EDIT);
  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';
  const initialSection = [{ section: 'section1', fields: [], id: uuidv4() }];

  React.useEffect(
    () => {
      setErrors([]);
    },
    // eslint-disable-next-line
    [],
  );
  const [, setShowExitPrompt] = useExitPrompt(false);
  React.useEffect(() => {
    const hasChanged = JSON.stringify(originalTemplate.current) !== JSON.stringify(formSchema);
    setShowExitPrompt(hasChanged);
  }, [formSchema, originalTemplate, setShowExitPrompt]);

  React.useEffect(() => {
    if (templateId) {
      getTemplateById({ account: accountId, id: templateId, history });
    } else {
      setCurrentTemplate(undefined);
      setFormSchema(initialSection);
      originalTemplate.current = initialSection;
    }
    // eslint-disable-next-line
  }, [templateId]);

  React.useEffect(() => {
    if (isEdit && currentTemplate?.type === 'default') {
      history.replace(PATH_ADMINISTRATION_TEMPLATES);
      return;
    }
    if (!currentTemplate?.questions?.length || !templateId) {
      setFormSchema(initialSection);
      originalTemplate.current = initialSection;
      return;
    }
    const grouped = mapFormSchema({
      currentTemplate: currentTemplate.questions,
      formBuilderSchemas,
    });

    setFormSchema(grouped);
    originalTemplate.current = grouped;
    // eslint-disable-next-line
  }, [currentTemplate, templateId]);
  function findIndexById(id: string) {
    return formSchema.flatMap((group) => group.fields).findIndex((group) => group.id === id);
  }

  function handleDraggedElement(type: FormBuilderSchema | null) {
    setDraggedElement(type);
  }

  return (
    <HireFormContext.Provider
      value={{
        formSchema,
        setFormSchema,
        languages,
        language,
        setLanguage,
        templateName,
        setTemplateName,
        findIndexById,
        draggedElement,
        setDraggedElement: handleDraggedElement,
        setShowExitPrompt,
      }}
    >
      {children}
    </HireFormContext.Provider>
  );
};

export { HireFormProvider, HireFormContext };

export const useHireFormContext = () => useContext(HireFormContext);
