import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  Badge,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Spinner,
  Accordion,
  Flex,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReactQuill from 'react-quill';
import { meetViewStyle } from '../meet/MeetVide.styles';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { CandidateInfoForGuest } from '../meet/MeetTokenResponse';
import { Experience } from '../messaging/seeker/candidate/profile/components/Experience';
import SeekerExperiencePresenter from '../messaging/seeker/candidate/profile/seekerExperiencePresenter';
import { CandidateAvailabilities } from '../messaging/seeker/candidate/profile/components/CandidateAvailabilities';
import colors from '../../styles/colors';
import { Label } from '../common';
import { phoneFormat } from '../../utils/PhoneUtils';
import { GuestInterviewRating } from './GuestInterviewRating';
import { getGradientBackground } from '../../utils/uiUtils';
import { CandidateNightAvailabilities } from '../messaging/seeker/candidate/profile/components/CandidateNightAvailabilities';
import { CollaboratorNotesView } from './CollaboratorNotesView';

enum AvailabilityTypes {
  DAY = 'Day',
  NIGHT = 'Night',
}

enum Tab {
  NOTES,
  CANDIDATE_INFO,
}

function InterviewCandidateInformation({
  candidateId,
  showReviewRating,
  setShowReviewRating,
  quitRoom,
  showCandidateAssessmentButton,
}: {
  candidateId: string;
  showReviewRating: boolean;
  setShowReviewRating: (isOpen: boolean) => void;
  quitRoom: () => void;
  // eslint-disable-next-line react/require-default-props
  showCandidateAssessmentButton?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const [candidateInfo, setCandidateInfo] = useState<CandidateInfoForGuest | undefined>();
  const [availabilityType, setAvailabilityType] = React.useState<AvailabilityTypes>(AvailabilityTypes.DAY);
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [isNoteSaving, setIsNoteSaving] = useState<boolean>(false);
  const [reloadNotes, setReloadNotes] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.CANDIDATE_INFO);
  const { getCandidateDataOfMeet } = useStoreActions((actions) => actions.meet);
  const { saveNote } = useStoreActions((actions) => actions.account);
  const accountId = useStoreState((actions) => actions.app.accounts?.id);
  const candidateData = candidateId.split('_');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [noteContent, setNoteContent] = useState('');

  const fetchCandidateInfo = useCallback(async () => {
    setIsLoading(true);
    await getCandidateDataOfMeet({ positionId: candidateData[0], seekerId: candidateData[1] })
      .then((response) => {
        setCandidateInfo(response as any);
      })
      .catch((err) => {
        console.log('error:', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchCandidateInfo();
  }, [fetchCandidateInfo]);

  const submitNote = async () => {
    setIsNoteSaving(true);
    if (accountId) {
      await saveNote({
        accountId,
        positionId: candidateData[0],
        seekerId: candidateData[1],
        note: noteContent,
      });
      setIsNoteSaving(false);
      setReloadNotes(true);
      setNoteContent('');
      onClose();
    }
  };

  return (
    <Box css={meetViewStyle}>
      <Box>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className="candidate-card-header"
          py={4}
          px={4}
          borderBottom="1px solid #E5E7EB"
        >
          <Badge colorScheme="blue" variant="subtle" textTransform="capitalize" px={3} py={1} borderRadius={2} color="blue.500">
            {t('common:candidateDetailsDrawer.details')}
          </Badge>
          <Box>
            <Button data-testid="add-note-btn" size="sm" colorScheme="blue" onClick={onOpen} mr={2}>
              {t('common:interviewCandidate.addNote')}
            </Button>
            {candidateInfo && showCandidateAssessmentButton && (
              <GuestInterviewRating
                candidateInfo={candidateInfo}
                showReviewRating={showReviewRating}
                setShowReviewRating={setShowReviewRating}
                quitRoom={quitRoom}
              />
            )}
          </Box>
        </Flex>
        <Box fontSize="sm">
          <Box p={4} className="position-detail">
            <Heading as="h4" fontSize="md" lineHeight={1.1} mb={2}>
              {t('common:candidateDetailsDrawer.positionDetails')}
            </Heading>
            {isloading ? (
              <Flex alignItems="center" justifyContent="center" minH="200px">
                <Spinner color="blue.500" />
              </Flex>
            ) : (
              <Box>
                <Text fontWeight={500}>{candidateInfo?.businessLocation}</Text>
                <Text fontWeight={600}>{candidateInfo?.position}</Text>
                <Text fontWeight={400}>{candidateInfo?.businessCompleteAddress}</Text>
              </Box>
            )}
            <Box position="relative" top={4} borderBottom="1px solid #E5E7EB" />
          </Box>
          <Box p={4} className="candidate-detail">
            <Flex borderBottom="1px solid #E5E7EB" alignItems="center" fontWeight="bold" fontSize="sm">
              <Box
                px={4}
                py={2}
                cursor="pointer"
                bg={activeTab === Tab.CANDIDATE_INFO ? 'blue.100' : 'transparent'}
                color={activeTab === Tab.CANDIDATE_INFO ? 'blue.500' : 'black'}
                onClick={() => setActiveTab(Tab.CANDIDATE_INFO)}
              >
                {t('common:candidateDetailsDrawer.candidateDetail')}
              </Box>
              <Box
                px={4}
                py={2}
                cursor="pointer"
                bg={activeTab === Tab.NOTES ? 'blue.100' : 'transparent'}
                color={activeTab === Tab.NOTES ? 'blue.500' : 'black'}
                onClick={() => {
                  setActiveTab(Tab.NOTES);
                  setReloadNotes(true);
                }}
              >
                Notes
              </Box>
            </Flex>
            {/* eslint-disable-next-line no-nested-ternary */}
            {activeTab === Tab.NOTES ? (
              <CollaboratorNotesView
                positionId={candidateData[0]}
                seekerId={candidateData[1]}
                reloadNotes={reloadNotes}
                setReloadNotes={setReloadNotes}
              />
            ) : isloading ? (
              <Flex alignItems="center" justifyContent="center" minH="200px">
                <Spinner color="blue.500" />
              </Flex>
            ) : (
              <Box pt={4}>
                <List spacing={2}>
                  <ListItem>
                    <Text fontWeight={700} color="gray.700">
                      {t('common:candidateDetailsDrawer.candidateName')}
                    </Text>
                    <Text fontWeight={400} color="gray.500">
                      {candidateInfo?.name}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text fontWeight={700} color="gray.700">
                      {t('common:candidateDetailsDrawer.email')}
                    </Text>
                    <Text fontWeight={400} color="gray.500">
                      {candidateInfo?.email}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text fontWeight={700} color="gray.700">
                      {t('common:candidateDetailsDrawer.phone')}
                    </Text>
                    <Text fontWeight={400} color="gray.500">
                      {phoneFormat(candidateInfo?.phone ?? '')}
                    </Text>
                  </ListItem>
                  {candidateInfo?.noticePeriod && candidateInfo?.noticePeriod.value && candidateInfo?.noticePeriod.value !== '' && (
                    <ListItem>
                      <Text fontWeight={700} color="gray.700">
                        {t('common:noticePeriod')}
                      </Text>
                      <Text fontWeight={400} color="gray.500">
                        {candidateInfo?.noticePeriod.value === '0'
                          ? t('common:immediately')
                          : `${candidateInfo?.noticePeriod.value} ${candidateInfo?.noticePeriod.unit}`}
                      </Text>
                    </ListItem>
                  )}
                  {candidateInfo?.earliestAvailabilityDate && (
                    <ListItem>
                      <Text fontWeight={700} color="gray.700">
                        {t('common:earliestAvailabilityDate')}
                      </Text>
                      <Text fontWeight={400} color="gray.500">
                        {moment(candidateInfo?.earliestAvailabilityDate).locale(i18n.language).format('ll')}
                      </Text>
                    </ListItem>
                  )}
                  <ListItem>
                    <Accordion allowToggle allowMultiple defaultIndex={[0, 1]} data-testid="Candidate-detail">
                      <AccordionItem style={{ border: 0 }}>
                        <AccordionButton
                          bgColor="#FFF"
                          py={2}
                          px={1}
                          _hover={{ outline: 'none', boxShadow: 'none' }}
                          _active={{ outline: 'none', boxShadow: 'none' }}
                          _focus={{ outline: 'none', boxShadow: 'none' }}
                          pl={0}
                          data-testid="experienceBtn"
                        >
                          <Box flex="1" textAlign="left">
                            <Text fontWeight="semibold" pb={1} fontSize=".95rem">
                              {t('profile.experience.title')}
                            </Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel
                          className="experience-wrapper"
                          pb={3}
                          px={0}
                          borderTopWidth="1px"
                          borderTopColor="#ededed"
                          borderTopStyle="solid"
                        >
                          {candidateInfo?.experience ? (
                            <Box border="1px solid" borderColor="gray.50">
                              {candidateInfo?.experience?.map((experience, index) => (
                                <Experience
                                  key={experience.id}
                                  experience={new SeekerExperiencePresenter(experience)}
                                  dataIndex={index}
                                  totalItems={candidateInfo?.experience.length}
                                  paddingTop={2}
                                  displayBusiness
                                />
                              ))}
                              {candidateInfo?.experience.length === 0 && (
                                <Label p={4} size="sm" color={colors.gray[500]} colorDarkMode={colors.gray['600']}>
                                  {t('profile.experience.none')}
                                </Label>
                              )}
                            </Box>
                          ) : (
                            <Label p={4} size="sm" color={colors.gray[500]} colorDarkMode={colors.gray['600']}>
                              {t('profile.experience.none')}
                            </Label>
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem style={{ border: 0 }}>
                        <AccordionButton
                          bgColor="#FFF"
                          py={2}
                          px={1}
                          _hover={{ outline: 'none', boxShadow: 'none' }}
                          _active={{ outline: 'none', boxShadow: 'none' }}
                          _focus={{ outline: 'none', boxShadow: 'none' }}
                          pl={0}
                          data-testid="availabilityBtn"
                        >
                          <Box flex="1" textAlign="left">
                            <Text fontWeight="semibold" pb={1} fontSize=".95rem">
                              {t('profile.availability.title')}
                            </Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={0} px={0} mb={3} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            border="1px solid #E5E7EB"
                            borderRadius="4px"
                            mb={2}
                            p={1}
                            position="relative"
                            width="100%"
                            height="40px"
                            background={getGradientBackground(availabilityType, '5')}
                            overflow="hidden"
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              fontWeight="700"
                              cursor="pointer"
                              flex="1"
                              mr="50px"
                              mt="8px"
                              onClick={/* istanbul ignore next */ () => setAvailabilityType(AvailabilityTypes.DAY)}
                              zIndex={1}
                              data-testid="dayBtn"
                              color={
                                availabilityType === AvailabilityTypes.DAY ? 'blue.500' : /* istanbul ignore next */ 'gray.800'
                              }
                            >
                              <Text fontSize="14px" fontWeight={700}>
                                {t('availability.day')}
                              </Text>
                            </Flex>
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              fontWeight="700"
                              cursor="pointer"
                              flex="1"
                              ml="50px"
                              mb="8px"
                              onClick={/* istanbul ignore next */ () => setAvailabilityType(AvailabilityTypes.NIGHT)}
                              zIndex={1}
                              data-testid="nightBtn"
                              color={
                                /* istanbul ignore next */ availabilityType === AvailabilityTypes.NIGHT ? 'blue.500' : 'gray.800'
                              }
                            >
                              <Text fontSize="14px" fontWeight={700}>
                                {t('availability.night')}
                              </Text>
                            </Flex>
                          </Flex>

                          {/* eslint-disable no-nested-ternary */}
                          {availabilityType === AvailabilityTypes.DAY && candidateInfo?.availability ? (
                            <CandidateAvailabilities candidateAvailabilities={candidateInfo.availability} />
                          ) : availabilityType === AvailabilityTypes.NIGHT &&
                            /* istanbul ignore next */ candidateInfo?.nightAvailabilities ? (
                            // eslint-disable-next-line react/jsx-indent
                            /* istanbul ignore next */ <CandidateNightAvailabilities
                              nightAvailabilities={candidateInfo?.nightAvailabilities}
                            />
                          ) : (
                            <Label
                              p={4}
                              size="sm"
                              color={colors.gray[500]}
                              colorDarkMode={colors.gray['600']}
                              border="1px solid #eceff1"
                            >
                              ---
                            </Label>
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </ListItem>
                </List>
              </Box>
            )}
          </Box>
          <Modal isCentered onClose={onClose} isOpen={isOpen} size="6xl" scrollBehavior="inside" motionPreset="slideInBottom">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t('common:interviewCandidate.addNote')}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box w="100%" mb="40px">
                  <ReactQuill
                    style={{ height: '45vh' }}
                    data-testid="quill-editor"
                    value={noteContent}
                    onChange={setNoteContent}
                  />
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  {t('common:videoInterviewReminder.close')}
                </Button>
                <Button
                  isLoading={isNoteSaving}
                  disabled={isNoteSaving}
                  colorScheme="blue"
                  size="md"
                  mr="4"
                  onClick={submitNote}
                  data-testid="save-note-button"
                >
                  {t('common:interviewCandidate.saveNote')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
}

export default InterviewCandidateInformation;
