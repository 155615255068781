/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Flex, Text } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { LockIcon } from '@chakra-ui/icons';
import { Fragment } from 'react';
import colors from '../../../../../../styles/colors';
import { chatMessageCSS } from './ChatMessage.styles';
import { AppointmentMessage } from '../appointmentMessage/AppointmentMessage';
import { InterviewConfigPresenter } from '../../../header/actionsDropdown/interviewConfigPresenter';
import { MessagePresenter } from '../messagePresenter';
import { CandidateHistoryActionName } from '../../../../../../firebase/firestore/documents/candidateAuditActionNames';
import { OfferMadeMessages } from '../offerMademessages/OfferMadeMessage';
import { DialogFlowPicture } from '../../../../../common/Picture/DialogFlowPicture';
import { UserPicture } from './UserProfile';
import { messageVariants } from './MessageVariants';

const MotionBox = motion.custom(Box);
export type ChatMessageProps = {
  message: MessagePresenter;
  interviewConfig: InterviewConfigPresenter | undefined;
  isMonetized: boolean;
};

const ChatMessage = ({ message, interviewConfig, isMonetized }: ChatMessageProps): JSX.Element => {
  const { t } = useTranslation('messaging');
  const textColor = message.isFromSeeker ? colors.black : colors.white;

  const onRenderChatBody = () => {
    if (message.isChatMessage) {
      return (
        <Box css={chatMessageCSS} data-testid="ChatMessageBox" className={messageVariants(message).className}>
          <Text data-testid="ChatMessageContent" px={3} py={1} color={textColor} lineHeight="shorter">
            {!isMonetized && message.isFromSeeker ? (
              <Fragment>
                {t('candidateLocked')}
                <LockIcon marginLeft={3} marginBottom={1} />
              </Fragment>
            ) : (
              message.content
            )}
          </Text>
        </Box>
      );
    }
    if (
      message.action === CandidateHistoryActionName.CANDIDATE_FORM_HELP_REQUESTED ||
      message.action === CandidateHistoryActionName.OFFER_MADE ||
      message.action === CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER ||
      message.action === CandidateHistoryActionName.OFFER_ACCEPETED_BY_SEEKER ||
      message.action === CandidateHistoryActionName.OFFER_REVOKE ||
      message.action === CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED ||
      message.action === CandidateHistoryActionName.OFFER_REJECTED_BY_RECRUITER ||
      message.action === CandidateHistoryActionName.HIRE_FORM_REQUESTED ||
      message.action === CandidateHistoryActionName.HIRE_FORM_IN_PROGRESS ||
      message.action === CandidateHistoryActionName.HIRE_FORM_COMPLETED ||
      message.action === CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_REQUESTED ||
      message.action === CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_IN_PROGRESS ||
      message.action === CandidateHistoryActionName.CANDIDATE_REJECTED ||
      message.action === CandidateHistoryActionName.CANDIDATE_UNHIRED ||
      message.action === CandidateHistoryActionName.CANDIDATE_APPROVED ||
      message.action === CandidateHistoryActionName.OFFBOARDED ||
      message.action === CandidateHistoryActionName.CANDIDATE_DISMISSED
    ) {
      return <OfferMadeMessages message={message} />;
    }
    return <AppointmentMessage message={message} interviewConfig={interviewConfig} data-testid="AppointmentMessageBox" />;
  };

  return (
    <MotionBox data-testid="ChatMessage" variants={messageVariants(message).variant} mt={message.hasMetaContent ? 5 : 1}>
      {message.hasMetaContent && (
        <Flex justifyContent={messageVariants(message).justify} alignItems="center" mb={1}>
          {message.showPicture &&
            ((message.action === CandidateHistoryActionName.OFFBOARDED ? true : message.automation) ||
            message.dialogflow ||
            message.action === CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED ? (
              <DialogFlowPicture
                data-testid="UserPicturDialogFlow"
                size="sm"
                style={{ backgroundColor: '#ECEFF1' }}
                bg="transparent"
                p="2px"
                order={2}
              />
            ) : (
              <UserPicture
                data-testid="UserPicture"
                size="sm"
                style={messageVariants(message).userPictureStyle}
                name={message.isFromSeeker ? message?.candidateFullName : message?.recruiterFullName}
                bg="transparent"
                message={message}
                order={2}
              />
            ))}
          <Box textAlign="right" id={message.id} order={messageVariants(message).order} mx={2}>
            {message.dateString && (
              <Text data-testid="DateHeader" fontSize="xs">
                {message.dateString}
              </Text>
            )}
            {message.showPicture && !message.isFromSeeker && !message.automation && (
              <Text data-testid="RecruiterFullNameHeader" fontSize="xs">
                {message.recruiterFullName}
              </Text>
            )}
          </Box>
        </Flex>
      )}
      <Flex justifyContent={messageVariants(message).justify}>{onRenderChatBody()}</Flex>
    </MotionBox>
  );
};

export default ChatMessage;
