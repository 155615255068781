import { formBuilderType } from '../formElements/formBuilderSchema';

const mobilePreviewSchema = {
  [formBuilderType.text]: {
    answer: 'Answer',
  },
  [formBuilderType.email]: {
    answer: 'email@example.com',
  },
  [formBuilderType.phone]: {
    answer: '+1 (234) 567-8901',
  },
  [formBuilderType.date]: {
    answer: '01-01-2022',
  },
  [formBuilderType.time]: {
    answer: '05:45 AM',
  },
  [formBuilderType.link]: {
    answer: null,
  },
  [formBuilderType.number]: {
    answer: '123456',
  },
  [formBuilderType.address]: {
    answer: '123 Main St, Anytown, USA',
  },
  [formBuilderType.checkbox]: {
    answer: null,
  },
  [formBuilderType.gender]: {
    answer: null,
  },
  [formBuilderType.radio]: {
    answer: null,
  },
  [formBuilderType.image]: {
    answer: null,
  },
  [formBuilderType.file]: {
    answer: null,
  },
  [formBuilderType.yesNo]: {
    answer: 'Yes',
  },
  [formBuilderType.documentReview]: {
    answer: null,
  },
  [formBuilderType.singleCheckbox]: {
    answer: null,
  },
  [formBuilderType.button]: {
    answer: null,
  },
  [formBuilderType.bankAccountNumber]: {
    answer: '1234567890',
  },
  [formBuilderType.routingNumber]: {
    answer: '1234567890',
  },
  [formBuilderType.branchTransitNumber]: {
    answer: '1234567890',
  },
  [formBuilderType.financialInstitutionNumber]: {
    answer: '1234567890',
  },
  [formBuilderType.correspondenceLanguage]: {
    answer: null,
  },
  [formBuilderType.sin]: {
    answer: '223-08-4452',
  },
  [formBuilderType.ssn]: {
    answer: '223-08-4452',
  },
  signature: undefined,
  section: undefined,
  documentPreview: undefined,
  [formBuilderType.canadianBank]: undefined,
  [formBuilderType.americanBank]: undefined,
  [formBuilderType.emergencyContact]: undefined,
  [formBuilderType.miscellaneous]: undefined,
  earliestStartDate: undefined,
  addressValidator: undefined,
  confirmation: undefined,
  info: undefined,
  [formBuilderType.checkboxDisable]: undefined,
  sinAdvanced: undefined,
  ssnAdvanced: undefined,
  signatureAdvanced: undefined,
  [formBuilderType.fullName]: undefined,
};

export default mobilePreviewSchema;
