import { AxiosResponse } from 'axios';
import _omit from 'lodash/omit';
import {
  setPositionOverviewVideoRef,
  setPositionOverviewThumbnailRef,
  getPositionOverviewVideoRef,
  findPositionOverviewVideoThumbnailRef,
} from '../firebase/firestore/storage';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import {
  DeepLinkOfPositionType,
  JobPosition,
  PositionListQueryParams,
  PositionStatus,
} from '../modules/administration/positionManagement/PositionType';
import { TopCandidateBodyDataType } from '../modules/administration/topCandidates/TopCandidateDataType';
import { AutomationTypes } from '../modules/administration/positionManagement/createJobs/AutomationTypes';
import { CandidateListType, SearchAndSortTypes } from '../modules/messaging/sidebar/filters/seekersFiltersContextValueType';
import { Position } from '../firebase/firestore/documents/position';
import { CandidateSearchResponse } from './candidateController';

export class PositionManagementController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async getIndustryList(): Promise<AxiosResponse> {
    const path = `/api/v1/industries`;
    return this.httpClient.get(path);
  }

  async getJobList(): Promise<AxiosResponse> {
    const path = `/api/v1/jobs`;
    return this.httpClient.get(path);
  }

  async getOccupationList(): Promise<AxiosResponse> {
    const path = `/api/v1/occupations`;
    return this.httpClient.get(path);
  }

  async createJobPosition(accountId: string, jobPositionData: JobPosition): Promise<AxiosResponse> {
    const { proposedSalary } = jobPositionData;
    /* istanbul ignore next */
    const valueCentSalary = {
      ...proposedSalary,
      minValue: proposedSalary.minValue ? parseFloat(proposedSalary.minValue.toString()) * 100 : null,
      maxValue: proposedSalary.maxValue ? parseFloat(proposedSalary.maxValue.toString()) * 100 : null,
    };
    const changedJobPositionData = { ...jobPositionData, proposedSalary: valueCentSalary };
    const path = `/api/v1/accounts/${accountId}/businesses/${jobPositionData.business}/position`;
    const body = _omit(changedJobPositionData, [
      'industry',
      'occupation',
      'business',
      'businessName',
      'businessAddress',
      'videoUrl',
      'videoUrlThumbnail',
    ]);
    return this.httpClient.post(path, body);
  }

  async copyJobPosition(jobPositionToCopy: JobPosition): Promise<AxiosResponse> {
    const { proposedSalary } = jobPositionToCopy;
    /* istanbul ignore next */
    const valueCentSalary = {
      ...proposedSalary,
      minValue: proposedSalary.minValue ? parseFloat(proposedSalary.minValue.toString()) * 100 : null,
      maxValue: proposedSalary.maxValue ? parseFloat(proposedSalary.maxValue.toString()) * 100 : null,
    };

    const updatedJobPositionToCopy = _omit(jobPositionToCopy, [
      'industry',
      'occupation',
      'businessAddress',
      'videoUrl',
      'videoUrlThumbnail',
      'jobCustomName',
      'jobDescription',
    ]);
    const changedJobPositionData = { ...updatedJobPositionToCopy, proposedSalary: valueCentSalary };
    const path = `/api/v1/position/copy`;
    return this.httpClient.post(path, changedJobPositionData);
  }

  async getPositionDetail(accountId: string, businessId: string, positionId: string): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/business/${businessId}/position/${positionId}`;
    return this.httpClient.get(path);
  }

  async getDeepLinkOfPosition(payload: DeepLinkOfPositionType): Promise<AxiosResponse> {
    const body = {
      metaData: {
        position: payload.positionId,
        priority: payload.priority,
        referrerId: payload?.referrerId,
        source: payload?.source,
        medium: payload?.medium,
        campaign: payload?.campaign,
        locale: payload?.locale,
        priorityExpiry: payload?.priorityExpiry,
      },
      type: 'employer_shared_position',
    };
    const path = `/api/v1/dynamicLinks/position/${payload?.positionId}/locale/${payload?.locale}`;
    return this.httpClient.post(path, body);
  }

  async updateJobPosition(accountId: string, jobPositionData: JobPosition, positionId: string): Promise<AxiosResponse> {
    const { proposedSalary } = jobPositionData;
    /* istanbul ignore next */
    const valueCentSalary = {
      ...proposedSalary,
      minValue: proposedSalary.minValue ? parseFloat(proposedSalary.minValue.toString()) * 100 : null,
      maxValue: proposedSalary.maxValue ? parseFloat(proposedSalary.maxValue.toString()) * 100 : null,
    };
    const changedJobPositionData = { ...jobPositionData, proposedSalary: valueCentSalary };
    const path = `/api/v1/account/${accountId}/business/${jobPositionData.business}/position/${positionId}`;
    const body = _omit(changedJobPositionData, [
      'industry',
      'occupation',
      'business',
      'businessName',
      'businessAddress',
      'videoUrl',
      'videoUrlThumbnail',
    ]);
    return this.httpClient.put(path, body);
  }

  async updatePositionStatus(
    accountId: string,
    businessId: string,
    positionId: string,
    positionStatus: PositionStatus,
    doCancellAppointmentHireOrOffer: boolean | undefined,
  ): Promise<AxiosResponse> {
    const path = `api/v1/position/${positionId}/setState`;
    const body = {
      open: positionStatus.open,
      doCancellAppointmentHireOrOffer: Boolean(doCancellAppointmentHireOrOffer),
    };
    return this.httpClient.post(path, body);
  }

  async updateArchivePosition(positionId: string): Promise<AxiosResponse> {
    const path = `/api/v1/position/${positionId}/archive`;
    return this.httpClient.put(path);
  }

  async updateUnArchivePosition(positionId: string): Promise<AxiosResponse> {
    const path = `/api/v1/position/${positionId}/unarchive`;
    return this.httpClient.put(path);
  }

  async updateNoOfOpenings(
    accountId: string,
    businessId: string,
    positionId: string,
    noOfOpenings: number,
  ): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/business/${businessId}/position/${positionId}/updateNoOfOpenings`;
    const body = {
      noOfOpenings,
    };
    return this.httpClient.put(path, body);
  }

  async updatePositionByFollow(positionId: string): Promise<AxiosResponse> {
    const path = `api/v1/position/${positionId}/assignedRecruiters`;
    return this.httpClient.post(path);
  }

  async updatePositionByUnFollow(positionId: string, recruiterId: string): Promise<AxiosResponse> {
    const path = `api/v1/position/${positionId}/assignedRecruiters/${recruiterId}`;
    return this.httpClient.delete(path);
  }

  async getPositionList(accountId: string, businessId: string, params: PositionListQueryParams): Promise<AxiosResponse> {
    const path = `/api/v2/account/${accountId}/business/${businessId}/positions?positionStatus=${params.positionStatus}&followedOnly=${params.followedOnly}&sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&page=${params.page}&limit=${params.limit}&searchTerm=${params.searchTerm}&archived=${params.archive}`;
    return this.httpClient.get(path);
  }

  async getRecruiterProfilePicture(accountId: string): Promise<string> {
    const path = `api/v1/user/${accountId}/profilePicture?timeStamp=${new Date().getTime()}`;

    const { data } = await this.httpClient.get<ArrayBuffer>(path, { responseType: 'arraybuffer' });

    const bufferArray = new Uint8Array(data);
    /* istanbul ignore next */
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const stringBuffer = [].reduce.call(new Uint8Array(bufferArray), (p, c) => p + String.fromCharCode(c), '') as string;
    return `data:image/*;base64,${btoa(stringBuffer)}`;
  }

  async uploadOverviewVideo(userId: string, positionId: string, video: File): Promise<void> {
    await setPositionOverviewVideoRef(userId, positionId, video);
  }

  async uploadOverviewThumbnail(userId: string, positionId: string, thumbnail: File): Promise<void> {
    await setPositionOverviewThumbnailRef(userId, positionId, thumbnail);
  }

  async uploadOverviewVideoConfirmCall(positionId: string): Promise<AxiosResponse> {
    const path = `/api/v1/position/${positionId}/addOverviewVideo`;
    return this.httpClient.post(path);
  }

  async getOverviewVideo(accountId: string, positionId: string): Promise<string> {
    const videoLink = await getPositionOverviewVideoRef(accountId, positionId);
    return videoLink;
  }

  async getOverviewThumbnail(accountId: string, positionId: string): Promise<string> {
    const thumbnailLink = await findPositionOverviewVideoThumbnailRef(accountId, positionId);
    return thumbnailLink;
  }

  async removeOverviewVideo(positionId: string): Promise<AxiosResponse> {
    const path = `/api/v1/position/${positionId}/deleteOverviewVideo`;
    return this.httpClient.post(path);
  }

  async getTopCandidatesOfPosition(positionId: string, topCandidateData: TopCandidateBodyDataType): Promise<AxiosResponse> {
    const body = topCandidateData;
    const path = `/api/v1/position/${positionId}/seeker/searchTopCandidates`;
    return this.httpClient.post(path, body);
  }

  async updateContactRequestNoteOfPosition(positionId: string, seekerId: string, recruiterNotes: string): Promise<AxiosResponse> {
    const body = { recruiterNotes };
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/sendContactRequestWithNotes`;
    return this.httpClient.post(path, body);
  }

  async unlock(positionId: string, seekerId: string, recruiterId: string, modernHireEnable: boolean): Promise<AxiosResponse> {
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/unlock`;
    const body = { recruiterId, modernHireEnable };
    return this.httpClient.post(path, body);
  }

  async getShiftIntersection(positionId: string, seekerId: string, requisitionId: string): Promise<AxiosResponse> {
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/shiftintersection`;
    const body = {
      positionId,
      requisitionId,
    };
    return this.httpClient.post(path, body);
  }

  // istanbul ignore next
  async setPositionAutomation(
    positionId: string,
    automationType: AutomationTypes,
    virtualRecruiterEnabled?: boolean,
    candidateScreenTarget?: number,
  ): Promise<AxiosResponse> {
    const path = `/api/v1/position/${positionId}/automationLevel`;
    let body;
    if (virtualRecruiterEnabled) {
      body = { automationType, candidateScreenTarget, virtualRecruiterEnabled };
    } else if (AutomationTypes.SCREENING === automationType) {
      body = { automationType, candidateScreenTarget };
    } else {
      body = { automationType };
    }

    return this.httpClient.put(path, body);
  }

  // istanbul ignore next
  async getPositionRequisition(positionId: string): Promise<AxiosResponse> {
    const path = `api/v1/positions/${positionId}/requisitions`;
    return this.httpClient.get(path);
  }

  // istanbul ignore next
  getPositionsWithCandidates(
    businessId: string,
    filters: {
      sortBy: 'name' | 'createdAt';
      sortOrder: 'asc' | 'desc';
      searchQuery?: string;
      listType?: CandidateListType;
      advancedFilters: SearchAndSortTypes;
    },
  ): Promise<
    AxiosResponse<
      (Position & {
        candidates: CandidateSearchResponse['data'];
      })[]
    >
  > {
    const path = `/api/v1/position/business/${businessId}/positionsWithCandidate`;
    return this.httpClient.post(path, filters);
  }
}
