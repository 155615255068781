export type LanguageEvaluationType = {
  id?: string;
  templateName: string;
  language?: string;
  languageEvaluationTemplates?: {
    id: string;
    language: string;
    phrase: string;
    phraseId: string;
  }[];
};

export interface TemplateList {
  languageEvaluationTemplates: any;
  firstId?: string;
  lastId?: string;
  hasNext?: boolean;
  hasPrevious?: boolean;
  count?: number;
  data: LanguageEvaluationTemplateResponseType[];
}

export type LanguageEvaluationTemplateResponseType = {
  id: string;
  createdAt?: Date;
  default?: boolean;
  templateName: string;
  recruiters?: string[];
  affectedPositions?: number;
  language: string;
  phraseIds?: string[];
  bands?: {
    [key: string]: Band;
  };
  languageEvaluationTemplates?: {
    id: string;
    language: string;
    phrase: string;
    phraseId: string;
  }[];
  actionType?: ActionEnum;
};

export type LanguageEvaluationTemplateDataType = {
  accountId?: string;
  templateName?: string;
  language?: string;
  id: string;
  affectedPositions?: number;
  phraseIds?: string[] | undefined;
  createdAt?: Date;
  modifiedAt?: Date;
  recruiters?: string[];
  bands?: {
    [key: string]: Band;
  };
  default?: boolean;
  actionType?: ActionEnum;
};

export type Band = {
  accuracy: number;
  completeness: number;
  confidence: number;
  fluency: number;
  pronunciation: number;
};

export enum ActionEnum {
  DUPLICATE = 'Duplicate',
  EDIT = 'Edit',
  DELETE = 'Delete',
}

export type AudioDataType = {
  language: string;
  phrase: string | null;
  mimeType: string;
  sampleRate: number;
  channels: number;
  bitsPerSample: number;
  recordingFile: string;
};

export type AudioResponseType = {
  comparisonResult: Band;
  status?: string;
};

export enum BandHeaders {
  EXPERT = 'EXPERT',
  COMPETENT = 'COMPETENT',
  LIMITED = 'LIMITED',
  OCCASIONAL = 'OCCASIONAL',
}
