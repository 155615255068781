import React, { useCallback, useEffect } from 'react';
import { Box, List, Text, HStack, Center, RadioGroup, Radio, Spinner } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { ProductType } from '../TokenTypes';
import { useStoreActions } from '../../../models/hooks';

export type TokenProps = {
  onTokenSelect: (token: ProductType, isSelected: boolean) => void;
};

export const TokenList = ({ onTokenSelect }: TokenProps): JSX.Element => {
  const { t } = useTranslation('tokenPayment');
  const [selectedProduct, setSelectedProduct] = React.useState<string>('');
  const [productsData, setProductsData] = React.useState<ProductType[]>([]);
  const [loadingToken, setLoadingToken] = React.useState<boolean>(true);

  const { getProducts } = useStoreActions((actions) => actions.product);

  // function that fetches tokens/products
  const fetchProducts = useCallback(async () => {
    const productsResponse = await getProducts(null);
    setLoadingToken(false);

    setProductsData(productsResponse.productList);
  }, [getProducts]);

  useEffect(() => {
    fetchProducts().catch((err) => console.log(err));
  }, [fetchProducts]);

  // istanbul ignore next
  const handleRadioChange = (product: ProductType) => {
    setSelectedProduct(product.productId);
    onTokenSelect(product, true);
  };

  return (
    <Box paddingTop="10px">
      <RadioGroup value={selectedProduct}>
        <HStack justify="space-around" spacing="16px" flexWrap="wrap">
          {productsData.map((product) => {
            const isSelected = product.productId;
            return (
              <List key={product.productId}>
                <Box
                  py="16px"
                  px="28px"
                  borderWidth="0.5px"
                  borderColor={selectedProduct === isSelected ? /* istanbul ignore next */ 'blue.500' : 'E2E8F0'}
                  borderRadius="10px"
                  boxShadow="md"
                  position="relative"
                  cursor="pointer"
                  onClick={
                    /* istanbul ignore next */ () => {
                      handleRadioChange(product);
                    }
                  }
                >
                  {product.isRecommended === true && (
                    /* istanbul ignore next */
                    <Center>
                      <Box position="absolute" top={-3} backgroundColor="blue.500" borderRadius="16px">
                        <Text color="white" fontWeight="bold" fontSize="10px" py={1} px={3}>
                          {t('recommend')}
                        </Text>
                      </Box>
                    </Center>
                  )}
                  <Radio value={product.productId} position="absolute" right={3} />
                  <Center>
                    <Text
                      fontSize="32px"
                      fontWeight={700}
                      color={selectedProduct === isSelected ? /* istanbul ignore next */ 'blue.500' : 'black.500'}
                    >
                      {product.credit}
                    </Text>
                  </Center>
                  <Center>
                    <Text fontSize="14px" fontWeight={400} color="#A0AEC0">
                      {t('credits')}
                    </Text>
                  </Center>
                  <Center paddingTop="2rem">
                    <Text fontSize="24px" fontWeight={700}>{`$${product.price}`}</Text>
                  </Center>
                </Box>
              </List>
            );
          })}
        </HStack>
      </RadioGroup>

      {loadingToken && (
        <Center>
          <Spinner color="blue.500" />
        </Center>
      )}
    </Box>
  );
};
