export type CandidateSearchDataType = {
  fullName: string;
  location: string;
  phone: string;
  email?: string;
  statusDetails: {
    status: string;
    lastUpdatedAt: Date;
  };
  businessName?: string;
  locationId?: string;
  seekerHireDetailId: string;
  id: string;
};

export type SearchDataType = {
  businessName?: string | null;
  email?: string | null;
  fullName?: string | null;
  limit?: number;
  page?: number;
  phone?: string | null;
  positionName?: string | null;
  requisitionId?: string | null;
  seekerHireDetailId?: string | null;
  seekerId?: string | null;
  status?: string[];
};

export type CandidateSearchResponseType = {
  id: string;
  accountId?: string;
  seekerId?: string;
  businessName?: string;
  fullName?: string;
  email?: string;
  phone?: string;
  positionName?: string;
  positionId?: string;
  locationName?: string;
  locationId?: string;
  statusDetails?: {
    status: string;
    lastUpdatedAt: Date;
  };
  dismissed?: boolean;
  modernHireEnabled?: boolean;
  kioskEnabled?: boolean;
  positionOpen?: boolean;
  monetized?: boolean;
  indexedAt?: Date;
  ghostingStatus?: {
    status: string;
    lastUpdatedDate: Date;
  };
  isStudent?: boolean;
};

export type CandidateSearchListType = {
  page: number;
  from?: number;
  total: number;
  size: number;
  data: CandidateSearchResponseType[];
};

export enum CandidateStatus {
  Liked = 'LIKED',
  Blacklisted = 'BLACKLISTED',
  Bookmarked = 'BOOKMARKED',
  ContactRequestAccepted = 'CONTACT_REQUEST_ACCEPTED',
  ContactRequestDeclined = 'CONTACT_REQUEST_DECLINED',
  ContactRequested = 'CONTACT_REQUESTED',
  Dismissed = 'DISMISSED',
  Hired = 'HIRED',
  HireInReview = 'HIRE_IN_REVIEW',
  HiredOnAnotherPosition = 'HIRED_ON_ANOTHER_POSITION',
  AppointmentAccepted = 'APPOINTMENT_ACCEPTED',
  AppointmentCancelled = 'APPOINTMENT_CANCELLED',
  AppointmentDeclined = 'APPOINTMENT_DECLINED',
  AppointmentRequested = 'APPOINTMENT_REQUESTED',
  AppointmentReschedulingNeeded = 'APPOINTMENT_RESCHEDULING_NEEDED',
  NotLookingForWork = 'NOT_LOOKING_FOR_WORK',
  OfferMade = 'OFFER_MADE',
  OfferRejected = 'OFFER_REJECTED',
  OfferRevoked = 'OFFER_REVOKED',
  InterestRevoked = 'INTEREST_REVOKED',
  Unhired = 'UNHIRED',
}
