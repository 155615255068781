export default {
  bundleName: 'AppyHere Recruiter',
  firstName: 'AppyHere',
  lastName: 'Recruiter',
  loading: 'AppyHere…',
  deviceDetect: {
    header: "Our bad, we're still working on AppyHere for mobile browsers!",
    message: 'We strongly recommend you use a desktop for an optimal experience.',
  },
  exitPromptMessage: 'Any unsaved changes to your form will be lost. Are you sure you want to leave?',
};
