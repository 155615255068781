import React, { useEffect, useState, Fragment } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AvatarBadge,
  Box,
  Flex,
  Text,
  Button,
  useDisclosure,
  Tooltip,
  Image,
  useToast,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import _isNil from 'lodash/isNil';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import { Link } from 'react-router-dom';
import { FaUserTimes } from 'react-icons/fa';
import { AxiosError } from 'axios';
import { Label, ProfilePicture } from '../../common';
import colors from '../../../styles/colors';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import { useTopCandidatesContext, SelectedTab } from './TopCandidatesContext';
import SeekerExperiencePresenter from '../../messaging/seeker/candidate/profile/seekerExperiencePresenter';
import SeekerEducationPresenter from '../../messaging/seeker/candidate/profile/seekerEducationPresenter';
import { Experience } from '../../messaging/seeker/candidate/profile/components/Experience';
import { Education } from '../../messaging/seeker/candidate/profile/components/Education';
import useEducationLevels from '../../../app/hooks/useEducationLevels';
import { CandidateAvailabilities } from '../../messaging/seeker/candidate/profile/components/CandidateAvailabilities';
import { ContactRequestModalView } from './ContactRequestModalView';
import { CandidateNightAvailabilities } from '../../messaging/seeker/candidate/profile/components/CandidateNightAvailabilities';
import { getGradientBackground } from '../../../utils/uiUtils';
import useCandidate from '../../../app/components/useCandidate';
import ProfileQuestions from './ProfileQuestions';
import useKnockedOutAutomaticDismissalHoursDelay from '../../../app/hooks/useknockedOutAutomaticDismissalHoursDelay';
import ProfileHistory from './ProfileHistory';
import { SeekerAllCandidates } from '../../messaging/seeker/candidate/profile/components/SeekerAllCandidates';
import { TopCandidateBookmark } from './TopCandidateBookmark';
import { POSITION_CANDIDATE_TABS } from '../../../routes/constants';
import { SeekerContainerTabItems } from '../../messaging/seeker/SeekerContainerContext';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';

export type SeekerProfilePropsType = {
  onDismissModalOpen: () => void;
  onEnagageModalOpen: () => void;
  handleActionAfterEngage: (seekerId: string) => void;
};

enum AvailabilityTypes {
  DAY = 'Day',
  NIGHT = 'Night',
}

export const SeekerProfile = ({
  onDismissModalOpen,
  onEnagageModalOpen,
  handleActionAfterEngage,
}: SeekerProfilePropsType): JSX.Element => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const { currentUserProfile } = useCurrentUserProfile();
  const educationLevels = useEducationLevels();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { tab, selectedTopCandidate, positionDocument } = useTopCandidatesContext();
  const accountStore = useStoreState((s) => s.app.accounts);
  const seekersProfilePictures = useStoreState((state) => state.messaging.seekersProfilePictures);
  const isModernHireEnable = useStoreState((s) => s.app.accounts?.configuration?.modernHireEnable);

  const { unlock } = useStoreActions((action) => action.positionManagement);

  const [profileURL, setProfileURl] = useState<string>();
  const [availabilityType, setAvailabilityType] = React.useState<AvailabilityTypes>(AvailabilityTypes.DAY);
  const [isKeepRegardlessOpen, setIsKeepRegardlessOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const candidateId = `${positionDocument?.id}_${selectedTopCandidate?.seeker?.id}`;
  const selectedCandidate = useCandidate(candidateId);

  const minutesBeforeInactivity = useStoreState((state) => state.remoteConfig[RemoteConfigKey.MINUTES_BEFORE_INACTIVITY]);
  const recentlyActive =
    moment(selectedTopCandidate?.seeker?.lastActivity).toDate() > moment().subtract(minutesBeforeInactivity, 'minute').toDate();

  const candidateExperiences = (
    selectedTopCandidate?.seeker?.experiences?.map((experience) => new SeekerExperiencePresenter(experience)) ?? []
  ).sort((x, y) => Number(y.current) - Number(x.current));

  const candidateEducations =
    selectedTopCandidate?.seeker?.educations?.map((education) => new SeekerEducationPresenter(education)) ?? [];

  useEffect(() => {
    if (selectedTopCandidate?.seeker) {
      const url = seekersProfilePictures[selectedTopCandidate?.seeker?.id];
      setProfileURl(url);
    }
  }, [selectedTopCandidate, seekersProfilePictures]);

  const firstName = selectedTopCandidate?.seeker && selectedTopCandidate?.seeker.firstName.trim();
  const lastName = selectedTopCandidate?.seeker && selectedTopCandidate?.seeker.lastName.trim();

  const saveFromDismissal = useStoreActions((actions) => actions.candidate.saveFromDismissal);
  const knockedOutAutomaticDismissalHoursDelay = useKnockedOutAutomaticDismissalHoursDelay();

  const getHoursToDismissal = () => {
    const knockedOutAt = selectedCandidate?.knockedOutAt;
    const dismissalDate = moment(knockedOutAt).add(knockedOutAutomaticDismissalHoursDelay, 'hours');

    return dismissalDate.diff(moment(), 'hours');
  };

  const canReviveKnockedOutCandidate = () => {
    const knockedOutAt = selectedCandidate?.knockedOutAt;
    if (!knockedOutAt) return false;

    const dismissalDate = moment(knockedOutAt).add(knockedOutAutomaticDismissalHoursDelay, 'hours');
    return dismissalDate.isAfter(moment());
  };

  /// funciton to handle regardless butotn click
  const handleRegardlessBtn = async () => {
    if (selectedTopCandidate?.seeker?.id && positionDocument?.id) {
      try {
        await saveFromDismissal({
          seekerId: selectedTopCandidate?.seeker.id,
          positionId: positionDocument?.id,
        });
        toast({
          title: t('common:onKeepRegardlessSuccesLabel'),
          duration: 5000,
          isClosable: true,
          status: 'success',
        });
        setIsKeepRegardlessOpen(false);
      } catch (err) {
        console.error('Error occurred while saving from dismissal', err);
      }
    }
  };

  const getHoursToDismissalTranslation = (hoursToDismissal: number) => {
    if (hoursToDismissal < 1) {
      return t('common:hoursToDismissal.zero');
    }
    if (hoursToDismissal === 1) {
      return t('common:hoursToDismissal.one');
    }
    return t('common:hoursToDismissal.other', { count: hoursToDismissal });
  };

  useEffect(() => {
    setIsKeepRegardlessOpen(false);
  }, [selectedTopCandidate]);

  // engaging candidates for subscription accounts
  const handleEngageCandidate = async (pId: string, sId: string, recruiterId: string, modernHireEnabled?: boolean) => {
    try {
      setIsLoading(true);
      await unlock({
        positionId: pId,
        seekerId: sId,
        recruiterId,
        modernHireEnable: modernHireEnabled ?? false,
      });
      handleActionAfterEngage(sId);
    } catch (error) {
      let defaultMessage = t('businessManagement:topCandidate.engageErrorText');
      const newError = error as AxiosError<{ message: string }>;
      if (newError.response?.status === 401) {
        defaultMessage = newError.response.data.message || defaultMessage;
      }
      toast({
        description: defaultMessage,
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateAppointmentRequest = async () => {
    if (!currentUserProfile) return;

    if (positionDocument && selectedTopCandidate) {
      await handleEngageCandidate(
        positionDocument.id,
        selectedTopCandidate?.seeker.id,
        currentUserProfile.id,
        isModernHireEnable,
      );
    }
  };

  return (
    <Box h="100%">
      {selectedTopCandidate?.seeker && (
        <Box position="relative" h="100%" pb="64px">
          <Box height="100%" overflowY="auto">
            <Box p="12px" borderBottom="1px solid #E5E7EB">
              <Flex alignItems="center">
                <ProfilePicture
                  ml="10px"
                  src={profileURL}
                  style={{ backgroundColor: colors.orange[500], color: 'white' }}
                  name={`${firstName} ${lastName}`}
                  bg="transparent"
                >
                  {recentlyActive && <AvatarBadge width="14px" height="14px" bg={colors.green['200']} />}
                </ProfilePicture>
                <Box ml="10px">
                  <Flex alignItems="center">
                    {selectedCandidate?.monetized ? (
                      <Text fontWeight={700} fontSize="16px" mr="4px">
                        {`${selectedCandidate?.firstName} ${selectedCandidate?.lastName}`}
                      </Text>
                    ) : (
                      <Text fontWeight={700} fontSize="16px" mr="4px">
                        {`${selectedTopCandidate?.seeker.firstName} ${selectedTopCandidate?.seeker.lastName}`}
                      </Text>
                    )}
                    {tab === SelectedTab.ACTIVE && <TopCandidateBookmark selectedCandidate={selectedCandidate} />}

                    {selectedTopCandidate?.seeker?.deactivated && (
                      <Tooltip label={t('candidateStatus:DEACTIVATED')} hasArrow fontSize="sm">
                        <Box as="span" ml={2}>
                          <FaUserTimes size="16px" />
                        </Box>
                      </Tooltip>
                    )}

                    {(tab === SelectedTab.BOOKMARKED || tab === SelectedTab.BEST_MATCH) &&
                      selectedCandidate?.system?.shouldBeDismissed &&
                      canReviveKnockedOutCandidate() &&
                      !selectedCandidate?.monetized &&
                      !selectedCandidate?.dismissed && (
                        <Tooltip
                          label={`${t('common:keepRegardlessLabel')}  ${getHoursToDismissalTranslation(getHoursToDismissal())}`}
                          hasArrow
                        >
                          <Image
                            src="/isGoingToBeDismissed.png"
                            alt="is_going_to_be_dismissed icon"
                            width="auto"
                            height="16px"
                            data-testid="dismissed-icon"
                            ml={1}
                            onClick={() => setIsKeepRegardlessOpen((prevState) => !prevState)}
                          />
                        </Tooltip>
                      )}
                  </Flex>
                </Box>
              </Flex>
            </Box>
            {isKeepRegardlessOpen && canReviveKnockedOutCandidate() && (
              <Flex direction="column" p="12px" backgroundColor={colors.red[500]}>
                <Text fontSize="14px" color="white" mb={1}>
                  {`${t('common:keepRegardlessLabel')}  ${getHoursToDismissalTranslation(getHoursToDismissal())}`}
                </Text>
                <Button backgroundColor="white" color={colors.blue[500]} fontSize="14px" onClick={() => handleRegardlessBtn()}>
                  {t('common:keepRegardlessBtn')}
                </Button>
              </Flex>
            )}
            <Box p="12px">
              {(selectedTopCandidate?.seeker.flexibleTransportation ||
                selectedTopCandidate?.seeker?.isStudent ||
                (_isEmpty(selectedTopCandidate?.seeker?.nightAvailabilities) &&
                  _isEmpty(selectedTopCandidate?.seeker?.newAvailabilities))) && (
                // eslint-disable-next-line react/jsx-indent
                <Fragment>
                  <Text fontSize="14px" fontWeight={700}>
                    {t('common:profile.appyPros.title')}
                  </Text>
                  {selectedTopCandidate?.seeker?.flexibleTransportation && (
                    <Flex alignItems="center">
                      <RiCheckboxCircleFill color={colors.green[500]} size={24} />
                      <Text ml="12px" fontSize="14px" fontWeight={400} color={colors.slate}>
                        {t('common:profile.appyPros.flexibleTransportation')}
                      </Text>
                    </Flex>
                  )}
                  {selectedTopCandidate?.seeker?.isStudent && (
                    <Flex alignItems="center">
                      <RiCheckboxCircleFill color={colors.green[500]} size={24} />
                      <Text ml="12px" fontSize="14px" fontWeight={400} color={colors.slate}>
                        {t('businessManagement:topCandidate.seekerIsStudent')}
                      </Text>
                    </Flex>
                  )}

                  {_isEmpty(selectedTopCandidate?.seeker?.nightAvailabilities) &&
                    _isEmpty(selectedTopCandidate?.seeker?.newAvailabilities) && (
                      <Flex alignItems="center">
                        <RiCheckboxCircleFill color={colors.green[500]} size={24} />
                        <Text ml="12px" fontSize="14px" fontWeight={400} color={colors.slate}>
                          {t('businessManagement:topCandidate.emptyAvailabilities')}
                        </Text>
                      </Flex>
                    )}
                </Fragment>
              )}
            </Box>

            {!_isNil(selectedTopCandidate?.seeker.noticePeriod) &&
              !_isNil(selectedTopCandidate?.seeker.noticePeriod.value) &&
              selectedTopCandidate?.seeker.noticePeriod.value !== '' && (
                <Box p="0 12px 12px 12px" borderBottom="1px solid #E5E7EB">
                  <Text fontWeight="semibold" fontSize=".95rem">
                    {t('common:noticePeriod')}
                  </Text>
                  <Text color="gray.500" fontSize="14px">
                    {String(selectedTopCandidate?.seeker.noticePeriod.value) === '0'
                      ? t('common:immediately')
                      : `${selectedTopCandidate?.seeker.noticePeriod.value}${
                          selectedTopCandidate?.seeker.noticePeriod.unit
                            ? ` ${t(
                                `businessManagement:topCandidate.seekerProfile.${selectedTopCandidate?.seeker.noticePeriod.unit}`,
                              )}`
                            : ''
                        }`}
                  </Text>
                </Box>
              )}
            <Box p="0 0 12px 12px">
              <Accordion defaultIndex={[0]} allowMultiple>
                {(tab === SelectedTab.BOOKMARKED || tab === SelectedTab.ACTIVE || tab === SelectedTab.BEST_MATCH) &&
                  !_isUndefined(selectedCandidate) && (
                    <AccordionItem border="none" p="0px" pr="12px" borderBottom="1px solid #E5E7EB" data-testid="questionSection">
                      <h2>
                        <AccordionButton p="12px 0px" _focus={{ outline: 'none' }} data-testid="questionBtn">
                          <Box as="span" flex="1" textAlign="left" p="0px" fontSize="14px" fontWeight={700}>
                            {t('common:tabs.questions')}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb="12px" px={0}>
                        {selectedCandidate && <ProfileQuestions candidateId={candidateId} />}
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                <AccordionItem border="none" p="0px" pr="12px" borderBottom="1px solid #E5E7EB" data-testid="availabilitySection">
                  <h2>
                    <AccordionButton p="12px 0px" _focus={{ outline: 'none' }} data-testid="availabilityBtn">
                      <Box flex="1" textAlign="left">
                        <Text fontWeight="semibold" pb={1} fontSize="14px">
                          {t('profile.availability.title')}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  {(selectedTopCandidate?.seeker?.newAvailabilities || selectedTopCandidate?.seeker?.nightAvailabilities) &&
                  (!_isEmpty(selectedTopCandidate?.seeker?.newAvailabilities) ||
                    !_isEmpty(selectedTopCandidate?.seeker?.nightAvailabilities)) ? (
                    // eslint-disable-next-line react/jsx-indent
                    <AccordionPanel pb={0} px={0} mb={3} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                      {selectedTopCandidate?.seeker.availabilityUpdatedAt && (
                        <Box fontSize="sm" color="gray.500" mb={2}>
                          {`${t('profile.updatedAt')} ${moment(selectedTopCandidate?.seeker.availabilityUpdatedAt)
                            .locale(i18n.language)
                            .format('lll')}`}
                        </Box>
                      )}
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        border="1px solid #E5E7EB"
                        borderRadius="4px"
                        mb={2}
                        p={1}
                        position="relative"
                        width="100%"
                        height="42px"
                        background={getGradientBackground(availabilityType)}
                        overflow="hidden"
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          fontWeight="700"
                          cursor="pointer"
                          flex="1"
                          mr="50px"
                          mt="8px"
                          onClick={() => setAvailabilityType(AvailabilityTypes.DAY)}
                          zIndex={1}
                          color={availabilityType === AvailabilityTypes.DAY ? 'blue.500' : 'gray.800'}
                          data-testid="dayBtn"
                        >
                          <Text fontSize="14px" fontWeight={700}>
                            {t('availability.day')}
                          </Text>
                        </Flex>
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          fontWeight="700"
                          cursor="pointer"
                          flex="1"
                          ml="50px"
                          mb="8px"
                          onClick={() => setAvailabilityType(AvailabilityTypes.NIGHT)}
                          zIndex={1}
                          color={availabilityType === AvailabilityTypes.NIGHT ? 'blue.500' : 'gray.800'}
                          data-testid="nightBtn"
                        >
                          <Text fontSize="14px" fontWeight={700}>
                            {t('availability.night')}
                          </Text>
                        </Flex>
                      </Flex>

                      {/* eslint-disable no-nested-ternary */}
                      {availabilityType === AvailabilityTypes.DAY && selectedTopCandidate?.seeker?.newAvailabilities ? (
                        <CandidateAvailabilities candidateAvailabilities={selectedTopCandidate?.seeker.newAvailabilities} />
                      ) : availabilityType === AvailabilityTypes.NIGHT && selectedTopCandidate?.seeker?.nightAvailabilities ? (
                        <CandidateNightAvailabilities nightAvailabilities={selectedTopCandidate?.seeker?.nightAvailabilities} />
                      ) : (
                        <Label
                          p={4}
                          size="sm"
                          color={colors.gray[500]}
                          colorDarkMode={colors.gray['600']}
                          border="1px solid #eceff1"
                        >
                          ---
                        </Label>
                      )}
                    </AccordionPanel>
                  ) : (
                    <AccordionPanel pb={0} px={0} mb={3} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                      <Label
                        p={4}
                        size="sm"
                        color={colors.gray[500]}
                        colorDarkMode={colors.gray['600']}
                        border="1px solid #eceff1"
                      >
                        ---
                      </Label>
                    </AccordionPanel>
                  )}
                </AccordionItem>
                <AccordionItem border="none" p="0px" pr="12px" borderBottom="1px solid #E5E7EB" data-testid="experienceSection">
                  <h2>
                    <AccordionButton p="12px 0px" _focus={{ outline: 'none' }} data-testid="experienceBtn">
                      <Box as="span" flex="1" textAlign="left" p="0px" fontSize="14px" fontWeight={700}>
                        {t('common:profile.experience.title')}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb="12px" px={0}>
                    {candidateExperiences.length > 0 &&
                      candidateExperiences.map((experience, index) => (
                        <Experience
                          key={experience.id}
                          experience={experience}
                          totalItems={candidateExperiences.length}
                          dataIndex={index}
                          displayBusiness={selectedCandidate?.monetized}
                        />
                      ))}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border="none" p="0px" pr="12px" borderBottom="1px solid #E5E7EB" data-testid="educationSection">
                  <h2>
                    <AccordionButton p="12px 0px" _focus={{ outline: 'none' }} data-testid="educationBtn">
                      <Box as="span" flex="1" textAlign="left" p="0px" fontSize="14px" fontWeight={700}>
                        {t('common:profile.education.title')}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb="12px" px={0}>
                    {candidateEducations.length > 0 &&
                      candidateEducations.map((education, index) => (
                        <Education
                          key={education.id}
                          dataIndex={index}
                          education={education}
                          totalItems={candidateEducations.length}
                          educationLevels={educationLevels}
                          displaySchoolName={selectedCandidate?.monetized}
                        />
                      ))}
                  </AccordionPanel>
                </AccordionItem>
                {!_isUndefined(selectedCandidate) && (
                  <AccordionItem
                    border="none"
                    p="0px"
                    pr="12px"
                    borderBottom="1px solid #E5E7EB"
                    data-testid="otherApplicationSection"
                  >
                    <h2>
                      <AccordionButton p="12px 0px" _focus={{ outline: 'none' }} data-testid="otherApplicationBtn">
                        <Box as="span" flex="1" textAlign="left" p="0px" fontSize="14px" fontWeight={700}>
                          {t('businessManagement:topCandidate.otherApplication')}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb="12px" px={0}>
                      {selectedCandidate && (
                        <SeekerAllCandidates selectedCandidateId={selectedCandidate?.seeker} isTopCandidate />
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                )}

                {(tab === SelectedTab.BOOKMARKED || tab === SelectedTab.ACTIVE || tab === SelectedTab.BEST_MATCH) &&
                  !_isUndefined(selectedCandidate) && (
                    <AccordionItem border="none" p="0px" pr="12px" borderBottom="1px solid #E5E7EB" data-testid="historySection">
                      <h2>
                        <AccordionButton p="12px 0px" _focus={{ outline: 'none' }} data-testid="historyBtn">
                          <Box as="span" flex="1" textAlign="left" p="0px" fontSize="14px" fontWeight={700}>
                            {t('common:tabs.history')}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb="12px" px={0}>
                        {selectedCandidate && <ProfileHistory candidateId={candidateId} />}
                      </AccordionPanel>
                    </AccordionItem>
                  )}
              </Accordion>
            </Box>
          </Box>
          <Box py={3} px={0} w="100%" bg="#fff" position="absolute" left="0" bottom="0" borderTop="1px solid #E5E7EB">
            <Flex justifyContent="space-around">
              <Fragment>
                <Box>
                  <Button
                    variant="outline"
                    colorScheme="red"
                    size="sm"
                    fontSize={{ base: '9px', md: '11px', lg: '14px' }}
                    mr="5px"
                    onClick={() => onDismissModalOpen()}
                    style={{ whiteSpace: 'pre-wrap', padding: '15px' }}
                    data-testid="dismissBtn"
                  >
                    {t('businessManagement:topCandidate.dismissCandidate')}
                  </Button>
                </Box>

                <Box>
                  {tab === SelectedTab.BEST_MATCH &&
                    !selectedTopCandidate?.seeker?.deactivated &&
                    (!_isUndefined(selectedCandidate) && selectedTopCandidate?.status === 'LIKED' ? (
                      <Button
                        // isDisabled={selectedCandidate?.system?.shouldBeDismissed}
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        variant="solid"
                        colorScheme="blue"
                        size="sm"
                        fontSize={{ base: '9px', md: '11px', lg: '14px' }}
                        ml="5px"
                        onClick={() => {
                          if (!_isNil(accountStore) && accountStore.monetizationEnabled === false) {
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            handleCreateAppointmentRequest();
                          } else {
                            onEnagageModalOpen();
                          }
                        }}
                        style={{ whiteSpace: 'pre-wrap', padding: '15px' }}
                        data-testid="engageBtn"
                      >
                        {t('businessManagement:topCandidate.engageBtnLabel')}
                      </Button>
                    ) : (
                      <Button
                        variant="solid"
                        colorScheme="blue"
                        size="sm"
                        fontSize={{ base: '9px', md: '11px', lg: '14px' }}
                        onClick={() => onOpen()}
                        style={{ whiteSpace: 'pre-wrap', padding: '15px' }}
                        data-testid="contactRequestBtn"
                      >
                        {t('businessManagement:topCandidate.sendContactRequest')}
                      </Button>
                    ))}
                  {tab === SelectedTab.BOOKMARKED && (
                    <Flex>
                      {selectedTopCandidate.status === 'CONTACT_REQUESTED' && !selectedTopCandidate?.seeker?.deactivated ? (
                        <Tooltip
                          label={t('businessManagement:topCandidate.sendAgainTooltipText')}
                          shouldWrapChildren
                          aria-label="A tooltip"
                          mr="10px"
                        >
                          <Button
                            variant="solid"
                            colorScheme="blue"
                            size="sm"
                            fontSize={{ base: '9px', md: '11px', lg: '14px' }}
                            ml="5px"
                            onClick={() => onOpen()}
                            style={{ whiteSpace: 'pre-wrap', padding: '15px' }}
                            data-testid="sendAgainBtn"
                          >
                            {t('businessManagement:topCandidate.sendAgainBtnLabel')}
                          </Button>
                        </Tooltip>
                      ) : selectedTopCandidate?.status === 'CONTACT_REQUEST_ACCEPTED' ||
                        selectedTopCandidate?.status === 'LIKED' ? (
                        // eslint-disable-next-line react/jsx-indent
                        <Button
                          // isDisabled={selectedCandidate?.system?.shouldBeDismissed}
                          isDisabled={isLoading}
                          isLoading={isLoading}
                          variant="solid"
                          colorScheme="blue"
                          size="sm"
                          fontSize={{ base: '9px', md: '11px', lg: '14px' }}
                          ml="5px"
                          onClick={() => {
                            if (!_isNil(accountStore) && accountStore.monetizationEnabled === false) {
                              // eslint-disable-next-line @typescript-eslint/no-floating-promises
                              handleCreateAppointmentRequest();
                            } else {
                              onEnagageModalOpen();
                            }
                          }}
                          style={{ whiteSpace: 'pre-wrap', padding: '15px' }}
                          data-testid="engageBtn"
                        >
                          {t('businessManagement:topCandidate.engageBtnLabel')}
                        </Button>
                      ) : null}
                    </Flex>
                  )}
                </Box>
                {isOpen && selectedTopCandidate && (
                  <ContactRequestModalView isOpen={isOpen} onClose={onClose} selectedTopCandidate={selectedTopCandidate} />
                )}
              </Fragment>

              {tab === SelectedTab.ACTIVE && (
                <Fragment>
                  <Box />
                  <Box>
                    <Link
                      to={{
                        pathname: POSITION_CANDIDATE_TABS(positionDocument?.id ?? '', SeekerContainerTabItems.Messaging),
                        state: { seekerIdFromTopCandidate: selectedTopCandidate?.seeker },
                      }}
                    >
                      <Button
                        variant="solid"
                        colorScheme="blue"
                        size="sm"
                        fontSize={{ base: '9px', md: '11px', lg: '14px' }}
                        style={{ whiteSpace: 'pre-wrap', padding: '15px' }}
                        data-testid="communicateBtn"
                      >
                        {t('businessManagement:topCandidate.communicationCandidate')}
                      </Button>
                    </Link>
                  </Box>
                </Fragment>
              )}
            </Flex>
          </Box>
        </Box>
      )}
    </Box>
  );
};
