/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import {
  Badge,
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  Image,
} from '@chakra-ui/core';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { RiLock2Fill } from 'react-icons/ri';
import {
  Candidate,
  CandidateHireFormDetailResponse,
  CandidateHireFormDetailResponseWithError,
} from '../../../../../firebase/firestore/documents/candidate';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import colors from '../../../../../styles/colors';
import { HireFormTypeEnum } from '../actionsDropdown/HireFormTypeEnum';
import HireFromLogo from '../../../../../assets/img/Hire_Form_logo.svg';
import { getTranslatedStringValue } from '../../../../../utils/localizedNumber';
import SeekerHireFormAnswers from './SeekerHireFormAnswers';
import MultipleImagePreview from './MultipleImagePreview';
import PixelatedImage from '../../../../../assets/img/pixelated-image.png';

export type SeekerHireFormStatusProps = BoxProps & {
  selectedCandidate: Candidate;
};

const SeekerHireFormStatus = ({ selectedCandidate, ...rest }: SeekerHireFormStatusProps): JSX.Element => {
  const { t } = useTranslation('hireDetail');
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isHireDetailRejectedReasonModalOpen,
    onOpen: onHireDetailRejectedReasonModalOpen,
    onClose: onHireDetailRejectedReasonModalClose,
  } = useDisclosure();
  const [hireDetailOfCandidate, setHireDetailOfCandidate] = useState<CandidateHireFormDetailResponse | undefined>(undefined);
  const hasHireFormTemplate = !!selectedCandidate?.hireFormTemplateId;

  const { loadHireDetailOfCandidate } = useStoreActions((actions) => actions.candidate);

  const loadHireDetail = async () => {
    const hireDetailResponse = await loadHireDetailOfCandidate({ candidateId: selectedCandidate.id });
    const { status, title } = hireDetailResponse as CandidateHireFormDetailResponseWithError;
    if (status) {
      toast({
        title: `${title}`,
        status: 'error',
        isClosable: true,
      });
    } else {
      setHireDetailOfCandidate(hireDetailResponse as CandidateHireFormDetailResponse);
      onOpen();
    }
  };
  const superOwners = useStoreState((s) => s.app.accounts?.superOwners);
  const userId = useStoreState((s) => s.app.user?.id);

  const isSuperAdmin = (): boolean => {
    if (!superOwners || !userId) {
      return false;
    }
    return superOwners?.includes(userId);
  };

  const getColorScheme = (status: string | undefined): string => {
    switch (status) {
      case 'completed':
        return 'green';
      case 'inProgress':
        return 'teal';
      case 'approved':
        return 'cyan';
      case 'declined':
        return 'orange';
      case 'needsAction':
        return 'purple';
      case 'rejected':
        return 'red';
      case 'helpRequested':
        return 'yellow';
      default:
        return 'blue';
    }
  };

  const isReadyToShowHireDetail = (status: string | undefined): boolean => {
    return !(status === 'requested' || status === 'inProgress');
  };

  const checkResubmissionField = (field: string) => {
    const errorMsg = selectedCandidate.hireDetailsResubmissionRequest?.find((item) => item.field === field)?.comment;
    if (errorMsg) {
      return (
        <Text color="tomato" fontSize="xs">
          {errorMsg}
        </Text>
      );
    }
    return '';
  };

  const hasResubmissionField = (field: string) => {
    return !!selectedCandidate.hireDetailsResubmissionRequest?.find((item) => item.field === field);
  };

  const redactNumber = (str: string | undefined) => {
    if (!isSuperAdmin() && str) {
      const { length } = str;
      const hiddenPart = length > 3 ? '*'.repeat(length - 3) : /* istanbul ignore next */ '';
      return hiddenPart + str.substring(length - 3);
    }
    return str;
  };

  return (
    <Box {...rest} mr={2}>
      <Tooltip label={t('hiringFormStatus')} hasArrow fontSize="sm">
        <Badge
          data-testid="loadHireDetailButton"
          colorScheme={getColorScheme(selectedCandidate.hireDetailsStatus)}
          textTransform="none"
          cursor="pointer"
          borderRadius="4px"
          variant="subtle"
          onClick={() =>
            selectedCandidate.hireDetailsStatus === 'rejected' ? onHireDetailRejectedReasonModalOpen() : loadHireDetail()
          }
        >
          <Flex alignItems="center" padding="4px">
            <img src={HireFromLogo} alt="App Logo" width={22} />
            <Text ml={1} color={colors.black}>
              {t(`status.${selectedCandidate.hireDetailsStatus}`)}
            </Text>
          </Flex>
        </Badge>
      </Tooltip>
      {hireDetailOfCandidate && (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader px={5} paddingRight="2.1rem" borderBottom={`1px solid ${colors.gray[100]}`}>
              {`${t('hireDetailInformation')} (${t(`hireFormType.${hireDetailOfCandidate.seekerHireDetail.type}`)})`}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody p={0}>
              <Box>
                <Box>
                  <Heading px={5} as="h3" fontSize="md">
                    {t('hiringInformation')}
                  </Heading>
                  <Box px={5} pb={1} borderTop={`1px solid ${colors.gray[50]}`} borderBottom={`1px solid ${colors.gray[50]}`}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                        {t('positionName')}
                      </Heading>
                      <Text fontSize="sm" textAlign="right" flex="1">
                        {hireDetailOfCandidate?.seekerHireDetail.positionName}
                      </Text>
                    </Flex>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                        {t('locationName')}
                      </Heading>
                      <Text fontSize="sm" textAlign="right" flex="1">
                        {hireDetailOfCandidate?.seekerHireDetail.business.name}
                      </Text>
                    </Flex>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                        {t('jobStatus')}
                      </Heading>
                      <Text fontSize="sm" textAlign="right" flex="1">
                        {hireDetailOfCandidate?.seekerHireDetail.fullTime ? t('fullTime') : t('partTime')}
                      </Text>
                    </Flex>
                    {!hireDetailOfCandidate?.seekerHireDetail.fullTime && (
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                          {t('worksHoursPerWeek')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {getTranslatedStringValue(hireDetailOfCandidate?.seekerHireDetail.partTimeHours, i18next.language)}
                        </Text>
                      </Flex>
                    )}
                    <Flex alignItems="center" justifyContent="space-between">
                      <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                        {t('startingDate')}
                      </Heading>
                      <Text fontSize="sm" textAlign="right" flex="1">
                        {moment(hireDetailOfCandidate?.seekerHireDetail.startingDate).format('ll')}
                      </Text>
                    </Flex>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                        {t('wage')}
                      </Heading>
                      <Text fontSize="sm" textAlign="right" flex="1">
                        {`${getTranslatedStringValue(
                          (hireDetailOfCandidate?.seekerHireDetail.wage.value / 100).toFixed(2),
                          i18next.language,
                        )} ${
                          hireDetailOfCandidate?.seekerHireDetail.wage.unit === 'custom'
                            ? `${hireDetailOfCandidate?.seekerHireDetail.wage.unit}/${hireDetailOfCandidate?.seekerHireDetail.wage.customUnit}`
                            : t(hireDetailOfCandidate?.seekerHireDetail.wage.unit)
                        }`}
                      </Text>
                    </Flex>
                    <Flex alignItems="flex-start" flexDirection="column" justifyContent="space-between">
                      <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                        {t('note')}
                      </Heading>
                      <Text fontSize="sm" textAlign="right" flex="1">
                        {`${hireDetailOfCandidate?.seekerHireDetail.note ?? ''}`}
                      </Text>
                    </Flex>
                  </Box>
                </Box>

                {!hasHireFormTemplate && isReadyToShowHireDetail(selectedCandidate.hireDetailsStatus) && (
                  <Box>
                    <Heading px={5} as="h3" fontSize="md">
                      {t('candidateInformation')}
                    </Heading>
                    <Box px={5} pb={1} borderTop={`1px solid ${colors.gray[50]}`} borderBottom={`1px solid ${colors.gray[50]}`}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('firstName') ? 'tomato' : 'gray.800'}
                        >
                          {t('firstName')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.firstName}
                          {checkResubmissionField('firstName')}
                        </Text>
                      </Flex>
                      {hireDetailOfCandidate?.seekerHireDetail?.middleName && (
                        <Flex alignItems="center" justifyContent="space-between">
                          <Heading
                            flex="1"
                            as="h6"
                            fontSize="sm"
                            lineHeight="2.2"
                            color={hasResubmissionField('middleName') ? 'tomato' : 'gray.800'}
                          >
                            {t('middleName')}
                          </Heading>
                          <Text fontSize="sm" textAlign="right" flex="1">
                            {hireDetailOfCandidate?.seekerHireDetail?.middleName}
                            {checkResubmissionField('middleName')}
                          </Text>
                        </Flex>
                      )}

                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('lastName') ? 'tomato' : 'gray.800'}
                        >
                          {t('lastName')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.lastName}
                          {checkResubmissionField('lastName')}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('gender') ? 'tomato' : 'gray.800'}
                        >
                          {t('gender')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {t(hireDetailOfCandidate?.seekerHireDetail.gender)}
                          {checkResubmissionField('gender')}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('dateOfBirth') ? 'tomato' : 'gray.800'}
                        >
                          {t('dateOfBirth')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.dateOfBirth
                            ? moment(hireDetailOfCandidate?.seekerHireDetail.dateOfBirth).format(
                                isSuperAdmin() ? 'll' : 'MMM ** YYYY',
                              )
                            : ''}
                          {checkResubmissionField('dateOfBirth')}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                          {t('nickName')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.nickname}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('email') ? 'tomato' : 'gray.800'}
                        >
                          {t('email')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.email}
                          {checkResubmissionField('email')}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('correspondenceLanguage') ? 'tomato' : 'gray.800'}
                        >
                          {t('correspondenceLanguage')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {t(hireDetailOfCandidate?.seekerHireDetail.correspondenceLanguage)}
                          {checkResubmissionField('correspondenceLanguage')}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('phoneNumber') ? 'tomato' : 'gray.800'}
                        >
                          {t('phoneNumber')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.phoneNumber}
                          {checkResubmissionField('phoneNumber')}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('optionalPhoneNumber') ? 'tomato' : 'gray.800'}
                        >
                          {t('optionalPhoneNumber')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.optionalPhoneNumber ?? ''}
                          {checkResubmissionField('optionalPhoneNumber')}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('socialInsuranceNumber') ? 'tomato' : 'gray.800'}
                        >
                          {t('socialInsuranceNumber')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {redactNumber(hireDetailOfCandidate?.seekerHireDetail.socialInsuranceNumber)}
                          {checkResubmissionField('socialInsuranceNumber')}
                        </Text>
                      </Flex>

                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                          {t('address')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {redactNumber(hireDetailOfCandidate?.seekerHireDetail?.address?.addressLineOne)}
                        </Text>
                      </Flex>

                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('completeAddress') ? 'tomato' : 'gray.800'}
                        >
                          {t('city')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.address.city ?? ''}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('completeAddress') ? 'tomato' : 'gray.800'}
                        >
                          {t('region')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.address.region ?? ''}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('completeAddress') ? 'tomato' : 'gray.800'}
                        >
                          {t('country')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.address.country ?? ''}
                          {checkResubmissionField('completeAddress')}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('postalCode') ? 'tomato' : 'gray.800'}
                        >
                          {t('postalCode')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.postalCode}
                          {checkResubmissionField('postalCode')}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('apartment') ? 'tomato' : 'gray.800'}
                        >
                          {t('apartment')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.apartment}
                          {checkResubmissionField('apartment')}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                )}

                {hireDetailOfCandidate.seekerHireDetail.type === HireFormTypeEnum.SEASONAL && (
                  <Box>
                    <Heading px={5} as="h3" fontSize="md">
                      {t('contract')}
                    </Heading>
                    <Box px={5} pb={1} borderTop={`1px solid ${colors.gray[50]}`} borderBottom={`1px solid ${colors.gray[50]}`}>
                      <Flex alignItems="flex-start" justifyContent="space-between">
                        <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                          {t('messaging:actions.hire.confirmationModal.contractStartDate')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {moment(hireDetailOfCandidate?.seekerHireDetail.startingDate).format('ll')}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                          {t('messaging:actions.hire.confirmationModal.contractEndDate')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {moment(hireDetailOfCandidate?.seekerHireDetail.endDate).format('ll')}
                        </Text>
                      </Flex>
                      <Box alignItems="center" justifyContent="space-between">
                        <Heading flex="1" as="h6" fontSize="sm" lineHeight="2.2">
                          {t('messaging:actions.hire.confirmationModal.contractDetailsWording')}
                        </Heading>
                        <Text fontSize="sm" textAlign="left" flex="1" whiteSpace="pre-line" lineHeight={1.3}>
                          {hireDetailOfCandidate?.seekerHireDetail.miscellaneous?.contractDetailsWordings}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {!hasHireFormTemplate && isReadyToShowHireDetail(selectedCandidate.hireDetailsStatus) && (
                  <Box>
                    <Heading px={5} as="h3" fontSize="md">
                      {t('workPermit')}
                    </Heading>
                    <Box px={5} pb={1} borderTop={`1px solid ${colors.gray[50]}`} borderBottom={`1px solid ${colors.gray[50]}`}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Heading
                          flex="1"
                          as="h6"
                          fontSize="sm"
                          lineHeight="2.2"
                          color={hasResubmissionField('workPermitEndDate') ? 'tomato' : 'gray.800'}
                        >
                          {t('messaging:actions.hire.confirmationModal.workPermitEndDate')}
                        </Heading>
                        <Text fontSize="sm" textAlign="right" flex="1">
                          {hireDetailOfCandidate?.seekerHireDetail.workPermitEndDate &&
                            moment(hireDetailOfCandidate?.seekerHireDetail.workPermitEndDate).format('ll')}
                          {checkResubmissionField('workPermitEndDate')}
                        </Text>
                      </Flex>
                      {hireDetailOfCandidate?.seekerHireDetail.workPermitImageUrl && (
                        <Box w="100%" mr={2}>
                          <Heading
                            flex="1"
                            as="h6"
                            fontSize="sm"
                            lineHeight="2.2"
                            color={hasResubmissionField('workPermitImageUrl') ? 'tomato' : 'gray.800'}
                          >
                            {t('workPermit')}
                          </Heading>
                          {!Array.isArray(hireDetailOfCandidate?.seekerHireDetail.workPermitImageUrl) ? (
                            <Box position="relative">
                              <Image
                                src={isSuperAdmin() ? hireDetailOfCandidate?.seekerHireDetail.workPermitImageUrl : PixelatedImage}
                                alt="Work permit photo"
                              />
                              {!isSuperAdmin() && (
                                <Flex
                                  border="1px solid"
                                  borderColor="gray.50"
                                  p={2}
                                  position="absolute"
                                  top={0}
                                  left={0}
                                  alignItems="center"
                                  justifyContent="center"
                                  h="100%"
                                  w="100%"
                                >
                                  <Text d="flex" alignItems="center">
                                    <RiLock2Fill style={{ marginRight: '5px' }} />
                                    {t('noPermissionToViewImage')}
                                  </Text>
                                </Flex>
                              )}
                            </Box>
                          ) : (
                            <MultipleImagePreview
                              showImage={isSuperAdmin()}
                              imageURLs={hireDetailOfCandidate?.seekerHireDetail.workPermitImageUrl}
                            />
                          )}
                          {checkResubmissionField('workPermitImageUrl')}
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
                {!hasHireFormTemplate &&
                  isReadyToShowHireDetail(selectedCandidate.hireDetailsStatus) &&
                  hireDetailOfCandidate?.seekerHireDetail.emergencyContact && (
                    <Box>
                      <Heading px={5} as="h3" fontSize="md">
                        {t('primaryContactInformation')}
                      </Heading>
                      <Box px={5} pb={1} borderTop={`1px solid ${colors.gray[50]}`} borderBottom={`1px solid ${colors.gray[50]}`}>
                        <Flex alignItems="center" justifyContent="space-between">
                          <Heading
                            flex="1"
                            as="h6"
                            fontSize="sm"
                            lineHeight="2.2"
                            color={hasResubmissionField('emergencyContact') ? 'tomato' : 'gray.800'}
                          >
                            {t('firstName')}
                          </Heading>
                          <Text fontSize="sm" textAlign="right" flex="1">
                            {hireDetailOfCandidate?.seekerHireDetail.emergencyContact.firstName}
                          </Text>
                        </Flex>
                        <Flex alignItems="center" justifyContent="space-between">
                          <Heading
                            flex="1"
                            as="h6"
                            fontSize="sm"
                            lineHeight="2.2"
                            color={hasResubmissionField('emergencyContact') ? 'tomato' : 'gray.800'}
                          >
                            {t('lastName')}
                          </Heading>
                          <Text fontSize="sm" textAlign="right" flex="1">
                            {hireDetailOfCandidate?.seekerHireDetail.emergencyContact.lastName}
                          </Text>
                        </Flex>
                        <Flex alignItems="center" justifyContent="space-between">
                          <Heading
                            flex="1"
                            as="h6"
                            fontSize="sm"
                            lineHeight="2.2"
                            color={hasResubmissionField('emergencyContact') ? 'tomato' : 'gray.800'}
                          >
                            {t('phoneNumber')}
                          </Heading>
                          <Text fontSize="sm" textAlign="right" flex="1">
                            {hireDetailOfCandidate?.seekerHireDetail.emergencyContact.phoneNumber}
                          </Text>
                        </Flex>
                        {checkResubmissionField('emergencyContact')}
                      </Box>
                    </Box>
                  )}
                {!hasHireFormTemplate &&
                  isReadyToShowHireDetail(selectedCandidate.hireDetailsStatus) &&
                  hireDetailOfCandidate?.seekerHireDetail.canadianBankAccountDetails && (
                    <Box>
                      <Heading px={5} as="h3" fontSize="md">
                        {t('canadianBankAccountDetails')}
                      </Heading>
                      <Box px={5} pb={1} borderTop={`1px solid ${colors.gray[50]}`} borderBottom={`1px solid ${colors.gray[50]}`}>
                        <Flex alignItems="center" justifyContent="space-between">
                          <Heading
                            flex="1"
                            as="h6"
                            fontSize="sm"
                            lineHeight="2.2"
                            color={hasResubmissionField('canadianBankAccountDetails') ? 'tomato' : 'gray.800'}
                          >
                            {t('branchTransitNumber')}
                          </Heading>
                          <Text fontSize="sm" textAlign="right" flex="1">
                            {redactNumber(hireDetailOfCandidate?.seekerHireDetail.canadianBankAccountDetails.branchTransitNumber)}
                          </Text>
                        </Flex>
                        <Flex alignItems="center" justifyContent="space-between">
                          <Heading
                            flex="1"
                            as="h6"
                            fontSize="sm"
                            lineHeight="2.2"
                            color={hasResubmissionField('canadianBankAccountDetails') ? 'tomato' : 'gray.800'}
                          >
                            {t('financialInstitutionNumber')}
                          </Heading>
                          <Text fontSize="sm" textAlign="right" flex="1">
                            {redactNumber(
                              hireDetailOfCandidate?.seekerHireDetail.canadianBankAccountDetails.financialInstitutionNumber,
                            )}
                          </Text>
                        </Flex>
                        <Flex alignItems="center" justifyContent="space-between">
                          <Heading
                            flex="1"
                            as="h6"
                            fontSize="sm"
                            lineHeight="2.2"
                            color={hasResubmissionField('canadianBankAccountDetails') ? 'tomato' : 'gray.800'}
                          >
                            {t('bankAccountNumber')}
                          </Heading>
                          <Text fontSize="sm" textAlign="right" flex="1">
                            {redactNumber(hireDetailOfCandidate?.seekerHireDetail.canadianBankAccountDetails.bankAccountNumber)}
                          </Text>
                        </Flex>
                        {checkResubmissionField('canadianBankAccountDetails')}
                      </Box>
                    </Box>
                  )}

                {!hasHireFormTemplate &&
                  isReadyToShowHireDetail(selectedCandidate.hireDetailsStatus) &&
                  hireDetailOfCandidate?.seekerHireDetail.americanBankAccountDetails && (
                    <Box>
                      <Heading px={5} as="h3" fontSize="md">
                        {t('americanBankAccountDetails')}
                      </Heading>
                      <Box px={5} pb={1} borderTop={`1px solid ${colors.gray[50]}`} borderBottom={`1px solid ${colors.gray[50]}`}>
                        <Flex alignItems="center" justifyContent="space-between">
                          <Heading
                            flex="1"
                            as="h6"
                            fontSize="sm"
                            lineHeight="2.2"
                            color={hasResubmissionField('americanBankAccountDetails') ? 'tomato' : 'gray.800'}
                          >
                            {t('routingNumber')}
                          </Heading>
                          <Text fontSize="sm" textAlign="right" flex="1">
                            {redactNumber(hireDetailOfCandidate?.seekerHireDetail.americanBankAccountDetails.routingNumber)}
                          </Text>
                        </Flex>
                        <Flex alignItems="center" justifyContent="space-between">
                          <Heading
                            flex="1"
                            as="h6"
                            fontSize="sm"
                            lineHeight="2.2"
                            color={hasResubmissionField('americanBankAccountDetails') ? 'tomato' : 'gray.800'}
                          >
                            {t('bankAccountNumber')}
                          </Heading>
                          <Text fontSize="sm" textAlign="right" flex="1">
                            {redactNumber(hireDetailOfCandidate?.seekerHireDetail.americanBankAccountDetails.bankAccountNumber)}
                          </Text>
                        </Flex>
                        {checkResubmissionField('americanBankAccountDetails')}
                      </Box>
                    </Box>
                  )}
                {!hasHireFormTemplate && isReadyToShowHireDetail(selectedCandidate.hireDetailsStatus) && (
                  <Box>
                    <Heading px={5} as="h3" fontSize="md">
                      {t('documents')}
                    </Heading>
                    <Box p={5} mb={2} borderTop={`1px solid ${colors.gray[50]}`} borderBottom={`1px solid ${colors.gray[50]}`}>
                      <Box alignItems="center">
                        {hireDetailOfCandidate?.seekerHireDetail.chequebookImageUrl && (
                          <Box w="100%" mr={2}>
                            <Heading
                              flex="1"
                              as="h6"
                              fontSize="sm"
                              lineHeight="2.2"
                              color={hasResubmissionField('chequebookImageUrl') ? 'tomato' : 'gray.800'}
                            >
                              {hireDetailOfCandidate?.seekerHireDetail.americanBankAccountDetails ? t('check') : t('chequeBook')}
                            </Heading>
                            {!Array.isArray(hireDetailOfCandidate?.seekerHireDetail.chequebookImageUrl) ? (
                              <Box position="relative">
                                <Image
                                  src={
                                    isSuperAdmin() ? hireDetailOfCandidate?.seekerHireDetail.chequebookImageUrl : PixelatedImage
                                  }
                                  alt="Cheque book photo"
                                />
                                {!isSuperAdmin() && (
                                  <Flex
                                    border="1px solid"
                                    borderColor="gray.50"
                                    p={2}
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    alignItems="center"
                                    justifyContent="center"
                                    h="100%"
                                    w="100%"
                                  >
                                    <Text d="flex" alignItems="center">
                                      <RiLock2Fill style={{ marginRight: '5px' }} />
                                      {t('noPermissionToViewImage')}
                                    </Text>
                                  </Flex>
                                )}
                              </Box>
                            ) : (
                              <MultipleImagePreview
                                showImage={isSuperAdmin()}
                                imageURLs={hireDetailOfCandidate?.seekerHireDetail.chequebookImageUrl}
                              />
                            )}

                            {checkResubmissionField('chequebookImageUrl')}
                          </Box>
                        )}
                        {(hireDetailOfCandidate?.seekerHireDetail.documentIdImageUrl ||
                          checkResubmissionField('documentIdImageUrl')) && (
                          <Box w="100%" mr={2}>
                            <Heading
                              flex="1"
                              as="h6"
                              fontSize="sm"
                              lineHeight="2.2"
                              color={hasResubmissionField('documentIdImageUrl') ? 'tomato' : 'gray.800'}
                            >
                              {t('documentToImageURL')}
                            </Heading>
                            {hireDetailOfCandidate?.seekerHireDetail.documentIdImageUrl &&
                              (!Array.isArray(hireDetailOfCandidate?.seekerHireDetail.documentIdImageUrl) ? (
                                <Box position="relative">
                                  <Image
                                    src={
                                      isSuperAdmin() ? hireDetailOfCandidate?.seekerHireDetail.documentIdImageUrl : PixelatedImage
                                    }
                                    alt="Hire Document photo"
                                  />
                                  {!isSuperAdmin() && (
                                    <Flex
                                      border="1px solid"
                                      borderColor="gray.50"
                                      p={2}
                                      position="absolute"
                                      top={0}
                                      left={0}
                                      alignItems="center"
                                      justifyContent="center"
                                      h="100%"
                                      w="100%"
                                    >
                                      <Text d="flex" alignItems="center">
                                        <RiLock2Fill style={{ marginRight: '5px' }} />
                                        {t('noPermissionToViewImage')}
                                      </Text>
                                    </Flex>
                                  )}
                                </Box>
                              ) : (
                                <MultipleImagePreview
                                  showImage={isSuperAdmin()}
                                  imageURLs={hireDetailOfCandidate?.seekerHireDetail.documentIdImageUrl}
                                />
                              ))}
                            {checkResubmissionField('documentIdImageUrl')}
                          </Box>
                        )}
                        {hireDetailOfCandidate?.seekerHireDetail.signatureImageUrl && (
                          <Box w="100%">
                            <Heading
                              flex="1"
                              as="h6"
                              fontSize="sm"
                              lineHeight="2.2"
                              color={hasResubmissionField('signatureImageUrl') ? 'tomato' : 'gray.800'}
                            >
                              {t('signature')}
                            </Heading>

                            {!Array.isArray(hireDetailOfCandidate?.seekerHireDetail.signatureImageUrl) ? (
                              <Image
                                src={hireDetailOfCandidate?.seekerHireDetail.signatureImageUrl}
                                alt="Signature photo"
                                maxWidth="250px"
                              />
                            ) : (
                              <MultipleImagePreview
                                showImage
                                imageURLs={hireDetailOfCandidate?.seekerHireDetail.signatureImageUrl}
                              />
                            )}

                            {checkResubmissionField('signatureImageUrl')}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
                {isReadyToShowHireDetail(selectedCandidate.hireDetailsStatus) && hasHireFormTemplate && (
                  <SeekerHireFormAnswers selectedCandidate={selectedCandidate} />
                )}
              </Box>
            </ModalBody>
            <ModalFooter px={5}>
              <Button colorScheme="blue" onClick={onClose}>
                {t('close')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {isHireDetailRejectedReasonModalOpen && selectedCandidate.hireDetailsStatus === 'rejected' && (
        <Modal isOpen={isHireDetailRejectedReasonModalOpen} onClose={onHireDetailRejectedReasonModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('rejectedReason')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{selectedCandidate.rejectedReason}</ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onHireDetailRejectedReasonModalClose}>
                {t('close')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default SeekerHireFormStatus;
