import { useTranslation } from 'react-i18next';
import { useCurrentUserProfile } from '../../app/hooks/useCurrentUserProfile';
import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { Query } from '../../firebase/firestore/query/query';
import { Collections } from '../../firebase/firestore/collections';
import { AppointmentAvailability } from '../../firebase/firestore/documents/appointmentAvaiability';
import CalendarPresenter from './calendarPresenter';
import { useStoreActions, useStoreState } from '../../models/hooks';
// import useAccountCandidates from '../administration/recruiterCalendar/useAccountCandidates';
import useRecruiter from '../messaging/seeker/candidate/notes/useRecruiter';
import useCandidates, { CandidatesRenderedByEnum } from './useCandidates';

const useCalendar = (
  selectedRecruiters: string[],
  activeCurrentDateRange: { fromDate: Date; toDate: Date },
  adminView?: boolean,
): CalendarPresenter => {
  const { i18n } = useTranslation();
  const { currentUserProfile } = useCurrentUserProfile();
  const { setCalendarPresenter } = useStoreActions((a) => a.calendar);
  const candidates = useCandidates(activeCurrentDateRange, CandidatesRenderedByEnum.USE_CALENDAR);
  // const candidatesOfAccount = useAccountCandidates();
  const allRecruiters = useRecruiter();
  const isOperator = useStoreState((s) => s.app.user?.operator?.enabled);
  const accessibleAccounts = useStoreState((s) => s.app.user?.operator?.accessibleAccounts);
  const defaultAccount = useStoreState((s) => s.app.user?.operator?.defaultAccount);

  // istanbul ignore next
  const recruiters = allRecruiters?.filter((r) => r?.anonymousUser !== true && r?.firstName !== 'anonymous');

  const availableAppointments = useQuerySnapshot<AppointmentAvailability>(
    Collections.APPOINTMENT_AVAILABILITY,
    [
      Query.field<AppointmentAvailability>('userId').in(selectedRecruiters.length > 0 ? selectedRecruiters.slice(0, 9) : ['']),
      Query.field<AppointmentAvailability>('fromDate').greaterThanOrEqual(activeCurrentDateRange.fromDate),
      Query.field<AppointmentAvailability>('fromDate').smallerThanOrEqual(activeCurrentDateRange.toDate),
    ],
    false,
  );
  const availableAppointments2 = useQuerySnapshot<AppointmentAvailability>(
    Collections.APPOINTMENT_AVAILABILITY,
    [
      Query.field<AppointmentAvailability>('userId').in(selectedRecruiters.length > 9 ? selectedRecruiters.slice(9, 19) : ['']),
      Query.field<AppointmentAvailability>('fromDate').greaterThanOrEqual(activeCurrentDateRange.fromDate),
      Query.field<AppointmentAvailability>('fromDate').smallerThanOrEqual(activeCurrentDateRange.toDate),
    ],
    false,
  );
  const availableAppointments3 = useQuerySnapshot<AppointmentAvailability>(
    Collections.APPOINTMENT_AVAILABILITY,
    [
      Query.field<AppointmentAvailability>('userId').in(selectedRecruiters.length > 19 ? selectedRecruiters.slice(19, 29) : ['']),
      Query.field<AppointmentAvailability>('fromDate').greaterThanOrEqual(activeCurrentDateRange.fromDate),
      Query.field<AppointmentAvailability>('fromDate').smallerThanOrEqual(activeCurrentDateRange.toDate),
    ],
    false,
  );

  if (availableAppointments && availableAppointments2 && availableAppointments3 && candidates && currentUserProfile) {
    const mergedAvailableAppointments = availableAppointments.concat(availableAppointments2).concat(availableAppointments3);
    let finalAvailableAppointments;
    if (adminView) {
      finalAvailableAppointments = mergedAvailableAppointments;
    } else if (isOperator) {
      finalAvailableAppointments = mergedAvailableAppointments.filter((value) => {
        return (
          accessibleAccounts?.includes(value.accountId) ||
          defaultAccount === value.accountId ||
          value.accountId === currentUserProfile.account
        );
      });
    } else {
      finalAvailableAppointments = mergedAvailableAppointments.filter((value) => value.accountId === currentUserProfile.account);
    }

    const calendarPresenter = new CalendarPresenter(finalAvailableAppointments, candidates, recruiters, i18n.language);
    setCalendarPresenter(calendarPresenter);
    return calendarPresenter;
  }

  return new CalendarPresenter([], [], [], i18n.language);
};

export default useCalendar;
