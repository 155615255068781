/* eslint-disable react/jsx-indent */
/* istanbul ignore file */
import React, { Fragment } from 'react';
import {
  Box,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  PopoverCloseButton,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Tooltip,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Requisition, ShiftDetail, Shifts } from '../../../../firebase/firestore/documents/requisition';
import colors from '../../../../styles/colors';
import { tableStyle } from './actionsDropdown/HireWithRequisitionStyle';

const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri'];
const weekEnds = ['sat', 'sun'];

export type CandidateRequisitionProps = {
  intersectionShift: Shifts | undefined;
  selectedRequisition: Requisition | null;
  candidateAvailabilities: { [key: string]: { start_1: string; end_1: string } } | null | undefined;
  candidateNightAvailabilities: { [key: string]: { start_1: string; end_1: string } } | null | undefined;
};

export const CandidateRequisitionOverlap = ({
  intersectionShift,
  selectedRequisition,
  candidateAvailabilities,
  candidateNightAvailabilities,
}: CandidateRequisitionProps): JSX.Element => {
  const { t } = useTranslation('requisition');

  /* istanbul ignore next */
  const onGetColorByIndex = (index: number) => {
    if (index % 2) return '#edf2f7';
    return '#ffffff';
  };

  // helper function
  const getDayRange = (day: string) => {
    const daysOfWeek = [...weekDays, ...weekEnds];
    const currentIndex = daysOfWeek.indexOf(day);
    const nextDay = currentIndex < daysOfWeek.length - 1 ? daysOfWeek[currentIndex + 1] : daysOfWeek[0];
    return `${t(`common:days.${day}`)} - ${t(`common:days.${nextDay}`)}`;
  };

  function isDifferentDayRange(start: string, end: string): boolean {
    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');
    return startTime.isAfter(endTime);
  }

  const normalizeRequisitionEndTime = (time: string | undefined): string | undefined => {
    // eslint-disable-next-line no-nested-ternary
    return time ? (time === '00:00' ? '24:00' : time) : undefined;
  };

  const isAllIntersectionsNotMatched = (reqIntersectionShift: ShiftDetail, day: string) => {
    if (reqIntersectionShift && selectedRequisition) {
      return (
        (reqIntersectionShift?.end_1 &&
          reqIntersectionShift?.end_1 !== normalizeRequisitionEndTime(selectedRequisition.shifts?.[day]?.end_1)) ||
        (reqIntersectionShift?.start_1 && reqIntersectionShift?.start_1 !== selectedRequisition.shifts?.[day]?.start_1) ||
        (reqIntersectionShift?.start_2 && reqIntersectionShift?.start_2 !== selectedRequisition.shifts?.[day]?.start_2) ||
        (reqIntersectionShift?.end_2 &&
          reqIntersectionShift?.end_2 !== normalizeRequisitionEndTime(selectedRequisition.shifts?.[day]?.end_2)) ||
        (reqIntersectionShift?.end_3 &&
          reqIntersectionShift?.end_3 !== normalizeRequisitionEndTime(selectedRequisition.shifts?.[day]?.end_1)) ||
        (reqIntersectionShift?.start_3 && reqIntersectionShift?.start_3 !== selectedRequisition.shifts?.[day]?.start_1) ||
        (reqIntersectionShift?.start_4 && reqIntersectionShift?.start_4 !== selectedRequisition.shifts?.[day]?.start_2) ||
        (reqIntersectionShift?.end_4 &&
          reqIntersectionShift?.end_4 !== normalizeRequisitionEndTime(selectedRequisition.shifts?.[day]?.end_2))
      );
    }
    return false;
  };

  const areAllInDifferentDayRanges = (shift: ShiftDetail): boolean => {
    if (!shift) return false;

    const pairs = [
      [shift.start_1, shift.end_1],
      [shift.start_2, shift.end_2],
      [shift.start_3, shift.end_3],
      [shift.start_4, shift.end_4],
    ];

    // Check if all pairs are different day ranges
    // eslint-disable-next-line no-restricted-syntax
    for (const [start, end] of pairs) {
      if (start && end && !isDifferentDayRange(start, end)) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <Flex alignItems="center" mb="6px" mt="12px" data-testid="header-section">
        <Text fontSize="14px" fontWeight="700" mr="10px" data-testid="header-title">
          {t('candidateRequisitionMatch')}
        </Text>
        <Tooltip label={t('candidateAvailabilitiesInfo')} placement="top" shouldWrapChildren data-testid="header-tooltip">
          <IoIosInformationCircleOutline />
        </Tooltip>
      </Flex>
      {intersectionShift && !_isEmpty(intersectionShift) && selectedRequisition && selectedRequisition.shifts && (
        <Flex mt="16px" alignItems="baseline" width="100%" data-testid="shift-section">
          <Box style={{ width: '50%', paddingRight: '12px' }} css={tableStyle} pr={4} data-testid="weekday-shifts">
            <table>
              {weekDays.map((day, index) => {
                const reqIntersectionShift = intersectionShift && intersectionShift[day];

                const ifEitherOfThirdOrFourthIntersectionFallOnDifferentDays =
                  (reqIntersectionShift &&
                    reqIntersectionShift?.start_3 &&
                    reqIntersectionShift?.end_3 &&
                    isDifferentDayRange(reqIntersectionShift?.start_3, reqIntersectionShift.end_3)) ||
                  (reqIntersectionShift &&
                    reqIntersectionShift?.start_4 &&
                    reqIntersectionShift?.end_4 &&
                    isDifferentDayRange(reqIntersectionShift?.start_4, reqIntersectionShift.end_4));

                return (
                  <Popover autoFocus={false} key={day}>
                    <PopoverTrigger>
                      <Flex direction="column">
                        <tr
                          style={{
                            background: isAllIntersectionsNotMatched(reqIntersectionShift, day)
                              ? colors.red[100]
                              : /* istanbul ignore next */ onGetColorByIndex(index),
                            borderTop: '1px solid #edf2f7',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          data-testid={`weekday-row-${day}`}
                        >
                          <td data-testid={`weekday-name-${day}`}>{t(`common:days.${day}`)}</td>

                          <td
                            style={{
                              padding: 0,
                              alignItems: 'center',
                              width: '74%',
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'flex-end',
                            }}
                            data-testid={`weekday-times-${day}`}
                          >
                            {reqIntersectionShift?.start_1 ||
                            reqIntersectionShift?.start_2 ||
                            reqIntersectionShift?.start_3 ||
                            reqIntersectionShift?.start_4 ? (
                              <Fragment>
                                {reqIntersectionShift &&
                                  reqIntersectionShift.start_1 &&
                                  reqIntersectionShift.end_1 &&
                                  selectedRequisition.shifts &&
                                  selectedRequisition.shifts[day] && (
                                    <td style={{ float: 'right' }}>
                                      <Flex>
                                        <Text fontSize={15} fontWeight="normal">
                                          <span
                                            style={{
                                              fontWeight:
                                                reqIntersectionShift.start_1 !== selectedRequisition?.shifts[day].start_1
                                                  ? 'bold'
                                                  : 'normal',
                                            }}
                                          >
                                            {`${moment(reqIntersectionShift.start_1, ['HH:mm']).format('LT')}`}
                                          </span>
                                          <span>{` - `}</span>
                                          <span
                                            style={{
                                              fontWeight:
                                                reqIntersectionShift.end_1 !==
                                                normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_1)
                                                  ? 'bold'
                                                  : 'normal',
                                            }}
                                          >
                                            {`${moment(reqIntersectionShift.end_1, ['HH:mm']).format('LT')}`}
                                          </span>
                                        </Text>
                                      </Flex>
                                    </td>
                                  )}

                                {/* 2nd intersection shift */}
                                {reqIntersectionShift &&
                                  reqIntersectionShift.start_2 &&
                                  reqIntersectionShift.end_2 &&
                                  selectedRequisition.shifts &&
                                  selectedRequisition.shifts[day] && (
                                    <td style={{ float: 'right' }}>
                                      <Flex>
                                        <Text fontSize={15} fontWeight="normal">
                                          <span
                                            style={{
                                              fontWeight:
                                                intersectionShift[day].start_2 !== selectedRequisition?.shifts[day].start_2
                                                  ? 'bold'
                                                  : 'normal',
                                            }}
                                          >
                                            {`${moment(intersectionShift[day].start_2, ['HH:mm']).format('LT')}`}
                                          </span>
                                          <span>{` - `}</span>
                                          <span
                                            style={{
                                              fontWeight:
                                                intersectionShift[day].end_2 !==
                                                normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_2)
                                                  ? 'bold'
                                                  : 'normal',
                                            }}
                                          >
                                            {`${moment(intersectionShift[day].end_2, ['HH:mm']).format('LT')}`}
                                          </span>
                                        </Text>
                                      </Flex>
                                    </td>
                                  )}

                                {/* 3rd intersection shift */}
                                {reqIntersectionShift &&
                                  reqIntersectionShift.start_3 &&
                                  reqIntersectionShift.end_3 &&
                                  selectedRequisition.shifts &&
                                  !isDifferentDayRange(reqIntersectionShift.start_3, reqIntersectionShift.end_3) &&
                                  selectedRequisition.shifts[day] && (
                                    <>
                                      <td style={{ float: 'right' }}>
                                        <Flex style={{ float: 'right', padding: '0' }}>
                                          <Text fontSize={15} fontWeight="normal">
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].start_3 !== selectedRequisition?.shifts[day].start_1
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].start_3, ['HH:mm']).format('LT')}`}
                                            </span>
                                            <span>{` - `}</span>
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].end_3 !==
                                                  normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_1)
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].end_3, ['HH:mm']).format('LT')}`}
                                            </span>
                                          </Text>
                                        </Flex>
                                      </td>
                                    </>
                                  )}

                                {/* 4th intersection shift */}
                                {reqIntersectionShift &&
                                  reqIntersectionShift.start_4 &&
                                  reqIntersectionShift.end_4 &&
                                  selectedRequisition.shifts &&
                                  !isDifferentDayRange(reqIntersectionShift.start_4, reqIntersectionShift.end_4) &&
                                  selectedRequisition.shifts[day] && (
                                    <>
                                      <td style={{ float: 'right' }}>
                                        <Flex>
                                          <Text fontSize={15} fontWeight="normal">
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].start_4 !== selectedRequisition?.shifts[day].start_2
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].start_4, ['HH:mm']).format('LT')}`}
                                            </span>
                                            <span>{` - `}</span>
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].end_4 !==
                                                  normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_2)
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].end_4, ['HH:mm']).format('LT')}`}
                                            </span>
                                          </Text>
                                        </Flex>
                                      </td>
                                    </>
                                  )}

                                {areAllInDifferentDayRanges(intersectionShift[day]) && (
                                  <td style={{ float: 'right' }}>
                                    <Text>---</Text>
                                  </td>
                                )}
                              </Fragment>
                            ) : (
                              <Box paddingRight={5}>
                                <Text>---</Text>
                              </Box>
                            )}
                          </td>
                        </tr>
                      </Flex>
                    </PopoverTrigger>

                    {ifEitherOfThirdOrFourthIntersectionFallOnDifferentDays && (
                      <PopoverTrigger>
                        <Flex direction="column">
                          <tr
                            style={{
                              background: isAllIntersectionsNotMatched(reqIntersectionShift, day)
                                ? colors.red[100]
                                : /* istanbul ignore next */ onGetColorByIndex(index),
                              borderTop: '1px solid #edf2f7',
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <td>{getDayRange(day)}</td>

                            <td
                              style={{
                                padding: 0,
                                alignItems: 'center',
                                width: '74%',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                              }}
                            >
                              {reqIntersectionShift?.start_3 || reqIntersectionShift?.start_4 ? (
                                <Fragment>
                                  {/* 3rd intersection shift */}
                                  {reqIntersectionShift &&
                                    reqIntersectionShift.start_3 &&
                                    reqIntersectionShift.end_3 &&
                                    selectedRequisition.shifts &&
                                    isDifferentDayRange(reqIntersectionShift.start_3, reqIntersectionShift.end_3) &&
                                    selectedRequisition.shifts[day] && (
                                      <td style={{ float: 'right' }}>
                                        <Flex style={{ float: 'right', padding: '0' }}>
                                          <Text fontSize={15} fontWeight="normal">
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].start_3 !== selectedRequisition?.shifts[day].start_1
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].start_3, ['HH:mm']).format('LT')}`}
                                            </span>
                                            <span>{` - `}</span>
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].end_3 !==
                                                  normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_1)
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].end_3, ['HH:mm']).format('LT')}`}
                                            </span>
                                          </Text>
                                        </Flex>
                                      </td>
                                    )}

                                  {reqIntersectionShift &&
                                    reqIntersectionShift.start_4 &&
                                    reqIntersectionShift.end_4 &&
                                    selectedRequisition.shifts &&
                                    isDifferentDayRange(reqIntersectionShift.start_4, reqIntersectionShift.end_4) &&
                                    selectedRequisition.shifts[day] && (
                                      <td style={{ float: 'right' }}>
                                        <Flex>
                                          <Text fontSize={15} fontWeight="normal">
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].start_4 !== selectedRequisition?.shifts[day].start_2
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].start_4, ['HH:mm']).format('LT')}`}
                                            </span>
                                            <span>{` - `}</span>
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].end_4 !==
                                                  normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_2)
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].end_4, ['HH:mm']).format('LT')}`}
                                            </span>
                                          </Text>
                                        </Flex>
                                      </td>
                                    )}
                                </Fragment>
                              ) : (
                                <Box paddingRight={5}>
                                  <Text>---</Text>
                                </Box>
                              )}
                            </td>
                          </tr>
                        </Flex>
                      </PopoverTrigger>
                    )}
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Flex>
                          <Text fontSize="16px" fontWeight={700} w="50%">
                            {t('common:requisitions')}
                          </Text>
                          <Text fontSize="16px" fontWeight={700} w="50%">
                            {t('common:candidatesHours')}
                          </Text>
                        </Flex>

                        <Flex justifyContent="space-between" backgroundColor="#edf2f7" p="10px" borderRadius="4px" mt="6px">
                          <Box w="50%">
                            {selectedRequisition?.shifts && selectedRequisition?.shifts[day] ? (
                              <Fragment>
                                <Text fontSize="14px" fontWeight={500}>
                                  {`${moment(selectedRequisition?.shifts[day].start_1, ['HH:mm']).format(
                                    'LT',
                                  )} - ${moment(selectedRequisition?.shifts[day].end_1, ['HH:mm']).format('LT')}`}
                                </Text>

                                {selectedRequisition?.shifts[day].start_2 && selectedRequisition?.shifts[day].end_2 && (
                                  <Text fontSize="14px" fontWeight={500}>
                                    {`${moment(selectedRequisition?.shifts[day].start_2, ['HH:mm']).format(
                                      'LT',
                                    )} - ${moment(selectedRequisition?.shifts[day].end_2, ['HH:mm']).format('LT')}`}
                                  </Text>
                                )}
                              </Fragment>
                            ) : (
                              <Text fontSize="14px" fontWeight={500}>
                                ---
                              </Text>
                            )}
                          </Box>

                          <Box w="50%">
                            {
                              /* istanbul ignore next */
                              (candidateAvailabilities && candidateAvailabilities[day]) ||
                              (candidateNightAvailabilities && candidateNightAvailabilities[day]) ? (
                                <Fragment>
                                  {candidateAvailabilities && candidateAvailabilities[day] && (
                                    <Text fontSize="14px" fontWeight={500}>
                                      {`${moment(candidateAvailabilities[day].start_1, ['HH:mm']).format(
                                        'LT',
                                      )} - ${moment(candidateAvailabilities[day].end_1, ['HH:mm']).format('LT')}`}
                                    </Text>
                                  )}
                                  {candidateNightAvailabilities && candidateNightAvailabilities[day] && (
                                    <Text fontSize="14px" fontWeight={500}>
                                      {`${moment(candidateNightAvailabilities[day].start_1, ['HH:mm']).format(
                                        'LT',
                                      )} - ${moment(candidateNightAvailabilities[day].end_1, ['HH:mm']).format('LT')}`}
                                    </Text>
                                  )}
                                </Fragment>
                              ) : (
                                <Text fontSize="14px" fontWeight={500}>
                                  ---
                                </Text>
                              )
                            }
                          </Box>
                        </Flex>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                );
              })}
            </table>
          </Box>

          <Box style={{ width: '50%' }} ml="12px" css={tableStyle} data-testid="weekend-shifts">
            <table>
              {weekEnds.map((day, index) => {
                const reqIntersectionShift = intersectionShift && intersectionShift[day];

                const ifEitherOfThirdOrFourthIntersectionFallOnDifferentDays =
                  (reqIntersectionShift &&
                    reqIntersectionShift?.start_3 &&
                    reqIntersectionShift?.end_3 &&
                    isDifferentDayRange(reqIntersectionShift?.start_3, reqIntersectionShift.end_3)) ||
                  (reqIntersectionShift &&
                    reqIntersectionShift?.start_4 &&
                    reqIntersectionShift?.end_4 &&
                    isDifferentDayRange(reqIntersectionShift?.start_4, reqIntersectionShift.end_4));

                return (
                  <Popover autoFocus={false} key={day}>
                    <PopoverTrigger>
                      <Flex direction="column">
                        <tr
                          style={{
                            background: isAllIntersectionsNotMatched(reqIntersectionShift, day)
                              ? colors.red[100]
                              : /* istanbul ignore next */ onGetColorByIndex(index),
                            borderTop: '1px solid #edf2f7',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          data-testid={`weekend-row-${day}`}
                        >
                          <td data-testid={`weekend-name-${day}`}>{t(`common:days.${day}`)}</td>

                          <td
                            style={{
                              padding: 0,
                              alignItems: 'center',
                              width: '74%',
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'flex-end',
                            }}
                            data-testid={`weekend-times-${day}`}
                          >
                            {reqIntersectionShift?.start_1 ||
                            reqIntersectionShift?.start_2 ||
                            (reqIntersectionShift?.start_3 &&
                              reqIntersectionShift.end_3 &&
                              !isDifferentDayRange(reqIntersectionShift.start_3, reqIntersectionShift.end_3)) ||
                            (reqIntersectionShift?.start_4 &&
                              reqIntersectionShift.end_4 &&
                              !isDifferentDayRange(reqIntersectionShift.start_4, reqIntersectionShift.end_4)) ? (
                              <Fragment>
                                {reqIntersectionShift &&
                                  reqIntersectionShift.start_1 &&
                                  reqIntersectionShift.end_1 &&
                                  selectedRequisition.shifts &&
                                  selectedRequisition.shifts[day] && (
                                    <td style={{ float: 'right' }}>
                                      <Flex>
                                        <Text fontSize={15} fontWeight="normal">
                                          <span
                                            style={{
                                              fontWeight:
                                                reqIntersectionShift.start_1 !== selectedRequisition?.shifts[day].start_1
                                                  ? 'bold'
                                                  : 'normal',
                                            }}
                                          >
                                            {`${moment(reqIntersectionShift.start_1, ['HH:mm']).format('LT')}`}
                                          </span>
                                          <span>{` - `}</span>
                                          <span
                                            style={{
                                              fontWeight:
                                                reqIntersectionShift.end_1 !==
                                                normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_1)
                                                  ? 'bold'
                                                  : 'normal',
                                            }}
                                          >
                                            {`${moment(reqIntersectionShift.end_1, ['HH:mm']).format('LT')}`}
                                          </span>
                                        </Text>
                                      </Flex>
                                    </td>
                                  )}

                                {/* 2nd intersection shift */}
                                {reqIntersectionShift &&
                                  reqIntersectionShift.start_2 &&
                                  reqIntersectionShift.end_2 &&
                                  selectedRequisition.shifts &&
                                  selectedRequisition.shifts[day] && (
                                    <td style={{ float: 'right' }}>
                                      <Flex>
                                        <Text fontSize={15} fontWeight="normal">
                                          <span
                                            style={{
                                              fontWeight:
                                                intersectionShift[day].start_2 !== selectedRequisition?.shifts[day].start_2
                                                  ? 'bold'
                                                  : 'normal',
                                            }}
                                          >
                                            {`${moment(intersectionShift[day].start_2, ['HH:mm']).format('LT')}`}
                                          </span>
                                          <span>{` - `}</span>
                                          <span
                                            style={{
                                              fontWeight:
                                                intersectionShift[day].end_2 !==
                                                normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_2)
                                                  ? 'bold'
                                                  : 'normal',
                                            }}
                                          >
                                            {`${moment(intersectionShift[day].end_2, ['HH:mm']).format('LT')}`}
                                          </span>
                                        </Text>
                                      </Flex>
                                    </td>
                                  )}

                                {/* 3rd intersection shift */}
                                {reqIntersectionShift &&
                                  reqIntersectionShift.start_3 &&
                                  reqIntersectionShift.end_3 &&
                                  selectedRequisition.shifts &&
                                  !isDifferentDayRange(reqIntersectionShift.start_3, reqIntersectionShift.end_3) &&
                                  selectedRequisition.shifts[day] && (
                                    <>
                                      <td style={{ float: 'right' }}>
                                        <Flex style={{ float: 'right', padding: '0' }}>
                                          <Text fontSize={15} fontWeight="normal">
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].start_3 !== selectedRequisition?.shifts[day].start_1
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].start_3, ['HH:mm']).format('LT')}`}
                                            </span>
                                            <span>{` - `}</span>
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].end_3 !==
                                                  normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_1)
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].end_3, ['HH:mm']).format('LT')}`}
                                            </span>
                                          </Text>
                                        </Flex>
                                      </td>
                                    </>
                                  )}

                                {reqIntersectionShift &&
                                  reqIntersectionShift.start_4 &&
                                  reqIntersectionShift.end_4 &&
                                  selectedRequisition.shifts &&
                                  !isDifferentDayRange(reqIntersectionShift.start_4, reqIntersectionShift.end_4) &&
                                  selectedRequisition.shifts[day] && (
                                    <>
                                      <td style={{ float: 'right' }}>
                                        <Flex>
                                          <Text fontSize={15} fontWeight="normal">
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].start_4 !== selectedRequisition?.shifts[day].start_2
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].start_4, ['HH:mm']).format('LT')}`}
                                            </span>
                                            <span>{` - `}</span>
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].end_4 !==
                                                  normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_2)
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].end_4, ['HH:mm']).format('LT')}`}
                                            </span>
                                          </Text>
                                        </Flex>
                                      </td>
                                    </>
                                  )}
                              </Fragment>
                            ) : (
                              <Box paddingRight={5}>
                                <Text>---</Text>
                              </Box>
                            )}
                          </td>
                        </tr>
                      </Flex>
                    </PopoverTrigger>

                    {ifEitherOfThirdOrFourthIntersectionFallOnDifferentDays && (
                      <PopoverTrigger>
                        <Flex direction="column">
                          <tr
                            style={{
                              background:
                                reqIntersectionShift?.end_1 !==
                                  normalizeRequisitionEndTime(selectedRequisition.shifts?.[day]?.end_1) ||
                                reqIntersectionShift?.start_1 !== selectedRequisition.shifts?.[day]?.start_1 ||
                                reqIntersectionShift?.start_2 !== selectedRequisition.shifts?.[day]?.start_2 ||
                                reqIntersectionShift?.end_2 !==
                                  normalizeRequisitionEndTime(selectedRequisition.shifts?.[day]?.end_2) ||
                                reqIntersectionShift?.end_3 !==
                                  normalizeRequisitionEndTime(selectedRequisition.shifts?.[day]?.end_1) ||
                                reqIntersectionShift?.start_3 !== selectedRequisition.shifts?.[day]?.start_1 ||
                                reqIntersectionShift?.start_4 !== selectedRequisition.shifts?.[day]?.start_2 ||
                                reqIntersectionShift?.end_4 !==
                                  normalizeRequisitionEndTime(selectedRequisition.shifts?.[day]?.end_2)
                                  ? colors.red[100]
                                  : /* istanbul ignore next */ onGetColorByIndex(index),
                              borderTop: '1px solid #edf2f7',
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            {/* {anyIntersectionAvailable ? (
                            <td>{isDayRangeRequired ? getDayRange(day) : t(`common:days.${day}`)}</td>
                          ) : ( */}
                            <td>{getDayRange(day)}</td>
                            {/* )} */}

                            <td
                              style={{
                                padding: 0,
                                alignItems: 'center',
                                width: '74%',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                              }}
                            >
                              {reqIntersectionShift?.start_3 || reqIntersectionShift?.start_4 ? (
                                <Fragment>
                                  {/* 3rd intersection shift */}
                                  {reqIntersectionShift &&
                                    reqIntersectionShift.start_3 &&
                                    reqIntersectionShift.end_3 &&
                                    selectedRequisition.shifts &&
                                    isDifferentDayRange(reqIntersectionShift.start_3, reqIntersectionShift.end_3) &&
                                    selectedRequisition.shifts[day] && (
                                      <td style={{ float: 'right' }}>
                                        <Flex style={{ float: 'right', padding: '0' }}>
                                          <Text fontSize={15} fontWeight="normal">
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].start_3 !== selectedRequisition?.shifts[day].start_1
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].start_3, ['HH:mm']).format('LT')}`}
                                            </span>
                                            <span>{` - `}</span>
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].end_3 !==
                                                  normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_1)
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].end_3, ['HH:mm']).format('LT')}`}
                                            </span>
                                          </Text>
                                        </Flex>
                                      </td>
                                    )}

                                  {reqIntersectionShift &&
                                    reqIntersectionShift.start_4 &&
                                    reqIntersectionShift.end_4 &&
                                    selectedRequisition.shifts &&
                                    isDifferentDayRange(reqIntersectionShift.start_4, reqIntersectionShift.end_4) &&
                                    selectedRequisition.shifts[day] && (
                                      <td style={{ float: 'right' }}>
                                        <Flex>
                                          <Text fontSize={15} fontWeight="normal">
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].start_4 !== selectedRequisition?.shifts[day].start_2
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].start_4, ['HH:mm']).format('LT')}`}
                                            </span>
                                            <span>{` - `}</span>
                                            <span
                                              style={{
                                                fontWeight:
                                                  intersectionShift[day].end_4 !==
                                                  normalizeRequisitionEndTime(selectedRequisition.shifts[day].end_2)
                                                    ? 'bold'
                                                    : 'normal',
                                              }}
                                            >
                                              {`${moment(intersectionShift[day].end_4, ['HH:mm']).format('LT')}`}
                                            </span>
                                          </Text>
                                        </Flex>
                                      </td>
                                    )}
                                </Fragment>
                              ) : (
                                <Box paddingRight={5}>
                                  <Text>---</Text>
                                </Box>
                              )}
                            </td>
                          </tr>
                        </Flex>
                      </PopoverTrigger>
                    )}
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Flex>
                          <Text fontSize="16px" fontWeight={700} w="50%">
                            {t('common:requisitions')}
                          </Text>
                          <Text fontSize="16px" fontWeight={700} w="50%">
                            {t('common:candidatesHours')}
                          </Text>
                        </Flex>

                        <Flex justifyContent="space-between" backgroundColor="#edf2f7" p="10px" borderRadius="4px" mt="6px">
                          <Box w="50%">
                            {selectedRequisition?.shifts && selectedRequisition?.shifts[day] ? (
                              <Fragment>
                                <Text fontSize="14px" fontWeight={500}>
                                  {`${moment(selectedRequisition?.shifts[day].start_1, ['HH:mm']).format(
                                    'LT',
                                  )} - ${moment(selectedRequisition?.shifts[day].end_1, ['HH:mm']).format('LT')}`}
                                </Text>

                                {selectedRequisition?.shifts[day].start_2 && selectedRequisition?.shifts[day].end_2 && (
                                  <Text fontSize="14px" fontWeight={500}>
                                    {`${moment(selectedRequisition?.shifts[day].start_2, ['HH:mm']).format(
                                      'LT',
                                    )} - ${moment(selectedRequisition?.shifts[day].end_2, ['HH:mm']).format('LT')}`}
                                  </Text>
                                )}
                              </Fragment>
                            ) : (
                              <Text fontSize="14px" fontWeight={500}>
                                ---
                              </Text>
                            )}
                          </Box>

                          <Box w="50%">
                            {
                              /* istanbul ignore next */
                              (candidateAvailabilities && candidateAvailabilities[day]) ||
                              (candidateNightAvailabilities && candidateNightAvailabilities[day]) ? (
                                <Fragment>
                                  {candidateAvailabilities && candidateAvailabilities[day] && (
                                    <Text fontSize="14px" fontWeight={500}>
                                      {`${moment(candidateAvailabilities[day].start_1, ['HH:mm']).format(
                                        'LT',
                                      )} - ${moment(candidateAvailabilities[day].end_1, ['HH:mm']).format('LT')}`}
                                    </Text>
                                  )}
                                  {candidateNightAvailabilities && candidateNightAvailabilities[day] && (
                                    <Text fontSize="14px" fontWeight={500}>
                                      {`${moment(candidateNightAvailabilities[day].start_1, ['HH:mm']).format(
                                        'LT',
                                      )} - ${moment(candidateNightAvailabilities[day].end_1, ['HH:mm']).format('LT')}`}
                                    </Text>
                                  )}
                                </Fragment>
                              ) : (
                                <Text fontSize="14px" fontWeight={500}>
                                  ---
                                </Text>
                              )
                            }
                          </Box>
                        </Flex>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                );
              })}
            </table>
          </Box>
        </Flex>
      )}
    </>
  );
};
