export type RecruiterTodoListHeaderType = {
  action: string;
  lastStatus: string;
  status: string;
  locationAndPosition: string;
  candidateName: string;
};

export type RecruiterTodoListDataType = {
  id: string;
  actionType: ActionEnum;
  title: string;
  description: string;
  lastStatus: string;
  status: string;
  location: string;
  position: string;
  candidateFullName: string;
  candidateId: string;
  customName: string;
  todoStatus: string;
  category: string;
  helpRequestedMessages?: {
    userType: 'seeker' | 'recruiter';
    message: string;
    timestamp: Date;
    name: string;
  }[];
  latestHelpRequestedFor?: string;
  hireDetailsRequestedAt?: string;
  seekerHireDetailId?: string;
  statusAssignedBy?: TodoStatusAssignedByType;
};

export type TodoStatusAssignedByType = {
  recruiterName: string;
  recruiterId: string;
};

export enum ActionEnum {
  BACKGROUND_CHECK,
  HIRE_FORM,
  OFFER,
  LANGUAGE_EVALUATION,
  CONVERSATIONAL_AI,
  OTHER,
}

export enum StatusEnum {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}
