import { action, Action, thunk, Thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { HRFormTemplateController } from '../../../controllers/HRFormTemplateController';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { FieldSchema, HrFormTemplateGetAllResponse } from '../hireForm/formElements/formBuilderSchema';
import { ValidationErrorItem } from 'joi';
import { AxiosError } from 'axios';
import { History } from 'history';
import { PATH_ADMINISTRATION_TEMPLATES } from '../../../routes/constants';

export interface CurrentSchema {
  account: string;
  type: string | null;
  createdAt: string;
  createdBy: string;
  id?: string;
  name?: string;
  needFinalSignature?: boolean;
  questions: FieldSchema[];
}
export type HrFormTemplateStoreModel = {
  hrFormTemplate: HRFormTemplateModel;
};
export interface HRFormTemplateModel {
  isLoading: boolean;
  setLoader: Action<HRFormTemplateModel, boolean>;
  isUpdating: boolean;
  setIsUpdating: Action<HRFormTemplateModel, boolean>;
  templates: HrFormTemplateGetAllResponse | null;
  errors: ValidationErrorItem[];
  setErrors: Action<HRFormTemplateModel, ValidationErrorItem[]>;
  setTemplates: Action<HRFormTemplateModel, HrFormTemplateGetAllResponse>;
  currentTemplate?: CurrentSchema;
  setCurrentTemplate: Action<HRFormTemplateModel, CurrentSchema | undefined>;
  getTemplates: Thunk<
    HRFormTemplateModel,
    { account: string; type?: string | null; after?: string | null; before?: string | null; limit?: number | null },
    void,
    HttpStoreModel,
    Promise<HrFormTemplateGetAllResponse>
  >;
  createTemplate: Thunk<
    HRFormTemplateModel,
    { account: string; payload: { name: string; questions: any; needFinalSignature: boolean } },
    void,
    HttpStoreModel,
    Promise<any>
  >;
  getTemplateById: Thunk<
    HRFormTemplateModel,
    { account: string; id: string; history: History },
    void,
    HttpStoreModel,
    Promise<any>
  >;
  updateTemplate: Thunk<
    HRFormTemplateModel,
    { account: string; id: string; payload: { name: string; questions: any; needFinalSignature: boolean } },
    void,
    HttpStoreModel,
    Promise<any>
  >;
  deleteTemplate: Thunk<HRFormTemplateModel, { account: string; id: string }, void, HttpStoreModel, Promise<any>>;
  removeTemplateFromList: Action<HRFormTemplateModel, string>;
  uploadingDocument: boolean;
  setUploadingDocument: Action<HRFormTemplateModel, boolean>;
  uploadDocument: Thunk<HRFormTemplateModel, { account: string; payload: FormData }, void, HttpStoreModel, Promise<string>>;
  tags: string[];
  setTags: Action<HRFormTemplateModel, string[]>;
  getTags: Thunk<HRFormTemplateModel, { account: string }, void, HttpStoreModel, Promise<any>>;
  checkUniqueTemplateName: Thunk<HRFormTemplateModel, { account: string; name: string }, void, HttpStoreModel, Promise<string>>;
}

export const hrFormTemplateStoreModel: HRFormTemplateModel = {
  isLoading: false,
  setLoader: action((state, payload) => {
    state.isLoading = payload;
  }),
  isUpdating: false,
  setIsUpdating: action((state, payload) => {
    state.isUpdating = payload;
  }),
  templates: null,
  setTemplates: action((state, payload) => {
    state.templates = payload;
  }),
  errors: [],
  setErrors: action((state, payload) => {
    state.errors = payload;
  }),
  currentTemplate: undefined,
  setCurrentTemplate: action((state, payload) => {
    state.currentTemplate = payload;
  }),
  getTemplates: thunk(async (actions, { account, type, after, before, limit }, { getStoreState }) => {
    try {
      actions.setLoader(true);
      const response = await new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry).getTemplates(
        account,
        type,
        after,
        before,
        limit,
      );
      actions.setTemplates(response?.data);
      return response?.data;
    } finally {
      actions.setLoader(false);
    }
  }),
  createTemplate: thunk(async (actions, { account, payload }, { getStoreState }) => {
    try {
      actions.setIsUpdating(true);
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.createTemplate(account, payload);
      actions.setErrors([]);
      return response?.data;
    } catch (error) {
      const newErr = error as AxiosError<{ code: number; message: string; errors: ValidationErrorItem[] }>;
      if (newErr.response?.status === 409) {
        const error = newErr.response.data.errors as any;
        actions.setErrors([
          {
            path: ['name'],
            message: error,
          } as ValidationErrorItem,
        ]);
      } else {
        actions.setErrors(newErr.response?.data?.errors ?? []);
      }
      throw newErr;
    } finally {
      actions.setIsUpdating(false);
    }
  }),
  getTemplateById: thunk(async (actions, { account, id, history }, { getStoreState }) => {
    try {
      actions.setLoader(true);
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.getTemplateById(account, id);

      actions.setCurrentTemplate(response?.data as any);
      return response?.data;
    } catch (error) {
      const newErr = error as AxiosError<{ code: number; message: string; errors: ValidationErrorItem[] }>;
      if (newErr.response?.status === 401) {
        history.replace(PATH_ADMINISTRATION_TEMPLATES);
      }
    } finally {
      actions.setLoader(false);
    }
  }),
  updateTemplate: thunk(async (actions, { account, id, payload }, { getStoreState }) => {
    try {
      actions.setIsUpdating(true);
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.updateTemplate(account, id, payload);
      actions.setErrors([]);
      return response?.data?.data?.templateId;
    } catch (error) {
      const newErr = error as AxiosError<{ code: number; message: string; errors: ValidationErrorItem[] }>;
      if (newErr.response?.status === 409) {
        const error = newErr.response.data.errors as any;
        actions.setErrors([
          {
            path: ['name'],
            message: error,
          } as ValidationErrorItem,
        ]);
      } else {
        actions.setErrors(newErr.response?.data?.errors ?? []);
      }
      throw newErr;
    } finally {
      actions.setIsUpdating(false);
    }
  }),
  deleteTemplate: thunk(async (actions, { account, id }, { getStoreState }) => {
    try {
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.deleteTemplate(account, id);
      actions.removeTemplateFromList(id);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }),
  removeTemplateFromList: action((state, payload) => {
    const templates = [...state.templates?.templates!]?.filter((template) => template.id !== payload);
    state.templates = { ...state.templates!, templates };
  }),
  uploadingDocument: false,
  setUploadingDocument: action((state, payload) => {
    state.uploadingDocument = payload;
  }),

  uploadDocument: thunk(async (actions, { account, payload }, { getStoreState }) => {
    try {
      actions.setUploadingDocument(true);
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.uploadDocument(account, payload);
      return response?.data?.data?.url;
    } catch (error) {
      throw error;
    } finally {
      actions.setUploadingDocument(false);
    }
  }),

  tags: [],
  setTags: action((state, payload) => {
    state.tags = payload;
  }),

  getTags: thunk(async (actions, { account }, { getStoreState }) => {
    try {
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.getTags(account);
      actions.setTags(response?.data);
      return response?.data;
    } catch (error) {
      actions.setTags([]);
      throw error;
    }
  }),
  checkUniqueTemplateName: thunk(async (_actions, { account, name }, { getStoreState }) => {
    try {
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.checkUniqueTemplateName(account, name);
      return response?.data?.suggestedName;
    } catch (error) {
      throw error;
    }
  }),
};
