import { AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { FitbandTemplateData } from '../modules/administration/fitbandConfiguration/FitbandConfigurationTypes';

export class FitbandConfigurationController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async createFitbandTemplate(accountId: string, templateData: FitbandTemplateData): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/fitBandTemplate`;
    const body = templateData;
    return this.httpClient.post(path, body);
  }

  async copyFitbandTemplate(accountId: string, templateId: string): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/fitBandTemplate/${templateId}/copy`;
    return this.httpClient.post(path);
  }

  async getFitbandTemplates(
    accountId: string,
    params: { after?: string | null; before?: string | null },
  ): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/fitBandTemplate`;
    const response = await this.httpClient.get(path, { params });
    return response;
  }

  async updateFitbandTemplate(accountId: string, templateId: string, templateData: FitbandTemplateData): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/fitBandTemplate/${templateId}`;
    const body = templateData;
    return this.httpClient.put(path, body);
  }

  async getFitbandTemplateDetail(accountId: string, templateId: string): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/fitBandTemplate/${templateId}/getTemplateById`;
    return this.httpClient.get(path);
  }
}
