export default {
  VIDEO: 'Video',
  PHONE: 'Phone',
  INPERSON: 'In Person',
  APPOINTMENT_ASSESSMENT_POSITIVE: 'Interview Assessment Positive',
  APPOINTMENT_ASSESSMENT_NEGATIVE: 'Interview Assessment Negative',
  SEEKER_APPOINTMENT_REMINDER_SENT: 'Seeker Interview Reminder Sent',
  CANDIDATE_BOOKMARKED: 'Bookmarked',
  CANDIDATE_CALLED: 'Call',
  CANDIDATE_DISMISSED: 'Dismissed',
  CANDIDATE_HIRED: 'Hired',
  OFFER_MADE: 'Offer Made',
  // OFFER_REJECTED: 'Candidate Dismissed',
  OFFER_REJECTED: 'Offer Revoked',
  CANDIDATE_UNHIRED: 'Candidate Unhired',
  CANDIDATE_UNLOCKED: 'Engaged',
  CONTACT_REQUEST_SENT: 'Contact Request Sent',
  CONTACT_REQUEST_ACCEPTED: 'Contact Request Accepted',
  CONTACT_REQUEST_DECLINED: 'Contact Request Declined',
  POSITION_LIKED: 'Applied',
  POSITION_UNLIKED: 'Unliked',
  QUESTION_ANSWERED: 'Question Answered',
  INTEREST_REVOKED: 'Interest Revoked',
  SAVED_FROM_AUTO_DISMISSAL: 'Saved From Auto Dismissal',
  APPOINTMENT_PERIOD_CANCELLED: 'Interview Canceled',
  INVITED_FOR_SCREENING: 'Background Check Invitation',
  SCREENING_COMPLETED: 'Background Check Complete',
  VIEW_CHECK_HISTORY_ONLY: 'View Background Check History Only',
  NO_BACKGROUND_HISTORY: 'No background Check History',
  UNKNOWN: 'Unknown',
  CALL: 'Call',
  VIDEO_INTERVIEW_JOIN_FAILED: 'Trouble Connecting To Video Interview',
  VIDEO_INTERVIEW_JOINED: 'Joined Video Interview',
  FREE_FORM_QUESTION_ANSWERED: 'Free Form Question Answered',
  APPOINTMENT_REQUEST_ACCEPTED: 'Interview Request Accepted',
  APPOINTMENT_REQUEST_CANCELLED: 'Interview Request Cancelled',
  APPOINTMENT_REQUEST_CREATED: 'Interview Request Created',
  OFFER_ACCEPETED_BY_SEEKER: 'Offer Accepted',
  OFFER_REJECTED_BY_SEEKER: 'Offer Rejected',
  View_free_form_answers: 'View Free Form Answers',
  not_answered: 'Not Answered',
  answered: 'Answered',
  yes: 'YES',
  no: 'NO',
  automation: 'Automation',
  HIRE_FORM_REQUESTED: 'Hire Form Requested',
  HIRE_FORM_COMPLETED: 'Hire Form Completed',
  HIRE_FORM_RESUBMISSION_REQUESTED: 'Hire Form Resubmission Requested',
  CANDIDATE_FORM_HELP_REQUESTED: 'Hire Form Help Requested',
  backgroundcheck: 'Background Check',
  hireForm: 'Documents',
  appointment: 'Interview',
  offer: 'Offer',
  candidate: 'Candidate',
  all: 'All',
  no_hire_form_history: 'No Documents History',
  no_appointement_history: 'No Appointment History',
  no_offer_history: 'No Offer Form History',
  no_candidate_history: 'No Candidates Form History',
  no_history: 'No History',
  MODERN_HIRE_ASSESSMENT_STARTED: 'Requested',
  MODERN_HIRE_ASSESSMENT_IN_PROGRESS: 'In Progress',
  MODERN_HIRE_ASSESSMENT_PASSED: 'Passed',
  MODERN_HIRE_ASSESSMENT_FAILED: 'Failed',
  modernHire: 'Competency Evaluation Status',
  ASSESSMENT_STARTED: 'Requested',
  CANDIDATE_APPROVED: 'Hire Form Approved',
  CANDIDATE_REJECTED: 'Hire Form Rejected',
  CANDIDATE_RECORDING_UPLOADED: 'Language Audio Available',
  languageEvaluation: 'Language Evaluation',
  CANDIDATE_LANGUAGE_EVALUATION_EXPERT: 'Language Evaluation Completed - Expert',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_EXPERT: 'Language Evaluation Completed - Expert',
  CANDIDATE_LANGUAGE_EVALUATION_COMPETENT: 'Language Evaluation Completed - Competent',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_COMPETENT: 'Language Evaluation Completed - Competent',
  CANDIDATE_LANGUAGE_EVALUATION_LIMITED: 'Language Evaluation Completed - Limited',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_LIMITED: 'Language Evaluation Completed - Limited',
  CANDIDATE_LANGUAGE_EVALUATION_OCCASIONAL: 'Language Evaluation Completed - Minimal',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_OCCASIONAL: 'Language Evaluation Completed - Minimal',
  CANDIDATE_LANGUAGE_EVALUATION_NEEDS_REVIEW: 'Language Evaluation Completed - Needs Interview',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_NEEDS_REVIEW: 'Language Evaluation Completed - Needs Interview',
  APPOINTMENT_REQUEST_DECLINED: '$t({{ appointmentType }}) Interview Request Declined',
  OFFER_REVOKE: 'Offer Revoked',
  close: 'Close',
  hireDetailsResubmissionRequestTitle: 'Hire Form Resubmission Request',
  hireDetailsResubmissionRequestSubTitle: 'The candidate has been asked to resubmit the following information:',
  view: 'View Details',
  CANDIDATE_LANGUAGE_EVALUATION_REQUESTED: 'Language Evaluation Requested',
  HIRE_FORM_IN_PROGRESS: 'Hire Form Started',
  HIRE_FORM_RESUBMISSION_IN_PROGRESS: 'Hire Form Resubmission Started',
  CANDIDATE_APPOINTMENT_EXPIRED: 'Interview Request Expired',

  CANDIDATE_LANGUAGE_EVALUATION_RE_REQUESTED: 'Language Evaluation Re-requested',
  coInterviewerCandidateAssessment: `Co-Interviewer Candidate Assessment`,
  COLLABORATOR_INTERVIEW_REVIEW: `Co-Interviewer Assessment`,
  viewReview: 'View Assessment',
  availability: 'Availability',
  compact: 'Compact',
  detailed: 'Detailed',
  expectedWorkHours: "Candidate's Max Hours",
  NOT_LOOKING_FOR_WORK: 'Not looking for work',
  DEACTIVATED: 'Deactivated account',
  OFFBOARDED: 'Employee Terminated',
  ONDEMAND_INTERVIEW_CREATED: 'On-Demand Interview Scheduled',
  ONDEMAND_INTERVIEW_CANCELLED: 'On-Demand Interview Canceled',
  ONDEMAND_INTERVIEW_ACCEPTED: 'On-Demand Interview Accepted',
};
