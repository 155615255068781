import React from 'react';
import DroppableElement from '../formElementBuilder/DroppableElement';
import { EmptyComponent } from '../EmptyComponent';
import theme from '../../../../../styles/customTheme';
import { HStack, Text } from '@chakra-ui/core';
import { IoMoveSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import useHandleDrop from '../useHandleDrop';

interface EmptySectionDropZoneProps {
  sectionIndex: number;
}

const EmptySectionDropZone = React.memo(
  function ({ sectionIndex }: EmptySectionDropZoneProps) {
    const { t } = useTranslation('hrFormTemplate');
    // const { formSchema, setFormSchema, draggedElement } = useHireFormContext();
    const { handleDropToSection } = useHandleDrop({ sectionIndex, fieldIndex: 0 });

    // const handleDrop = () => {
    //   if (draggedElement) {
    //     if (draggedElement.panel === 'basic') {
    //       const { defaultValue } = draggedElement;
    //       if (isArray(defaultValue)) return;
    //       const newSchema = formSchema?.map<SectionSchema>((item, index) => {
    //         if (index === sectionIndex) {
    //           return {
    //             ...item,
    //             fields: [...item.fields, { ...defaultValue, timestamp: Date.now(), id: uuidv4(), section: item.section }],
    //           };
    //         }
    //         return item;
    //       });

    //       setFormSchema(newSchema);
    //     }
    //   }
    // };
    return (
      <DroppableElement
        onDrop={(_, dropTarget) => {
          handleDropToSection({ dropTarget });
        }}
        // currentType={draggedElement?.panel!}
        acceptedTypes={['basic', 'advanced']}
        index={-1}
        disableDropZone
        hideDivider
      >
        <EmptyComponent
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: sectionIndex === 0 ? 200 : 'auto',
            margin: theme.space[4],
          }}
        >
          <HStack color="#586073" align="center" justify="center" flexGrow={1}>
            <IoMoveSharp size={24} />
            <Text fontSize="sm" fontWeight="bold">
              {t('formBuilder.dragBoxInfo')}
            </Text>
          </HStack>
        </EmptyComponent>
      </DroppableElement>
    );
  },
  (prev, next) => prev.sectionIndex === next.sectionIndex,
);

export default EmptySectionDropZone;
