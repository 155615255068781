import { Grid, GridItem, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import FormComponent from './FormComponent';
import theme from '../../../../styles/customTheme';
import { ComponentPanels } from '../formElements/formBuilderSchema';
import { formBuilderSchemas } from '../formBuilderSchemas/formBuilder.schema';

export interface ComponentGridProps {
  currentPanel: ComponentPanels;
  search: string;
  title?: string;
}

const ComponentGrid = React.memo(({ currentPanel, search, title }: ComponentGridProps) => {
  const elements = Object.entries(formBuilderSchemas)?.filter(
    ([_, schema]) =>
      !(schema.panel !== currentPanel) && !schema.hidden && schema.label.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <Stack>
      {title && elements.length > 0 && <Text fontSize="sm">{title}</Text>}
      <Grid gridTemplateColumns="repeat(auto-fit, minmax(136px,1fr))" gridAutoRows="69px" gridGap={theme.space[3]}>
        {elements?.map(([inputType, schema]) => {
          return (
            <GridItem key={inputType} gridColumn={Object.keys(formBuilderSchemas).length > 1 ? 'auto' : '1 / -1'}>
              <FormComponent component={schema} />
            </GridItem>
          );
        })}
      </Grid>
    </Stack>
  );
});

export default ComponentGrid;
