/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { Redirect } from 'react-router-dom';
import { PATH_MAIN } from '../../routes/constants';
import useConfiguration, { IsUnderMaintenanceEnum } from '../../app/hooks/useConfiguration';
import { MaintenanceView } from '../../error/MaintenanceView';

import useAccounts from '../../app/hooks/useAccounts';
import { CalendarPanel } from './CalendarPanel';
import useCalendar from './useCalendar';
import useCalendarEvent from './useCalendarEvent';
import useRecruiter from '../messaging/seeker/candidate/notes/useRecruiter';
import { useCurrentUserProfile } from '../../app/hooks/useCurrentUserProfile';
import usePositions from '../administration/recruiterCalendar/usePositions';
import { CalendarSidebarTabTypeEnum, RecruiterCalendarSidebarTabTypeEnum } from './CalendarSidebarTab';

const getCurrentUserId = (currentUserProfileId: string | undefined) => {
  return currentUserProfileId ? [currentUserProfileId] : [];
};

export const CalendarView = (): JSX.Element => {
  const [showCalendar, setShowCalendar] = React.useState<boolean>(true);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedRecruitersOfPositions, setSelectedRecruitersOfPositions] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<RecruiterCalendarSidebarTabTypeEnum>(RecruiterCalendarSidebarTabTypeEnum.SCHEDULE);
  const { currentUserProfile } = useCurrentUserProfile();
  const [activeCurrentDateRange, setActiveCurrentDateRange] = useState<{ fromDate: Date; toDate: Date }>({
    fromDate: moment(moment().startOf('month').subtract(6, 'days').format('YYYY-MM-DDT00:00:00')).toDate(),
    toDate: moment(moment().endOf('month').add(6, 'days').format('YYYY-MM-DDT23:59:59')).toDate(),
  });

  useCalendar(
    activeTab === RecruiterCalendarSidebarTabTypeEnum.SCHEDULE
      ? getCurrentUserId(currentUserProfile?.id)
      : selectedRecruitersOfPositions,
    activeCurrentDateRange,
  );
  useCalendarEvent();

  const allRecruiters = useRecruiter();
  const positions = usePositions();

  const accounts = useAccounts();
  const useConfig = useConfiguration();

  // istanbul ignore next
  const recruiters = allRecruiters?.filter((r) => r?.anonymousUser !== true && r?.firstName !== 'anonymous');

  const myPositions =
    currentUserProfile && positions.filter((position) => position.assignedRecruiters?.includes(currentUserProfile?.id));

  useEffect(() => {
    if (accounts) {
      setShowCalendar(accounts.calendarEnabled === true);
    }
  }, [accounts]);

  const filteredPositions = () => {
    if (currentUserProfile) {
      return positions.filter((position) => position.assignedRecruiters?.includes(currentUserProfile?.id));
    }
    return positions;
  };

  const handleAssignedRecruiter = (positionIds: string[]) => {
    let assignedRecruiters: string[] = [];
    positionIds.forEach((positionId) => {
      const positionRecruiters: string[] | undefined = filteredPositions().filter((position) => position.id === positionId)[0]
        ?.assignedRecruiters;
      /* istanbul ignore next */
      if (positionRecruiters && !assignedRecruiters.some((r) => positionRecruiters.includes(r))) {
        assignedRecruiters = assignedRecruiters.concat(positionRecruiters);
      }
    });
    setSelectedRecruitersOfPositions(assignedRecruiters);
  };

  const handleCheckedPositions = (positionIds: string[]) => {
    setSelectedPositions(positionIds);
    handleAssignedRecruiter(positionIds);
  };

  const handleActiveTab = (tab: RecruiterCalendarSidebarTabTypeEnum | CalendarSidebarTabTypeEnum) => {
    setActiveTab(tab as RecruiterCalendarSidebarTabTypeEnum);
  };

  if (!showCalendar) {
    return <Redirect to={PATH_MAIN} />;
  }

  const setActiveDateRange = (fromDate: Date, toDate: Date) => {
    setActiveCurrentDateRange({ fromDate, toDate });
  };

  return (
    <React.Fragment>
      {useConfig === IsUnderMaintenanceEnum.MAINTENANCE && <MaintenanceView />}
      <CalendarPanel
        todayDate={moment().toDate()}
        recruiters={recruiters}
        positions={myPositions}
        administrationCalendar={false}
        handleCheckedPositions={handleCheckedPositions}
        checkedPositions={selectedPositions}
        activeTab={activeTab}
        setActiveTab={handleActiveTab}
        setActiveDateRange={setActiveDateRange}
      />
    </React.Fragment>
  );
};
