/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* istanbul ignore file */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  Flex,
  Box,
  Button,
  Heading,
  Text,
  // Stack,
  // InputGroup,
  // InputLeftElement,
  // Input,
  FormLabel,
  FormControl,
  Switch,
  useDisclosure,
  // Divider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@chakra-ui/core';
import { Column } from 'react-table';
// import { SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import axios, { AxiosError } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { IoAdd } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import FitbandConfigurationTableView from './FitbandConfigurationTableView';
import { FitbandTemplateCreateModal } from './FitbandTemplateCreateModal';
import { FormattedError } from '../../../utils/FormErrorUtils';
import { FitbandTemplateType, FitbandType } from './FitbandConfigurationTypes';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { TemplatePagination } from '../languageEvaluation/languageEvaluationTemplate/TemplatePagination';
import { PATH_ADMINISTRATION } from '../../../routes/constants';

export type FitbandCalculationConfiguration = {
  requisitionChecked: boolean;
  availabilityChecked: boolean;
  reqDays: number;
  reqHours: number;
  extraDays: number;
  extraHours: number;
};

export const FitbandConfigurationView = (): JSX.Element => {
  const { t } = useTranslation('administration');
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const toast = useToast();
  const dataTableContainer = useRef<HTMLDivElement | null>(null);
  const accountId = useStoreState((state) => state.app.accounts?.id);
  const appUserId = useStoreState((s) => s.app.user?.id);
  const superOwners = useStoreState((s) => s.app.accounts?.superOwners);
  const isFitbandTemplateEnabled = useStoreState((s) => s.app.accounts?.configuration?.fitbandTemplateEnabled) ?? false;

  const isSuperAdmin = (): boolean => {
    if (!superOwners || !appUserId) {
      return false;
    }
    return superOwners?.includes(appUserId);
  };

  useEffect(() => {
    // istanbul ignore next
    if (!isFitbandTemplateEnabled) {
      history?.replace(PATH_ADMINISTRATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFitbandTemplateEnabled]);

  const { getFitbandTemplates, copyFitbandTemplate, getFitbandTemplateDetail, updateFitbandTemplate } = useStoreActions(
    (actions) => actions.fitbandTemplate,
  );

  const initialFitbandData = {
    name: '',
    goodFit: 0,
    mediumFit: 0,
  };

  const [fitbandTemplate, setFitbandTemplate] = useState<FitbandType>(initialFitbandData);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [fitbandToEdit, setFitbandToEdit] = useState<FitbandTemplateType | null>(null);
  const [viewFitbandTemplate, setViewFitbandTemplate] = useState<FitbandTemplateType | null>(null);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(false);

  const [currentTemplates, setCurrentTemplates] = useState<FitbandTemplateType[]>([]);
  const [showDefault, setShowDefault] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasNextValue, setNextValue] = useState<boolean | undefined>(false);
  const [hasPrevValue, setPrevValue] = useState<boolean | undefined>(false);
  const [firstId, setFirstId] = useState<string | undefined>(undefined);
  const [lastId, setLastId] = useState<string | undefined>(undefined);
  const [totalCount, setTotalCount] = useState<number>();
  const [dataCount, setDataCount] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  // const [queryParams, setQueryParams] = useState({
  //   searchQuery: '',
  // });

  const [penaltyData, setPenaltyData] = useState<FitbandCalculationConfiguration>({
    requisitionChecked: false,
    availabilityChecked: false,
    reqDays: 0,
    reqHours: 0,
    extraDays: 0,
    extraHours: 0,
  });
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);

  const templateHeaderFromTranslation = {
    name: t('fitbandConfiguration.tableHeaders.name'),
    created: t('fitbandConfiguration.tableHeaders.createdOn'),
    edited: t('fitbandConfiguration.tableHeaders.lastEdited'),
    action: t('languageEvaluation.action'),
  };

  const templateColumns: Column<FitbandTemplateType>[] = [
    {
      Header: templateHeaderFromTranslation.name,
      accessor: 'name',
    },
    {
      Header: templateHeaderFromTranslation.created,
      accessor: 'createdAt',
    },
    {
      Header: templateHeaderFromTranslation.edited,
      accessor: 'modifiedAt',
    },
  ];

  if (isSuperAdmin()) {
    templateColumns.push({
      Header: templateHeaderFromTranslation.action,
      accessor: 'actionType',
    } as Column<FitbandTemplateType>);
  }

  // const onChangeSearchText = (value: string) => {
  //   setQueryParams((prevData) => ({
  //     ...prevData,
  //     searchQuery: value,
  //     // page: 1,
  //   }));
  // };

  // function that fetches fitband templates
  const onGetFitbandTemplates = useCallback(
    // eslint-disable-next-line consistent-return
    async (pageParams?: { after?: string; before?: string }) => {
      if (accountId) {
        const params: { after?: string; before?: string; type?: string; limit?: string } = {
          limit: '10',
        };

        if (pageParams?.after) {
          params.after = pageParams.after;
        }

        if (pageParams?.before) {
          params.before = pageParams.before;
        }

        if (showDefault) {
          params.type = 'default';
        }

        const response = await getFitbandTemplates({ accountId, params });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const sortedTemplates = response?.templates;

        setNextValue(response.hasNext);
        setPrevValue(response.hasPrevious);
        setFirstId(response?.before);
        setLastId(response?.after);
        setCurrentTemplates(sortedTemplates);
        setTotalCount(response.total);

        setIsLoading(false);
        return response;
      }
    },
    [accountId, getFitbandTemplates, showDefault],
  );

  useEffect(() => {
    onGetFitbandTemplates().catch((err) => console.log(err));
  }, [onGetFitbandTemplates]);

  useEffect(() => {
    // istanbul ignore next
    if (!isFetching) {
      if (showDefault) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onGetFitbandTemplates().then((response) => {
          setDataCount(response?.templates?.length);
        });
      } else {
        // setDataCount(currentTemplates.length);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onGetFitbandTemplates().then((response) => {
          setDataCount(response?.templates?.length);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDefault]);

  useEffect(() => {
    setDataCount((prevCount) => (prevCount === 0 ? currentTemplates.length : prevCount));
  }, [currentTemplates]);

  const handleNextPage = async () => {
    if (hasNextValue && lastId && !isFetching) {
      setIsFetching(true);
      setIsLoading(true);
      try {
        const res = await onGetFitbandTemplates({ after: lastId });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/restrict-plus-operands
        setDataCount((prevCount) => prevCount + res?.templates?.length);
      } finally {
        setIsLoading(false);
        setIsFetching(false);
      }
    }
  };

  const handlePreviousPage = async () => {
    if (hasPrevValue && firstId && !isFetching) {
      setIsFetching(true);
      setIsLoading(true);
      try {
        await onGetFitbandTemplates({ before: firstId });
        setDataCount((prevCount) => prevCount - currentTemplates.length);
      } finally {
        setIsLoading(false);
        setIsFetching(false);
      }
    }
  };

  // copy template
  const handleFitbandCopy = async (data: FitbandTemplateType) => {
    if (!data.id) return;

    if (accountId) {
      await copyFitbandTemplate({ accountId, templateId: data.id });
      toast({
        title: t('fitbandConfiguration.fitbandToastMessage.copyTemplateTitle'),
        description: t('fitbandConfiguration.fitbandToastMessage.copyTemplateSuccess', {
          templateName: data.name,
        }),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }
    await onGetFitbandTemplates();
  };

  // get template detail by id
  const getFitbandEdit = async (id: string) => {
    if (!id) return;

    if (accountId) {
      const res = await getFitbandTemplateDetail({ accountId, templateId: id });
      setFitbandToEdit(res);
    }
    onOpen();
  };

  const handleViewFitband = async (id: string) => {
    if (!id) return;

    if (accountId) {
      const response = await getFitbandTemplateDetail({ accountId, templateId: id });
      setViewFitbandTemplate(response);
      setIsEditDisabled(true);
    }
    onOpen();
  };

  const resetData = () => {
    setFitbandTemplate({
      name: '',
      goodFit: 0,
      mediumFit: 0,
    });
    setPenaltyData((prevData) => ({
      ...prevData,
      requisitionChecked: false,
      availabilityChecked: false,
      reqDays: 0,
      reqHours: 0,
      extraDays: 0,
      extraHours: 0,
    }));
    setFitbandToEdit(null);
  };

  const onFitbandUpdate = async () => {
    if (!fitbandToEdit?.id) return;

    const payload = {
      name: fitbandTemplate.name,
      fitBand: {
        goodFit: fitbandTemplate.goodFit,
        mediumFit: fitbandTemplate.mediumFit,
      },
      fitBandCalculationConfigurations: {
        shouldPenalizeExtraTime: penaltyData.availabilityChecked,
        penalizeFactorExtraDays: !isEmpty(penaltyData.extraDays) ? penaltyData.extraDays : 0,
        penalizeFactorExtraHours: !isEmpty(penaltyData.extraHours) ? penaltyData.extraHours : 0,
        shouldPenalizeRequiredTime: penaltyData.requisitionChecked,
        penalizeFactorRequiredDays: !isEmpty(penaltyData.reqDays) ? penaltyData.reqDays : 0,
        penalizeFactorRequiredHours: !isEmpty(penaltyData.reqHours) ? penaltyData.reqHours : 0,
      },
    };

    try {
      setIsUpdateLoading(true);
      if (fitbandToEdit?.id && accountId) {
        await updateFitbandTemplate({ accountId, templateId: fitbandToEdit?.id, templateData: payload });

        toast({
          title: t('fitbandConfiguration.fitbandToastMessage.templateEdit'),
          description: t('fitbandConfiguration.fitbandToastMessage.templateEditSuccess', {
            templateName: fitbandTemplate.name,
          }),
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setIsUpdateLoading(false);
        resetData();
        onConfirmClose();
        await onGetFitbandTemplates();
      }
    } catch (err) {
      setIsUpdateLoading(false);
      console.error('Error updating template', err);
      let defaultMessage = t('fitbandConfiguration.fitbandToastMessage.nameExists');
      const newError = err as AxiosError<{ message: string }>;
      if (axios.isAxiosError(newError)) {
        defaultMessage = newError.response?.data?.message || defaultMessage;
      }
      toast({
        description: defaultMessage,
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
      throw err;
    }
  };

  return (
    <Box backgroundColor="white" p={4}>
      {/* <Heading as="h4" fontSize="lg" lineHeight="1.3">
        Fitband Configuration Templates
      </Heading>
      <Divider pt={4} color={colors.gray[100]} /> */}
      <Flex py={4} px={3} alignItems="center" justifyContent="space-between" width="100%">
        <Heading as="h4" fontSize="lg" lineHeight="1.3">
          {t('fitbandConfiguration.templateHeader')}
        </Heading>
        {/* Will be used for enhancement feature */}
        {/* <Box width="50%">
          <Stack spacing={1}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" fontSize={14} />
              </InputLeftElement>
              <Input
                data-testid="searchInput"
                type="text"
                value={queryParams.searchQuery}
                placeholder="Search a template by name"
                style={{ backgroundColor: '#E2E8F0', borderRadius: '20px' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeSearchText(e.target.value)}
                // onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && onGetBusinessList(false)}
              />
            </InputGroup>
          </Stack>
        </Box> */}
        <Box display="flex" justifyContent="flex-end" alignItems="center" width="50%">
          <FormControl w="auto" display="flex" alignItems="center" mr={3}>
            <FormLabel htmlFor="showDefaultTemplate" mb="0">
              {t('fitbandConfiguration.showDefaults')}
            </FormLabel>
            <Switch
              size="sm"
              id="showDefaultTemplate"
              data-testid="showDefaultTemplate"
              cursor="pointer"
              onChange={(e) => setShowDefault(e.target.checked)}
            />
          </FormControl>
          {isSuperAdmin() && (
            <Button
              data-testid="createBtn"
              colorScheme="blue"
              leftIcon={<IoAdd size={22} />}
              size="md"
              marginLeft="6px"
              onClick={onOpen}
            >
              <Text>{t('fitbandConfiguration.createNew')}</Text>
            </Button>
          )}
        </Box>
      </Flex>

      <Box ref={dataTableContainer} height="100%" overflowY="auto">
        <Box bg="#fff">
          <FitbandConfigurationTableView
            data={currentTemplates}
            columns={templateColumns}
            isLoading={isLoading}
            handleCopy={handleFitbandCopy}
            handleEdit={getFitbandEdit}
            handleView={handleViewFitband}
          />
          <TemplatePagination
            hasNext={hasNextValue}
            hasPrevious={hasPrevValue}
            isFetching={isFetching}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            totalDataCount={totalCount}
            dataCount={dataCount}
            showingResultsTranslation={(variable: string) => t('languageEvaluation.showingResult', variable)}
          />
        </Box>
      </Box>

      {isOpen && (
        <FitbandTemplateCreateModal
          isOpen={isOpen}
          onConfirmOpen={onConfirmOpen}
          onClose={onClose}
          templateData={fitbandTemplate}
          setTemplateData={setFitbandTemplate}
          fitbandTemplateEdit={fitbandToEdit}
          setFitbandTemplateEdit={setFitbandToEdit}
          fitbandTemplateView={viewFitbandTemplate}
          setFitbandTemplateView={setViewFitbandTemplate}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          getFitbandTemplates={onGetFitbandTemplates}
          isDisabled={isEditDisabled}
          setIsDisabled={setIsEditDisabled}
          penaltyData={penaltyData}
          setPenaltyData={setPenaltyData}
        />
      )}

      {isConfirmOpen && (
        <Modal
          isOpen={isConfirmOpen}
          onClose={onConfirmClose}
          isCentered
          size="2xl"
          data-testid="fitbandConfirmModal"
          scrollBehavior="inside"
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent overflow="auto" p={3}>
            <ModalHeader>
              <Box>
                <Text fontSize="20px" fontWeight={700}>
                  {t('fitbandConfiguration.confirmChanges')}
                </Text>
              </Box>
            </ModalHeader>
            <ModalBody>
              <Box>
                <Text fontSize="14px" fontWeight={400}>
                  {t('fitbandConfiguration.areYouSureText')}
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter mt={3}>
              <Button data-testid="cancelBtn" colorScheme="red" onClick={() => onConfirmClose()}>
                {t('fitbandConfiguration.cancelBtn')}
              </Button>
              <Button
                isLoading={isUpdateLoading}
                data-testid="saveBtn"
                colorScheme="blue"
                ml={3}
                disabled={isUpdateLoading}
                onClick={onFitbandUpdate}
              >
                {t('fitbandConfiguration.saveBtn')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};
