import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { HStack, Stack } from '@chakra-ui/core';
import InputPreview from './InputPreview';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';
import { useTranslation } from 'react-i18next';

export default function AddressSchemaBuilder(props: SchemaBuilderProps) {
  const { t } = useTranslation('hrFormTemplate');

  return (
    <Stack>
      <HStack justify="space-between">
        <LabelSchemaBuilder {...props} />
        {props?.labelRightRenderer}
      </HStack>
      <FormElementTextBuilder {...props} placeholder={t('formBuilder.editorPlaceholder.address')} />
      <InputPreview value={props?.defaultValue?.previewPlaceholders?.placeholder ?? 'Address'} />
    </Stack>
  );
}
