import { Checkbox, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import { FieldSchema, formBuilderType, isFieldSchema, SchemaBuilderProps, Sections } from './formBuilderSchema';
import { schemaFields } from '../formBuilderSchemas/formBuilder.schema';

export default function CheckboxDisable({ defaultValue, language, section, onChange }: SchemaBuilderProps) {
  const isDisabled = React.useMemo(
    () => defaultValue?.disabled || false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValue],
  );
  const [value, setValue] = React.useState(!isDisabled);

  React.useEffect(() => {
    setValue(!isDisabled);
  }, [isDisabled]);

  function handleOnChange(value: boolean) {
    setValue(value);
    const disableId = defaultValue.disabledId;
    const fields = section.fields
      ?.filter((field) => isFieldSchema(field) && !field.isFrontEndOnly)
      .map((field) => {
        if (isFieldSchema(field)) {
          if (defaultValue.section === Sections.sin && field.field === schemaFields.socialInsuranceNumber) {
            return { ...field, enableWorkPermit: value };
          } else if (
            defaultValue.id !== field.id &&
            disableId &&
            disableId === field.disabledId &&
            field.inputType !== formBuilderType.checkboxDisable
          ) {
            return { ...field, disabled: !value };
          }
        }

        return field as FieldSchema;
      });

    onChange(fields);
  }

  return (
    <Stack>
      <Checkbox isChecked={value} onChange={(e) => handleOnChange(e.target.checked)}>
        <Text fontSize="sm">{defaultValue.text?.[language]}</Text>
      </Checkbox>
    </Stack>
  );
}
