export default {
  header: {
    date: 'Date',
    status: 'Statut',
  },
  createNotes: {
    saveNote: 'Enregistrer la note',
    deleteNote: 'Supprimer la note',
    cancel: 'Annuler',
  },
  noteList: {
    compose: 'Composer',
    status: 'Statut',
    salaryOffered: 'Salaire offert',
    unit: 'Unité',
    recruiterName: 'Nom du recruteur',
    collaboratorName: 'Co-intervieweur',
    anonymousRecruiter: 'Recruteur anonyme',
    updatedDate: 'Date de mise à jour',
    noteType: 'Type de note',
    interveiwRating: "Évaluation de l'entrevue",
    salary: 'Le salaire et sa fréquence',
    dateOfJoining: 'Date of joining',
  },
  noteDetail: {
    editNote: 'Modifier la note',
    back: 'Retour',
    status: 'Statut',
  },
  deleteNote: {
    headerText: 'Supprimer la note',
    description: 'Cette note sera supprimée définitivement. Cette action est irréversible.',
  },
  deleteSuccessMsg: {
    title: 'Succès !',
    description: 'Note supprimée avec succès.',
  },
  noteType: {
    NOTE: 'Note',
    FEEDBACK: 'Évaluation',
    OFFER_DETAILS: "Détails de l'offre",
    OFFER_REJECTED_DETAILS: "Détails de l'offre refusé",
    HIRE_DETAILS: "Détails de l'embauche",
    UNHIRE_DETAILS: "Détails de l'annulation d'embauche",
  },
  noNotes: 'Pas de notes!',
  noNotesMessage:
    "Vous pouvez ajouter des notes sur ce candidat ici, elles seront visibles si le candidat postule pour un autre poste à l'avenir.",
  noteSuccessMsg: {
    title: 'Succès !',
    description: 'Note enregistrée avec succès',
  },
  manual: 'Défini par recruteur',
};
