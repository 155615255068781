import { HStack, Text } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../styles/customTheme';

interface ButtonPreviewProps {
  label: string;
  rightContent?: React.ReactNode;
  disabled?: boolean;
}
export default function ButtonPreview({ label, rightContent, disabled = false }: ButtonPreviewProps) {
  return (
    <HStack
      border="1px solid"
      borderColor={theme.colors.blue[400]}
      borderRadius={theme.radii['3xl']}
      paddingX={3}
      paddingY={1}
      width="fit-content"
      opacity={disabled ? 0.5 : 1}
    >
      <Text fontSize={theme.fontSizes.sm} color={theme.colors.blue[400]} fontWeight={'bold'}>
        {label}
      </Text>
      {rightContent}
    </HStack>
  );
}
