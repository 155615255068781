import Joi from 'joi';

export default function useHandleTemplateValidation() {
  // Define the LabelSchema
  const LabelSchema = Joi.object({
    en: Joi.string().required().min(1).messages({
      'any.required': 'validation.fieldRequired',
      'string.empty': 'validation.fieldRequired',
      'string.min': 'validation.fieldRequired',
    }), // At least 'en' is required
    fr: Joi.string().when('en', {
      is: Joi.valid(null, '').required(), // Check if `en` is missing
      then: Joi.string().required().messages({
        'any.required': 'validation.requiredInEnglish',
        'string.empty': 'validation.requiredInEnglish',
      }),
      otherwise: Joi.allow(null, ''),
    }),
  });

  // Define the OptionSchema
  const OptionSchema = Joi.object({
    id: Joi.string()
      .guid({ version: ['uuidv4'] })
      .required(),
    text: LabelSchema.required(),
    tag: Joi.string().allow(null, '').optional(),
  });

  // Define the DocumentReviewSchema
  const DocumentReviewSchema = Joi.object({
    acknowledgeContent: LabelSchema.required(),
    description: LabelSchema.required(),
    confirmText: LabelSchema.required(),
    url: Joi.object({
      en: Joi.string().uri().messages({
        'string.uri': 'validation.validUrlInEnglish',
        'string.empty': 'validation.urlRequiredInEnglish',
      }),

      fr: Joi.string().uri().messages({
        'string.uri': 'validation.validUrlInFrench',
        'string.empty': 'validation.urlRequiredInFrench',
      }),
    })
      .custom((value, helpers) => {
        const { en, fr } = value;

        if (!en && !fr) {
          return helpers.error('any.required', { message: 'validation.urlRequiredForBoth' });
        }

        // If the user is in English mode, but `fr` is missing, show the error in English
        if (!fr) {
          return helpers.error('any.required', { message: 'validation.urlRequiredInFrench' });
        }

        // If the user is in French mode, but `en` is missing, show the error in French
        if (!en) {
          return helpers.error('any.required', { message: 'validation.urlRequiredInEnglish' });
        }

        return value;
      })
      .required()
      .messages({
        'object.missing': 'validation.urlRequiredForBoth',
        'any.required': 'validation.urlRequiredForBoth',
        'string.empty': 'validation.urlRequiredForBoth',
      }),
  }).allow(null);

  const FollowUpSchema = Joi.object({
    id: Joi.string()
      .guid({ version: ['uuidv4'] })
      .required(),
    label: LabelSchema.required(),
    field: Joi.string().required(),
    inputType: Joi.string().required(),
    text: LabelSchema.required(),
    isInfo: Joi.boolean(),
    order: Joi.number().integer().positive().required(),
    optionList: Joi.array().items(OptionSchema).allow(null),
    isFollowUp: Joi.boolean().optional(),
    min: Joi.number().integer().positive().allow(null),
    max: Joi.number().integer().positive().allow(null),
    link: LabelSchema.allow(null),
    parent: Joi.string()
      .guid({ version: ['uuidv4'] })
      .allow(null),
    optionId: Joi.string()
      .guid({ version: ['uuidv4'] })
      .allow(null),
    followUpQuestionList: Joi.array().items(Joi.link('#followUpSchema')).allow(null),
    section: Joi.string().required(),
    parentSection: Joi.string().allow(null),
  }).custom((value, helpers) => {
    if (value.isFollowUp !== true) {
      return helpers.error('any.invalid', {
        message: 'validation.invalidFollowUp',
      });
    }
    return value;
  });

  // Define the FieldSchema
  const QuestionSchema = Joi.object({
    id: Joi.string()
      .guid({ version: ['uuidv4'] })
      .required(),
    label: LabelSchema.required(),
    field: Joi.string().required(),
    inputType: Joi.string().required(),
    text: LabelSchema.required(),
    isInfo: Joi.boolean(),
    order: Joi.number().integer().positive().required(),
    optionList: Joi.array().items(OptionSchema).allow(null),
    isFollowUp: Joi.boolean().optional(),
    followUpQuestionList: Joi.array().items(FollowUpSchema).allow(null),
    min: Joi.number().integer().positive().allow(null),
    max: Joi.number().integer().positive().allow(null),
    link: LabelSchema.allow(null),
    parent: Joi.string()
      .guid({ version: ['uuidv4'] })
      .allow(null),
    optionId: Joi.string()
      .guid({ version: ['uuidv4'] })
      .allow(null),
    documentReview: DocumentReviewSchema, // Optional but required fields inside documentReview if present
    enableWorkPermit: Joi.boolean().optional(),
    section: Joi.string().required(),
    parentSection: Joi.string().allow(null),
  }).custom((value, helpers) => {
    // Validate `followUpQuestionList` optionIds
    if (value.followUpQuestionList && value.optionList) {
      const validOptionIds = value.optionList.map((option: { id: string }) => option.id);
      for (const followUp of value.followUpQuestionList) {
        if (followUp.optionId && !validOptionIds.includes(followUp.optionId)) {
          return helpers.error('any.invalid', {
            message: 'validation.invalidFollowUpOption',
          });
        }
      }
    }

    // Validate unique IDs in `optionList`
    if (value.optionList) {
      const optionIds = value.optionList.map((option: { id: string }) => option.id);
      const uniqueOptionIds = new Set(optionIds);
      if (uniqueOptionIds.size !== optionIds.length) {
        return helpers.error('any.invalid', {
          message: 'validation.duplicateOptions',
        });
      }
    }

    return value;
  });
  // Set the ID for the base schema

  // Define the Question schema that extends from the base schema
  // const QuestionSchema = BaseQuestionSchema.keys({
  //   section: Joi.string().required(),
  // })
  //   .id('questionSchema')

  // Define the root schema
  const QuestionsSchema = Joi.array()
    .items(QuestionSchema)
    .custom((values, helpers) => {
      // Check for unique IDs across the entire form
      const ids = values.map((item: { id: string }) => item.id);
      const uniqueIds = new Set(ids);
      if (uniqueIds.size !== ids.length) {
        return helpers.error('any.invalid', {
          message: 'validation.duplicateQuestionId',
        });
      }

      return values;
    });

  const TemplateSchema = Joi.object({
    name: Joi.string().required().messages({
      'any.required': 'validation.templateNameRequired',
      'string.empty': 'validation.templateNameRequired',
    }),
    questions: QuestionsSchema.required(),
  });

  return { QuestionSchema, QuestionsSchema, TemplateSchema };
}
