import isArray from 'lodash/isArray';
import { useHireFormContext } from '../HireForm.context';
import { FormBuilderSchema, formBuilderType, generateFormSchema } from './formBuilderSchema';

export default function useHandleAddSignature() {
  const { formSchema } = useHireFormContext()!;

  function checkAddSignature({
    defaultValue,
    type,
  }: {
    defaultValue: FormBuilderSchema['defaultValue'];
    type: FormBuilderSchema['type'];
  }) {
    if (isArray(defaultValue)) {
      return defaultValue;
    }

    if (type === formBuilderType.signature) {
      const schemaQuestions = generateFormSchema(formSchema, 'en');
      const signatures = schemaQuestions
        ?.filter((question) => question.inputType === formBuilderType.signature)
        ?.reduce((acc, question) => {
          acc.push(question?.label?.en ?? '');
          return acc;
        }, [] as string[]);
      const highestSignature = signatures
        .map((sig) => parseInt(sig.replace('signature', ''), 10)) // Extract numbers
        .filter((num) => !isNaN(num)) // Remove any NaN values (if any)
        .reduce((max, num) => Math.max(max, num), 0);
      return { ...defaultValue, label: { en: `signature${highestSignature + 1}`, fr: `signature${highestSignature + 1}` } };
    }
    return defaultValue;
  }

  return { checkAddSignature };
}
