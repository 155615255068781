import { BsInputCursorText } from 'react-icons/bs';
import {
  AdvancedFormBuilderTypes,
  BasicFormBuilderTypes,
  FieldSchema,
  FormBuilderSchema,
  formBuilderType,
  FormBuilderTypes,
  LabelSchema,
  Sections,
} from '../formElements/formBuilderSchema';
import TextFieldSchemaBuilder from '../formElements/TextFieldSchemaBuilder';
import { v4 as uuidv4 } from 'uuid';
import {
  MdFiberPin,
  MdLink,
  MdMiscellaneousServices,
  MdOutlineAccessTime,
  MdOutlineArticle,
  MdOutlineBackup,
  MdOutlineCheckBox,
  MdOutlinePerson,
  MdOutlinePin,
  MdSmartButton,
  MdTextFields,
  MdViewAgenda,
  MdContactPhone,
  MdPerson,
} from 'react-icons/md';
import EmailSchemaBuilder from '../formElements/EmailSchemaBuilder';
import { IoCalendar, IoCall, IoCheckbox, IoImage, IoLocation, IoMail, IoRadioButtonOnOutline, IoToggle } from 'react-icons/io5';
import PhoneSchemaBuilder from '../formElements/PhoneSchemaBuilder';
import LinkSchemaBuilder from '../formElements/LinkSchemaBuilder';
import DatePickerSchemaBuilder from '../formElements/DatePickerSchemaBuilder';
import TimePickerSchemaBuilder from '../formElements/TimePickerSchemaBuilder';
import NumberSchemaBuilder from '../formElements/NumberSchemaBuilder';
import RadioSchemaBuilder from '../formElements/RadioSchemaBuilder';
import MCQSchemaBuilder from '../formElements/MCQSchemaBuilder';
import YesNoSchemaBuilder from '../formElements/YesNoSchemaBuilder';
import AddressSchemaBuilder from '../formElements/AddressSchemaBuilder';
import ImageSchemaBuilder from '../formElements/ImageSchemaBuilder';
import CheckBoxSchemaBuilder from '../formElements/CheckBoxSchemaBuilder';
import ButtonSchemaBuilder from '../formElements/ButtonSchemaBuilder';
import { FaLanguage, FaSignature } from 'react-icons/fa';
import SignatureSchemaBuilder from '../formElements/SignatureSchemaBuilder';
import { AiFillBank } from 'react-icons/ai';
import DocumentPreview from '../formElements/advancedElements/documentPreview';
import CheckboxDisable from '../formElements/CheckboxDisable';

export const fields = [
  'name',
  'email',
  'gender',
  'dateOfBirth',
  'correspondenceLanguage',
  'phoneNumber',
  'optionalPhoneNumber',
  'socialInsuranceNumber',
  'completeAddress',
  'postalCode',
  'apartment',
  'emergencyContact',
  'signatureImageUrl',
  'chequebookImageUrl',
  'workPermitImageUrl',
  'workPermitEndDate',
  'address',
  'documentIdImageUrl',
  'firstName',
  'middleName',
  'lastName',
  'nickname',
  'bankAccountNumber',
  'routingNumber',
  'branchTransitNumber',
  'financialInstitutionNumber',
  'addressLineOne',
  'addressLineTwo',
  'city',
  'region',
  'postalCode',
  'country',
  'contractDetailsWordings',
  'emergencyContactFirstName',
  'emergencyContactLastName',
  'emergencyContactPhoneNumber',
  'codeofconductandethics',
  'privacynoticeforemployees',
  'electronicmonitoringpolicy',
];

export enum schemaFields {
  name = 'name',
  email = 'email',
  gender = 'gender',
  dateOfBirth = 'dateOfBirth',
  correspondenceLanguage = 'correspondenceLanguage',
  phoneNumber = 'phoneNumber',
  optionalPhoneNumber = 'optionalPhoneNumber',
  socialInsuranceNumber = 'socialInsuranceNumber',
  socialSecurityNumber = 'socialSecurityNumber',
  completeAddress = 'completeAddress',
  apartment = 'apartment',
  emergencyContact = 'emergencyContact',
  signatureImageUrl = 'signatureImageUrl',
  chequebookImageUrl = 'chequebookImageUrl',
  workPermitImageUrl = 'workPermitImageUrl',
  workPermitEndDate = 'workPermitEndDate',
  address = 'address',
  documentIdImageUrl = 'documentIdImageUrl',
  firstName = 'firstName',
  middleName = 'middleName',
  lastName = 'lastName',
  nickname = 'nickname',
  bankAccountNumber = 'bankAccountNumber',
  routingNumber = 'routingNumber',
  branchTransitNumber = 'branchTransitNumber',
  financialInstitutionNumber = 'financialInstitutionNumber',
  addressLineOne = 'addressLineOne',
  addressLineTwo = 'addressLineTwo',
  city = 'city',
  region = 'region',
  postalCode = 'postalCode',
  country = 'country',
  contractDetailsWordings = 'contractDetailsWordings',
  emergencyContactFirstName = 'emergencyContactFirstName',
  emergencyContactLastName = 'emergencyContactLastName',
  emergencyContactPhoneNumber = 'emergencyContactPhoneNumber',
  endDate = 'endDate',
  codeofconductandethics = 'codeofconductandethics',
  privacynoticeforemployees = 'privacynoticeforemployees',
  electronicmonitoringpolicy = 'electronicmonitoringpolicy',
  earliestAvailabilityDate = 'earliestAvailabilityDate',
  signature = 'signature',

  workPermitType = 'workPermitType',
  legalFirstName = 'legalFirstName',
  legalMiddleName = 'legalMiddleName',
  legalLastName = 'legalLastName',
}

export const inputFieldLabel: Record<BasicFormBuilderTypes, LabelSchema> = {
  [formBuilderType.text]: {
    en: 'Text',
    fr: 'Texte',
  },
  [formBuilderType.info]: {
    en: 'Info',
    fr: 'Info',
  },
  [formBuilderType.number]: {
    en: 'Number',
    fr: 'Nombre',
  },
  [formBuilderType.date]: {
    en: 'Date',
    fr: 'Date',
  },
  [formBuilderType.time]: {
    en: 'Time',
    fr: 'Heure',
  },
  [formBuilderType.phone]: {
    en: 'Phone',
    fr: 'Telephone',
  },
  [formBuilderType.yesNo]: {
    en: 'Yes/No',
    fr: 'Oui/Non',
  },
  [formBuilderType.checkbox]: {
    en: 'Multiple Choice',
    fr: 'Choix multiples',
  },
  [formBuilderType.radio]: {
    en: 'Single Choice',
    fr: 'Choix unique',
  },
  [formBuilderType.image]: {
    en: 'Image',
    fr: 'Image',
  },
  [formBuilderType.file]: {
    en: 'File',
    fr: 'Fichier',
  },
  [formBuilderType.link]: {
    en: 'Link',
    fr: 'Lien',
  },
  [formBuilderType.button]: {
    en: 'Button',
    fr: 'Bouton',
  },
  [formBuilderType.singleCheckbox]: {
    en: 'Checkbox',
    fr: 'case à cocher',
  },
  [formBuilderType.documentReview]: {
    en: 'Document Review',
    fr: 'Examen des documents',
  },
  [formBuilderType.checkboxDisable]: {
    en: 'Checkbox Disable',
    fr: 'Case à cocher désactivée',
  },
  [formBuilderType.sin]: {
    en: 'Social Insurance Number',
    fr: 'Numéro d’assurance sociale',
  },
  [formBuilderType.ssn]: {
    en: 'Social Security Number',
    fr: 'Numéro de securité sociale',
  },
  [formBuilderType.signature]: {
    en: 'Signature',
    fr: 'Signature',
  },
  [schemaFields.bankAccountNumber]: {
    en: 'Bank Account Number',
    fr: 'Numéro de compte bancaire',
  },
  [schemaFields.routingNumber]: {
    en: 'Routing Number',
    fr: 'Numéro d’acheminement',
  },
  [schemaFields.branchTransitNumber]: {
    en: 'Branch Transit Number',
    fr: 'Numéro de transit de l’agence',
  },
  [schemaFields.financialInstitutionNumber]: {
    en: 'Financial Institution Number',
    fr: 'Numéro de l’institut financier',
  },
  [schemaFields.correspondenceLanguage]: {
    en: 'Correspondence Language',
    fr: 'Langue de correspondance',
  },
  [schemaFields.gender]: {
    en: 'Gender',
    fr: 'Sexe',
  },
  [schemaFields.email]: {
    en: 'Email',
    fr: 'Email',
  },
  [schemaFields.address]: {
    en: 'Address',
    fr: 'Adresse',
  },
};

export const defaultFieldLabel: Record<schemaFields, LabelSchema> = {
  [schemaFields.name]: {
    en: 'Name',
    fr: 'Nom',
  },
  [schemaFields.dateOfBirth]: {
    en: 'Date of Birth',
    fr: 'Date de naissance',
  },
  [schemaFields.phoneNumber]: {
    en: 'Phone Number',
    fr: 'Numéro de téléphone',
  },
  [schemaFields.optionalPhoneNumber]: {
    en: 'Optional Phone Number',
    fr: 'Numéro de téléphone facultatif',
  },
  [schemaFields.socialInsuranceNumber]: {
    en: 'Social Insurance Number',
    fr: 'Numéro d’assurance sociale',
  },
  [schemaFields.socialSecurityNumber]: {
    en: 'Social Security Number',
    fr: 'Numéro de securité sociale',
  },
  [schemaFields.completeAddress]: {
    en: 'Complete Address',
    fr: 'Adresse complète',
  },
  [schemaFields.apartment]: {
    en: 'Apartment',
    fr: 'Appartement',
  },
  [schemaFields.emergencyContact]: {
    en: 'Emergency Contact',
    fr: 'Contact d’urgence',
  },
  [schemaFields.signatureImageUrl]: {
    en: 'Signature',
    fr: 'Signature',
  },
  [schemaFields.chequebookImageUrl]: {
    en: 'Cheque',
    fr: 'Chèque',
  },
  [schemaFields.workPermitImageUrl]: {
    en: 'Work Permit',
    fr: 'Permis de travail',
  },
  [schemaFields.workPermitEndDate]: {
    en: 'Work Permit End Date',
    fr: 'Date de fin du permis de travail',
  },
  [schemaFields.documentIdImageUrl]: {
    en: 'Document Image',
    fr: 'Image du document',
  },
  [schemaFields.firstName]: {
    en: 'First Name',
    fr: 'Prénom',
  },
  [schemaFields.middleName]: {
    en: 'Middle Name',
    fr: 'Deuxième prénom',
  },
  [schemaFields.lastName]: {
    en: 'Last Name',
    fr: 'Nom de famille',
  },
  [schemaFields.nickname]: {
    en: 'Nickname',
    fr: 'Pseudo',
  },
  [schemaFields.addressLineOne]: {
    en: 'Address Line 1',
    fr: 'Ligne d’adresse 1',
  },
  [schemaFields.addressLineTwo]: {
    en: 'Address Line 2',
    fr: 'Ligne d’adresse 2',
  },
  [schemaFields.city]: {
    en: 'City',
    fr: 'Ville',
  },
  [schemaFields.region]: {
    en: 'Region',
    fr: 'Région',
  },
  [schemaFields.postalCode]: {
    en: 'Postal Code',
    fr: 'Code postal',
  },
  [schemaFields.country]: {
    en: 'Country',
    fr: 'Pays',
  },
  [schemaFields.contractDetailsWordings]: {
    en: 'Contract Details Wordings',
    fr: 'Libellés des détails du contrat',
  },
  [schemaFields.emergencyContactFirstName]: {
    en: 'Emergency Contact First Name',
    fr: 'Prénom du contact d’urgence',
  },
  [schemaFields.emergencyContactLastName]: {
    en: 'Emergency Contact Last Name',
    fr: 'Nom de famille du contact d’urgence',
  },
  [schemaFields.emergencyContactPhoneNumber]: {
    en: 'Emergency Contact Phone Number',
    fr: 'Numéro de téléphone du contact d’urgence',
  },
  [schemaFields.endDate]: {
    en: 'End Date',
    fr: 'Date de fin',
  },
  [schemaFields.codeofconductandethics]: {
    en: 'Code of Conduct and Ethics',
    fr: 'Code de conduite et d’ethique',
  },
  [schemaFields.privacynoticeforemployees]: {
    en: 'Privacy Notice for Employees',
    fr: 'Avis de confidentialité pour les employés',
  },
  [schemaFields.electronicmonitoringpolicy]: {
    en: 'Electronic Monitoring Policy',
    fr: 'Politique de surveillance électronique',
  },
  [schemaFields.bankAccountNumber]: {
    en: 'Bank Account Number',
    fr: 'Numéro de compte bancaire',
  },
  [schemaFields.routingNumber]: {
    en: 'Routing Number',
    fr: 'Numéro d’acheminement',
  },
  [schemaFields.branchTransitNumber]: {
    en: 'Branch Transit Number',
    fr: 'Numéro de transit de l’agence',
  },
  [schemaFields.financialInstitutionNumber]: {
    en: 'Financial Institution Number',
    fr: 'Numéro de l’institut financier',
  },
  [schemaFields.correspondenceLanguage]: {
    en: 'Correspondence Language',
    fr: 'Langue de correspondance',
  },
  [schemaFields.gender]: {
    en: 'Gender',
    fr: 'Genre',
  },
  [schemaFields.email]: {
    en: 'Email',
    fr: 'Courriel',
  },
  [schemaFields.address]: {
    en: 'Address',
    fr: 'Adresse',
  },
  [schemaFields.earliestAvailabilityDate]: {
    en: 'Earliest Availability Date',
    fr: 'Date de disponibilité la plus proche',
  },
  [schemaFields.workPermitType]: {
    en: 'Work Permit Type',
    fr: 'Type de permis de travail',
  },
  [schemaFields.signature]: {
    en: 'Signature',
    fr: 'Signature',
  },
  [schemaFields.legalFirstName]: {
    en: 'Legal First Name',
    fr: 'Prénom juridique',
  },
  [schemaFields.legalMiddleName]: {
    en: 'Legal Middle Name',
    fr: 'Deuxième prénom juridique',
  },
  [schemaFields.legalLastName]: {
    en: 'Legal Last Name',
    fr: 'Nom de famille juridique',
  },
};

export const fieldLabel: Record<schemaFields | BasicFormBuilderTypes, LabelSchema> = {
  ...defaultFieldLabel,
  ...inputFieldLabel,
} as const;

export const schemaFieldsByType: Record<FormBuilderTypes, string[] | null> = {
  [formBuilderType.text]: [
    formBuilderType.text,
    // schemaFields.name,
    // schemaFields.firstName,
    // schemaFields.middleName,
    // schemaFields.lastName,
    schemaFields.nickname,
    // schemaFields.apartment,
  ],
  [formBuilderType.number]: [formBuilderType.number, schemaFields.postalCode],
  [formBuilderType.email]: [],
  [formBuilderType.phone]: [formBuilderType.phone, schemaFields.phoneNumber, schemaFields.optionalPhoneNumber],
  [formBuilderType.date]: [formBuilderType.date, schemaFields.dateOfBirth, schemaFields.endDate],
  [formBuilderType.radio]: [formBuilderType.radio, schemaFields.correspondenceLanguage, schemaFields.gender],
  [formBuilderType.address]: [],
  [formBuilderType.gender]: [],
  [formBuilderType.image]: [formBuilderType.image, schemaFields.documentIdImageUrl],
  [formBuilderType.file]: [],
  [formBuilderType.yesNo]: [],
  [formBuilderType.checkbox]: [],
  [formBuilderType.link]: [],
  [formBuilderType.button]: [],
  [schemaFields.bankAccountNumber]: [schemaFields.bankAccountNumber],
  [schemaFields.routingNumber]: [schemaFields.routingNumber],
  [schemaFields.branchTransitNumber]: [schemaFields.branchTransitNumber],
  [schemaFields.financialInstitutionNumber]: [schemaFields.financialInstitutionNumber],
  [formBuilderType.info]: [],
  [formBuilderType.singleCheckbox]: [],
  [formBuilderType.time]: [],
  [formBuilderType.documentReview]: [
    formBuilderType.documentReview,
    schemaFields.codeofconductandethics,
    schemaFields.privacynoticeforemployees,
    schemaFields.electronicmonitoringpolicy,
  ],
  [formBuilderType.checkboxDisable]: [],
  [formBuilderType.correspondenceLanguage]: [],
  [formBuilderType.sin]: [],
  [formBuilderType.ssn]: [],
  [formBuilderType.signature]: [],
  // ADVANCED
  [formBuilderType.sinAdvanced]: [],
  [formBuilderType.ssnAdvanced]: [],
  [formBuilderType.documentPreview]: [],
  [formBuilderType.canadianBank]: [],
  [formBuilderType.americanBank]: [],
  [formBuilderType.signatureAdvanced]: [],
  [formBuilderType.addressValidator]: [],
  // [formBuilderType.confirmation]: [],
  [formBuilderType.earliestStartDate]: [],
  [formBuilderType.emergencyContact]: [],
  [formBuilderType.miscellaneous]: [],
  [formBuilderType.fullName]: [],
  // PAGE ELEMENTS
  [formBuilderType.section]: [],
};

type DefaultValueProps = Pick<
  FieldSchema,
  | 'text'
  | 'label'
  | 'isInfo'
  | 'link'
  | 'optionList'
  | 'validations'
  | 'previewPlaceholders'
  | 'documentReview'
  | 'disabled'
  | 'disabledId'
  | 'isFrontEndOnly'
  | 'hideFieldSelector'
  | 'followUpQuestionList'
  | 'min'
  | 'max'
  | 'enableWorkPermit'
> & {
  inputType?: Pick<FieldSchema, 'inputType'>['inputType'];
  isFollowUp?: Pick<FieldSchema, 'isFollowUp'>['isFollowUp'];
  field?: Pick<FieldSchema, 'field'>['field'];
} & { isFollowUp?: Pick<FieldSchema, 'isFollowUp'>['isFollowUp'] };

interface getSchemaValuesProps extends Pick<FormBuilderSchema, 'type' | 'label' | 'icon' | 'component' | 'hidden' | 'section'> {
  defaultValue: DefaultValueProps | DefaultValueProps[];
  panel?: Pick<FormBuilderSchema, 'panel'>['panel'];
}

function getSchemaValues({
  defaultValue = {},
  label: _label,
  icon,
  panel = 'basic',
  component,
  type,
  hidden = false,
  section,
}: getSchemaValuesProps): FormBuilderSchema {
  function getDefaultValue(value: DefaultValueProps) {
    const {
      text = { en: '', fr: '' },
      label,
      isInfo = false,
      optionList = null,
      validations,
      previewPlaceholders,
      inputType,
      isFollowUp = false,
      field,
      documentReview = null,
      disabled = false,
      disabledId,
      isFrontEndOnly = false,
      hideFieldSelector = false,
      followUpQuestionList = null,
      min = null,
      max = null,
      enableWorkPermit = null,
      link = null,
    } = value;

    return {
      timestamp: Date.now(),
      id: uuidv4(),
      required: true,
      order: 1,
      validations,
      isInfo,
      inputType: inputType ?? type,
      text,
      label: label ?? { en: _label, fr: fieldLabel?.[(inputType ?? type) as schemaFields | BasicFormBuilderTypes]?.fr ?? '' },
      optionList,
      previewPlaceholders,
      section,
      parentSection: null,
      isFollowUp,
      field: field ?? type,
      documentReview,
      disabled,
      disabledId,
      isFrontEndOnly,
      hideFieldSelector,
      followUpQuestionList,
      min,
      max,
      enableWorkPermit,
      link,
    };
  }

  const currentDefaultValues = Array.isArray(defaultValue) ? defaultValue.map(getDefaultValue) : getDefaultValue(defaultValue);

  return {
    type,
    component,
    props: {},
    defaultValue: currentDefaultValues,
    icon,
    label: _label,
    panel,
    hidden,
    section,
  };
}

export const basicFormBuilderSchemas: Record<BasicFormBuilderTypes, FormBuilderSchema> = {
  [formBuilderType.text]: getSchemaValues({
    type: formBuilderType.text,
    component: TextFieldSchemaBuilder,
    defaultValue: {
      min: 3,
      max: 500,
    },
    icon: BsInputCursorText,
    label: 'text',
  }),

  [formBuilderType.email]: getSchemaValues({
    type: formBuilderType.email,
    component: EmailSchemaBuilder,
    defaultValue: {
      text: {
        en: '<p>Enter your email address.</p>',
        fr: '<p>Entrez votre adresse e-mail.</p>',
      },
    },
    icon: IoMail,
    label: 'email',
  }),
  [formBuilderType.phone]: getSchemaValues({
    type: formBuilderType.phone,
    component: PhoneSchemaBuilder,
    defaultValue: {
      text: { en: '<p>What is your phone number?</p>', fr: '<p>Quel est votre numéro de téléphone?</p>' },
    },
    icon: IoCall,
    label: 'phone',
  }),
  [formBuilderType.link]: getSchemaValues({
    type: formBuilderType.link,
    component: LinkSchemaBuilder,
    defaultValue: { link: { en: '' } },
    icon: MdLink,
    label: 'link',
    hidden: true,
  }),
  [formBuilderType.date]: getSchemaValues({
    type: formBuilderType.date,
    component: DatePickerSchemaBuilder,
    defaultValue: {},
    icon: IoCalendar,
    label: 'date',
  }),
  [formBuilderType.time]: getSchemaValues({
    type: formBuilderType.time,
    component: TimePickerSchemaBuilder,
    defaultValue: {},
    icon: MdOutlineAccessTime,
    label: 'time',
    hidden: true,
  }),
  [formBuilderType.number]: getSchemaValues({
    type: formBuilderType.number,
    component: NumberSchemaBuilder,
    defaultValue: { min: 10, max: 100 },
    icon: MdOutlinePin,
    label: 'number',
  }),
  [formBuilderType.radio]: getSchemaValues({
    type: formBuilderType.radio,
    component: RadioSchemaBuilder,
    defaultValue: {
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: 'Option 1', fr: 'Option 1' } },
        { key: 'B', id: uuidv4(), text: { en: 'Option 2', fr: 'Option 2' } },
      ],
    },
    icon: IoRadioButtonOnOutline,
    label: 'radio',
  }),
  [formBuilderType.checkbox]: getSchemaValues({
    type: formBuilderType.checkbox,
    component: MCQSchemaBuilder,
    defaultValue: {
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: 'Option 1', fr: 'Option 1' } },
        { key: 'B', id: uuidv4(), text: { en: 'Option 2', fr: 'Option 2' } },
      ],
    },
    icon: IoCheckbox,
    label: 'checkbox',
  }),
  [formBuilderType.info]: getSchemaValues({
    type: formBuilderType.info,
    component: TextFieldSchemaBuilder,
    defaultValue: { isInfo: true },
    icon: MdTextFields,
    label: 'info',
  }),
  [formBuilderType.gender]: getSchemaValues({
    type: formBuilderType.gender,
    component: RadioSchemaBuilder,
    defaultValue: {
      inputType: formBuilderType.radio,
      text: { en: '<p>What is your gender?</p>', fr: '<p>Quel est votre sexe?</p>' },
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: 'Female', fr: 'Femme' } },
        { key: 'B', id: uuidv4(), text: { en: 'Male', fr: 'Homme' } },
        { key: 'C', id: uuidv4(), text: { en: 'Non-Binary', fr: 'Non-Binaire' } },
        { key: 'D', id: uuidv4(), text: { en: 'Undeclared', fr: 'Non-Déclaré' } },
      ],
      field: schemaFields.gender,
      label: { en: 'Gender', fr: 'Genre' },
      // hideFieldSelector: true,
    },
    icon: MdOutlinePerson,
    label: 'gender',
    hidden: true,
  }),
  [formBuilderType.yesNo]: getSchemaValues({
    type: formBuilderType.yesNo,
    component: YesNoSchemaBuilder,
    defaultValue: {
      optionList: [
        { key: 'Yes', id: uuidv4(), text: { en: 'Yes', fr: 'Oui' } },
        { key: 'No', id: uuidv4(), text: { en: 'No', fr: 'Non' } },
      ],
    },
    icon: IoToggle,
    label: 'yesNo',
  }),
  [formBuilderType.address]: getSchemaValues({
    type: formBuilderType.address,
    component: AddressSchemaBuilder,
    defaultValue: {
      text: {
        en: '<p>What is your current address? Use the button below to search addresses.</p>',
        fr: '<p>Quel est votre adresse actuelle? Utilisez le bouton ci-dessous pour rechercher des adresses.</p>',
      },
    },
    icon: IoLocation,
    label: 'address',
  }),
  [formBuilderType.image]: getSchemaValues({
    type: formBuilderType.image,
    component: ImageSchemaBuilder,
    defaultValue: {
      validations: {
        acceptType: ['image/jpg', 'image/jpeg', 'image/png'],
        maxFileSize: 2 * 1024 * 1024, // 2MB
      },
    },
    icon: IoImage,
    label: 'image',
  }),
  [formBuilderType.file]: getSchemaValues({
    type: formBuilderType.file,
    component: ImageSchemaBuilder,
    defaultValue: {
      validations: {
        acceptType: ['application/pdf'],
        maxFileSize: 2 * 1024 * 1024, // 2MB
      },
    },
    icon: MdOutlineBackup,
    label: 'file',
    hidden: true,
  }),
  [formBuilderType.documentReview]: getSchemaValues({
    type: formBuilderType.documentReview,
    component: DocumentPreview,
    defaultValue: {
      documentReview: {
        confirmText: { en: 'Confirm', fr: 'Confirmer' },
        description: {
          en: '<p>You need to consent to the document after you read it</p>',
          fr: '<p>Vous devez accepter le document après avoir lu</p>',
        },
        acknowledgeContent: {
          en: '<p>I acknowledge that I have read all the required documents.</p>',
          fr: '<p>Je reconnais avoir pris connaissance de tous les documents requis.</p>',
        },
        url: { en: '' },
      },
    },
    icon: MdOutlineBackup,
    label: 'document',
  }),
  [formBuilderType.singleCheckbox]: getSchemaValues({
    type: formBuilderType.singleCheckbox,
    component: CheckBoxSchemaBuilder,
    defaultValue: {},
    icon: MdOutlineCheckBox,
    label: 'singleCheckbox',
    hidden: true,
  }),
  [formBuilderType.button]: getSchemaValues({
    type: formBuilderType.button,
    component: ButtonSchemaBuilder,
    defaultValue: {},
    icon: MdSmartButton,
    label: 'button',
    hidden: true,
  }),
  [formBuilderType.bankAccountNumber]: getSchemaValues({
    type: formBuilderType.bankAccountNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<p><strong>Bank Account Number:</strong> The Bank Account Number is the group of 7 to 12 numbers after the Financial Institution Number at the bottom of your cheque. You can view our guide or enter the numbers directly.</p>',
        fr:
          "<p><strong>Numéro de compte bancaire:</strong> Le numéro de compte bancaire est le groupe de 7 à 12 chiffres qui suit le numéro de l'institution financière au bas de votre chèque. Vous pouvez consulter notre guide ou saisir directement les numéros.</p>",
      },
      previewPlaceholders: { placeholder: '00 0000', disableMinMax: true },
      field: schemaFields.bankAccountNumber,
    },
    icon: MdOutlinePin,
    label: 'bankAccountNumber',
    hidden: true,
  }),
  [formBuilderType.routingNumber]: getSchemaValues({
    type: formBuilderType.routingNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<p><strong>Routing Number:</strong> The Routing Number is the first group of 9 numbers at the bottom of your cheque. You can view our guide or enter the numbers directly.</p>',
        fr:
          "<p><strong>Numéro de routage:</strong> Le numéro d'acheminement est le premier groupe de 9 chiffres au bas de votre chèque. Vous pouvez consulter notre guide ou saisir directement les numéros.</p>",
      },
      previewPlaceholders: { placeholder: '000000000', disableMinMax: true },
      field: schemaFields.routingNumber,
    },
    icon: MdOutlinePin,
    label: 'routingNumber',
    hidden: true,
  }),
  [formBuilderType.branchTransitNumber]: getSchemaValues({
    type: formBuilderType.branchTransitNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<p><strong>Branch Transit Number:</strong> The Branch Transit Number is the first group of 5 numbers at the bottom of your cheque. You can view our guide or enter the numbers directly.</p>',
        fr:
          '<p><strong>Numéro de transit de la branche:</strong> Le numéro de transit de la succursale est le premier groupe de cinq chiffres au bas de votre chèque. Vous pouvez consulter notre guide ou saisir directement les numéros.</p>',
      },
      previewPlaceholders: { placeholder: '00000', disableMinMax: true },
      field: schemaFields.branchTransitNumber,
    },
    icon: MdOutlinePin,
    label: 'branchTransitNumber',
    hidden: true,
  }),
  [formBuilderType.financialInstitutionNumber]: getSchemaValues({
    type: formBuilderType.financialInstitutionNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<p><strong>Financial Institution Number:</strong> The Financial Institution Number is the group of 3 numbers after the Branch Transit Number at the bottom of your cheque. You can view our guide or enter the numbers directly.</p>',
        fr:
          "<p><strong>Numéro de l'institution financière:</strong> Le numéro de l'institution financière est le groupe de trois chiffres qui suit le numéro de transit de la succursale au bas de votre chèque. Vous pouvez consulter notre guide ou saisir directement les numéros.</p>",
      },
      previewPlaceholders: { placeholder: '000', disableMinMax: true },
      field: schemaFields.financialInstitutionNumber,
    },
    icon: MdOutlinePin,
    label: 'financialInstitutionNumber',
    hidden: true,
  }),

  [formBuilderType.checkboxDisable]: getSchemaValues({
    type: formBuilderType.checkboxDisable,
    component: CheckboxDisable,
    defaultValue: {
      isFrontEndOnly: true,
    },
    icon: MdOutlinePin,
    label: 'checkboxDisabled',
    hidden: true,
  }),
  [formBuilderType.correspondenceLanguage]: getSchemaValues({
    type: formBuilderType.correspondenceLanguage,
    component: RadioSchemaBuilder,
    defaultValue: {
      inputType: formBuilderType.radio,
      text: {
        en: '<p>Choose your preferred correspondence language</p>',
        fr: '<p>Choisissez votre langue de correspondance préférée</p>',
      },
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: 'English', fr: 'Anglais' } },
        { key: 'B', id: uuidv4(), text: { en: 'French', fr: 'Français' } },
      ],
      field: schemaFields.correspondenceLanguage,
      label: fieldLabel[schemaFields.correspondenceLanguage],
      // hideFieldSelector: true,
    },
    icon: FaLanguage,
    label: 'correspondenceLanguage',
    section: 'signature',
    hidden: true,
  }),

  [formBuilderType.sin]: getSchemaValues({
    type: formBuilderType.sin,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: { en: '<p>Enter your Social Insurance Number</p>', fr: "<p>Saisissez votre numéro d'assurance sociale</p>" },
      label: fieldLabel[schemaFields.socialInsuranceNumber],
      previewPlaceholders: { placeholder: '000-000-000', disableMinMax: true },
      field: schemaFields.socialInsuranceNumber,
    },
    icon: MdOutlinePin,
    label: 'sin',
    hidden: true,
  }),
  [formBuilderType.ssn]: getSchemaValues({
    type: formBuilderType.ssn,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: { en: '<p>Enter your Social Security Number.</p>', fr: '<p>Saisissez votre numéro de sécurité sociale.</p>' },
      label: fieldLabel[schemaFields.socialSecurityNumber],
      previewPlaceholders: { placeholder: '000-000-000', disableMinMax: true },
      field: schemaFields.socialSecurityNumber,
    },
    icon: MdOutlinePin,
    label: 'ssn',
    hidden: true,
  }),
  [formBuilderType.signature]: getSchemaValues({
    type: formBuilderType.signature,
    component: SignatureSchemaBuilder,
    defaultValue: {
      text: { en: '<p>Add your signature here</p>', fr: '<p>Ajoutez votre signature ici</p>' },
      label: fieldLabel[schemaFields.signature],
      field: schemaFields.signature,
    },
    icon: FaSignature,
    label: 'signature',
    hidden: false,
  }),
};

const disableIds = {
  sinWorkPermit: 'sinWorkPermit',
  bankVoidCheque: 'bankVoidCheque',
};

export const advancedFormBuilderSchemas: Record<AdvancedFormBuilderTypes, FormBuilderSchema> = {
  [formBuilderType.sinAdvanced]: getSchemaValues({
    type: formBuilderType.sinAdvanced,
    component: null,
    defaultValue: [
      {
        text: { en: '<p>Enter your Social Insurance Number</p>', fr: "<p>Saisissez votre numéro d'assurance sociale</p>" },
        ...basicFormBuilderSchemas.sin.defaultValue,
        enableWorkPermit: true,
      },
      {
        ...basicFormBuilderSchemas.checkboxDisable.defaultValue,
        text: {
          en: 'Require permit if SIN begins with 9',
          fr: 'Nécessiter un permis si le NAS commence par 9',
        },
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.radio.defaultValue,
        text: { en: '<p>Select your permit type</p>', fr: '<p>Sélectionner ton type de permis</p>' },
        label: fieldLabel[schemaFields.workPermitType],
        field: schemaFields.workPermitType,
        optionList: [
          { key: 'A', id: uuidv4(), text: { en: 'Work Permit', fr: 'Permis de travail' } },
          { key: 'B', id: uuidv4(), text: { en: 'Study Permit', fr: "Permis d'études" } },
          { key: 'C', id: uuidv4(), text: { en: 'Post-Graduation Work Permit', fr: 'Permis de travail postdiplôme' } },
        ],
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.date.defaultValue,
        text: { en: '<p>What is the end date of your permit?</p>', fr: "<p>Quelle est la date d'expiration de ton permis?</p>" },
        label: { en: 'Permit end date', fr: "Date d'expiration du permis" },
        field: schemaFields.workPermitEndDate,
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>We will need a photo of your valid permit.</p>',
          fr: "<p>Nous aurons besoin d'une photo de ton permis valide.</p>",
        },
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>Make sure you follow these instructions when taking your photo:</p>',
          fr: '<p>Assure-toi de suivre ces instructions lors de la prise de ta photo:</p>',
        },
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.image.defaultValue,
        text: {
          en: '<p>All of the pages of the permit, and the permit’s end date, must be clearly visible in the photo preview</p>',
          fr:
            "<p>Toutes les pages du permis, ainsi que la date d'expiration du permis, doivent être clairement visibles dans l'aperçu de la photo.</p>",
        },
        label: { en: 'Permit Image', fr: 'Image du permis' },
        field: schemaFields.workPermitImageUrl,
        disabledId: disableIds.sinWorkPermit,
      },
    ],
    section: Sections.sin,
    icon: MdFiberPin,
    label: 'sin',
    panel: 'advanced',
  }),
  [formBuilderType.ssnAdvanced]: getSchemaValues({
    type: formBuilderType.ssnAdvanced,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.ssn.defaultValue,
        text: { en: '<p>Enter your Social Security Number.</p>', fr: '<p>Saisissez votre numéro de sécurité sociale.</p>' },
      },
    ],
    section: Sections.ssn,
    icon: MdFiberPin,
    label: 'ssn',
    panel: 'advanced',
  }),
  [formBuilderType.signatureAdvanced]: getSchemaValues({
    type: formBuilderType.signatureAdvanced,
    component: null,
    defaultValue: [
      { ...basicFormBuilderSchemas.signature.defaultValue, text: { en: 'Final review signature', fr: 'Signature de fin' } },
    ],
    icon: FaSignature,
    label: 'finalSinature',
    panel: 'advanced',
    section: Sections.signature,
    hidden: true,
  }),
  [formBuilderType.documentPreview]: getSchemaValues({
    type: formBuilderType.documentPreview,
    component: null,
    defaultValue: [
      {
        ...{
          ...basicFormBuilderSchemas.documentReview.defaultValue,
          documentReview: {
            ...((basicFormBuilderSchemas.documentReview.defaultValue as any).documentReview ?? {}),

            description: {
              en: '<p>You need to consent to this Code after you have read it.</p>',
              fr: '<p>Vous devez accepter ce Code après avoir lu le Code.</p>',
            },
            acknowledgeContent: {
              en:
                '<p>I acknowledge that I have read and understood the <span data-tag="true">[business_name]</span> Code of Conduct and Ethics (the "Code") and signify that I will comply with the rules, policies and procedures set forth in the Code.</p>',
              fr:
                "<p> Je reconnais avoir lu et compris le Code de conduite et d'éthique de <span data-tag=\"true\">[business_name]</span> (le « Code ») et j'atteste que je m'engage à respecter toutes les règles, politiques et procédures énoncées dans le Code.</p>",
            },
          },
        },
        field: schemaFields.codeofconductandethics,
        text: {
          en:
            '<p>Please review the <span data-tag="true">[business_name]</span> code of Conduct and Ethics. It addresses various topics and includes the rules and guidelines for personal conduct and ethical decisions in the course of your work.</p>',
          fr: '<p>Veuillez examiner le Code de conduite et d\'éthique de <span data-tag="true">[business_name]</span>.</p>',
        },
        label: fieldLabel[schemaFields.codeofconductandethics],
      },
      {
        ...{
          ...basicFormBuilderSchemas.documentReview.defaultValue,
          documentReview: {
            ...((basicFormBuilderSchemas.documentReview.defaultValue as any).documentReview ?? {}),

            description: {
              en: '<p>You need to consent to this Code after you have read it.</p>',
              fr: '<p>Vous devez accepter ce Code apres avoir lu le Code.</p>',
            },
            acknowledgeContent: {
              en:
                '<p>I acknowledge that I have read and understood the <span data-tag="true">[business_name]</span> Privacy Notice for Employees and signify that I will comply with the rules, policies and procedures set forth in this employee privacy policy.</p>',
              fr:
                '<p>Je reconnais avoir lu et compris le <span data-tag="true">[business_name]</span> Notice de Confidentialité des Employés et m’y engage.</p>',
            },
          },
        },
        field: schemaFields.privacynoticeforemployees,
        text: {
          en:
            '<p>Please review the <span data-tag="true">[business_name]</span> Privacy Notice for Employees that informs you on how your personal information is collected, for which purpose and how you may exercise certain privacy rights.</p>',
          fr:
            "<p>Je reconnais avoir lu et compris l'Avis sur la protection des renseignements personnels des employés et j'atteste que je m'engage à respecter toutes les règles, politiques et procédures énoncées dans cette politique de vie privée des employés</p>",
        },
        label: fieldLabel[schemaFields.privacynoticeforemployees],
      },
      {
        ...{
          ...basicFormBuilderSchemas.documentReview.defaultValue,
          documentReview: {
            ...((basicFormBuilderSchemas.documentReview.defaultValue as any).documentReview ?? {}),

            description: {
              en: '<p>You need to consent to this Employee Privacy Policy after you have read it.</p>',
              fr:
                '<p>Vous devez accepter cette Politique de Vie Privée des Employés apres avoir lu la Politique de Vie Privée des Employés.</p>',
            },
            acknowledgeContent: {
              en:
                '<p>I acknowledge that I have read and understood the Electronic Monitoring Policy and signify that I will comply with the rules, policies and procedures set forth in this employee privacy policy.</p>',
              fr:
                '<p>Je reconnais avoir lu et compris la Politique de surveillance électronique de <span data-tag="true">[business_name]</span> et j\'atteste que je m\'engage à respecter toutes les règles, politiques et procédures énoncées dans cette politique de vie privée des employés</p>',
            },
          },
        },
        field: schemaFields.electronicmonitoringpolicy,
        text: {
          en:
            '<p>Please review the Electronic Monitoring Policy that governs the use of <span data-tag="true">[business_name]</span> equipment and the tools used to monitor productive work.</p>',
          fr:
            '<p>Veuillez lire attentivement la <span data-tag="true">[business_name]</span> Politique de surveillance électronique.</p>',
        },
        label: fieldLabel[schemaFields.electronicmonitoringpolicy],
      },
    ],
    section: Sections.documentPreview,
    icon: MdOutlineArticle,
    label: 'documentPreview',
    panel: 'advanced',
    hidden: true,
  }),
  [formBuilderType.canadianBank]: getSchemaValues({
    type: formBuilderType.canadianBank,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en:
            '<p>In order to set you up in our system, we will need your banking information. These numbers can be found on a cheque or within your online banking portal.</p>',
          fr:
            '<p>Pour vous inscrire dans notre système, nous avons besoin de vos informations bancaires. Ces chiffres peuvent être trouvés sur un chèque ou dans le portail en ligne de votre banque.</p>',
        },
      },
      {
        ...basicFormBuilderSchemas.branchTransitNumber.defaultValue,
        label: fieldLabel[schemaFields.branchTransitNumber],
      },
      {
        ...basicFormBuilderSchemas.financialInstitutionNumber.defaultValue,
        label: fieldLabel[schemaFields.financialInstitutionNumber],
      },
      {
        ...basicFormBuilderSchemas.bankAccountNumber.defaultValue,
        label: fieldLabel[schemaFields.bankAccountNumber],
      },
      {
        ...basicFormBuilderSchemas.checkboxDisable.defaultValue,
        text: { en: 'Enable void cheque image upload', fr: 'Activer l’upload de la photo du chèque vide' },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>We now need a photo of your void cheque.</p>',
          fr: '<p>Nous avons maintenant besoin d’une photo de votre chèque vide.</p>',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>Make sure you follow these instructions when taking your photo:</p>',
          fr: '<p>Assurez-vous de suivre ces instructions lorsque vous prenez votre photo:</p>',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.image.defaultValue,
        text: {
          en:
            '<p>SPECIMEN or VOID must be written on the cheque. Make sure all the numbers on the void cheque are clearly visible in the photo preview. Handwritten information is not accepted.</p>',
          fr:
            '<p>SPECIMEN ou VOID doit être écrit sur le chèque. Assurez-vous de voir clairement tous les nombres sur le chèque vide. Les informations manuscrites ne sont pas acceptées.</p>',
        },
        disabledId: disableIds.bankVoidCheque,
        label: fieldLabel[schemaFields.chequebookImageUrl],
        field: schemaFields.chequebookImageUrl,
      },
    ],
    section: Sections.canadianBank,
    icon: AiFillBank,
    label: 'canadianBank',
    panel: 'advanced',
  }),
  [formBuilderType.americanBank]: getSchemaValues({
    type: formBuilderType.americanBank,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en:
            '<p>In order to set you up in our system, we need your banking information. These numbers can be found on a cheque or within your bank’s online portal.</p>',
          fr:
            '<p>Pour vous inscrire dans notre système, nous avons besoin de vos informations bancaires. Ces chiffres peuvent être trouvés sur un chèque ou dans le portail en ligne de votre banque.</p>',
        },
      },
      {
        ...basicFormBuilderSchemas.routingNumber.defaultValue,
        label: fieldLabel[schemaFields.routingNumber],
      },
      {
        ...basicFormBuilderSchemas.bankAccountNumber.defaultValue,
        label: fieldLabel[schemaFields.bankAccountNumber],
        text: {
          en:
            '<p><strong>Bank Account Number:</strong> The Bank Account Number is the second group of 10-12 numbers at the bottom of your check. You can view our guide or enter the numbers directly.</p>',
          fr:
            '<p><strong>Bank Account Number:</strong> Le Numéro de compte bancaire est le deuxième groupe de 10-12 chiffres en bas de votre chèque. Vous pouvez consulter notre guide ou saisir les chiffres directement.</p>',
        },
      },
      {
        ...basicFormBuilderSchemas.checkboxDisable.defaultValue,
        text: { en: 'Enable void cheque image upload', fr: 'Activer l’upload de la photo du chèque vide' },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>We now need a photo of your void cheque.</p>',
          fr: '<p>Nous avons maintenant besoin d’une photo de votre chèque vide.</p>',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>Make sure you follow these instructions when taking your photo:</p>',
          fr: '<p>Assurez-vous de suivre ces instructions lorsque vous prenez votre photo:</p>',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.image.defaultValue,
        text: {
          en:
            '<p>SPECIMEN or VOID must be written on the cheque. Make sure all the numbers on the void cheque are clearly visible in the photo preview. Handwritten information is not accepted.</p>',
          fr:
            '<p>SPECIMEN ou VOID doit être écrit sur le chèque. Assurez-vous de voir clairement tous les nombres sur le chèque vide. Les informations manuscrites ne sont pas acceptées.</p>',
        },
        disabledId: disableIds.bankVoidCheque,
        label: fieldLabel[schemaFields.chequebookImageUrl],
        field: schemaFields.chequebookImageUrl,
      },
    ],
    section: Sections.americanBank,
    icon: AiFillBank,
    label: 'americanBank',
    panel: 'advanced',
  }),
  [formBuilderType.earliestStartDate]: getSchemaValues({
    type: formBuilderType.earliestStartDate,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.date.defaultValue,
        text: {
          en:
            '<p>To proceed, let us know the earliest date that you are available to work! This position can start as early as <span data-tag="true">[position_start_date]</span> </p>',
          fr:
            '<p>Pour continuer, nous voulons savoir la date la plus tardive que vous pouvez travailler! Cette position peut commencer comme tard que <span data-tag="true">[position_start_date]</span></p>',
        },
        label: defaultFieldLabel.earliestAvailabilityDate,
        field: schemaFields.earliestAvailabilityDate,
      },
    ],
    section: Sections.earliestStartDate,
    icon: IoCalendar,
    label: 'earliestStartDate',
    panel: 'advanced',
    hidden: true,
  }),
  [formBuilderType.addressValidator]: getSchemaValues({
    type: formBuilderType.addressValidator,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.address.defaultValue,
        text: {
          en: '<p>What is your current address? Use the button below to search addresses.</p>',
          fr: '<p>Quelle est votre adresse actuelle? Utilisez le bouton ci-dessous pour rechercher des adresses.</p>',
        },
        label: { en: 'Current Address' },
      },
      // {
      //   ...basicFormBuilderSchemas.info.defaultValue,
      //   text: {
      //     en: '<p>Thank you for your patience. We have very few questions left.</p>',
      //     fr: '<p>Merci de votre patience. Nous avons encore quelques questions à poser.</p>',
      //   },
      // },
      // {
      //   ...basicFormBuilderSchemas.number.defaultValue,
      //   text: { en: '<p>Enter your postal code</p>', fr: '<p>Entrez votre code postal</p>' },
      //   previewPlaceholders: { placeholder: '000 000', disableMinMax: true },
      //   label: fieldLabel[schemaFields.postalCode],
      //   field: schemaFields.postalCode,
      // },
      addressValidatorApartmentNumber(),
    ],
    section: 'address validator',
    icon: IoLocation,
    label: Sections.addressValidator,
    panel: 'advanced',
  }),
  [formBuilderType.emergencyContact]: getSchemaValues({
    type: formBuilderType.emergencyContact,
    component: null,
    defaultValue: [emergencyContact()],
    section: Sections.emergencyContact,
    icon: MdContactPhone,
    label: Sections.emergencyContact,
    panel: 'advanced',
  }),
  [formBuilderType.miscellaneous]: getSchemaValues({
    type: formBuilderType.miscellaneous,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.text.defaultValue,
        text: { en: 'Any additional information you would like to share with us?' },
        field: schemaFields.contractDetailsWordings,
        label: fieldLabel[schemaFields.contractDetailsWordings],
        previewPlaceholders: { placeholder: 'formBuilder.editorPlaceholder.text', disableMinMax: true },
      },
    ],
    section: Sections.miscellaneous,
    icon: MdMiscellaneousServices,
    label: 'miscellaneous',
    panel: 'advanced',
  }),
  [formBuilderType.fullName]: getSchemaValues({
    type: formBuilderType.fullName,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: `<p>Let's start with your full name. This is the name found on your government identification.</p>`,
          fr: `<p>Commençons par ton nom complet. Il s'agit du nom figurant sur ta pièce d'identité officielle.</p>`,
        },
      },
      generateYesNoSchema({
        text: {
          en: '<p>Is <strong><span data-tag="true">[first_name]</span></strong> your legal first name?</p>',
          fr: '<p><strong><span data-tag="true">[first_name]</span></strong> est-il ton prénom légal?</p>',
        },
        label: fieldLabel[schemaFields.legalFirstName],
        followUpQuestions: ({ noOptionId }) => [
          {
            ...basicFormBuilderSchemas.text.defaultValue,
            inputType: formBuilderType.text,
            text: { en: 'Enter your legal first name.', fr: 'Saisis ton prénom légal.' },
            field: schemaFields.firstName,
            label: fieldLabel[schemaFields.firstName],
            previewPlaceholders: { placeholder: 'Enter text here', disableMinMax: true },
            optionId: noOptionId,
          },
        ],
      }),

      generateYesNoSchema({
        text: {
          en: '<p>Do you have a legal middle name ?</p>',
          fr: '<p>As-tu un deuxième prénom légal?</p>',
        },
        label: fieldLabel[schemaFields.legalMiddleName],
        followUpQuestions: ({ yesOptionId }) => [
          {
            ...basicFormBuilderSchemas.text.defaultValue,
            inputType: formBuilderType.text,
            text: { en: 'Enter your legal middle name.', fr: 'Saisis ton deuxième prénom légal.' },
            field: schemaFields.middleName,
            label: fieldLabel[schemaFields.middleName],
            previewPlaceholders: { placeholder: 'Enter text here', disableMinMax: true },
            optionId: yesOptionId,
          },
        ],
      }),
      generateYesNoSchema({
        text: {
          en: '<p>Is <strong><span data-tag="true">[last_name]</span></strong> your legal last name?</p>',
          fr: '<p><strong><span data-tag="true">[last_name]</span></strong> est-il ton nom de famille légal?</p>',
        },
        label: fieldLabel[schemaFields.legalLastName],
        followUpQuestions: ({ noOptionId }) => [
          {
            ...basicFormBuilderSchemas.text.defaultValue,
            inputType: formBuilderType.text,
            text: { en: 'Enter your legal last name.', fr: 'Saisis ton nom de famille légal.' },
            field: schemaFields.lastName,
            label: fieldLabel[schemaFields.lastName],
            previewPlaceholders: { placeholder: 'Enter text here', disableMinMax: true },
            optionId: noOptionId,
          },
        ],
      }),
    ],
    section: Sections.fullName,
    icon: MdPerson,
    label: 'fullname',
    panel: 'advanced',
  }),
};

export type FormBuilderSchemas = Record<FormBuilderTypes, FormBuilderSchema>;

export const formBuilderSchemas: FormBuilderSchemas = {
  // basic elements
  ...basicFormBuilderSchemas,

  // Page Elements
  [formBuilderType.section]: getSchemaValues({
    type: formBuilderType.section,
    component: null,
    defaultValue: {},
    icon: MdViewAgenda,
    label: 'section',
    panel: 'page-element',
  }),

  // Advanced elements
  ...advancedFormBuilderSchemas,
};

function addressValidatorApartmentNumber() {
  const parentID = uuidv4();
  const option1Id = uuidv4();
  const option2Id = uuidv4();
  const schema: FieldSchema = {
    timestamp: Date.now(),
    id: parentID,
    inputType: formBuilderType.yesNo,
    required: true,
    text: {
      en: 'Does your address have an apartment number?',
      fr: "Votre adresse comporte-t-elle un numéro d'appartement ?",
    },
    order: 13,
    section: 'address validator',
    optionList: [
      { key: 'Yes', id: option1Id, text: { en: 'Yes', fr: 'Oui' } },
      { key: 'No', id: option2Id, text: { en: 'No', fr: 'Non' } },
    ],
    isFollowUp: false,
    followUpQuestionList: [
      {
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.number,
        required: true,
        optionId: option1Id,
        text: { en: 'Enter your apartment number.', fr: "Saisissez votre numéro d'appartement." },
        parent: parentID,
        order: 1,
        label: fieldLabel[schemaFields.apartment],
        previewPlaceholders: {
          placeholder: 'Apartment Number',
          disableMinMax: true,
        },
        isFollowUp: true,
        field: schemaFields.apartment,
      },
    ],
    parentSection: null,
    field: schemaFields.address,
    label: fieldLabel[schemaFields.apartment],
  };
  return schema;
}

function emergencyContact() {
  const parentID = uuidv4();
  const option1Id = uuidv4();
  const option2Id = uuidv4();
  const schema: FieldSchema = {
    ...basicFormBuilderSchemas.yesNo.defaultValue,
    timestamp: Date.now(),
    id: parentID,
    inputType: formBuilderType.yesNo,
    text: {
      en: '<p>Do you have someone we can reach in case of an emergency?</p>',
      fr: '<p>Avez-vous quelqu’un que nous pouvons joindre en cas d’urgence?</p>',
    },
    order: 13,
    section: Sections.emergencyContact,
    optionList: [
      { key: 'Yes', id: option1Id, text: { en: 'Yes', fr: 'Oui' } },
      { key: 'No', id: option2Id, text: { en: 'No', fr: 'Non' } },
    ],
    isFollowUp: false,
    followUpQuestionList: [
      {
        ...basicFormBuilderSchemas.text.defaultValue,
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.text,
        optionId: option1Id,
        text: { en: '<p>What is their first name?</p>', fr: '<p>Quel est leur nom de famille?</p>' },
        parent: parentID,
        order: 1,
        label: defaultFieldLabel.emergencyContactFirstName,
        isFollowUp: true,
        field: schemaFields.emergencyContactFirstName,
      },
      {
        ...basicFormBuilderSchemas.text.defaultValue,
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.text,
        optionId: option1Id,
        text: { en: '<p>What is their last name?</p>', fr: '<p>Quel est leur prénom?</p>' },
        parent: parentID,
        order: 1,
        label: defaultFieldLabel.emergencyContactLastName,
        isFollowUp: true,
        field: schemaFields.emergencyContactLastName,
      },
      {
        ...basicFormBuilderSchemas.phone.defaultValue,
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.phone,
        required: true,
        optionId: option1Id,
        text: {
          en: '<p>What is their phone number? We will only contact them in case of an emergency.</p>',
          fr: '<p>Quel est leur numéro de téléphone? Nous ne nous contacterons que dans le cas d’urgence.</p>',
        },
        parent: parentID,
        order: 1,
        label: defaultFieldLabel.emergencyContactPhoneNumber,
        isFollowUp: true,
        field: schemaFields.emergencyContactPhoneNumber,
      },
    ],
    field: schemaFields.emergencyContact,
    label: defaultFieldLabel.emergencyContact,
    parentSection: null,
  };
  return schema;
}

function generateYesNoSchema({
  text,
  section,
  field,
  label,
  followUpQuestions,
}: Pick<FieldSchema, 'text' | 'section' | 'label'> & {
  field?: FieldSchema['field'];
  followUpQuestions: (optionIds: {
    yesOptionId: string;
    noOptionId: string;
  }) => Omit<FieldSchema, 'isFollowUp' | 'id' | 'parent' | 'order' | 'timestamp'>[];
}) {
  const parentID = uuidv4();
  const option1Id = uuidv4();
  const option2Id = uuidv4();

  function generateFollowUpQuestion(question: Omit<FieldSchema, 'isFollowUp' | 'id' | 'parent' | 'order' | 'timestamp'>) {
    const followup: FieldSchema = {
      ...question,
      timestamp: Date.now(),
      id: uuidv4(),
      parent: parentID,
      order: 1,
      isFollowUp: true,
    };
    return followup;
  }

  const schema: FieldSchema = {
    ...basicFormBuilderSchemas.yesNo.defaultValue,
    timestamp: Date.now(),
    id: parentID,
    inputType: formBuilderType.yesNo,
    text,
    order: 13,
    section,
    optionList: [
      { key: 'Yes', id: option1Id, text: { en: 'Yes', fr: 'Oui' } },
      { key: 'No', id: option2Id, text: { en: 'No', fr: 'Non' } },
    ],
    isFollowUp: false,
    followUpQuestionList: followUpQuestions({ yesOptionId: option1Id, noOptionId: option2Id }).map((question) =>
      generateFollowUpQuestion(question),
    ),
    field: field || basicFormBuilderSchemas.yesNo.type,
    label,
    parentSection: null,
  };
  return schema;
}
