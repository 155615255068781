import { HStack, Text, useToast } from '@chakra-ui/core';
import { IoMoveSharp } from 'react-icons/io5';
import { formBuilderSchemas } from '../../formBuilderSchemas/formBuilder.schema';
import { EmptyComponent } from '../EmptyComponent';
import { SchemaBuilderProps, FieldSchema, formBuilderType, MAX_FOLLOW_UP_DEPTH } from '../formBuilderSchema';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import isArray from 'lodash/isArray';
import DroppableElement from '../formElementBuilder/DroppableElement';
import { useHireFormContext } from '../../HireForm.context';
import { useTranslation } from 'react-i18next';

interface FollowUpEmptyComponentProps extends Omit<SchemaBuilderProps, 'onChange'> {
  followUpOptionId: string;
  onChange: (field: FieldSchema) => void;
}

export default function FollowUpEmptyComponent({
  defaultValue: parentDefaultValue,
  onChange,
  followUpOptionId,
  followUpLabel,
}: FollowUpEmptyComponentProps) {
  const { t } = useTranslation('hrFormTemplate');
  const followUpQuestions = parentDefaultValue.followUpQuestionList || [];
  const toast = useToast();
  const { draggedElement } = useHireFormContext();

  function handleDrop() {
    if (!draggedElement) return;
    const { type } = draggedElement;
    if (followUpLabel >= MAX_FOLLOW_UP_DEPTH && type === formBuilderType.yesNo) {
      toast({
        description: `Only ${MAX_FOLLOW_UP_DEPTH} nested level of follow-ups question is allowed`,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (type === formBuilderType.documentReview) {
      toast({
        description: 'Document review is not allowed in follow-up question',
        status: 'error',
        isClosable: true,
      });
      return;
    }
    const schema = formBuilderSchemas[type as keyof typeof formBuilderSchemas];

    if (schema.panel === 'advanced' || isArray(schema.defaultValue)) return;
    const defaultValue = schema.defaultValue;

    const newFollowUpQuestion = [
      ...followUpQuestions,
      {
        ...defaultValue,
        id: uuidv4(),
        parent: parentDefaultValue.id,
        optionId: followUpOptionId,
        timestamp: Date.now(),
        isFollowUp: true,
      },
    ];

    const newSchema = {
      ...parentDefaultValue,
      followUpQuestionList: newFollowUpQuestion,
    };

    onChange(newSchema);
  }
  return (
    <DroppableElement
      onDrop={handleDrop}
      currentType={draggedElement?.panel!}
      acceptedTypes={['basic']}
      index={-1}
      disableDropZone
      hideDivider
    >
      <EmptyComponent>
        <HStack
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="#586073"
          align="center"
          justify="center"
          flexGrow={1}
        >
          <IoMoveSharp size={24} />
          <Text fontSize="sm" fontWeight="bold">
            {t('formBuilder.emptyFollowUpPlaceholder')}
          </Text>
        </HStack>
      </EmptyComponent>
    </DroppableElement>
  );
}
