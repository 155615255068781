import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import TextSchemaBuilder from './TextSchemaBuilder';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

interface FormElementTextBuilderProps extends SchemaBuilderProps {
  placeholder?: string;
  startWithEditView?: boolean;
}

const FormElementTextBuilder = React.memo(
  function ({
    onChange,
    defaultValue,
    language,
    placeholder,
    startWithEditView,
    sectionIndex,
    fieldIndex,
    ...props
  }: FormElementTextBuilderProps) {
    const path = [...props.path, 'text', language];
    const handleChange = (value: string) => {
      const changedValue = { ...defaultValue, text: { ...defaultValue?.text!, [language]: value } };
      if (language === 'en' && !Boolean(defaultValue?.text?.fr?.trim())) {
        changedValue.text.fr = value;
      }
      if (language === 'fr' && !Boolean(defaultValue?.text?.en?.trim())) {
        changedValue.text.en = value;
      }
      onChange(changedValue);
    };

    return (
      <TextSchemaBuilder
        label={defaultValue?.text?.[language]!}
        placeholder={placeholder}
        onLabelChange={handleChange}
        startWithEditView={startWithEditView}
        language={language}
        path={path}
      />
    );
  },
  (prevProps, nextProps) => {
    return isEqual(omit(prevProps, ['startWithEditView', 'placeholder']), omit(nextProps, ['startWithEditView', 'placeholder']));
  },
);

export default FormElementTextBuilder;
