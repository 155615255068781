import { Button } from '@chakra-ui/core';
import React from 'react';
import { HiTrash } from 'react-icons/hi';
import DeletePopover from '../advancedElements/DeletePopover';
import { generateFormSchema } from '../formBuilderSchema';
import { useHireFormContext } from '../../HireForm.context';
import { v4 as uuidv4 } from 'uuid';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';

interface SectionDeleteProps {
  sectionIndex: number;
}

const SectionDelete = React.memo(
  function ({ sectionIndex }: SectionDeleteProps) {
    const { formSchema, setFormSchema, language } = useHireFormContext();
    const { errors } = useStoreState((state) => state.hrFormTemplate);
    const { setErrors } = useStoreActions((state) => state.hrFormTemplate);

    const currentSection = formSchema[sectionIndex];

    return (
      <DeletePopover
        popoverTrigger={
          <Button colorScheme="red" style={{ aspectRatio: '1/1' }} padding={1} size="sm" variant="ghost">
            <HiTrash />
          </Button>
        }
        onYes={() => {
          const newFormSchema = [...formSchema];
          const fields = generateFormSchema(newFormSchema, language).filter((field) => field.section === currentSection.section);

          newFormSchema.splice(sectionIndex, 1);

          if (newFormSchema.length === 0) {
            newFormSchema.push({ section: 'section1', fields: [], id: uuidv4() });
          }

          setErrors([...errors]?.filter((e) => !fields?.find((f) => f.order ?? 0 - 1 === e.path[1])));
          setFormSchema(newFormSchema);
          setFormSchema(newFormSchema);
        }}
      />
    );
  },
  (prev, next) => prev.sectionIndex === next.sectionIndex,
);

export default SectionDelete;
