import { Box, HStack, Text } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../styles/customTheme';

interface InputPreviewProps {
  value: string;
  rightContent?: React.ReactNode;
}
const InputPreview = React.memo(
  function ({ value, rightContent }: InputPreviewProps) {
    return (
      <Box backgroundColor="#F3F6F9" borderRadius={theme.radii.md} padding={theme.space[2]} display="flex">
        <HStack justify="space-between" align="center" w="100%">
          <Text fontSize="sm" color="#A0AEC0">
            {value}
          </Text>
          {rightContent}
        </HStack>
      </Box>
    );
  },
  (prev, next) => prev.value === next.value && prev.rightContent === next.rightContent,
);
export default InputPreview;
