/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, memo, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/core';
import { IoMdVideocam } from 'react-icons/io';
import { FaChevronDown } from 'react-icons/fa';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import {
  AppointmentType,
  AvailableBookingWithPosition,
  Booking,
} from '../../../firebase/firestore/documents/appointmentAvaiability';
import { bookingDrawer } from './BookingDrawer.Style';
import { useSeekerExperiences } from './useSeekerExperiences';
import { Experience } from '../../messaging/seeker/candidate/profile/components/Experience';
import { getTranslatedValue } from '../../../utils/localizedString';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import { INTERVIEW_MESSAGING_TABS, MESSAGING_TABS } from '../../../routes/constants';
import { SeekerContainerTabItems } from '../../messaging/seeker/SeekerContainerContext';
import { BookingDetailViewCancelModal } from './BookingDetailViewCancelModal';
import colors from '../../../styles/colors';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { SESSION_KEY_CANDIDATE_LIST_TYPE } from '../../messaging/sidebar/filters/SeekersFiltersContext';
import { CandidateListType } from '../../messaging/sidebar/filters/seekersFiltersContextValueType';
import useBusinessLocation from './useLocation';
import { InviteCollaboratorsView } from '../collaborators/InviteCollaboratorsView';
import { ReactComponent as _PositionStateDot } from '../../../assets/img/position-state.svg';

export type ScheduleByDateProps = {
  isOpen: boolean;
  onClose: () => void;
  bookingDetail: AvailableBookingWithPosition;
};

export const BookingDetailView = ({ isOpen, onClose, bookingDetail }: ScheduleByDateProps): JSX.Element => {
  const [accordionOpen, setAccordionOpen] = React.useState<boolean>(false);
  const { currentUserProfile } = useCurrentUserProfile();
  const { t } = useTranslation('calendar');
  const { i18n } = useTranslation();
  const history = useHistory();
  const businessLocation = useBusinessLocation(bookingDetail.booking.businessId);
  const PositionStateDot = memo(_PositionStateDot);

  const setInterviewEvent = useStoreActions((state) => state.interview.setInterviewEvent);
  const { updateRatingFor } = useStoreActions(/* istanbul ignore next */ (actions) => actions.interview);

  const seekerExperiences = useSeekerExperiences(bookingDetail?.candidate.seeker);

  const {
    isOpen: isBookingDetailViewModalOpen,
    onOpen: onBookingDetailViewModalOpen,
    onClose: onBookingDetailViewModalClose,
  } = useDisclosure();

  const gracePeriod: number = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.JOIN_VIDEO_GRACE_PERIOD_IN_MINUTE];
  });

  const enableMeetFeature: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_MEET_FEATURE];
  });

  const storeEventDetailForInterview = () => {
    sessionStorage.setItem(SESSION_KEY_CANDIDATE_LIST_TYPE, CandidateListType.UNKNOWN);
    setInterviewEvent(bookingDetail);
    history.push(INTERVIEW_MESSAGING_TABS(bookingDetail.candidate.id, SeekerContainerTabItems.Messaging));
  };

  const storeEventDetailForInterviewRating = () => {
    updateRatingFor({
      name: bookingDetail.candidate.firstName,
      seekerId: bookingDetail.booking.seekerId,
      positionId: bookingDetail.booking.positionId,
      selectedCandidate: bookingDetail.candidate,
    });
    history.push(MESSAGING_TABS(bookingDetail.candidate.id, SeekerContainerTabItems.Messaging));
  };

  const isAbleToJoinVideoInterview = useCallback(
    (booking: Booking) => {
      return moment().isBetween(
        moment(booking.date).subtract(5, 'minutes'),
        moment(booking.date).add(booking.duration, 'minutes').add(gracePeriod, 'minute'),
      );
    },
    [gracePeriod],
  );

  const isInPast = useCallback(
    (booking: Booking) => {
      return moment(booking.date).add(booking.duration, 'minutes').add(gracePeriod, 'minute').isBefore(moment());
    },
    [gracePeriod],
  );

  const is24HourLimitExceeded = useCallback((booking: Booking) => {
    return moment(booking.date).add(booking.duration, 'minutes').add(1440, 'minute').isBefore(moment());
  }, []);

  const isInterviewTimeFinished = useCallback((booking: Booking) => {
    return moment(booking.date).add(booking.duration, 'minutes').isBefore(moment());
  }, []);

  const isCurrentTimeBeforeCallStart = useCallback((booking: Booking) => {
    return moment().isBefore(moment(booking.date));
  }, []);

  const allowSuperUsersToDeleteAvailability: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ALLOW_SUPER_USERS_TO_DELETE_AVAILABILITY];
  });

  const appUserId = useStoreState((s) => s.app.user?.id);
  const appOwnersId = useStoreState((s) => s.app.accounts?.owners);
  const isAdministrationAccount = !isNil(appOwnersId) && !isNil(appUserId) && appOwnersId?.includes(appUserId);

  const enableCollaborators: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_COLLABORATOR_FEATURE];
  });

  const isPassedInterview = () => {
    const parsedDateTime = moment(bookingDetail.booking.date, 'YYYY-MM-DD HH:mm:ss');

    const newDateTime = parsedDateTime.add(bookingDetail.booking.duration, 'minutes');

    const currentDateTime = moment();

    // Compare the given date and time with the current date and time
    if (newDateTime.isBefore(currentDateTime)) {
      return true;
    }
    return false;
  };

  // istanbul ignore next
  const showCancelInterviewBtn = () => {
    if (!isInPast(bookingDetail.booking) && isCurrentTimeBeforeCallStart(bookingDetail.booking)) {
      if (allowSuperUsersToDeleteAvailability && isAdministrationAccount) {
        return true;
      }
      if (currentUserProfile?.id === bookingDetail.booking.recruiterId) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <Fragment>
      <BookingDetailViewCancelModal
        bookingDetailViewClose={onClose}
        bookingDetail={bookingDetail}
        isOpen={isBookingDetailViewModalOpen}
        onClose={onBookingDetailViewModalClose}
        showWarning={isAdministrationAccount && currentUserProfile?.id !== bookingDetail.booking.recruiterId}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay>
          <DrawerContent css={bookingDrawer} className="booking-detail-drawer">
            <DrawerCloseButton _focus={{ border: '1px solid #1F3CBA' }} />
            <DrawerHeader borderColor="#EBEDF3" borderBottomWidth="1px">
              <Heading as="h3" size="sm" color={`${colors.blue.default} !important`}>
                {t('booking.interviewHeader')}
                {!bookingDetail.candidate.dismissed &&
                  (bookingDetail.booking.type === AppointmentType.video ||
                    bookingDetail.booking.type[0] === AppointmentType.video) &&
                  enableMeetFeature && (
                    <Button
                      data-testid="VideoCallButton"
                      leftIcon={<IoMdVideocam />}
                      colorScheme="orange"
                      variant="solid"
                      isDisabled={!isAbleToJoinVideoInterview(bookingDetail.booking)}
                      onClick={() => storeEventDetailForInterview()}
                    >
                      {t('eventForm.startVideoInterview')}
                    </Button>
                  )}
              </Heading>
            </DrawerHeader>
            <DrawerBody p="0">
              <Box className="booking-title" borderColor="#EBEDF3" borderBottomWidth="1px" p="4">
                <Text fontSize="sm">{bookingDetail.candidate.businessName}</Text>
                <Text fontSize="xs" fontWeight="bold">
                  {businessLocation?.completeAddress}
                </Text>
                <Heading as="h4" size="xs" color={`${colors.blue.default} !important`}>
                  {getTranslatedValue(bookingDetail.candidate.jobTitle, i18n.language)}
                </Heading>
                <Text fontSize="sm">
                  {`${moment(bookingDetail.booking.date).format('hh:mm A')} - ${moment(bookingDetail.booking.date)
                    .add(bookingDetail.booking.duration, 'minutes')
                    .format('hh:mm A')}`}
                </Text>
                {bookingDetail.candidate.dismissed && (
                  <Flex alignItems="center">
                    <PositionStateDot color={colors.red[600]} />

                    <Text
                      fontSize="xs"
                      color={colors.red[600]}
                      lineHeight="shorter"
                      pt={1}
                      maxW={{ base: '11rem', md: '12rem', lg: '18rem' }}
                    >
                      {t('candidateStatus:DISMISSED')}
                    </Text>
                  </Flex>
                )}
              </Box>
              <Box p="0px 15px">
                <Box borderColor="#EBEDF3" borderBottomWidth="1px" p="15px 0">
                  <Heading as="h4" size="xs">
                    {t('eventForm.interviewLabel')}
                  </Heading>
                  {bookingDetail.candidate.appointment?.adhoc ? (
                    <Flex>
                      <Text fontSize="sm">{t(`common:appointmentTypes.${bookingDetail.booking.type}`)}</Text>
                      <Text fontSize="sm" fontStyle="italic" color={colors.gray[500]} ml={1}>
                        {t('availabilityDetail.onDemand')}
                      </Text>
                    </Flex>
                  ) : (
                    <Text fontSize="sm">{t(`common:appointmentTypes.${bookingDetail.booking.type}`)}</Text>
                  )}
                </Box>
                {bookingDetail.booking.type === AppointmentType.inPerson && (
                  <Box borderColor="#EBEDF3" borderBottomWidth="1px" p="15px 0">
                    <Heading as="h4" size="xs">
                      {t('interview.location')}
                    </Heading>
                    <Text fontSize="sm">{bookingDetail.booking.location}</Text>
                  </Box>
                )}
                <Box borderColor="#EBEDF3" borderBottomWidth="1px" p="15px 0">
                  <Heading as="h4" size="xs">
                    {t('eventForm.interviewerLabel')}
                  </Heading>
                  <Text fontSize="sm">{`${bookingDetail.booking.recruiterName}`}</Text>
                </Box>
                <Flex borderColor="#EBEDF3" borderBottomWidth="1px" p="15px 0">
                  <Box>
                    <Heading as="h4" size="xs">
                      {t('eventForm.nameLabel')}
                    </Heading>
                    <Text fontSize="sm">{`${bookingDetail.candidate.firstName} ${bookingDetail.candidate.lastName}`}</Text>
                  </Box>
                  <Spacer />
                  {currentUserProfile?.id === bookingDetail.booking.recruiterId && (
                    <Text fontSize="xs" className="communicate-badge">
                      <Link to={`candidates/${bookingDetail.candidate.id}/messaging`}>{t('eventForm.sendMessage')}</Link>
                    </Text>
                  )}
                </Flex>

                {seekerExperiences && seekerExperiences.hasExperiences && seekerExperiences.experiences && (
                  <Box borderColor="#EBEDF3" borderBottomWidth="0">
                    <Box
                      m="0px -15px"
                      className={/* istanbul ignore next */ accordionOpen ? 'accordion-opened' : 'accordion-collapsed'}
                    >
                      <Box
                        p="15px"
                        d="flex"
                        alignItems="center"
                        onClick={/* istanbul ignore next */ () => setAccordionOpen(!accordionOpen)}
                      >
                        <Box flex="1" textAlign="left">
                          <Heading as="h4" size="xs">
                            {t('eventForm.experienceLabel')}
                          </Heading>
                          <Text fontSize="sm">{t('interview.longDuration')}</Text>
                        </Box>
                        <Box className="drop-arrow">
                          <FaChevronDown />
                        </Box>
                      </Box>
                      <Box className="experience-block">
                        <Box>
                          <Stack spacing={2}>
                            {seekerExperiences.experiences.map((experience, index) => (
                              <Experience
                                key={experience.id}
                                experience={experience}
                                dataIndex={index}
                                totalItems={seekerExperiences.experiences.length}
                                paddingTop={2}
                                displayBusiness
                              />
                            ))}
                          </Stack>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              {enableCollaborators && !bookingDetail.candidate.dismissed && !isPassedInterview() && (
                <Box p="15px">
                  <InviteCollaboratorsView bookingDetail={bookingDetail} />
                </Box>
              )}
            </DrawerBody>
            <Flex justify="flex-start" py={3} borderTop="2px" borderColor="#EBEDF3">
              {/* <Button data-testid="RescheduleEventBtn" colorScheme="blue" variant="solid" borderRadius={3} ml={6}>
                {t('eventForm.rescheduleEvent')}
              </Button> */}
              {showCancelInterviewBtn() && (
                <Button
                  variant="outline"
                  ml={6}
                  borderRadius={3}
                  mr={3}
                  onClick={onBookingDetailViewModalOpen}
                  data-testid="CancelDrawerBtn"
                >
                  {t('booking.cancelEvent')}
                </Button>
              )}

              {isInterviewTimeFinished(bookingDetail.booking) &&
                bookingDetail.candidate &&
                currentUserProfile?.id &&
                bookingDetail.booking.joinedRecruiterId?.includes(currentUserProfile?.id) &&
                !bookingDetail.candidate?.dismissed && (
                  <Tooltip
                    label={is24HourLimitExceeded(bookingDetail.booking) ? t('meet:ratingDisabledTooltip') : ''}
                    fontSize="md"
                    placement="top"
                  >
                    <Box>
                      <Button
                        variant="solid"
                        colorScheme="blue"
                        ml={6}
                        borderRadius={3}
                        data-testid="RateInterviewBtn"
                        mr={3}
                        isDisabled={is24HourLimitExceeded(bookingDetail.booking)}
                        onClick={storeEventDetailForInterviewRating}
                      >
                        {t('interview.rateBtn')}
                      </Button>
                    </Box>
                  </Tooltip>
                )}
            </Flex>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Fragment>
  );
};
