import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  theme,
  Text,
  HStack,
  ButtonGroup,
  useToast,
  Spinner,
  Select,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { MdOutlineBorderColor } from 'react-icons/md';
import { CloseIcon } from '@chakra-ui/icons';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import Autolinker from 'autolinker';
import { CreateTemplateSettingsRequest } from '../hireForm/templateSettings/templateSettings.interface';
import { useStoreActions } from '../../../models/hooks';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import MobilePreviewView, { BusinessLogoMobilePreview } from '../hireForm/mobilePreview/MobilePreviewView';
import { Language, languages } from '../hireForm/formElements/formBuilderSchema';
import useExitPrompt from './useExitPrompt';

function strip(html: string): string {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

interface DocumentSettingsProps {
  activeTab: number;
  currentTab: number;
}

export default function DocumentSettings({ activeTab, currentTab }: DocumentSettingsProps) {
  const { t } = useTranslation('administration');
  const [isCreating, setIsCreating] = React.useState(false);
  const [devicePreview, setDevicePreview] = React.useState(true);
  const toast = useToast();
  const [isEdited, setIsEdited] = React.useState(false);
  const previousState = React.useRef<CreateTemplateSettingsRequest | null>(null);
  const [disclaimerError, setDisclaimerError] = React.useState<Record<Language, string> | null>(null);
  const [consentTextError, setConsentTextError] = React.useState<Record<Language, string> | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedLanguage, setSelectedLanguage] = React.useState<Language>('en');
  const languageRef = React.useRef(selectedLanguage);

  const [formData, setFormData] = React.useState<CreateTemplateSettingsRequest>({
    hireFormDisclaimer: null,
    consentText: {
      en: 'I consent',
      fr: 'Je consent',
    },
  });
  React.useEffect(() => {
    languageRef.current = selectedLanguage; // Update the ref whenever selectedLanguage changes
  }, [selectedLanguage]);

  const [, setShowExitPrompt, triggerExitPrompt] = useExitPrompt(false);
  React.useEffect(() => {
    setShowExitPrompt(isEdited);
  }, [isEdited, setShowExitPrompt]);

  const { postOrganizationHireFormSettings, getOrganizationHireFormSettings } = useStoreActions((state) => state.account);
  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';

  React.useEffect(() => {
    if (previousState.current && !isEqual(JSON.stringify(previousState.current), JSON.stringify(formData))) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  }, [formData]);

  React.useEffect(() => {
    const getOrganizationSettings = async () => {
      try {
        setIsLoading(true);
        const response = await getOrganizationHireFormSettings({ accountId });
        const newData = {
          ...formData,
          ...omit(response.data.data, 'id'),
        };
        setFormData(newData);
        previousState.current = newData;
      } finally {
        setIsLoading(false);
      }
    };
    // eslint-disable-next-line
    getOrganizationSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const checkDisclaimerError = (disclaimer: Record<Language, string> | null) => {
    setDisclaimerError(null);

    if (disclaimer) {
      // eslint-disable-next-line
      for (const [language, text] of Object.entries(disclaimer)) {
        if (!strip(text).trim()) {
          setDisclaimerError((prev) => ({
            ...prev!,
            // eslint-disable-next-line
            [language]: 'companyResources.emptyDisclaimerMessage' as string,
          }));
          return { hasError: true, firstErrorLanguage: language as Language };
        }
      }
    }

    return { hasError: false, firstErrorLanguage: null };
  };

  const checkConsentTextError = (consentText: Record<Language, string> | null) => {
    setConsentTextError(null);

    if (consentText) {
      // eslint-disable-next-line
      for (const [language, text] of Object.entries(consentText)) {
        if (!strip(text).trim()) {
          setConsentTextError((prev) => ({
            ...prev!,
            [language]: 'companyResources.emptyConsentMessage',
          }));
          return { hasError: true, firstErrorLanguage: language as Language };
        }
      }
    }

    return { hasError: false, firstErrorLanguage: null };
  };

  const handleCheckError = (onSuccess?: () => Promise<void>) => {
    let hasError = false;
    let firstErrorLanguage: Language | null = null;

    let res = checkDisclaimerError(formData.hireFormDisclaimer);
    hasError = res.hasError;
    firstErrorLanguage = res.firstErrorLanguage;
    res = checkConsentTextError(formData.consentText);
    hasError = hasError || res.hasError;
    firstErrorLanguage = firstErrorLanguage ?? res.firstErrorLanguage;
    if (firstErrorLanguage) {
      setSelectedLanguage(firstErrorLanguage);
    }
    if (!hasError) {
      // eslint-disable-next-line
      onSuccess?.();
    }
  };

  const onSubmit = () => {
    handleCheckError(async () => {
      try {
        if (isCreating) return;
        setIsCreating(true);
        await postOrganizationHireFormSettings({
          accountId,
          hireFormSettings: formData,
        });
        previousState.current = formData;
        setIsEdited(false);
        toast({
          description: t('companyResources.successMessage'),
          status: 'success',
          isClosable: true,
        });
      } catch {
        toast({
          description: t('companyResources.errorMessage'),
          status: 'error',
          isClosable: true,
        });
      } finally {
        setIsCreating(false);
      }
    });
  };

  const handleCancel = () => {
    setTimeout(() => {
      setFormData({ ...previousState.current! });
    }, 0);
  };

  React.useEffect(() => {
    if (activeTab !== currentTab) {
      triggerExitPrompt();
      // handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const onClickUpdate = () => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(() => {
      onSubmit();
    }, 0);
  };

  const handleLanguageChange = (language: Language) => {
    setSelectedLanguage(language);
  };

  const handleDisclaimerChange = (value: string) => {
    const newDisclaimer = { ...formData.hireFormDisclaimer!, [languageRef.current]: value || '' };

    setFormData((prev) => ({
      ...(prev || {}),
      hireFormDisclaimer: {
        ...prev.hireFormDisclaimer!,
        [languageRef.current]: value || '',
      },
    }));
    checkDisclaimerError(newDisclaimer);
  };

  if (isLoading) {
    return (
      <Box padding={theme.space[8]} backgroundColor={theme.colors.white} borderRadius={theme.radii.md} align="center">
        <Spinner color={theme.colors.blue[500]} />
      </Box>
    );
  }

  return (
    <HStack align="flex-start">
      <Stack padding={theme.space[2]} backgroundColor={theme.colors.white} borderRadius={theme.radii.md} align="center">
        <FormControl>
          <FormLabel fontWeight={700} color="#1A202C" marginBottom={theme.space[4]}>
            <HStack justify="space-between">
              <Text>{t('companyResources.disclaimerText')}</Text>
              <HStack spacing={4}>
                <Box>
                  <Select
                    onChange={(e) => handleLanguageChange(e.target.value as Language)}
                    value={selectedLanguage}
                    variant="outline"
                    size="sm"
                    colorScheme="blue[100]"
                    borderRadius={theme.radii.md}
                    maxWidth="fit-content"
                    textTransform="capitalize"
                    disabled={isLoading}
                  >
                    {languages.map((lang) => (
                      <option key={lang} style={{ textTransform: 'capitalize' }}>
                        {lang}
                      </option>
                    ))}
                  </Select>
                </Box>
                {!devicePreview && (
                  <Button
                    variant="link"
                    fontSize={12}
                    fontWeight="normal"
                    textDecoration="underline"
                    colorScheme="blue"
                    onClick={() => setDevicePreview(true)}
                    disabled={isLoading}
                  >
                    {t('companyResources.preview')}
                  </Button>
                )}
              </HStack>
            </HStack>
            <Text fontSize="sm" color="gray.500" fontWeight={400}>
              {t('companyResources.disclaimerSubText')}
            </Text>
          </FormLabel>
          <Stack
            spacing={2}
            background="#EDF2F7"
            padding={0}
            borderRadius={theme.radii.md}
            _focusWithin={{
              outline: `1px solid ${theme.colors.blue[300]}`,
            }}
            paddingBottom={theme.space[4]}
            sx={{
              '& p': {
                margin: 'revert !important',
              },
            }}
          >
            <QuillEditor
              value={formData.hireFormDisclaimer?.[selectedLanguage] || ''}
              onChange={handleDisclaimerChange}
              // language={selectedLanguage}
            />
          </Stack>
        </FormControl>
        {disclaimerError && (
          <Text fontSize="xs" color="red.500" fontWeight={400} width="100%">
            {t(disclaimerError[selectedLanguage])}
          </Text>
        )}
        <Box>
          <EditableButton
            value={formData.consentText?.[selectedLanguage]}
            onChange={(value) => {
              const newConsentText = { ...formData.consentText, [languageRef.current]: value || '' };

              setFormData((prev) => ({
                ...prev,
                consentText: {
                  ...prev.consentText,
                  [languageRef.current]: value || '',
                },
              }));
              checkConsentTextError(newConsentText);
            }}
          />
        </Box>
        {consentTextError && (
          <Text fontSize="xs" color="red.500" fontWeight={400} width="100%">
            {t(consentTextError[selectedLanguage])}
          </Text>
        )}
        {isEdited && (
          <HStack justify="flex-end" marginTop={theme.space[4]} width="100%">
            <ButtonGroup size="sm">
              <Button colorScheme="red" onClick={handleCancel} disabled={isCreating}>
                {t('companyResources.cancel')}
              </Button>
              <Button onMouseDown={(e) => e.preventDefault()} colorScheme="blue" isLoading={isCreating} onClick={onClickUpdate}>
                {t('companyResources.update')}
              </Button>
            </ButtonGroup>
          </HStack>
        )}
      </Stack>
      {devicePreview && (
        <Box
          backgroundColor={theme.colors.white}
          borderRadius={theme.radii.md}
          padding={theme.space[4]}
          flexBasis="350px"
          flexShrink={0}
          height="100%"
        >
          <HStack align="flex-start" justify="space-between" marginBottom={theme.space[2]}>
            <Text fontWeight={700} color="#1A202C" fontSize={theme.fontSizes.lg}>
              {t('companyResources.devicePreview')}
            </Text>
            <Button size="sm" variant="square" onClick={() => setDevicePreview(false)}>
              <CloseIcon />
            </Button>
          </HStack>
          <PreviewView formData={formData} selectedLanguage={selectedLanguage} />
        </Box>
      )}
    </HStack>
  );
}

interface QuillEditorProps {
  value: string;
  onChange: (value: string) => void;
  // language: Language;
  // isEditing?: boolean;
  // setIsEditing?: (isEditing: boolean) => void;
}

const QuillEditor = ({ value, onChange }: QuillEditorProps) => {
  const { t } = useTranslation('administration');

  const quillRef = React.useRef<ReactQuill>(null);
  const boxRef = React.useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const handleOutsideClick = (event: MouseEvent) => {
    if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
      setIsEditing?.(false);
      quillRef.current?.blur();
    }
  };
  React.useEffect(() => {
    if (isEditing) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);
  const isValidUrl = (url: string): boolean => {
    // Use Autolinker's method to extract valid URLs
    const autolinker = new Autolinker({ email: false, phone: false, hashtag: false, mention: false, sanitizeHtml: true });
    const result = autolinker.link(url);

    // If the result contains a valid URL, return true
    return result !== url && result !== '';
  };

  React.useEffect(() => {
    const quill = quillRef.current?.getEditor();

    if (quill) {
      let selectedText: string | null = null;
      let selection: { index: number; length: number } | null = null;
      quill.on('selection-change', (range) => {
        if (range) {
          selectedText = quill.getText(range.index, range.length);
          selection = quill.getSelection();
        }
      });
      // Handle the paste event
      quill.root.addEventListener('paste', (e: ClipboardEvent) => {
        e.preventDefault();

        function hyperlinkSelectedTextToUrl(selected: string, url: string) {
          const hyperlink = `<a href="${url}" target="_blank">${selected}</a>`;

          return hyperlink;
        }
        // eslint-disable-next-line
        const clipboardData = e.clipboardData || (window as any).clipboardData;
        // eslint-disable-next-line
        const pastedText = clipboardData?.getData('text') || '';
        if (selection && isValidUrl(pastedText)) {
          const index = selection.index || 0;
          const linkedText = Autolinker.link(pastedText, { sanitizeHtml: true });
          if (selectedText?.length && selection) {
            quill.clipboard.dangerouslyPasteHTML(index + selection.length, hyperlinkSelectedTextToUrl(selectedText, pastedText));
          } else {
            quill.clipboard.dangerouslyPasteHTML(index, linkedText);
          }
        } else {
          // eslint-disable-next-line
          quill.clipboard.dangerouslyPasteHTML(selection?.index || 0, clipboardData.getData('text/html'));
        }
        selectedText = null;
        selection = null;
      });
    }

    return () => {
      // eslint-disable-next-line
      const quill = quillRef.current?.getEditor();
      if (quill) {
        quill.root.removeEventListener('paste', () => {});
        quill.off('selection-change', () => {});
      }
    };
  }, []);

  const handleChange = React.useCallback(
    debounce((content: string) => {
      if (quillRef.current) {
        let contentText = content;

        if (content === '<p><br></p>') {
          contentText = '';
        }

        // Check if it's a valid URL
        if (isValidUrl(contentText)) {
          // Use Autolinker to link the URL
          const autolinker = new Autolinker({
            email: false,
            phone: false,
            hashtag: false,
            mention: false,
          });

          contentText = autolinker.link(contentText);
        }

        // Call the provided onChange function with the modified content
        onChange(contentText);
      }
    }, 300), // debounce delay (500ms)
    [],
  );

  return (
    <Box
      sx={{
        '& .quill,  & .ql-container': {
          border: 'none !important',
        },
        '& .ql-toolbar': {
          borderTop: 'none !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          backgroundColor: 'white',
          display: isEditing ? 'block' : 'none',
        },
      }}
      ref={boxRef}
    >
      <ReactQuill
        value={value}
        onChange={handleChange}
        ref={quillRef}
        theme="snow"
        data-testid="editor"
        placeholder={t('companyResources.editorPlaceholderText')}
        modules={{
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
            ],
          },
          clipboard: {
            matchVisual: false,
          },
        }}
        formats={['bold', 'italic', 'underline', 'list', 'bullet', 'link']}
        onChangeSelection={(_section, source) => {
          if (source === 'user') {
            setIsEditing?.(true);
          }
        }}
      />
    </Box>
  );
};

interface EditableInputProps {
  value: string;
  onChange: (value: string) => void;
}
const EditableButton = ({ value, onChange }: EditableInputProps) => {
  const { t } = useTranslation('administration');
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  return (
    <Box
      sx={{
        backgroundColor: theme.colors.orange[500],
        borderRadius: theme.radii.md,
      }}
      minW="100px"
      onClick={() => setIsEditing(true)}
    >
      <InputGroup>
        <Input
          variant="unstyled"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          width="100%"
          onBlur={() => setIsEditing(false)}
          size="md"
          placeholder={t('companyResources.consentButtonPlaceholder')}
          minWidth="fit-content"
          sx={{
            paddingX: theme.space[2],
            paddingY: theme.space[2],
            color: theme.colors.white,
            fontWeight: 'bold',
            '&:focus': {
              outline: '2px solid',
              outlineColor: theme.colors.blue[300],
              outlineOffset: '1px',
              transition: 'unset',
            },
          }}
          _placeholder={{ color: theme.colors.white, fontSize: theme.fontSizes.sm, fontWeight: 'normal' }}
        />
        {!isEditing && (
          <InputRightElement pointerEvents="none" padding={0}>
            <MdOutlineBorderColor color={theme.colors.gray[200]} />
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  );
};

interface PreviewViewProps {
  formData: CreateTemplateSettingsRequest;
  selectedLanguage: Language;
}

const PreviewView = ({ formData, selectedLanguage }: PreviewViewProps) => {
  return (
    <Stack
      sx={{
        '& p': {
          margin: 'revert !important',
        },
        '& a': {
          textDecoration: 'underline',
          color: theme.colors.blue[400],
        },
      }}
    >
      <MobilePreviewView hideProgress height="100%">
        <Stack>
          <Stack spacing={1} align="center" padding={theme.space[5]}>
            <BusinessLogoMobilePreview />
            <Box
              dangerouslySetInnerHTML={{ __html: formData.hireFormDisclaimer?.[selectedLanguage] || '' }}
              fontSize="sm"
              lineHeight={theme.fontSizes.lg}
              color="#718096"
              sx={{ '*': { wordBreak: 'break-all' } }}
            />
            <Button colorScheme="orange" isFullWidth>
              {formData.consentText[selectedLanguage]}
            </Button>
          </Stack>
        </Stack>
      </MobilePreviewView>
    </Stack>
  );
};
