import { Box, Stack, theme } from '@chakra-ui/core';
import React from 'react';
import {
  FieldSchema,
  formBuilderType,
  generateFormSchema,
  isFieldSchema,
  mapField,
  Sections,
  SectionSchema,
} from './formBuilderSchema';
import { FormBuilderProps } from '../formBuilder/FormBuilder';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { v4 as uuidv4 } from 'uuid';
import { isArray } from 'lodash';
import { useHireFormContext } from '../HireForm.context';
import { formBuilderSchemas } from '../formBuilderSchemas/formBuilder.schema';
import { SortableFormElementBuilder } from './formElementBuilder/FormElementBuilder';
import update from 'immutability-helper';
import DroppableElement from './formElementBuilder/DroppableElement';
import EmptySectionDropZone from './section/EmptySectionDropZone';
import SectionActions from './section/SectionActions';
import useHandleDrop from './useHandleDrop';

interface FormBuilderSectionsProps extends Omit<FormBuilderProps, 'templateName' | 'setTemplateName' | 'language'> {}

export default function FormBuilderSections(props: FormBuilderSectionsProps) {
  const { formSchema, setFormSchema } = useHireFormContext();

  const handleDropSection = (event: React.DragEvent<HTMLDivElement>) => {
    const type = event.dataTransfer.getData('type');
    const schemas = [...formSchema];
    const isSignature = type === formBuilderType.signatureAdvanced;
    const hasSignature = formSchema.some((section) => section.section === Sections.signature && section.isAdvanced);

    if (type === 'section') {
      const newSection = { section: 'section' + (formSchema.length + 1), fields: [], id: uuidv4() };
      if (hasSignature) {
        schemas.splice(formSchema.length - 1, 0, newSection);
      } else {
        schemas.push(newSection);
      }
      setFormSchema(schemas);
    } else {
      const schema = formBuilderSchemas[type as keyof typeof formBuilderSchemas];
      if (schema.panel === 'advanced' && isArray(schema.defaultValue)) {
        const newSchema = {
          section: schema.section!,
          fields: schema.defaultValue?.map((field) => ({ ...mapField(field) })),
          isAdvanced: true,
          id: uuidv4(),
        };
        if (hasSignature) {
          schemas.splice(schemas.length - 1, 0, newSchema);
        } else if (isSignature || (schemas.length === 1 && schemas[0].fields.length === 0)) {
          schemas.push(newSchema);
        } else {
          schemas.push(newSchema);
        }
        setFormSchema(schemas);
      } else {
        const defaultValue = schema.defaultValue as FieldSchema;
        const field = { ...mapField(defaultValue) };
        const newSection: SectionSchema = { section: 'section' + (schemas.length + 1), fields: [field], id: uuidv4() };

        if (hasSignature) {
          schemas.splice(schemas.length - 1, 0, newSection);
        } else {
          schemas.push(newSection);
        }
        setFormSchema(schemas);
      }
    }
  };
  return (
    <Box
      marginBottom={8}
      flexGrow={1}
      onDrop={handleDropSection}
      onDragOver={(e) => e.preventDefault()}
      data-testid="form-builder-sections"
    >
      <SortableSectionContainer
        {...props}
        onSortEnd={({ oldIndex, newIndex }) => {
          // const signatureIndex = formSchema.findIndex((section) =>
          //   section.fields.some((field) => isFieldSchema(field) && field.inputType === formBuilderType.signature),
          // );
          // if (newIndex === signatureIndex) {
          //   toast({
          //     status: 'warning',
          //     description: t('formBuilder.signatureSwapError'),
          //   });
          //   return;
          // }

          const updatedList = update(formSchema, {
            $splice: [
              [oldIndex, 1],
              [newIndex, 0, formSchema[oldIndex]],
            ],
          });
          setFormSchema(updatedList);
        }}
        distance={20}
        axis="y"
        lockAxis="y"
        hideSortableGhost={true}
        lockToContainerEdges={true}
        useDragHandle
      />
    </Box>
  );
}

const SortableSectionContainer = SortableContainer(function ({ ...props }: FormBuilderSectionsProps) {
  const { formSchema } = useHireFormContext();

  return (
    <Stack flexGrow={1} height="100%">
      {formSchema.map((section, sectionIndex) => {
        if (!section) return null;
        // const isSignature = section.fields.some((field) => isFieldSchema(field) && field.inputType === formBuilderType.signature);
        return (
          <SortableSectionElement
            key={section.id}
            section={section}
            sectionIndex={sectionIndex}
            index={sectionIndex}
            {...props}
            // disabled={isSignature}
          />
        );
      })}
    </Stack>
  );
});

interface SortableSectionElementProps extends FormBuilderSectionsProps {
  section: SectionSchema;
  sectionIndex: number;
}

const SortableSectionElement = React.memo(
  SortableElement(function ({ section, sectionIndex }: SortableSectionElementProps) {
    const { formSchema, setFormSchema } = useHireFormContext();
    const { handleDropToParent } = useHandleDrop({ sectionIndex });

    return (
      <DroppableElement
        onDrop={(_, dropTarget) => {
          handleDropToParent({ dropTarget });
        }}
        hideDivider
        acceptedTypes={['page-element', 'advanced', 'basic']}
        style={{
          height: formSchema.length === 1 && !section.fields.length ? '100%' : 'auto',
          margin: 0,
        }}
        index={sectionIndex}
      >
        <SectionElementContainer
          section={section}
          sectionIndex={sectionIndex}
          key={sectionIndex}
          onSortEnd={({ oldIndex, newIndex }) => {
            const newFormSchemas = [...formSchema];
            const fields = [...section.fields];
            const updatedList = update(fields, {
              $splice: [
                [oldIndex, 1],
                [newIndex, 0, fields[oldIndex]],
              ],
            });
            const newSection = { ...section, fields: updatedList };
            setFormSchema(
              newFormSchemas.map((item, index) => {
                if (index === sectionIndex) {
                  return newSection;
                }
                return item;
              }),
            );
          }}
          distance={20}
          axis="y"
          lockAxis="y"
          hideSortableGhost={true}
          useDragHandle
          lockToContainerEdges
        />
      </DroppableElement>
    );
  }),
);

interface SectionElementContainerProps {
  section: SectionSchema;
  sectionIndex: number;
}

const SectionElementContainer = SortableContainer(function ({ section, sectionIndex }: SectionElementContainerProps) {
  const { formSchema, language, setFormSchema } = useHireFormContext();

  const currentSection = React.useMemo(
    () => formSchema.find((s) => s.id === section.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formSchema],
  );

  const isAdvancedSection = section?.isAdvanced ?? false;

  return (
    <Box
      role="group"
      position="relative"
      marginTop={sectionIndex === 0 ? 0 : 2}
      flexGrow={currentSection?.fields?.length === 0 ? 1 : 0}
    >
      <SectionActions {...{ sectionIndex, section, isAdvancedSection }} />
      <Box
        backgroundColor={theme.colors.white}
        borderRadius={theme.radii.md}
        outline={`2px solid ${currentSection?.isAdvanced ? theme.colors.blue[200] : 'transparent'}`}
      >
        <Stack spacing={0}>
          {currentSection?.fields?.length === 0 && <EmptySectionDropZone sectionIndex={sectionIndex} />}
          {currentSection?.fields
            ?.filter((item) => (isFieldSchema(item) ? !item?.disabled : true))
            ?.map((item, index) => {
              const pathIndex = generateFormSchema(formSchema, language, true)?.findIndex((schema) => schema.id === item.id);

              return (
                <SortableFormElementBuilder
                  key={item.id}
                  field={item}
                  fieldIndex={index}
                  sectionIndex={sectionIndex}
                  isAdvancedSection={isAdvancedSection}
                  index={index}
                  isLastElement={index === currentSection?.fields?.length - 1}
                  pathIndex={pathIndex}
                  section={currentSection}
                  onChange={(value) => {
                    const newFormSchema = [...formSchema];
                    if (isArray(value)) {
                      value.forEach((item) => {
                        const fieldIndex = newFormSchema[sectionIndex].fields.findIndex((f) => f.id === item.id);
                        newFormSchema[sectionIndex].fields[fieldIndex] = item;
                      });
                    } else {
                      const fieldIndex = newFormSchema[sectionIndex].fields.findIndex((f) => f.id === value.id);
                      newFormSchema[sectionIndex].fields[fieldIndex] = value;
                    }
                    setFormSchema(newFormSchema);
                  }}
                />
              );
            })}
        </Stack>
      </Box>
    </Box>
  );
});
