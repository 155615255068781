import { thunk, Thunk } from 'easy-peasy';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { CandidateSearchListType, SearchDataType } from '../CandidateSearchTypes';
import { CandidateSearchController } from '../../../controllers/candidateSearchController';

export type CandidateSearchStoreModel = {
  candidateSearch: CandidateSearchModel;
};

export interface CandidateSearchModel {
  searchCandidates: Thunk<
    CandidateSearchModel,
    { accountId: string; searchData: SearchDataType },
    void,
    HttpStoreModel,
    Promise<CandidateSearchListType>
  >;
}

export const candidateSearchModel: CandidateSearchModel = {
  searchCandidates: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CandidateSearchController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.searchCandidates(payload.accountId, payload.searchData);
    return response?.data as CandidateSearchListType;
  }),
};
