/* eslint-disable @typescript-eslint/no-unused-expressions */
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, ButtonHTMLAttributes, useEffect } from 'react';
import { Box, Flex, Grid, Text, Badge, useToast, Spinner, Tooltip, Button } from '@chakra-ui/core';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { BsBookmarkStar, BsBookmarkStarFill } from 'react-icons/bs';
import _includes from 'lodash/includes';
import { IoIosArrowUp } from 'react-icons/io';
import { FaRegCopy, FaUserTimes } from 'react-icons/fa';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import useCandidatesPicker from './candidatesDropdown/useCandidatesPicker';
import { PositionStatesOverview } from './seekerInfo/positionStatesOverview/PositionStatesOverview';
import CandidatesDropdown from './candidatesDropdown/CandidatesDropdown';
import { avatarBoxCSS, backgroundCheckStatus, disabledStyle, seekerHeaderGridCSS, seekerInfoCSS } from './SeekerHeader.styles';
import { ActionsDropdown } from './actionsDropdown/ActionsDropdown';
import SeekerTabs from './seekerTabs/SeekerTabs';
import useSeekerInfo from './seekerInfo/useSeekerInfo';
import { useSeekerPicture } from '../../useSeekerPicture';
import { useSeekerContainerContext } from '../SeekerContainerContext';
import { ProfilePicture } from '../../../common';
import { useSelectedEngagedCandidate } from '../../MessagingViewContext';
import { CandidateController } from '../../../../controllers/candidateController';
import HttpClientWithRetry from '../../../../http/client/HttpClientWithRetry';
import { useStoreState, useStoreActions } from '../../../../models/hooks';
import colors from '../../../../styles/colors';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { useUserDocumentSnapshot } from '../../../../firebase/firestore/hooks';
import { useSeekersFilters } from '../../sidebar/filters/SeekersFiltersContext';
import SeekerHireFormStatus from './seekerHireFormStatus/SeekerHireFormStatus';
import SeekerModernHireStatus from './seekerModernHireStatus/SeekerModernHireStatus';
import CertnLogo from '../../../../assets/img/certn-logo.svg';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import KisokIcon from '../../../../assets/img/Kiosk.svg';
import AppyHereIcon from '../../../../assets/img/icon-appyhere.svg';
import { isCandidatePage, isPositionCandidatesPage } from '../../../../utils/candidatesUtils';
import { PATH_POSITION_TABS } from '../../../../routes/constants';
import { SelectedTab } from '../../../administration/topCandidates/TopCandidatesContext';

export type SeekerHeaderProps = {
  isInterviewEnabled: boolean;
  isCertnFeatureEnabled: boolean;
  isCertnEnabled: boolean;
  isShowFavoriteStatusEnabled: boolean;

  isUserDeactivated: boolean;
  isUndismissedeEnabled: boolean;
  isMessagingView: boolean;
};

const SeekerHeader = ({
  isInterviewEnabled,
  isCertnFeatureEnabled,
  isCertnEnabled,
  isShowFavoriteStatusEnabled,
  isUserDeactivated,
  isUndismissedeEnabled,
  isMessagingView,
}: SeekerHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const candidates = useCandidatesPicker();

  const { selectedCandidate, setSelectedCandidate } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const candidate = selectedCandidate || selectedEngagedCandidate;
  const { candidateId: candidateIdFromParams } = useParams<{ candidateId: string }>();

  const { currentUserProfile } = useCurrentUserProfile();
  const seekerInfo = useSeekerInfo(candidate);
  const picture = useSeekerPicture(candidate?.seeker);
  const { ghostedListView, archiveListView, dismissListView } = useSeekersFilters();

  const { client } = useStoreState((s) => s.http);
  const { addSeekerToFavorite, removeSeekerFromFavorite } = useStoreActions((actions) => actions.account);
  const hireFormEnabled = useStoreState((state) => state.app.accounts?.configuration?.hireFormEnabled);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const user = useUserDocumentSnapshot(currentUserProfile?.id);
  const isFavorite = user?.favouriteSeekers?.find((seeker) => seeker.id === candidate?.seeker);

  // istanbul ignore next
  const showPdf = () => {
    const controller = new CandidateController(client as HttpClientWithRetry);

    const getPdf = async () => {
      setLoading(true);

      if (candidate) {
        try {
          await controller.getCertnPdf(candidate.id).then((response: string) => {
            const file = new Blob([response], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          });
        } catch (error) {
          toast({
            title: t('certn:screeningError'),
            description: t('certn:incompleteScreeningError'),
            duration: 3000,
            isClosable: true,
            status: 'warning',
          });
        }
      }
      setLoading(false);
    };
    // eslint-disable-next-line
    getPdf();
  };

  const addSeekerToFavoriteHandler = async () => {
    try {
      setDisabled(true);
      await addSeekerToFavorite({
        accountId: candidate?.account as string,
        userId: currentUserProfile?.id as string,
        seekerId: candidate?.seeker as string,
      });
    } finally {
      setDisabled(false);
      toast({
        title: t('notification:seekerHeader.actionSuccessFul'),
        description: t('notification:seekerHeader.addedToFavorite'),
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const removeSeekerFromFavoriteHandler = async () => {
    try {
      setDisabled(true);
      await removeSeekerFromFavorite({
        accountId: candidate?.account as string,
        userId: currentUserProfile?.id as string,
        seekerId: candidate?.seeker as string,
      });
    } finally {
      setDisabled(false);
      toast({
        title: t('notification:seekerHeader.actionSuccessFul'),
        description: t('notification:seekerHeader.removedFromFavorite'),
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const hireFormEnabledPositions = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabledPositions);

  const isModernHireEnable = useStoreState((s) => s.app.accounts?.configuration?.modernHireEnable);

  const isPriorityFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_PRIORITY_CANDIDATE_FILTER];
  });

  // istanbul ignore next
  const onCopyCandidateURL = async (candidateId: string) => {
    await navigator.clipboard.writeText(`${window.location.origin}/candidate/${candidateId}/messaging`);
    toast({
      title: t('notification:seekerHeader.urlCopiedInfo'),
      duration: 2000,
      isClosable: true,
    });
  };

  const location = useLocation();
  const history = useHistory();

  return (
    <Grid css={seekerHeaderGridCSS} data-testid="SeekerHeaderContainer" className="seeker-panel__header">
      <Flex alignItems="center">
        {isCandidatePage() && /* istanbul ignore next */ location.state && (
          // {/* {isCandidatePage() && /* istanbul ignore next */ location.state && ( */}
          // istanbul ignore next
          <Button
            onClick={
              // istanbul ignore next
              () => {
                history.push({
                  pathname: PATH_POSITION_TABS(candidateIdFromParams.split('_')[0], SelectedTab.ACTIVE),
                });
              }
            }
            variant="ghost"
            colorScheme="blue"
            style={{ backgroundColor: '#fff' }}
            px={2}
            py={0}
            mr={2}
            height="unset"
            data-testid="backBtn"
            leftIcon={<IoArrowBackOutline size={24} color={colors.blue[500]} />}
          >
            <Text color={colors.blue[500]}>{t('createJobPosition:stepsNav.back')}</Text>
          </Button>
        )}
        <Box css={avatarBoxCSS} px={3} pt={2}>
          <ProfilePicture
            size="lg"
            style={{ backgroundColor: isUserDeactivated ? colors.gray[200] : 'orange', color: 'white' }}
            name={seekerInfo?.fullName}
            src={isUserDeactivated ? '' : picture}
            bg="transparent"
          />
        </Box>
      </Flex>
      <Flex alignItems="center" css={seekerInfoCSS}>
        <Flex flexGrow={2} alignItems="center">
          <Flex direction="column" flex="1" minW={{ base: '8rem', md: '10rem', lg: '15rem' }}>
            <Box display="flex">
              <Text className="user-selectable" fontSize="lg" lineHeight="shorter" mb={1} data-testid="FullName" mr="4px">
                {seekerInfo?.fullName}
              </Text>
              {
                /* istanbul ignore next */
                isPriorityFeatureEnabled && candidate?.priority && (
                  <Box ml="2px">
                    <Tooltip hasArrow label={t('notification:seekerHeader.priorityTooltip')}>
                      <span
                        style={{
                          background: colors.green['600'],
                          display: 'inline-block',
                          padding: '4px',
                          borderRadius: '50%',
                          marginRight: '6px',
                        }}
                      >
                        <IoIosArrowUp
                          style={{ opacity: isUserDeactivated ? 0.5 : 1 }}
                          cursor={isUserDeactivated || disabled ? 'no-drop' : 'pointer'}
                          width="1rem"
                          size={14}
                          color={colors.white}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                )
              }
              {candidate?.kioskEnabled && (
                <Box>
                  <Tooltip hasArrow label={t('notification:seekerHeader.kioskTooltip')}>
                    <span
                      style={{
                        background: colors.blue['500'],
                        display: 'inline-block',
                        padding: '4px',
                        borderRadius: '50%',
                        marginRight: '6px',
                      }}
                    >
                      <img alt="Kisko mode" src={KisokIcon} width="14px" />
                    </span>
                  </Tooltip>
                </Box>
              )}

              {candidate?.interventionEnabled && (
                <Box>
                  <Tooltip hasArrow label={t('notification:seekerHeader.interventionTooltip')}>
                    <span
                      style={{
                        background: colors.blue['500'],
                        padding: '4px',
                        borderRadius: '50%',
                        marginRight: '6px',
                        height: '22px',
                        width: '22px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img alt="intervene" src={AppyHereIcon} width="auto" />
                    </span>
                  </Tooltip>
                </Box>
              )}

              <Box display="flex">
                {isShowFavoriteStatusEnabled &&
                  !dismissListView &&
                  !ghostedListView &&
                  !archiveListView &&
                  (isFavorite ? (
                    <Box
                      as="button"
                      disabled={isUserDeactivated || disabled}
                      data-testid="UnFavoriteCandidate"
                      onClick={removeSeekerFromFavoriteHandler}
                    >
                      <Tooltip
                        hasArrow
                        label={
                          isUserDeactivated
                            ? t('notification:seekerHeader.deactivatedFavoriteText')
                            : t('notification:seekerHeader.unFavoriteTooltip')
                        }
                      >
                        <span
                          style={{
                            background: '#FF6600',
                            display: 'inline-block',
                            padding: '4px',
                            borderRadius: '50%',
                            marginRight: '6px',
                          }}
                        >
                          <BsBookmarkStarFill
                            style={{ opacity: isUserDeactivated ? 0.5 : 1 }}
                            color="#ffffff"
                            width="1rem"
                            size={14}
                            cursor={isUserDeactivated || disabled ? 'no-drop' : 'pointer'}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  ) : (
                    <Box
                      as="button"
                      disabled={isUserDeactivated || disabled}
                      data-testid="FavoriteCandidate"
                      onClick={addSeekerToFavoriteHandler}
                    >
                      <Tooltip
                        hasArrow
                        label={
                          isUserDeactivated
                            ? t('notification:seekerHeader.deactivatedFavoriteText')
                            : t('notification:seekerHeader.favoriteTooltip')
                        }
                      >
                        <span
                          style={{
                            background: '#f3f3f3',
                            display: 'inline-block',
                            padding: '4px',
                            borderRadius: '50%',
                            marginRight: '6px',
                          }}
                        >
                          <BsBookmarkStar
                            style={{ opacity: isUserDeactivated ? 0.5 : 1 }}
                            cursor={isUserDeactivated || disabled ? 'no-drop' : 'pointer'}
                            width="1rem"
                            size={14}
                            color="#FF6600"
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  ))}

                {isPositionCandidatesPage() &&
                  // istanbul ignore next
                  candidate?.id && (
                    // istanbul ignore next
                    <Box as="button" display="flex">
                      <Tooltip hasArrow label={t('notification:seekerHeader.candidateURLCopyTooltip')}>
                        <span
                          style={{
                            background: '#f3f3f3',
                            display: 'inline-block',
                            padding: '4px',
                            borderRadius: '50%',
                            marginRight: '6px',
                          }}
                        >
                          <FaRegCopy
                            size={14}
                            onClick={
                              // istanbul ignore next
                              () => onCopyCandidateURL(candidate?.id)
                            }
                            data-testid="copyCandidateURL"
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  )}
              </Box>
            </Box>
            <PositionStatesOverview statuses={seekerInfo?.statuses} />

            <Flex alignItems="center">
              {candidate?.deactivated && (
                <Tooltip label={t('candidateStatus:DEACTIVATED')} hasArrow fontSize="sm">
                  <Badge
                    css={backgroundCheckStatus}
                    data-testid="loadHireDetailButton"
                    textTransform="none"
                    cursor="pointer"
                    onClick={showPdf}
                    variant="subtle"
                    borderRadius="4px"
                    mr={2}
                  >
                    <Flex alignItems="center" padding="4px">
                      <Box as="span" mr={3}>
                        <FaUserTimes />
                      </Box>
                      <Text ml="-8px" color={colors.black}>
                        {t(`candidateStatus:DEACTIVATED`)}
                      </Text>
                    </Flex>
                  </Badge>
                </Tooltip>
              )}
              {isCertnFeatureEnabled && isCertnEnabled && candidate?.screeningDetails && (
                // istanbul ignore next
                <Tooltip label={t('certn:bgCheckStatusLabel')} hasArrow fontSize="sm">
                  <Badge
                    css={backgroundCheckStatus}
                    data-testid="loadHireDetailButton"
                    textTransform="none"
                    cursor="pointer"
                    onClick={showPdf}
                    variant="subtle"
                    borderRadius="4px"
                    mr={2}
                  >
                    <Flex alignItems="center" padding="4px">
                      <Box as="span" mr={3} color={colors.blue.default} data-testid="reportLink">
                        {
                          /* istanbul ignore next */
                          loading ? (
                            <Spinner thickness="2px" speed="0.65s" emptyColor="gray.200" color={colors.blue.default} size="sm" />
                          ) : (
                            <img src={CertnLogo} width="22px" alt="Certn logo" />
                          )
                        }
                      </Box>
                      <Text ml="-8px" color={colors.black}>
                        {t(`certn:${candidate.screeningDetails.status}`)}
                      </Text>
                    </Flex>
                  </Badge>
                </Tooltip>
              )}
              {hireFormEnabled &&
                candidate?.hireDetailsStatus &&
                _includes(
                  hireFormEnabledPositions,
                  candidate.newHireData ? candidate?.newHireData?.positionId : /* istanbul ignore next */ candidate?.position,
                ) && <SeekerHireFormStatus selectedCandidate={candidate} />}
              {isModernHireEnable && candidate?.modernHire && <SeekerModernHireStatus selectedCandidate={candidate} />}
            </Flex>
          </Flex>
        </Flex>
        <Flex alignItems="center">
          <Box css={isInterviewEnabled ? disabledStyle : ''}>
            <CandidatesDropdown
              marginRight={2}
              candidates={candidates.candidatePickerItems}
              value={candidate}
              onPickItem={setSelectedCandidate}
            />
          </Box>
          <Box pr={4} disabled>
            <ActionsDropdown
              isCertnFeatureEnabled={isCertnFeatureEnabled}
              isUndismissedeEnabled={isUndismissedeEnabled}
              isCertnEnabled={isCertnEnabled}
              certnStatus={selectedCandidate?.screeningDetails?.status.toLocaleLowerCase()}
              isUserDeactivated={Boolean(
                selectedCandidate &&
                  (selectedCandidate.deactivated || selectedCandidate.scheduledDeletion || selectedCandidate.dismissed),
              )}
              isMessagingView={isMessagingView}
            />
          </Box>
        </Flex>
      </Flex>
      <SeekerTabs />
    </Grid>
  );
};

export default SeekerHeader;
