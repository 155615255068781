/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import isNil from 'lodash/isNil';
import { Box } from '@chakra-ui/core';

import { Redirect } from 'react-router-dom';
import useCalendar from '../../calendar/useCalendar';
import useCalendarEvent from '../../calendar/useCalendarEvent';
import useAccounts from '../../../app/hooks/useAccounts';
import usePositions from './usePositions';
import useConfiguration, { IsUnderMaintenanceEnum } from '../../../app/hooks/useConfiguration';
import { PATH_MAIN } from '../../../routes/constants';
import { MaintenanceView } from '../../../error/MaintenanceView';
import useRecruiter from '../../messaging/seeker/candidate/notes/useRecruiter';
import { CalendarPanel } from '../../calendar/CalendarPanel';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { CalendarSidebarTabTypeEnum, RecruiterCalendarSidebarTabTypeEnum } from '../../calendar/CalendarSidebarTab';
import { NotAuthorizedView } from '../NotAuthorizedView';
import { useStoreState } from '../../../models/hooks';

export const RecruiterCalendarView = (): JSX.Element => {
  const appUserId = useStoreState((s) => s.app.user?.id);
  const appOwnersId = useStoreState((s) => s.app.accounts?.owners);

  const isAuthorizationStateLoaded = Boolean(appOwnersId && appUserId);
  const isAdministrationAccount = !isNil(appOwnersId) && !isNil(appUserId) && appOwnersId?.includes(appUserId);

  const { currentUserProfile } = useCurrentUserProfile();
  const [showCalendar, setShowCalendar] = React.useState<boolean>(true);
  const [selectedRecruiters, setSelectedRecruiters] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedRecruitersOfPositions, setSelectedRecruitersOfPositions] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<CalendarSidebarTabTypeEnum>(CalendarSidebarTabTypeEnum.RECRUITERS);
  const [activeCurrentDateRange, setActiveCurrentDateRange] = useState<{ fromDate: Date; toDate: Date }>({
    fromDate: moment(moment().startOf('month').subtract(6, 'days').format('YYYY-MM-DDT00:00:00')).toDate(),
    toDate: moment(moment().endOf('month').add(6, 'days').format('YYYY-MM-DDT23:59:59')).toDate(),
  });
  useCalendar(
    activeTab === CalendarSidebarTabTypeEnum.RECRUITERS ? selectedRecruiters : selectedRecruitersOfPositions,
    activeCurrentDateRange,
    true,
  );
  useCalendarEvent();

  const allRecruiters = useRecruiter();

  // istanbul ignore next
  const recruiters = allRecruiters?.filter((r) => r?.anonymousUser !== true && r?.firstName !== 'anonymous');

  const positions = usePositions();
  const accounts = useAccounts();
  const useConfig = useConfiguration();

  useEffect(() => {
    if (accounts) {
      setShowCalendar(accounts.calendarEnabled === true);
    }
  }, [accounts]);

  useEffect(() => {
    if (currentUserProfile) {
      setSelectedRecruiters([...selectedRecruiters, currentUserProfile.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredPositions = (tab: CalendarSidebarTabTypeEnum) => {
    if (currentUserProfile && tab === CalendarSidebarTabTypeEnum.FOLLOWED_POSITIONS) {
      return positions.filter((position) => position.assignedRecruiters?.includes(currentUserProfile?.id));
    }
    return positions;
  };

  const handleAssignedRecruiter = (positionIds: string[], tab: CalendarSidebarTabTypeEnum) => {
    let assignedRecruiters: string[] = [];
    positionIds.forEach((positionId) => {
      const positionRecruiters: string[] | undefined = filteredPositions(tab).filter((position) => position.id === positionId)[0]
        ?.assignedRecruiters;
      /* istanbul ignore next */
      if (positionRecruiters && !assignedRecruiters.some((r) => positionRecruiters.includes(r))) {
        assignedRecruiters = assignedRecruiters.concat(positionRecruiters);
      }
    });
    setSelectedRecruitersOfPositions(assignedRecruiters);
  };

  const handleCheckedPositions = (positionIds: string[]) => {
    setSelectedPositions(positionIds);
    handleAssignedRecruiter(positionIds, activeTab);
  };

  const handleActiveTab = (tab: CalendarSidebarTabTypeEnum | RecruiterCalendarSidebarTabTypeEnum) => {
    setActiveTab(tab as CalendarSidebarTabTypeEnum);
    handleAssignedRecruiter(selectedPositions, tab as CalendarSidebarTabTypeEnum);
  };

  if (!showCalendar) {
    return <Redirect to={PATH_MAIN} />;
  }

  if (isAuthorizationStateLoaded && !isAdministrationAccount) {
    return (
      <Box data-testid="NotAuthorizedView">
        <NotAuthorizedView isAuthorizationStateLoaded />
      </Box>
    );
  }

  const setActiveDateRange = (fromDate: Date, toDate: Date) => {
    setActiveCurrentDateRange({ fromDate, toDate });
  };

  return (
    <React.Fragment>
      {useConfig === IsUnderMaintenanceEnum.MAINTENANCE && <MaintenanceView />}
      <CalendarPanel
        todayDate={moment().toDate()}
        recruiters={recruiters}
        administrationCalendar
        handleCheckedRecruiters={(rec) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          setSelectedRecruiters([...rec]);
        }}
        checkedRecruiters={selectedRecruiters}
        positions={filteredPositions(activeTab)}
        handleCheckedPositions={handleCheckedPositions}
        checkedPositions={selectedPositions}
        activeTab={activeTab}
        setActiveTab={handleActiveTab}
        setActiveDateRange={setActiveDateRange}
      />
    </React.Fragment>
  );
};
