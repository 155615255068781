import { Box, Divider, HStack, Stack } from '@chakra-ui/core';
import React from 'react';
import { formBuilderType, SchemaBuilderProps } from './formBuilderSchema';
import InputPreview from './InputPreview';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';
import InputMinMaxSelector from './InputMinMaxSelector';
import ButtonPreview from './ButtonPreview';
import { useTranslation } from 'react-i18next';

export default function NumberSchemaBuilder({ ...props }: SchemaBuilderProps) {
  const { t } = useTranslation('hrFormTemplate');

  const showShowMe = [
    formBuilderType.routingNumber,
    formBuilderType.bankAccountNumber,
    formBuilderType.branchTransitNumber,
    formBuilderType.financialInstitutionNumber,
  ].some((x) => x === props?.defaultValue?.inputType);
  return (
    <Stack>
      <HStack justify="space-between">
        <LabelSchemaBuilder {...props} />
        <HStack flexShrink={0}>
          <Divider orientation="vertical" />
          {props?.labelRightRenderer}
        </HStack>
      </HStack>
      <FormElementTextBuilder {...props} placeholder={t('formBuilder.editorPlaceholder.number')} />
      <InputPreview
        value={props?.defaultValue?.previewPlaceholders?.placeholder ?? '1234567890'}
        rightContent={props?.defaultValue?.previewPlaceholders?.disableMinMax ? null : <InputMinMaxSelector {...props} />}
      />
      {showShowMe && (
        <Box>
          <ButtonPreview label={t('formBuilder.showMe')} disabled />
        </Box>
      )}
    </Stack>
  );
}
