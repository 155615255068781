export default {
  searchTitle: 'Candidate Lookup',
  name: 'Name',
  email: 'Email Address',
  selectStatus: 'Select Status',
  enterHireId: 'Hire ID',
  requisitionId: 'Requisition',
  enterRequisition: 'Enter Requisition',
  enterLocationId: 'Enter location id or name',
  statusSelected: `{{statusCount}} status(es) selected`,
  tableHeader: {
    candidateName: 'Candidate Name',
    location: 'Location',
    phone: 'Phone',
    status: 'Status',
    lastUpdate: 'Last Update',
    hireId: 'Hire ID',
  },
  showingPageResults: 'Showing {{currentItems}} of {{totalItems}} candidates',
  exploreProfiles: 'Explore Candidate Profiles',
  enterDetailsText: `It looks like you haven't searched for anything yet. Enter your criteria above to find candidates that fit what you're looking for!`,
  noMatchFound: 'No Candidates Found',
  noMatchFoundText: `There are no candidates that match your search criteria. Try adjusting your filters or keywords to get a better result.`,
  candidateStatus: {
    DISMISSED: 'Dismissed',
    NOT_LOOKING_FOR_WORK: 'Not Looking for Work',
    HIRED: 'Hired',
    HIRE_IN_REVIEW: 'Hire in Review',
    HIRED_ON_ANOTHER_POSITION: 'Hired on Another Position',
    UNHIRED: 'Unhired',
    OFFER_MADE: 'Offer Made',
    OFFER_REJECTED: 'Offer Rejected',
    OFFER_REVOKED: 'Offer Revoked',
    CONTACT_REQUESTED: 'Contact Requested',
    CONTACT_REQUEST_ACCEPTED: 'Contact Request Accepted',
    CONTACT_REQUEST_DECLINED: 'Contact Request Declined',
    APPOINTMENT_REQUESTED: 'Interview Requested',
    APPOINTMENT_ACCEPTED: 'Interview Accepted',
    APPOINTMENT_DECLINED: 'Interview Declined',
    APPOINTMENT_CANCELLED: 'Interview Canceled',
    APPOINTMENT_RESCHEDULING_NEEDED: 'Interview Rescheduling Needed',
    BOOKMARKED: 'Bookmarked',
    LIKED: 'Applied',
    BLACKLISTED: 'Blacklisted',
    UNKNOWN: 'Unknown',
    INTEREST_REVOKED: 'Revoked Application',
    APPOINTMENT_REQUEST_MODIFIED: 'Appointment Request Modified',
    HIRE_FORM_REQUESTED: 'Hire Form Requested',
    HIRE_FORM_IN_PROGRESS: 'Hire Form In Progress',
    HIRE_FORM_HELP_REQUESTED: 'Hire Form Help Requested',
  },
  copiedHireId: 'Copied Hire Form ID.',
};
