export default {
  jobSearchPlaceholder: 'Recherchez par titre de position',
  createDate: 'Date de création',
  modifyDate: 'Date de modification',
  allPositions: 'Tous les postes',
  openPositions: 'Postes ouverts',
  openedPositions: 'Postes ouverts',
  closedPositions: 'Postes fermés',
  refresh: 'Rafraîchir',
  archivedPositions: 'Postes archivés',
  followedPositions: 'Postes suivis',
  archivedPosition: 'Positions archivées',
  position: 'poste',
  positions: 'postes',
  counting: 'Counting',
  filterByRequisitions: 'Cacher les postes sans réquisitions ouvertes',
  time: 'Type de poste',
  partTime: 'Temps partiel',
  fullTime: 'Temps plein',
  applicants: 'Candidats actifs',
  noOfHires: "Nombre d'embauches",
  open: 'Ouvrir',
  close: 'Fermer',
  archive: 'Archiver',
  unArchive: 'Désarchivé',
  follow: 'Suivre',
  unFollow: 'Arrêter de suivre',
  copy: 'Copier',
  edit: 'Modifier',
  recruiterFollowing: 'Recruteurs suivant ce poste',
  share: 'Partager',
  copiedMessage: 'Vous avez copié le lien pour ce poste.',
  noJobs: "Désolé, nous n'avons trouvé aucun résultat correspondant à cette recherche",
  loadMore: 'Montrer plus',
  searchPlaceholder: 'Rechercher par nom de poste',
  unfollowModal: {
    header: 'Dernier recruteur',
    body: 'Vous êtes la dernière personne à suivre ce poste. Si vous arrêtez de le suivre, il sera fermé',
  },
  unfollowWarningModal: {
    header: 'Êtes-vous certain de vouloir arrêter de suivre ce poste?',
    body:
      'Assurez-vous de ne pas avoir des entrevues de cédulées pour ce poste avant d’arrêter de le suivre. Il sera beaucoup plus difficile de surveiller ces candidats lorsque vous ne suivez plus le poste.',
  },
  positionImpactedModal: {
    emptyAutomatedPositions: 'Aucun poste affecté.',
  },
  openPositionModal: {
    title: 'Ouvrir ce poste',
    subtitle: 'Ce poste est configuré pour être automatisé avec les paramètres suivants:',
    disabled: {
      title: 'Trouvez des candidats',
      description: 'Je vais vous trouver les meilleurs candidats',
    },
    screening: {
      title: 'Présélectionnez les candidats',
      description: {
        zero: 'Je vais présélectionner {{count}} candidat',
        one: 'Je vais présélectionner {{count}} candidat',
        other: 'Je vais présélectionner {{count}} candidats',
      },
    },
    scheduling: {
      title: 'Occupez-vous de tout',
      description: 'Je vais planifier des entrevues avec les candidats',
    },
    noAutomation: {
      description: 'Êtes-vous sûr de vouloir ouvrir ce poste?',
    },
    openPositionButton: 'Confirmer',
    cancelModalButton: 'Annuler',
    requisition: {
      title: 'Réquisition',
      subtitle: 'Ce poste est contrôlé par le système de gestion des réquisitions. Voulez-vous toujours ouvrir ce poste?',
    },
  },
  closePositionModal: {
    title: 'Fermer ce poste',
    subtitle: {
      zero: 'Êtes-vous sûr de vouloir fermer ce poste?',
      one: '{{count}} autre membre de votre équipe suit ce poste.\nÊtes-vous sûr de vouloir fermer ce poste?',
      other: '{{count}} autres membres de votre équipe suivent ce poste.\nÊtes-vous sûr de vouloir fermer ce poste?',
    },
    confirmationText:
      'La fermeture de ce poste annulera toutes les futures entrevues ainsi que les demandes en attente des candidats.',
    closePositionButton: {
      title: 'Confirmer',
    },
    requisition: {
      title: 'Réquisition',
      subtitle:
        'Ce poste sera automatiquement ouvert par le système de gestion des réquisitions. Voulez-vous toujours ouvrir ce poste?',
    },
  },
  updateStatusConfirmation: {
    okayText: 'Je comprends',
  },
  archivePositionModal: {
    title: 'Archivé un poste',
    bodyMessage: 'Êtes-vous certain de vouloir archiver ce poste?',
    confirmText: 'Oui',
    cancelText: 'Annuler',
  },
  unarchivePositionModal: {
    title: 'Désarchivé un poste',
    bodyMessage: 'Êtes-vous certain de vouloir désarchiver ce poste?',
    confirmText: 'Oui',
    cancelText: 'Annuler',
  },
  info: {
    archived: 'Le poste que vous avez archivé est ici.',
    open: 'Le poste que vous avez ouvert est ici.',
    unArchive: 'Le poste que vous avez désarchivé est ici.',
    close: 'Le poste que vous avez fermé est ici.',
    copy: 'La position que vous venez de copier est ici',
  },
  createNewPosition: 'Créer un nouveau poste',
  editPosition: 'Modifier le poste',
  viewPositionList: 'Voir la liste des postes',
  newJobFor: 'Situé chez',
  updateJobPosition: 'Situé chez',
  automationTypes: {
    selectAutomationLevel: `Choisir le niveau d'automatisation`,
    none: `Pas d'automatisation`,
    screen: 'Présélectionner',
    screenAndScheduleInterviews: `Présélectionner & inviter à l'entrevue`,
    screenAndSendOffers: 'Présélectionner & envoyer les offres',
  },
  screenAndSendOffersModal: {
    warningText: `Pour que le recruteur virtuel fonctionne correctement, vous devez vous assurez que toutes les configurations nécessaires sont réglées pour ce poste. Si le poste n'a pas été configuré encore, consultez un administrateur de votre équipe pour pouvoir configurer le poste correctement.`,
    submitText: `Régler le niveau d'automatisation`,
    cancelText: 'Annuler',
  },
  screeningInputModal: {
    submitText: 'Confirmer',
    cancelText: 'Annuler',
  },
  positionAutomationSelect: {
    errorInUpdating: 'Une erreur est survenue pendant la mise à jour du poste.',
    errorUpdatingDescription: `Le niveau d'automatisation ne pouvait pas être changé. Essayez de nouveau!`,
    successInUpdating: `Le niveau d'automatisation {{automationLevel}} a été configuré pour ce poste avec succès.`,
  },
  automation: {
    title: 'Niveau d’automatisation',
    findMeCandidate: {
      toolTipMessage:
        'AppyHere va trouver les candidats idéales pour vous. Tout ce que vous devez faire est choisir ceux que vous voulez passer en entrevue!',
      label: 'AppyHere va trouver les candidats idéales pour vous',
    },
    screening: {
      toolTipMessage:
        'AppyHere va présélectionner {{count}} candidats pour vous. Tout ce que vous devez faire est de choisir ceux que vous voulez passer en entrevue!',
      label: 'AppyHere va présélectionner {{count}} candidats.',
    },
    scheduling: {
      toolTipMessage:
        "AppyHere va présélectionner et envoyer des demandes d'entrevues aux candidats pour vous. Tout ce que vous devez faire est de régler vos disponibilités et laisser les entrevues se planifier eux-mêmes!",
      label: "AppyHere va présélectionner et envoyer des demandes d'entrevues aux candidats",
    },
    schedulingVrEnabled: {
      toolTipMessage: "AppyHere le fera tout pour vous, même les offres d'emploi!",
      label: "AppyHere va présélectionner les candidats et leur envoyer des offres d'emploi.",
    },
  },
  days: {
    mon: 'Lundi',
    tue: 'Mardi',
    wed: 'Mercredi',
    thu: 'Jeudi',
    fri: 'Vendredi',
    sat: 'Samedi',
    sun: 'Dimanche',
  },
  availabilityHint:
    'Déterminez les jours et heures pour lesquels les candidats devraient être disponibles. Sélectionnez les jours, et ensuite utilisez le curseur pour régler les quarts de travail.',

  userRestrictedByAuthorizationMessageFront: 'Pour faire cette action, vous devez contacter',
  contactEmailForAccountAuthorisation: 'account-validation@appyhere.com',
  userRestrictedByAuthorizationMessageBack: 'pour vérifier votre compte.',
  contactToEnableAuthorization: 'Pour créer un poste, vous devez contacter {{configurableEmail}} pour vérifier votre compte.',
  startRecruitingImmediatelyModal: {
    heading: 'Voulez-vous ouvrir ce poste immédiatement?',
    bodyText: "L'ouverture du poste immédiatement va lancer le recrutement automatisé d'AppyHere.",
    yes: 'Oui',
    no: 'Non',
  },
  complianceModal: {
    heading: 'Rappel de conformité',
    bodyText:
      "En tant que recruteur, vous êtes responsable de vous assurer que ce poste est conforme à toutes les lois et réglementations du travail applicables, y compris, mais sans s'y limiter, le salaire minimum, les heures de travail, l'égalité des chances en matière d'emploi et d'autres exigences. En procédant, vous confirmez avoir examiné les normes de conformité pertinentes pour ce rôle.",
    dontRemindText: 'Ne pas me demander à nouveau',
    proceed: 'Je comprends',
    errorMessage:
      'Afin de poursuivre, veuillez confirmer que vous avez lu le rappel de conformité en cliquant sur "Je comprends".',
  },
  copyPositionLinkModal: {
    sharePositionLink: 'Créer un lien de poste',
    customizeLink: 'Créer un lien pour le poste avec des paramètres supplémentaires.',
    referralLink: 'Lien du poste',
    autoGenerateOnClick: 'Votre lien sera généré automatiquement une fois que vous aurez cliqué sur «Créer et copier».',
    trackingInfo: 'Paramètres supplémentaires',
    referralExpiry: 'Référence',
    setReferralLink: 'Ce lien est un lien de référence',
    referrer: 'Référence',
    source: 'Source',
    campaign: 'Campagne',
    medium: 'Médium',
    referrerMaxLength: 'La référence ne doit pas excéder 50 caractères.',
    sourceMaxLength: 'La source ne doit pas excéder 100 caractères.',
    campaignMaxLength: 'La campagne ne doit pas excéder 100 caractères.',
    mediumMaxLength: 'Le médium ne doit pas excéder 100 caractères.',
    createAndCopy: 'Créer et copier',
    enterReferrer: 'Entrer la référence',
    enterCampaign: 'Entrer la campagne',
    enterSource: 'Entrer la source',
    enterMedium: 'Entrer le médium',
  },
};
