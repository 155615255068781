export type Notification = {
  id: string;
  groupEventDate?: Date;
  groupEventId?: string;
  groupEventParticipantId?: string;
  additionalContextData?: AdditionalContextData;
  businessName?: string;
  candidate?: string;
  message: string;
  new: boolean;
  notificationType: string;
  position?: string;
  positionOpen: boolean;
  positionTitle?: string;
  sentAt: Date;
  title: string;
  user: string;
  accountId: string;
};

export type AdditionalContextData = {
  invitationStatus?: NotificationInvitationAction;
  businessName?: string;
  candidateName?: string;
  recruiterAvailabilityStatusDetails?: LowAvailabilityStatusRecruiter[];
  screeningOptions?: {
    [key: string]: boolean | undefined;
  };
};

export enum NotificationInvitationAction {
  ACCEPT = 'ACCEPTED',
  DECLINE = 'DECLINED',
}

export type LowAvailabilityStatusRecruiter = {
  recruiterUserId: string;
  recruiterEmail: string;
  recruiterName: string;
  excessAvailabilityPercent: number;
  candidatesWaitingForAvailabilityCount: number;
};
