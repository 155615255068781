/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState } from 'react';
import { Box, Image, Flex, BoxProps, Text } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { RiLock2Fill } from 'react-icons/ri';
import PixelatedImage from '../../../../../assets/img/pixelated-image.png';

export type MultipleImagePreviewProps = BoxProps & {
  imageURLs: string[];
  showImage: boolean;
};
const MultipleImagePreview = ({ imageURLs, showImage }: MultipleImagePreviewProps): JSX.Element => {
  const [selectedImage, setSelectedImage] = useState<string>(imageURLs[0]);
  const { t } = useTranslation('hireDetail');
  return (
    <Box mb="12px">
      <Box position="relative">
        <Image
          src={showImage ? selectedImage : PixelatedImage}
          alt="Work permit photo"
          width="auto"
          height={showImage ? '400px' : 'auto'}
          borderRadius="6px"
          objectFit="contain"
          m="auto"
        />
        {!showImage && (
          <Flex
            border="1px solid"
            borderColor="gray.50"
            p={2}
            position="absolute"
            top={0}
            left={0}
            alignItems="center"
            justifyContent="center"
            h="100%"
            w="100%"
          >
            <Text d="flex" alignItems="center">
              <RiLock2Fill style={{ marginRight: '5px' }} />
              {t('noPermissionToViewImage')}
            </Text>
          </Flex>
        )}
      </Box>
      {showImage && imageURLs.length !== 1 && (
        <Flex mt="12px" overflow="auto">
          {imageURLs.map((item) => (
            <Image
              key={item}
              src={item}
              cursor="pointer"
              alt="Work permit photo"
              width="120px"
              height="120px"
              objectFit="cover"
              borderRadius="6px"
              mr="8px"
              data-testid="selectImage"
              border={selectedImage === item ? '2px solid #1F3CBA' : 'unset'}
              onClick={() => setSelectedImage(item)}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default MultipleImagePreview;
