export default {
  createBusiness: {
    businessCreation: 'Location Creation',
    smbBusinessCreation: 'Location Creation',
    businessCreationDetail: 'Search for your business location by name. Location closer to you will appear first.',
    smbBusinessCreationDetail: 'Define a precise location for where your positions will be located.',
    businessName: 'Location Name',
    businessId: 'Location Id',
    address: 'Address',
    city: 'City',
    state: 'State / Province / Region',
    zip: 'ZIP / Postal Code',
    country: 'Country',
    canada: 'Canada',
    usa: 'USA',
    url: 'URL',
    phone: 'Phone Number',
    create: 'Create',
    businessNameRequired: 'Business Location name is required',
    cityNameRequired: 'City name is required',
    addressRequired: 'Business Address is required',
    countryRequired: 'Country is required',
    zipRequired: 'Zip or Postal code is required',
    webSiteRequired: 'Website is required',
    websiteInvalid: 'The website must have a valid URL',
    stateRequired: 'State or Province or Region is required',
    phoneRequired: 'Phone Number is required',
    phoneMin: 'Phone Number must be valid',
    latitudeRequired: 'Latitude is required',
    longitudeRequired: 'Longitude is required',
    placeIdRequired: 'Place Id  is required',
    positionRequired: 'Position  is required',
    viewBusinessList: 'View Location List',
    update: 'Update',
    successMsg: 'Business location is created successfully',
    successMsgBusinessUpdate: 'Business location updated successfully',
    businessAlreadyExists: 'There is already a business set with this location in AppyHere',
    group: 'Group',
    smbBusinessOnBoardingModal: {
      businessTitle: 'What’s Next?',
      businessHeading: 'Setting Up Your Location',
      businessDesc:
        'In order to help find the candidates closest to your location, we need some specific details, such as its specific address. This would be the address where your hired candidates would be working.',
      positionTitle: 'What’s Next?',
      positionHeading: 'Setting Up Your Position',
      positionDesc:
        'Let’s create the position your candidates will be applying for. Make sure to keep details as accurate as possible to make sure to attract the ideal candidate pool.',
      of: 'of',
      skip: 'Skip',
      next: 'Next',
    },
  },
  businessList: {
    myLocations: `My Locations`,
    allLocations: `All Locations`,
    searchBusiness: 'Search by location name or location ID',
    business: 'Locations',
    create: 'Create',
    listOfBusiness: 'List of Locations',
    loadMore: ' Load More',
    job: 'Position',
    jobs: 'Positions',
    openPositions: 'Open Position',
    noBusinessCreated: 'First add a business location to create jobs and see candidates',
  },
  topCandidate: {
    appliedFilterLabels: {
      salary: 'Salary',
      salaryRange: 'Salary Range',
      otherFilterParams: 'Other Filters',
      experienceRange: 'Experience',
      lastActiveDays: 'Last Activity',
    },
    filters: 'Filters',
    appliedFilters: 'Applied Filters',
    resetFilters: 'Reset Filters',
    salary: 'Salary',
    withInRange: 'Within Salary Range',
    aboveRange: 'Above Salary Range',
    salaryRange: 'Salary Range (Hourly Rate)',
    top: 'Top',
    bookmarked: 'Bookmarked',
    active: 'Active',
    searchCandidates: 'Search Candidates',
    minExperience: 'Minimum Experience',
    years: 'Years',
    lastActive: 'Last Active',
    reset: 'Reset',
    lessThanAWeek: 'Less than a week',
    oneMonth: ' 1 month',
    sixMonths: '6 months',
    lessThanSixMonth: 'Less than 6 months',
    oneYear: '1 year',
    sixYears: '6 years',
    flexTransport: 'Flexible Transportation',
    seekerIsStudent: 'Is a student',
    emptyAvailabilities: 'Candidate has not set their work availability',
    otherApplication: 'Other Applications',

    contactCandidate: `Send Contact Request?`,
    contactNote: `Sending a contact request will show the candidate your interest in their profile. They will be able to see the position and apply. You will only be able to see their full profile if they prequalify for the position.`,
    moreThan10years: 'More than 10 years of direct experience',
    experience: `{{years}} years of direct experience`,
    addNotes: 'We think you’d be a great fit for this position.',
    cancel: 'Cancel',
    sendContactRequest: 'Send Contact Request',
    sendCustomMessage: 'Send Custom Contact Request',

    hasApplied: 'Applied',
    accepted: 'Accepted',
    requested: 'Requested',
    dismissCandidate: 'Dismiss Candidate',
    communicationCandidate: 'Message This Candidate',

    engageCandidateModal: {
      header: 'You are using tokens for this account',
      bodyText: '{{name}} seems to be a good candidate?',
      useToken: 'Use 1 token to Call & Meet',
      engage: 'Engage',
    },
    engageBtnLabel: 'Engage Candidate',
    sendAgainBtnLabel: 'Send Again',
    engageErrorText: 'You are not authorized to perform this action.',
    sendAgainTooltipText: 'Your request has been sent. ⏱ Waiting for candidate to respond...',
    engageDisableMsg: 'Seeker has not accepted your contact request yet!',
    noBookmarkedCandidates: 'You have no bookmarked candidates.',
    noTopCandidates: 'There are no top candidates for this position.',
    noActiveCandidates: 'There are no active candidates for this position.',
    moreThan10Years: 'More than 10 years of direct experience',
    directExperience: 'years of direct experience',
    seekerProfile: {
      days: 'Days',
      weeks: 'Weeks',
      immediately: 'Immediately',
    },
    lowTokenWarningMessage:
      'Sending a contact request uses one of your tokens. Tokens are required to engage with candidates, so make sure to select candidates thoughtfully to maximize your opportunities.',
    greetingHello: 'Hey',
  },
};
