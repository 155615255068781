import { Select } from '@chakra-ui/core';
import { useHireFormContext } from '../HireForm.context';
import { FieldSchema } from './formBuilderSchema';
import React from 'react';
import theme from '../../../../styles/customTheme';
import isEqual from 'lodash/isEqual';

interface ElementSelectProps {
  value: string;
  options: string[];
  onChange: (field: string) => void;
  onFocus?: (field: string) => void;
}
const ElementSelect = React.memo(function ({ value, options = [], onChange, onFocus }: ElementSelectProps) {
  if (options.length === 0) return null;

  const formatLabel = (field: string): string => {
    const words = field?.match(/[A-Z]?[a-z]+|[0-9]+/g);
    return (
      words
        ?.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()))
        .join(' ') ?? ''
    );
  };

  return (
    <Select
      variant="filled"
      size="xs"
      fontSize="xs"
      width="fit-content"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onFocus={(e) => {
        const value = e.target.value;
        onFocus?.(value);
      }}
      data-testid="element-field-select"
      sx={{
        '&': {
          width: 'auto',
          padding: '0.30rem .5rem',
          paddingRight: '1.8rem',
          borderRadius: theme.radii.md,
        },
      }}
    >
      {options?.map((option) => (
        <option key={option} value={option}>
          {formatLabel(option)}
        </option>
      ))}
    </Select>
  );
});

interface ElementSelectFieldProps {
  defaultValue: FieldSchema;
  options: string[];
  onChange: (field: string) => void;
}

const ElementSelectField = React.memo(
  ({ options, defaultValue, onChange }: ElementSelectFieldProps) => {
    const { formSchema } = useHireFormContext();
    const [currentOptions, setCurrentOptions] = React.useState<string[]>(options);
    const value = defaultValue.field;

    React.useEffect(() => {
      setCurrentOptions(options);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    function handleOnChange(field: string) {
      onChange(field);
    }

    React.useEffect(() => {
      const baseFieldName = defaultValue.field?.split('_')[0];

      const newOptions = [...options];
      const optionIndex = newOptions.indexOf(baseFieldName);

      newOptions.splice(optionIndex, 1, defaultValue.field);
      const filteredOptions = getNewOptions(newOptions, defaultValue.field);

      if (!filteredOptions.includes(defaultValue.field)) {
        handleOnChange(filteredOptions[0]);
      }
      setCurrentOptions(filteredOptions);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (currentOptions.length < 2) return null;

    function getNewOptions(options: string[], currentField: string) {
      return filterOptions(formSchema?.flatMap((section) => section.fields as FieldSchema[]) ?? [], options, currentField);
    }

    function filterOptions(fields: FieldSchema[], options: string[], currentField: string): string[] {
      const usedFields = new Set<string>();

      // Helper function to recursively collect all `field` values
      const collectFields = (fieldList: FieldSchema[]) => {
        fieldList.forEach((field) => {
          if (field.field !== currentField) usedFields.add(field.field);
          if (field.followUpQuestionList) collectFields(field.followUpQuestionList);
        });
      };

      // Collect all `field` values from the JSON
      collectFields(fields);

      // Filter the options list
      return options.filter(
        (option) => !usedFields.has(option) || option.toLowerCase() === defaultValue.inputType?.toLowerCase(),
      );
    }

    return (
      <ElementSelect
        value={value}
        onChange={handleOnChange}
        options={currentOptions}
        onFocus={(field) => {
          const newOptions = getNewOptions(options, field);
          if (!newOptions.includes(field)) {
            handleOnChange(newOptions[0]);
          }
          setCurrentOptions(newOptions);
        }}
      />
    );
  },
  (p, n) => {
    return isEqual(p.defaultValue, n.defaultValue) && p.onChange === n.onChange && isEqual(p.options, n.options);
  },
);

export default ElementSelectField;
