/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormLabel, Heading, Select, Text, Flex, Image, Center, useToast } from '@chakra-ui/core';
import { ReactMic } from 'react-mic';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { MdAutorenew } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import colors from '../../../../styles/colors';
import { Band, BandHeaders, LanguageEvaluationType } from '../LanguageEvaluationTypes';
import AudioPlayer from '../../../common/AudioPlayer/AudioPlayer';
import { useStoreActions } from '../../../../models/hooks';
import { EvaluationContainerStyles } from './EvaluationScreen.styles';

type EvaluationScreenProps = {
  setIsRecordingOpen: (isRecordingOpen: boolean) => void;
  templateData: LanguageEvaluationType;
  language: string;
  bands: {
    [key: string]: Band;
  };
};

type AudioPayloadType = {
  mimeType: string;
  sampleRate: number;
  channels: number;
  bitsPerSample: number;
  language: string;
  phrase: string | null;
  recordingFile: string;
  bands?: {
    [key: string]: Band;
  };
};

export const EvaluationScreen = ({ setIsRecordingOpen, language, templateData, bands }: EvaluationScreenProps): JSX.Element => {
  const toast = useToast();
  const { t } = useTranslation('administration');
  const [isRecordingAvailable, setIsRecordingAvailable] = useState<boolean>(false);
  const [permissionsGranted, setPermissionsGranted] = useState<boolean>(false);
  const [allowAccess, setAllowAccess] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [base64String, setBase64String] = useState<string>('');
  const [time, setTime] = useState<number>(0);
  const [recordEvaluation, setRecordEvaluation] = useState<Band | null>(null);
  const [isAudioEvaluationLoading, setIsAudioEvaluationLoading] = useState<boolean>(false);
  const [bandStatus, setBandStatus] = useState<string | undefined>(undefined);
  const [selectedPhraseId, setSelectedPhraseId] = useState<string | null>(null);
  const [selectedPhraseDesc, setSelectedPhraseDesc] = useState<string | null>(null);

  const { evaluateLanguageEvaluation } = useStoreActions((actions) => actions.languageEvaluationTemplate);

  const onCloseRecording = () => {
    setIsRecordingOpen(false);
  };

  const showRerecordButton = !isNil(recordEvaluation);

  useEffect(() => {
    if (templateData?.languageEvaluationTemplates?.length === 0) {
      setSelectedPhraseId(null);
      setSelectedPhraseDesc(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData?.languageEvaluationTemplates?.length]);

  // function that handles microphone permission
  const askMicrophonePermission = async () => {
    try {
      if (navigator.permissions) {
        const status = await navigator.permissions.query({ name: 'microphone' });

        if (status.state === 'granted') {
          setPermissionsGranted(true);
          return true;
        }
        if (status.state === 'prompt') {
          setAllowAccess(true);
        } else if (status.state === 'denied') {
          setPermissionsGranted(false);
          setAllowAccess(false);
          return false;
        }
      }

      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true;
    } catch (error) {
      return false;
    }
  };

  const handlePermissionRequest = async () => {
    const hasPermission = await askMicrophonePermission();
    setPermissionsGranted(hasPermission);
    if (hasPermission) {
      setIsRecording(true);
    }
  };

  const resetAudioAndEvaluations = () => {
    setRecordEvaluation(null);
    setIsAudioEvaluationLoading(false);
    setAudioUrl(null);
    setBase64String('');
    setIsRecordingAvailable(false);
  };

  const handlePhraseChange = (phraseId: string) => {
    resetAudioAndEvaluations();
    setSelectedPhraseId(phraseId);

    const selectedPhrase = templateData?.languageEvaluationTemplates?.find((phrase) => phrase.phraseId === phraseId);
    setSelectedPhraseDesc(selectedPhrase?.phrase ?? null);
  };

  const validBands = () => {
    return Object.values(bands).every((band) =>
      Object.values(band).every((value) => value > 0 && value !== null && value !== undefined),
    );
  };

  const handleAudioSubmit = async () => {
    setIsAudioEvaluationLoading(true);

    const filteredBands = Object.keys(bands).reduce((result, bandName) => {
      const filteredFields = Object.entries(bands[bandName])
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, value]) => value > 0)
        .reduce((obj, [key, value]) => {
          obj[key as keyof Band] = key === 'confidence' ? Math.min(1, Math.max(0, value / 100)) : value;
          return obj;
        }, {} as Band);

      if (Object.keys(filteredFields).length > 0) {
        result[bandName] = filteredFields;
      }
      return result;
    }, {} as { [key: string]: Band });

    const audioPayload: AudioPayloadType = {
      mimeType: 'audio/wav',
      sampleRate: 16000,
      channels: 1,
      bitsPerSample: 16,
      language,
      phrase: selectedPhraseDesc,
      recordingFile: `${base64String}`,
    };

    // Add bands to payload only if they are valid
    if (validBands()) {
      audioPayload.bands = filteredBands;
    }
    try {
      const audioResponse = await evaluateLanguageEvaluation({ audioData: audioPayload });
      setRecordEvaluation(audioResponse?.comparisonResult);
      setBandStatus(audioResponse?.status);

      toast({
        title: t('languageEvaluation.recording.audioEvaluationTitle'),
        description: t('languageEvaluation.recording.audioEvaluationSuccess'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error evaluating audio:', error);
    } finally {
      setIsAudioEvaluationLoading(false);
    }
  };

  // Write WAV Header
  // istanbul ignore next
  const writeWavHeader = (view: DataView, buffer: AudioBuffer, numChannels: number) => {
    const { sampleRate } = buffer; // Sample rate of the audio
    const blockAlign = numChannels * 2; // Block align (numChannels * bytes per sample)
    const byteRate = sampleRate * blockAlign; // Byte rate (sampleRate * blockAlign)

    // RIFF chunk descriptor
    view.setUint32(0, 0x52494646, false); // "RIFF" in ASCII
    view.setUint32(4, 36 + buffer.length * blockAlign, true); // File size - 8 bytes
    view.setUint32(8, 0x57415645, false); // "WAVE" in ASCII

    // fmt sub-chunk
    view.setUint32(12, 0x666d7420, false); // "fmt " in ASCII
    view.setUint32(16, 16, true); // Subchunk1Size (16 for PCM)
    view.setUint16(20, 1, true); // AudioFormat (1 for PCM)
    view.setUint16(22, numChannels, true); // NumChannels
    view.setUint32(24, sampleRate, true); // SampleRate
    view.setUint32(28, byteRate, true); // ByteRate
    view.setUint16(32, blockAlign, true); // BlockAlign
    view.setUint16(34, 16, true); // BitsPerSample (16-bit audio)

    // data sub-chunk
    view.setUint32(36, 0x64617461, false); // "data" in ASCII
    view.setUint32(40, buffer.length * blockAlign, true); // Subchunk2Size (data size)
  };

  // istanbul ignore next
  const interleaveAudio = (buffer: AudioBuffer): Int16Array => {
    const channels = [];
    for (let i = 0; i < buffer.numberOfChannels; i++) {
      channels.push(buffer.getChannelData(i));
    }

    const length = buffer.length * buffer.numberOfChannels;
    const result = new Float32Array(length);

    for (let i = 0; i < buffer.length; i++) {
      for (let j = 0; j < buffer.numberOfChannels; j++) {
        result[i * buffer.numberOfChannels + j] = channels[j][i];
      }
    }

    const int16Array = new Int16Array(length);
    for (let i = 0; i < length; i++) {
      int16Array[i] = Math.max(-32768, Math.min(32767, result[i] * 32767));
    }

    return int16Array;
  };

  // Convert AudioBuffer to WAV Blob
  // istanbul ignore next
  const audioBufferToWav = (buffer: AudioBuffer): Blob => {
    const numChannels = buffer.numberOfChannels;
    const length = buffer.length * numChannels * 2 + 44;
    const bufferArray = new ArrayBuffer(length);
    const view = new DataView(bufferArray);

    writeWavHeader(view, buffer, numChannels);

    const interleaved = interleaveAudio(buffer);
    const output = new Int16Array(bufferArray, 44);
    interleaved.forEach((sample, index) => {
      output[index] = sample;
    });

    return new Blob([bufferArray], { type: 'audio/wav' });
  };

  // Convert WebM Blob to WAV Blob
  const convertWebmToWav = async (webmBlob: Blob): Promise<Blob> => {
    const audioContext = new AudioContext();
    const arrayBuffer = await webmBlob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    return audioBufferToWav(audioBuffer);
  };

  // istanbul ignore next
  const convertBlobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve((reader.result as string).split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleAudioComplete = async (recordedData: { blob: Blob }) => {
    try {
      setIsRecording(false);
      setIsRecordingAvailable(true);

      // Convert WebM to WAV
      const wavBlob = await convertWebmToWav(recordedData.blob);

      // Create URL for playback
      const url = URL.createObjectURL(wavBlob);
      setAudioUrl(url);

      // Convert WAV Blob to Base64
      const base64 = await convertBlobToBase64(wavBlob);
      setBase64String(base64);
    } catch (error) {
      console.error('Error processing recorded audio:', error);
    }
  };

  const handleDeleteAudio = () => {
    setAudioUrl(null);
    setBase64String('');
    setIsRecordingAvailable(false);
  };

  const handleRerecordAudio = () => {
    resetAudioAndEvaluations();
    // this timeout is added because there was issue audio not recording when recording
    setTimeout(() => {
      setIsRecording(true);
    }, 0);
  };

  // istanbul ignore next
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (isRecording) {
      timer = setInterval(() => setTime((prevTime) => prevTime + 1), 1000);
    } else {
      if (timer) clearInterval(timer);
      setTime(0);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isRecording]);

  // istanbul ignore next
  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  // istanbul ignore next
  const getBandTitleWithText = (bandTitle: string | undefined) => {
    switch (bandTitle) {
      case BandHeaders.EXPERT:
        return (
          <>
            <Text fontSize="24px" fontWeight={700} color={colors.green[500]}>
              {t('languageEvaluation.band.expert')}
            </Text>
            <Text fontWeight={400}>{t('languageEvaluation.evaluation.expertText')}</Text>
          </>
        );
      case BandHeaders.COMPETENT:
        return (
          <>
            <Text fontSize="24px" fontWeight={700} color={colors.green[500]}>
              {t('languageEvaluation.band.competent')}
            </Text>
            <Text fontWeight={400}>{t('languageEvaluation.evaluation.competentText')}</Text>
          </>
        );
      case BandHeaders.LIMITED:
        return (
          <>
            <Text fontSize="24px" fontWeight={700} color={colors.green[500]}>
              {t('languageEvaluation.band.limited')}
            </Text>
            <Text fontWeight={400}>{t('languageEvaluation.evaluation.limitedText')}</Text>
          </>
        );
      case BandHeaders.OCCASIONAL:
        return (
          <>
            <Text fontSize="24px" fontWeight={700} color={colors.green[500]}>
              {t('languageEvaluation.band.occasional')}
            </Text>
            <Text fontWeight={400}>{t('languageEvaluation.evaluation.occasionalText')}</Text>
          </>
        );

      default:
        return <Text fontWeight={400}>{t('languageEvaluation.evaluation.defaultText')}</Text>;
    }
  };

  return (
    <Box p={4}>
      <Flex justifyContent="space-between" alignItems="center" pb={2} borderBottom="1px solid #e2e8f0">
        <Heading as="h4" fontSize="lg" lineHeight="1.3">
          {t('languageEvaluation.recording.evaluateRecording')}
        </Heading>
        <Box data-testid="closeBtn" onClick={onCloseRecording} cursor="pointer">
          <IoMdClose size={20} color="black" />
        </Box>
      </Flex>

      <Box pt={4}>
        <FormControl isRequired>
          <FormLabel fontSize="14px" fontWeight={700}>
            {t('languageEvaluation.recording.selectPhrase')}
          </FormLabel>
          <Select
            data-testid="phraseSelect"
            value={selectedPhraseId || ''}
            placeholder={t('languageEvaluation.recording.choosePhrase')}
            onChange={(e) => handlePhraseChange(e.target.value)}
            fontSize="14px"
          >
            {templateData?.languageEvaluationTemplates?.map((phraseItem) => (
              <option key={phraseItem.phraseId} value={phraseItem.phraseId}>
                {phraseItem.phraseId}
              </option>
            ))}
          </Select>
        </FormControl>
        {selectedPhraseDesc && (
          <Box mt={4} data-testid="phraseDescription">
            <Text fontWeight={400} fontSize="14px">
              {selectedPhraseDesc}
            </Text>
          </Box>
        )}
      </Box>

      <Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          borderRadius="16px"
          borderColor="#E5E7EB"
          borderStyle="dotted"
          borderWidth={2}
          mt={7}
          padding="40px"
        >
          {!permissionsGranted ? (
            <>
              <Center>
                <Image src="/sound_sampler.png" alt="No mic file" width="64px" height="64px" />
              </Center>
              <Center mt={3} mb={3}>
                {!allowAccess ? (
                  <Text fontWeight={700} fontSize="14px">
                    {t('languageEvaluation.recording.clickRecordButton')}
                  </Text>
                ) : (
                  <Text fontWeight={700} fontSize="14px" color={colors.red[500]}>
                    {t('languageEvaluation.recording.allowMicrophoneAccess')}
                  </Text>
                )}
              </Center>
            </>
          ) : audioUrl ? (
            !isNil(recordEvaluation) ? (
              <Box fontSize={16} color={colors.gray[450]} fontWeight="700">
                {getBandTitleWithText(bandStatus)}
                <ul style={{ listStylePosition: 'inside', marginBottom: '1rem' }}>
                  <li>
                    {t('languageEvaluation.bandFields.accuracy')}
                    {`: ${recordEvaluation?.accuracy}`}
                  </li>
                  <li>
                    {t('languageEvaluation.bandFields.completeness')}
                    {`: ${recordEvaluation?.completeness}`}
                  </li>
                  <li>
                    {t('languageEvaluation.bandFields.confidence')}
                    {`: ${(recordEvaluation?.confidence * 100).toFixed(2)}`}
                  </li>
                  <li>
                    {t('languageEvaluation.bandFields.fluency')}
                    {`: ${recordEvaluation?.fluency}`}
                  </li>
                  <li>
                    {t('languageEvaluation.bandFields.pronunciation')}
                    {`: ${recordEvaluation?.pronunciation}`}
                  </li>
                </ul>
                <AudioPlayer audioSource={audioUrl} />
              </Box>
            ) : (
              <>
                <AudioPlayer audioSource={audioUrl} />
                <Flex justifyContent="flex-end" alignItems="center" width="100%" mt={3}>
                  <MdAutorenew
                    size={24}
                    data-testid="rerecordBtn"
                    style={{ marginRight: '10px' }}
                    color={colors.blue[500]}
                    onClick={handleRerecordAudio}
                    cursor="pointer"
                  />
                  <RiDeleteBin6Line
                    size={24}
                    color={colors.red[500]}
                    data-testid="deleteAudioBtn"
                    onClick={handleDeleteAudio}
                    cursor="pointer"
                  />
                </Flex>
              </>
            )
          ) : (
            <Center flexDirection="column" gap={4} css={EvaluationContainerStyles}>
              <ReactMic
                data-testid="react-mic-mock"
                record={isRecording}
                visualSetting="sinewave"
                onStop={handleAudioComplete}
                mimeType="audio/webm"
                strokeColor="#000000"
                noiseSuppression
                echoCancellation
                className="sound-wave"
                channelCount={1}
              />
              {isRecording && (
                <Box width="100%" display="flex" justifyContent="center">
                  <Box className="record-controls">
                    <button
                      type="button"
                      className="record-button"
                      data-testid="stopRecordBtn"
                      onClick={() => setIsRecording(false)}
                    >
                      ■
                    </button>

                    <span className="timer">{formatTime(time)}</span>
                  </Box>
                </Box>
              )}
            </Center>
          )}
          {!audioUrl && !isRecording && (
            <Center>
              <Button
                variant="outlined"
                borderRadius="6px"
                data-testid="startBtn"
                borderWidth={1}
                borderColor={colors.blue[500]}
                fontSize="14px"
                fontWeight={700}
                color={colors.blue[500]}
                onClick={handlePermissionRequest}
                disabled={!selectedPhraseId}
              >
                {t('languageEvaluation.recording.startRecording')}
              </Button>
            </Center>
          )}
        </Box>

        {audioUrl && (
          <Flex justifyContent="center" alignItems="center" mt={5}>
            <Button
              isLoading={isAudioEvaluationLoading}
              data-testid="evaluateBtn"
              colorScheme="blue"
              disabled={!isRecordingAvailable || isAudioEvaluationLoading}
              onClick={showRerecordButton ? handleRerecordAudio : handleAudioSubmit}
            >
              {showRerecordButton ? t('languageEvaluation.recording.reRecord') : t('languageEvaluation.recording.evaluate')}
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
};
