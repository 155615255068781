import { Button, HStack } from '@chakra-ui/core';
import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { useTranslation } from 'react-i18next';

export default function ButtonSchemaBuilder({ ...props }: SchemaBuilderProps) {
  const { t } = useTranslation('hrFormTemplate');

  return (
    <HStack align="flex-start">
      <Button variant="outline" size="sm" colorScheme="blue" borderRadius={20}>
        {t('formBuilder.languageReviewModal.confirmButton')}
      </Button>
    </HStack>
  );
}
