import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import FormElementTextBuilder from './FormElementTextBuilder';
import { Box, Divider, HStack, Stack } from '@chakra-ui/core';
import LabelSchemaBuilder from './LabelSchemaBuilder';
import InputPreview from './InputPreview';
import InputMinMaxSelector from './InputMinMaxSelector';
import { useTranslation } from 'react-i18next';

export default function TextFieldSchemaBuilder({ ...props }: SchemaBuilderProps) {
  const { t } = useTranslation('hrFormTemplate');
  const textField = props?.defaultValue?.isInfo ?? false;

  return (
    <Stack>
      <HStack justify="space-between">
        <Box>{!textField && <LabelSchemaBuilder {...props} />}</Box>
        <HStack flexShrink={0}>
          <Divider orientation="vertical" />
          {props?.labelRightRenderer}
        </HStack>
      </HStack>

      <FormElementTextBuilder {...props} />
      {!textField && (
        <InputPreview
          value={t(props?.defaultValue?.previewPlaceholders?.placeholder ?? 'formBuilder.inputField')}
          rightContent={<InputMinMaxSelector {...props} />}
        />
      )}
    </Stack>
  );
}
