import {
  ButtonGroup,
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  useDisclosure,
  Text,
  Select,
} from '@chakra-ui/core';
import React from 'react';
import { HrFromCreateTemplateResponse, Language } from '../formElements/formBuilderSchema';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useHireFormContext } from '../HireForm.context';
import { v4 as uuidv4 } from 'uuid';
import theme from '../../../../styles/customTheme';
import SectionSortableDrawer from '../formElements/sectionSortableDrawer/SectionSortableDrawer';
import { PATH_HIRE_FORM_COPY, PATH_HIRE_FORM_EDIT } from '../../../../routes/constants';
import { useTranslation } from 'react-i18next';
import SaveTemplate from './SaveTemplate';

interface TemplateActionsProps {
  templateName: string;
  needFinalSignature: boolean;
}

const TemplateActions = React.memo((props: TemplateActionsProps) => {
  return (
    <ButtonGroup variant="outline" size="sm" data-testid="template-actions">
      <SectionSortableDrawer />
      <SelectLanguage />
      <ClearAllTemplate {...props} />
      <SaveTemplate {...props} />
    </ButtonGroup>
  );
});

const SelectLanguage = () => {
  const { setLanguage: onLanguageChange, language, languages } = useHireFormContext();
  return (
    <Select
      onChange={(e) => onLanguageChange(e.target.value as Language)}
      value={language}
      variant="outline"
      size="sm"
      colorScheme="blue[100]"
      borderRadius={theme.radii.md}
      maxWidth="fit-content"
      textTransform="capitalize"
    >
      {languages.map((lang) => (
        <option key={lang} style={{ textTransform: 'capitalize' }}>
          {lang}
        </option>
      ))}
    </Select>
  );
};

const ClearAllTemplate = React.memo(
  ({ templateName, needFinalSignature }: Pick<TemplateActionsProps, 'templateName' | 'needFinalSignature'>) => {
    const { t } = useTranslation('hrFormTemplate');
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { isUpdating, isLoading } = useStoreState((state) => state.hrFormTemplate);
    const { setErrors, createTemplate, updateTemplate } = useStoreActions((state) => state.hrFormTemplate);
    const { formSchema, setFormSchema } = useHireFormContext();

    const { currentUserProfile } = useCurrentUserProfile();

    const templateId = useParams<{ templateId?: string }>()?.templateId ?? undefined;
    const isDuplicate = useRouteMatch(PATH_HIRE_FORM_COPY);
    const accountId = currentUserProfile?.account ?? '';
    const history = useHistory();

    async function handleClearAll() {
      let response: HrFromCreateTemplateResponse | undefined = undefined;
      if (templateId && !isDuplicate) {
        await updateTemplate({
          account: accountId,
          id: templateId,
          payload: { name: templateName?.trim(), questions: [], needFinalSignature },
        });
      } else if (templateId && isDuplicate) {
        response = await createTemplate({
          account: accountId,
          payload: { name: templateName?.trim(), questions: [], needFinalSignature },
        });
      }

      if (isDuplicate) {
        history.replace(PATH_HIRE_FORM_EDIT.replace(':templateId', response?.data?.templateId ?? (templateId || '')));
      }
      setFormSchema([{ section: 'section1', fields: [], id: uuidv4() }]);
      setErrors([]);
      onClose();
    }

    return (
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur closeOnEsc>
        <PopoverTrigger>
          <Button
            colorScheme="red"
            isDisabled={
              formSchema.length === 0 ||
              (formSchema.length === 1 && formSchema?.[0]?.fields?.length === 0) ||
              isUpdating ||
              isLoading
            }
          >
            {t('formBuilder.clearAll')}
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Text> {t('formBuilder.clearAllPopoverMessage')}</Text>
            </PopoverBody>
            <PopoverFooter display="flex" alignItems="center" justifyContent="flex-end">
              <ButtonGroup size="sm" variant="outline">
                <Button colorScheme="red" onClick={handleClearAll}>
                  {t('formBuilder.yes')}
                </Button>
                <Button onClick={onClose}>{t('formBuilder.no')}</Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  },
);

export default TemplateActions;
