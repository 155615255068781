export default {
  status: {
    requested: 'Demandé',
    inProgress: 'En cours',
    inprogress: 'En cours',
    completed: 'Complété',
    declined: 'Refusé',
    approved: 'Approuvé',
    rejected: 'Rejeté',
    needsAction: `Besoin d'intervention`,
    needsaction: `Besoin d'intervention`,
    helpRequested: 'Aide demandée',
    helprequested: 'Aide demandée',
  },
  hireDetailInformation: `Renseignements formulaire d'embauche`,
  hireFormType: {
    NEW_HIRE: 'Nouvelle embauche',
    WORK_PERMIT: 'Permis de travail',
    SEASONAL: 'Contrat saisonnier',
  },
  hiringInformation: 'Renseignements embauche',
  candidateInformation: "Renseignements du contact en cas d'urgence",
  primaryContactInformation: `Coordonnées des personnes à contacter en cas d'urgence`,
  hiringFormStatus: "Statut du formulaire d'embauche",
  documents: 'Documents',
  contract: 'Contrat',
  address: `Ligne d'adresse 1`,
  gender: 'Sexe',
  male: 'Homme',
  female: 'Femme',
  undeclared: 'Non déclaré',
  nonBinary: 'Non binaire',
  name: 'Nom',
  city: 'Ville',
  region: 'Région',
  country: 'Pays',
  correspondenceLanguage: 'Langue de correspondance',
  english: 'Anglais',
  french: 'Français',
  optionalPhoneNumber: 'Numéro de téléphone supplémentaire',
  postalCode: 'Code postal',
  positionName: 'Nom du poste',
  dateOfBirth: 'Date de naissance',
  socialInsuranceNumber: "Numéro d'assurance sociale",
  apartment: 'Appartement',
  startingDate: 'Première date de disponibilité du candidat',
  wage: 'Salaire',
  hourly: 'Horaire',
  annually: 'Annuellement',
  phoneNumber: 'Numéro de téléphone',
  jobStatus: 'Classification',
  partTime: 'Temps partiel',
  fullTime: 'Temps plein',
  businessName: "Nom de l'entreprise",
  chequeBook: 'Chèque',
  check: 'Chèque',
  documentToImageURL: `Document d'identité`,
  worksHoursPerWeek: 'Heures de travail par semaine',
  signature: 'Signature',
  workPermit: 'Permis de travail',
  note: 'Note du recruteur',
  close: 'Fermer',
  lastName: 'Nom de famille',
  firstName: 'Premier nom',
  middleName: 'Nom de famille',
  nickName: 'Nom préféré',
  email: 'Adresse électronique',
  workPermitEndDate: 'Données de fin de permis de travail',
  canadianBankAccountDetails: 'Détails du compte de banque Canadien',
  americanBankAccountDetails: 'Détails du compte de banque Américain',
  branchTransitNumber: "Numéro de l'unité de la succursale",
  financialInstitutionNumber: "Numéro d'institution financière",
  bankAccountNumber: 'Numéro du compte bancaire',
  routingNumber: "Numéro d'acheminement",
  rejectedReason: 'Raison du rejet',
  noPermissionToViewImage: `Vous n’avez pas les permissions appropriées afin de voir cette image`,
  locationName: 'Nom du site',
  completeAddress: 'Adresse complète',
  emergencyContact: `Contact en cas d'urgence`,
  chequebookImageUrl: `Image de spécimen de chèque`,
  codeofconductandethics: `Code de Conduite et d'Éthique`,
  privacynoticeforemployees: 'Avis sur la Protection des Renseignements Personnels des Employés',
  electronicmonitoringpolicy: 'Politique de Surveillance Électronique',
};
