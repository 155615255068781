/* istanbul ignore file */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/core';
import { MdArrowForwardIos } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { StatusEnum, TodoStatusAssignedByType } from './RecruiterTodoListType';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { CandidateRecruiterAction } from '../../firebase/firestore/documents/candidateRecruiterAction';
import { ToDoList } from '../../firebase/firestore/documents/toDo';
import colors from '../../styles/colors';

export type CandidateStatusDropdownProps = {
  id: string;
  status: string;
  candidateId: string;
  actionLabel: string;
  category: string;
  candidateRecruiterActions?: CandidateRecruiterAction[];
  setToDoList: React.Dispatch<React.SetStateAction<ToDoList>>;
  statusAssignedBy: TodoStatusAssignedByType | undefined;
};

export const CandidateStatusDropdown = ({
  id,
  candidateId,
  status,
  actionLabel,
  category,
  candidateRecruiterActions,
  setToDoList,
}: CandidateStatusDropdownProps): JSX.Element => {
  const [actionBtnLabel, setActionBtnLabel] = useState<string>(actionLabel);
  const [taskStatus, setTaskStatus] = useState<StatusEnum>(StatusEnum.TODO);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentDoc, setCurrentDoc] = useState<CandidateRecruiterAction | undefined>(undefined);
  const { updateRecruiterActionStatus } = useStoreActions((actions) => actions.toDoList);
  const userId = useStoreState((state) => state.app.user?.id);
  const { t } = useTranslation('toDoList');

  const setNewTodoList = useCallback(
    (actionStatusLabel: StatusEnum, givenId: string) => {
      setToDoList((prevState) => {
        const data = map(prevState.data, (item) => {
          if (item.recruiterActionId === givenId) {
            return {
              ...item,
              todoStatus: actionStatusLabel,
            };
          }
          return item;
        });

        return {
          ...prevState,
          count: prevState.count,
          data,
        };
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const updateCandidateRecruiterAction = async (actionStatusLabel: StatusEnum) => {
    const docIndex = candidateRecruiterActions?.find((doc) => doc.id === id);
    setCurrentDoc(docIndex);
    if (userId) {
      setIsLoading(true);
      await updateRecruiterActionStatus({
        userId,
        docId: `${id}`,
        status: actionStatusLabel,
        category,
        subCategory: status,
        candidateId,
      })
        .then(() => {
          if (actionStatusLabel === StatusEnum.IN_PROGRESS) {
            setTaskStatus(actionStatusLabel);
            setActionBtnLabel(t('inProgress'));
          } else {
            setTaskStatus(actionStatusLabel === StatusEnum.TODO ? StatusEnum.TODO : StatusEnum.COMPLETED);
            setActionBtnLabel(actionStatusLabel === StatusEnum.TODO ? t('toDo') : t('completed'));
          }
          setNewTodoList(actionStatusLabel, id);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    const docIndex = candidateRecruiterActions?.find((doc) => doc.id === id);
    setCurrentDoc(docIndex);
    if (docIndex && docIndex.status === 'COMPLETED') {
      setActionBtnLabel(t('completed'));
      setTaskStatus(StatusEnum.COMPLETED);
      setNewTodoList(StatusEnum.COMPLETED, id);
    } else if (docIndex && docIndex.status === StatusEnum.IN_PROGRESS) {
      setActionBtnLabel(t('inProgress'));
      setTaskStatus(StatusEnum.IN_PROGRESS);
      setNewTodoList(StatusEnum.IN_PROGRESS, id);
    } else {
      setActionBtnLabel(t('toDo'));
      setTaskStatus(StatusEnum.TODO);
      setNewTodoList(StatusEnum.TODO, id);
    }
  }, [candidateRecruiterActions, id, t, setNewTodoList]);

  const getButtonStyles = (givenStatus: StatusEnum): { bg: string; color: string } => {
    switch (givenStatus) {
      case StatusEnum.TODO:
        return {
          bg: '#e9ecf8',
          color: 'blue.500',
        };
      case StatusEnum.IN_PROGRESS:
        return {
          bg: '#D69E2E1A',
          color: '#B7791F',
        };
      case StatusEnum.COMPLETED:
        return {
          bg: '#ebf6f0',
          color: '#38A169',
        };
      default:
        return {
          bg: '#f5f5f5',
          color: 'gray.500',
        };
    }
  };

  const getCurrentStatus = currentDoc?.status ? currentDoc.status : taskStatus;

  return (
    <Box position="relative" py="15px">
      <Menu fixed={false} offset={[2, 3]}>
        <MenuButton as={Flex}>
          <Flex alignItems="center" maxW="160px">
            <Button
              bg={getButtonStyles(getCurrentStatus as StatusEnum).bg}
              color={getButtonStyles(getCurrentStatus as StatusEnum).color}
              borderRadius="64px"
              px="20px"
              py="8px"
              mr={2}
              fontSize="15px"
              fontWeight="600"
              h="2.1rem"
              w="100%"
              isLoading={isLoading}
              loadingText="Updating"
              border="1px solid white"
            >
              {actionBtnLabel}
            </Button>
            <MdArrowForwardIos style={{ transform: 'rotate(90deg)' }} />
          </Flex>
        </MenuButton>
        <MenuList borderColor="blue.30" fontSize="14px">
          <MenuItem
            fontWeight="600"
            onClick={() => updateCandidateRecruiterAction(StatusEnum.TODO)}
            isDisabled={taskStatus === StatusEnum.TODO}
          >
            {t('toDo')}
          </MenuItem>
          <MenuItem
            fontWeight="600"
            onClick={() => updateCandidateRecruiterAction(StatusEnum.IN_PROGRESS)}
            isDisabled={taskStatus === StatusEnum.IN_PROGRESS}
          >
            {t('inProgress')}
          </MenuItem>
          <MenuItem
            fontWeight="600"
            onClick={() => updateCandidateRecruiterAction(StatusEnum.COMPLETED)}
            isDisabled={taskStatus === StatusEnum.COMPLETED}
          >
            {t('completed')}
          </MenuItem>
        </MenuList>
      </Menu>
      {currentDoc?.recruiterName && (currentDoc.status === StatusEnum.IN_PROGRESS || currentDoc.status === StatusEnum.COMPLETED) && (
        <Text color={colors.gray[400]} fontWeight={400} fontSize={12} isTruncated noOfLines={1} maxWidth="25ch">
          {currentDoc?.recruiterName}
        </Text>
      )}
    </Box>
  );
};
