/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* istanbul ignore file */
import React, { useState } from 'react';
import {
  Flex,
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Input,
  Checkbox,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
  Tooltip,
  useToast,
} from '@chakra-ui/core';
import { MdOutlineInfo } from 'react-icons/md';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import axios, { AxiosError } from 'axios';
import { clearErrorMessage, errorFormat, FormattedError, getErrorMessage, hasErrorMessage } from '../../../utils/FormErrorUtils';
import { FitbandTemplateType, FitbandType } from './FitbandConfigurationTypes';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { FitbandCalculationConfiguration } from './FitbandConfigurationView';

type FitbandTemplateProps = {
  isOpen: boolean;
  onConfirmOpen: () => void;
  onClose: () => void;
  templateData: FitbandType;
  setTemplateData: React.Dispatch<React.SetStateAction<FitbandType>>;
  fitbandTemplateEdit: FitbandTemplateType | null;
  setFitbandTemplateEdit: React.Dispatch<React.SetStateAction<FitbandTemplateType | null>>;
  fitbandTemplateView: FitbandTemplateType | null;
  setFitbandTemplateView: React.Dispatch<React.SetStateAction<FitbandTemplateType | null>>;
  formErrors: FormattedError[];
  setFormErrors: React.Dispatch<React.SetStateAction<FormattedError[]>>;
  getFitbandTemplates: () => void;
  isDisabled: boolean;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  penaltyData: FitbandCalculationConfiguration;
  setPenaltyData: React.Dispatch<React.SetStateAction<FitbandCalculationConfiguration>>;
};

export const FitbandTemplateCreateModal = ({
  isOpen,
  onConfirmOpen,
  onClose,
  templateData,
  setTemplateData,
  fitbandTemplateEdit,
  setFitbandTemplateEdit,
  fitbandTemplateView,
  setFitbandTemplateView,
  formErrors,
  setFormErrors,
  getFitbandTemplates,
  isDisabled,
  setIsDisabled,
  penaltyData,
  setPenaltyData,
}: FitbandTemplateProps): JSX.Element => {
  const { t } = useTranslation('administration');
  const toast = useToast();
  const accountId = useStoreState((s) => s.app.accounts?.id);
  const isRequisitionEnabled = useStoreState((state) => state.app.accounts?.configuration?.requisitionEnabled);

  const { createFitbandTemplate } = useStoreActions((actions) => actions.fitbandTemplate);

  // const [isTestingOpen, setIsTestingOpen] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [isReqSubmitted, setIsReqSubmitted] = useState<boolean>(false);
  const [isExtraSubmitted, setIsExtraSubmitted] = useState<boolean>(false);
  const [isFitbandCreateLoading, setIsFitbandCreateLoading] = useState<boolean>(false);

  const [fitbandErrors, setFitbandErrors] = useState({
    goodFit: false,
    mediumFit: false,
  });

  const getFitbandTemplateUpdate = (template: FitbandTemplateType) => {
    if (!isNil(template)) {
      setTemplateData((prevData) => ({
        ...prevData,
        name: template.name || prevData.name,
        goodFit: template.fitBand?.goodFit || prevData.goodFit,
        mediumFit: template.fitBand?.mediumFit || prevData.mediumFit,
      }));
      setPenaltyData((prevData) => ({
        ...prevData,
        requisitionChecked: template.fitBandCalculationConfigurations?.shouldPenalizeRequiredTime || false,
        availabilityChecked: template.fitBandCalculationConfigurations?.shouldPenalizeExtraTime || false,
        reqDays: template.fitBandCalculationConfigurations?.penalizeFactorRequiredDays || prevData.reqDays,
        reqHours: template.fitBandCalculationConfigurations?.penalizeFactorRequiredHours || prevData.reqHours,
        extraDays: template.fitBandCalculationConfigurations?.penalizeFactorExtraDays || prevData.extraDays,
        extraHours: template.fitBandCalculationConfigurations?.penalizeFactorExtraHours || prevData.extraHours,
      }));
    }
  };

  React.useEffect(() => {
    if (fitbandTemplateEdit) {
      getFitbandTemplateUpdate(fitbandTemplateEdit);
    } else if (fitbandTemplateView) {
      getFitbandTemplateUpdate(fitbandTemplateView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fitbandTemplateEdit, fitbandTemplateView, setTemplateData]);

  const fitbandSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      name: Joi.string()
        .trim(true)
        .required()
        .messages({
          'string.empty': t('fitbandConfiguration.fitbandModal.templateNameError'),
        }),
      goodFit: Joi.number().integer().min(1).max(100).required(),
      mediumFit: Joi.alternatives()
        .try(
          Joi.number()
            .integer()
            .min(1)
            .max(100)
            .custom((value, helpers) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (value >= helpers.state.ancestors[0].goodFit) {
                return helpers.error('mediumFit.invalid'); // Custom error
              }
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return value;
            }),
        )
        .allow(null, '')
        .messages({
          'mediumFit.invalid': t('fitbandConfiguration.fitbandModal.mediumFitError'), // Show only this custom error
          'number.min': '',
          'any.required': '',
        }),

      // Adding `requisitionChecked` to the schema
      requisitionChecked: Joi.boolean().required(),
      availabilityChecked: Joi.boolean().required(),

      reqDays: Joi.number()
        .min(0)
        .max(2)
        .precision(2)
        .when('requisitionChecked', {
          is: true,
          then: Joi.number().min(0).max(2).precision(2).required(),
          otherwise: Joi.optional().allow(''),
        }),

      reqHours: Joi.number()
        .min(0)
        .max(2)
        .precision(2)
        .when('requisitionChecked', {
          is: true,
          then: Joi.number().min(0).max(2).precision(2).required(),
          otherwise: Joi.optional().allow(''),
        }),

      extraDays: Joi.number()
        .min(0)
        .max(2)
        .precision(2)
        .when('availabilityChecked', {
          is: true,
          then: Joi.number().min(0).max(2).precision(2).required(),
          otherwise: Joi.optional().allow(''),
        }),

      extraHours: Joi.number()
        .min(0)
        .max(2)
        .precision(2)
        .when('availabilityChecked', {
          is: true,
          then: Joi.number().min(0).max(2).precision(2).required(),
          otherwise: Joi.optional().allow(''),
        }),
    });

  const formValidation = (formData: any, scheme: Joi.ObjectSchema) => {
    const { error } = scheme.validate(formData);
    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }
    setFormErrors([]);
    return true;
  };

  const handleInputChange = (value: string, fieldName: string) => {
    setTemplateData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  const handleBandInputChange = (value: string, fieldName: string) => {
    const MIN_VALUE = 0;
    const MAX_VALUE = 100;
    const numericValue = Math.min(MAX_VALUE, Math.max(MIN_VALUE, Number(value)));
    if (!isNaN(numericValue)) {
      setTemplateData((prevData) => {
        const updatedData = { ...prevData, [fieldName]: numericValue || 0 };

        // check for mediumFit
        if (fieldName === 'mediumFit') {
          setFitbandErrors({
            ...fitbandErrors,
            mediumFit: numericValue >= updatedData.goodFit,
          });
        } else if (fieldName === 'goodFit') {
          setFitbandErrors({
            ...fitbandErrors,
            mediumFit: updatedData.mediumFit >= numericValue,
          });
        }

        return updatedData;
      });
    }
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  const handlePenaltyValueInput = (value: string, fieldName: string) => {
    if (!/^\d*\.?\d*$/.test(value)) return;

    const numericValue = parseFloat(value);

    // Allow clearing the field or allow values between 0 and 2
    if (value === '' || (numericValue >= 0 && numericValue <= 2)) {
      setPenaltyData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };

  const resetFromData = () => {
    setTemplateData({
      name: '',
      goodFit: 0,
      mediumFit: 0,
    });
    setPenaltyData((prevData) => ({
      ...prevData,
      requisitionChecked: false,
      availabilityChecked: false,
      reqDays: 0,
      reqHours: 0,
      extraDays: 0,
      extraHours: 0,
    }));
    setFitbandTemplateEdit(null);
  };

  // function to handle fitband template creation and edit
  const onFitbandTemplateCreate = async () => {
    setIsFormSubmitted(true);

    if (penaltyData.requisitionChecked) {
      setIsReqSubmitted(true);
    }

    if (penaltyData.availabilityChecked) {
      setIsExtraSubmitted(true);
    }

    const templateDataToVerify = {
      ...templateData,
      ...penaltyData,
    };
    const validate = formValidation(templateDataToVerify, fitbandSchema);

    if (!validate) return;

    const payload = {
      name: templateData.name,
      fitBand: {
        goodFit: templateData.goodFit,
        mediumFit: templateData.mediumFit,
      },
      fitBandCalculationConfigurations: {
        shouldPenalizeExtraTime: penaltyData.availabilityChecked,
        penalizeFactorExtraDays: !isEmpty(penaltyData.extraDays) ? penaltyData.extraDays : 0,
        penalizeFactorExtraHours: !isEmpty(penaltyData.extraHours) ? penaltyData.extraHours : 0,
        shouldPenalizeRequiredTime: penaltyData.requisitionChecked,
        penalizeFactorRequiredDays: !isEmpty(penaltyData.reqDays) ? penaltyData.reqDays : 0,
        penalizeFactorRequiredHours: !isEmpty(penaltyData.reqHours) ? penaltyData.reqHours : 0,
      },
    };

    if (!fitbandTemplateEdit) {
      setIsFitbandCreateLoading(true);
      try {
        if (accountId) {
          await createFitbandTemplate({ accountId, templateData: payload });

          toast({
            title: t('fitbandConfiguration.fitbandToastMessage.templateCreate'),
            description: t('fitbandConfiguration.fitbandToastMessage.templateCreateSuccess', {
              templateName: templateData.name,
            }),
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          setIsFitbandCreateLoading(false);
          resetFromData();
          onClose();
          getFitbandTemplates();
        }
      } catch (err) {
        setIsFitbandCreateLoading(false);
        console.error('Error creating template', err);
        let defaultMessage = t('fitbandConfiguration.fitbandToastMessage.nameExists');
        const newError = err as AxiosError<{ message: string }>;
        if (axios.isAxiosError(newError)) {
          defaultMessage = newError.response?.data?.message || defaultMessage;
        }
        toast({
          description: defaultMessage,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
        throw err;
      }
    } else {
      onClose();
      onConfirmOpen();
    }
  };

  const handleCancel = () => {
    resetFromData();
    setFormErrors([]);
    setFitbandTemplateView(null);
    setIsDisabled(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="3xl" scrollBehavior="inside" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent data-testid="fitbandTemplateModal" overflow="auto" p={4}>
        <ModalHeader borderBottom="1px solid #e2e8f0">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Heading as="h4" fontSize="lg" lineHeight="1.3">
              {fitbandTemplateView
                ? t('fitbandConfiguration.fitbandModal.viewFitband')
                : fitbandTemplateEdit
                ? t('fitbandConfiguration.fitbandModal.editFitband')
                : t('fitbandConfiguration.fitbandModal.createFitband')}
            </Heading>
            {/* <Box
          onClick={() => setIsTestingOpen(true)}
          data-testid="testSettings"
          cursor="pointer"
          fontSize="sm"
          fontWeight={400}
          color={colors.blue[500]}
        >
          Want to test these settings?
        </Box> */}
          </Box>
        </ModalHeader>
        <ModalBody>
          <Box pt={4}>
            <FormControl isInvalid={hasErrorMessage(formErrors, 'name')} isRequired={!fitbandTemplateView}>
              <FormLabel fontSize="14px" fontWeight={700}>
                {t('fitbandConfiguration.fitbandModal.templateName')}
              </FormLabel>
              <Input
                data-testid="templateIdField"
                value={templateData.name}
                onChange={(e) => {
                  const trimmedName = e.target.value
                    .replace(/[^a-zA-Z0-9\s.\-_]/g, '')
                    .replace(/\s+/g, ' ')
                    .trimStart();
                  handleInputChange(trimmedName, 'name');
                }}
                placeholder={t('fitbandConfiguration.fitbandModal.enterTemplateName')}
                fontSize="14px"
                maxLength={100}
                minLength={3}
                backgroundColor="#EDF2F7"
                _placeholder={{ color: '#A0AEC0' }}
                style={{ height: '40px' }}
                disabled={isDisabled}
                _disabled={{ color: 'black' }}
              />
              <FormErrorMessage>{getErrorMessage(formErrors, 'name')}</FormErrorMessage>
            </FormControl>

            <Box mt={5} display="flex" justifyContent="flex-start" alignItems="baseline" width="100%">
              {/* <Flex justifyContent="center" alignItems="center" width="50%"> */}
              <FormControl isInvalid={templateData.goodFit === 0 && isFormSubmitted} isRequired={!fitbandTemplateView}>
                <Flex justifyContent="flex-start" alignItems="baseline" width="50%">
                  <FormLabel fontSize="14px" fontWeight={700}>
                    {t('fitbandConfiguration.fitbandModal.goodFit')}
                  </FormLabel>
                  <Input
                    type="tel"
                    width="50px"
                    size="sm"
                    placeholder="100"
                    maxLength={3}
                    value={templateData.goodFit === 0 ? '' : templateData.goodFit}
                    onChange={(e) => handleBandInputChange(e.target.value, 'goodFit')}
                    disabled={isDisabled}
                    _disabled={{ borderWidth: '0' }}
                  />
                </Flex>
              </FormControl>
              {/* </Flex> */}
              {/* <Flex justifyContent="center" alignItems="center"> */}
              <FormControl
                isInvalid={
                  Boolean(getErrorMessage(formErrors, 'mediumFit')) ||
                  (templateData.mediumFit === 0 && isFormSubmitted) ||
                  fitbandErrors.mediumFit
                }
                isRequired={!fitbandTemplateView}
              >
                <Flex justifyContent="flex-start" alignItems="baseline" width="50%">
                  <FormLabel fontSize="14px" fontWeight={700} mt={1}>
                    {t('fitbandConfiguration.fitbandModal.mediumFit')}
                  </FormLabel>
                  <Input
                    type="tel"
                    width="50px"
                    size="sm"
                    placeholder="50"
                    maxLength={3}
                    value={templateData.mediumFit === 0 ? '' : templateData.mediumFit}
                    onChange={(e) => handleBandInputChange(e.target.value, 'mediumFit')}
                    disabled={isDisabled}
                    _disabled={{ borderWidth: '0' }}
                  />
                </Flex>
                <FormErrorMessage>{getErrorMessage(formErrors, 'mediumFit')}</FormErrorMessage>
              </FormControl>
              {/* </Flex> */}
            </Box>
            {isRequisitionEnabled && <Divider color="#e2e8f0" fontSize="0.25px" mt={5} mb={4} />}

            {isRequisitionEnabled && (
              <>
                <Flex justifyContent="flex-start" alignItems="center" mb={1}>
                  <Text fontSize="18px" fontWeight={700} mr={1}>
                    {t('fitbandConfiguration.fitbandModal.penalty')}
                  </Text>
                  <Tooltip hasArrow label={t('fitbandConfiguration.penaltyTooltipText')} placement="right">
                    <Box>
                      <MdOutlineInfo size={17} color="#718096" />
                    </Box>
                  </Tooltip>
                </Flex>

                <Flex width="100%" justifyContent="center" alignItems="center">
                  <Box width="50%">
                    <Flex justifyContent="flex-start" alignItems="center">
                      <Checkbox
                        mt={4}
                        mb={4}
                        data-testid="requisitionCheckbox"
                        isChecked={penaltyData.requisitionChecked}
                        onChange={(e) => {
                          setPenaltyData((prevData) => ({
                            ...prevData,
                            requisitionChecked: e.target.checked,
                          }));
                        }}
                        isDisabled={isDisabled}
                      >
                        <Text fontSize="14px" fontWeight={700}>
                          {t('fitbandConfiguration.fitbandModal.requisition')}
                        </Text>
                      </Checkbox>
                      <Tooltip hasArrow label={t('fitbandConfiguration.requisitionText')} placement="right">
                        <Box ml={1}>
                          <MdOutlineInfo size={17} color="#718096" />
                        </Box>
                      </Tooltip>
                    </Flex>
                    <Flex justifyContent="center" alignItems="center" width="100%">
                      <Text
                        fontSize="14px"
                        fontWeight={400}
                        width="35%"
                        color={penaltyData.requisitionChecked || isDisabled ? 'default' : 'grey'}
                      >
                        {t('fitbandConfiguration.fitbandModal.days')}
                      </Text>
                      <FormControl
                        isInvalid={String(penaltyData.reqDays) === '' && penaltyData.requisitionChecked && isReqSubmitted}
                        isRequired={penaltyData.requisitionChecked}
                      >
                        <Input
                          type="tel"
                          width="100px"
                          size="sm"
                          placeholder="0"
                          maxLength={3}
                          value={!penaltyData.requisitionChecked ? 0 : penaltyData.reqDays}
                          onChange={(e) => handlePenaltyValueInput(e.target.value, 'reqDays')}
                          disabled={!penaltyData.requisitionChecked || isDisabled}
                          _disabled={{ borderWidth: isDisabled ? '0' : 'default' }}
                        />
                      </FormControl>
                    </Flex>
                    <Flex justifyContent="center" alignItems="center" width="100%" mt={3}>
                      <Text
                        fontSize="14px"
                        fontWeight={400}
                        width="35%"
                        color={penaltyData.requisitionChecked || isDisabled ? 'default' : 'grey'}
                      >
                        {t('fitbandConfiguration.fitbandModal.hours')}
                      </Text>
                      <FormControl
                        isInvalid={String(penaltyData.reqHours) === '' && penaltyData.requisitionChecked && isReqSubmitted}
                        isRequired={penaltyData.requisitionChecked}
                      >
                        <Input
                          type="tel"
                          width="100px"
                          size="sm"
                          placeholder="0"
                          maxLength={3}
                          value={!penaltyData.requisitionChecked ? 0 : penaltyData.reqHours}
                          onChange={(e) => handlePenaltyValueInput(e.target.value, 'reqHours')}
                          disabled={!penaltyData.requisitionChecked || isDisabled}
                          _disabled={{ borderWidth: isDisabled ? '0' : 'default' }}
                        />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box width="50%">
                    <Flex justifyContent="flex-start" alignItems="center">
                      <Checkbox
                        mt={4}
                        mb={4}
                        data-testid="availabilityCheckbox"
                        isChecked={penaltyData.availabilityChecked}
                        onChange={(e) => {
                          setPenaltyData((prevData) => ({
                            ...prevData,
                            availabilityChecked: e.target.checked,
                          }));
                        }}
                        isDisabled={isDisabled}
                      >
                        <Text fontSize="14px" fontWeight={700}>
                          {t('fitbandConfiguration.fitbandModal.extraAvailability')}
                        </Text>
                      </Checkbox>
                      <Tooltip hasArrow label={t('fitbandConfiguration.extraAvailabilityText')} placement="right">
                        <Box ml={1}>
                          <MdOutlineInfo size={17} color="#718096" />
                        </Box>
                      </Tooltip>
                    </Flex>
                    <Flex justifyContent="center" alignItems="center" width="100%">
                      <Text
                        fontSize="14px"
                        fontWeight={400}
                        width="35%"
                        color={penaltyData.availabilityChecked || isDisabled ? 'default' : 'grey'}
                      >
                        {t('fitbandConfiguration.fitbandModal.days')}
                      </Text>
                      <FormControl
                        isInvalid={penaltyData.extraDays.toString() === '' && penaltyData.availabilityChecked && isExtraSubmitted}
                        isRequired={penaltyData.availabilityChecked}
                      >
                        <Input
                          type="tel"
                          width="100px"
                          size="sm"
                          placeholder=""
                          maxLength={3}
                          value={!penaltyData.availabilityChecked ? 0 : penaltyData.extraDays}
                          onChange={(e) => handlePenaltyValueInput(e.target.value, 'extraDays')}
                          disabled={!penaltyData.availabilityChecked || isDisabled}
                          _disabled={{ borderWidth: isDisabled ? '0' : 'default' }}
                        />
                      </FormControl>
                    </Flex>
                    <Flex justifyContent="center" alignItems="center" width="100%" mt={3}>
                      <Text
                        fontSize="14px"
                        fontWeight={400}
                        width="35%"
                        color={penaltyData.availabilityChecked || isDisabled ? 'default' : 'grey'}
                      >
                        {t('fitbandConfiguration.fitbandModal.hours')}
                      </Text>
                      <FormControl
                        isInvalid={String(penaltyData.extraHours) === '' && penaltyData.availabilityChecked && isExtraSubmitted}
                        isRequired={penaltyData.availabilityChecked}
                      >
                        <Input
                          type="tel"
                          width="100px"
                          size="sm"
                          placeholder=""
                          maxLength={3}
                          value={!penaltyData.availabilityChecked ? 0 : penaltyData.extraHours}
                          onChange={(e) => handlePenaltyValueInput(e.target.value, 'extraHours')}
                          disabled={!penaltyData.availabilityChecked || isDisabled}
                          _disabled={{ borderWidth: isDisabled ? '0' : 'default' }}
                        />
                      </FormControl>
                    </Flex>
                  </Box>
                </Flex>
              </>
            )}
          </Box>
          <ModalFooter pr={0} mt={2}>
            {fitbandTemplateView ? (
              <Button data-testid="closeBtn" colorScheme="blue" onClick={handleCancel}>
                {t('fitbandConfiguration.okBtn')}
              </Button>
            ) : (
              <>
                <Button data-testid="cancelBtn" colorScheme="red" onClick={handleCancel}>
                  {t('fitbandConfiguration.cancelBtn')}
                </Button>
                <Button
                  isLoading={isFitbandCreateLoading}
                  data-testid="createTemplateBtn"
                  colorScheme="blue"
                  ml={3}
                  disabled={isFitbandCreateLoading}
                  onClick={onFitbandTemplateCreate}
                >
                  {fitbandTemplateEdit
                    ? t('fitbandConfiguration.fitbandModal.updateBtn')
                    : t('fitbandConfiguration.fitbandModal.createBtn')}
                </Button>
              </>
            )}
          </ModalFooter>

          {/* {isTestingOpen && (
        <Box mt={4} borderTop="1px solid #e2e8f0">
          <AvailabilityEvaluation setTestingOpen={setIsTestingOpen} />
        </Box>
      )} */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
