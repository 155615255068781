import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/core';
import React from 'react';
import FormBuilderTags from '../formElements/FormBuilderTags';
import ComponentGrid from './ComponentGrid';
import theme from '../../../../styles/customTheme';
import { useTranslation } from 'react-i18next';

interface ComponentTabsProps {
  search: string;
}

const ComponentTabs = React.memo(({ search }: ComponentTabsProps) => {
  const { t } = useTranslation('hrFormTemplate');

  return (
    <Tabs isFitted size="sm">
      <TabList mb="1em">
        <Tab _focus={{ outline: 'none' }}>{t('formBuilder.componentSidebar.tabs.basic')}</Tab>
        <Tab _focus={{ outline: 'none' }}>{t('formBuilder.componentSidebar.tabs.advanced')}</Tab>
        <Tab _focus={{ outline: 'none' }}>{t('formBuilder.componentSidebar.tabs.tags')}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel id="basic" px={0}>
          <Stack spacing={theme.space[4]}>
            <ComponentGrid currentPanel="basic" search={search} />
            <ComponentGrid currentPanel="page-element" search={search} title={t('formBuilder.componentSidebar.pageElement')} />
          </Stack>
        </TabPanel>
        <TabPanel id="advanced" px={0}>
          <ComponentGrid currentPanel="advanced" search={search} />
        </TabPanel>
        <TabPanel id="tags" px={0}>
          <FormBuilderTags search={search} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
});

export default ComponentTabs;
