/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo, useEffect, Fragment } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Menu,
  MenuButton,
  MenuIcon,
  MenuItem,
  MenuList,
  useDisclosure,
  Spacer,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/core';
import Bugsnag from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import {
  IoMdAnalytics,
  IoMdChatbubbles,
  IoMdNotifications,
  IoMdFiling,
  IoIosLogOut,
  IoMdHelpCircle,
  IoIosListBox,
} from 'react-icons/io';
import { FaBriefcase } from 'react-icons/fa';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { AiFillExperiment } from 'react-icons/ai';
import { MdInsertChart, MdPersonSearch } from 'react-icons/md';
import { useCurrentUserProfile } from '../hooks/useCurrentUserProfile';
import { ReactComponent as _IconCalendar } from '../../assets/img/icon-calendar.svg';
import { headerGradientCSS, headerGradientCSSWhenSortAndSortIsOpen, menuItemCss, notificationCount } from './Header.styles';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { ProfilePicture } from '../../modules/common';
import useAccounts from '../hooks/useAccounts';
import { RemoteConfigKey } from '../../firebase/remoteConfig';
import { NotificationPanel } from './notification/NotificationPanel';
import { ProfileView } from '../../modules/profile/ProfileView';
import colors from '../../styles/colors';
import { PATH_ANALYTICS, PATH_HIRE_FORM, PATH_MAIN } from '../../routes/constants';
import { Logo } from './Logo';
import { JobPositionModalView } from '../../modules/jobPositionTopCandidate/JobPositionModalView';

const IconCalendar = memo(_IconCalendar);

type HeaderProps = {
  showMenu: boolean;
  showSearchSort?: boolean;
  showPageTitle?: string;
  // eslint-disable-next-line react/require-default-props
  setDarkModeTo?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Header = ({ showMenu, showSearchSort = true, showPageTitle = '', setDarkModeTo }: HeaderProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isConfirmationOpen, onOpen: onConfirmationOpen, onClose: onConfirmationClose } = useDisclosure();
  const {
    isOpen: isPositionMgmtForTopCandidateOpen,
    onOpen: onPositionMgmtForTopCandidateOpen,
    onClose: onPositionMgmtForTopCandidateClose,
  } = useDisclosure();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDarkMode, setisDarkMode] = React.useState<boolean>(false);
  const cancelRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [showAnalytics, setShowAnalytics] = React.useState<boolean>(false);
  const [showPrototype, setShowPrototype] = React.useState<boolean>(false);
  const [openProfile, setOpenProfile] = React.useState<boolean>(false);
  const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
  const [showDashboard, setShowDashboard] = React.useState<boolean>(false);
  const [newNotificationCount, setNewNotificationCount] = React.useState<number>(0);
  const setAccounts = useStoreActions((a) => a.app.setAccounts);
  const accounts = useStoreState((s) => s.app.accounts);

  const appUserId = useStoreState((s) => s.app.user?.id);
  const appOwnersId = useStoreState((s) => s.app.accounts?.owners);

  const { updateLocale } = useStoreActions((actions) => actions.user);

  const match = useRouteMatch();
  const { t, i18n } = useTranslation();

  const { currentUserProfile, initialized } = useCurrentUserProfile();
  const accountsData = useAccounts();
  const recruiterProfileImage = useStoreState((s) => s.app.recruiterProfileImage);

  const signOut = useStoreActions((actions) => actions.auth.signOut);

  const enableNotificationFeature: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_NOTIFICATION_FEATURE];
  });
  const enableUpdateProfileFeature: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_UPDATE_PROFILE_FEATURE_WEB_RECRUITER];
  });
  const enableTopCandidatesOfPosition: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_TOP_CANDIDATES_OF_POSITION];
  });

  const isPositionManagementFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_POSITION_MANAGEMENT_FEATURE];
  });

  const isSupportDocumentationEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_SUPPORT_DOCUMENTATION];
  });

  const isNewAnalyticEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_NEW_ANALYTIC_VIEW];
  });

  const superOwners = useStoreState((s) => s.app.accounts?.superOwners);

  const isCurrentPage = (path: string): boolean => match?.url?.includes(path);

  const location = useLocation();

  const updateLocaleHandler = () => {
    if (currentUserProfile) {
      updateLocale({
        userId: currentUserProfile.id,
        locale: i18n.language === 'fr' ? 'en' : 'fr',
      });
    }
    i18n
      .changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')
      .then(() => {})
      .catch((error) => {
        Bugsnag.notify(error);
      });
  };

  const unreadNotificationNumber = (num: number) => {
    setNewNotificationCount(num);
  };

  useEffect(() => {
    if (!accountsData) return;
    setAccounts(accountsData);
  }, [accountsData, setAccounts]);

  useEffect(() => {
    setDarkModeTo?.(isDarkMode);
  }, [isDarkMode, setDarkModeTo]);

  useEffect(() => {
    const isAnalyticsEnabled = accounts?.analyticsEnabled === true;
    const isPrototypingEnabled = accounts?.prototypeEnabled === true;
    const isCalendarEnabled = accounts?.calendarEnabled === true;
    const isDashboardEnabled = accounts?.configuration?.dashboardEnabled === true;
    setShowDashboard(isDashboardEnabled);
    setShowAnalytics(isAnalyticsEnabled);
    setShowPrototype(isPrototypingEnabled);
    setShowCalendar(isCalendarEnabled);
  }, [accounts]);

  useEffect(() => {
    i18n
      .changeLanguage(currentUserProfile?.locale)
      .then(() => {})
      .catch((error) => {
        Bugsnag.notify(error);
      });
  }, [currentUserProfile, i18n]);

  const isManagementOverviewEnabled = useStoreState((s) => s.app.accounts?.configuration?.managementOverviewEnabled);

  const isSuperAdmin = (): boolean => {
    if (!superOwners || !appUserId) {
      return false;
    }
    return superOwners?.includes(appUserId);
  };

  const isAdmin = !isNil(appOwnersId) && !isNil(appUserId) && appOwnersId?.includes(appUserId);

  const showManagementOverviewIcon = (isSuperAdmin() || isAdmin) && isManagementOverviewEnabled;

  return (
    <Fragment>
      <Flex
        data-testid="HeaderComponent"
        css={showSearchSort ? headerGradientCSSWhenSortAndSortIsOpen : headerGradientCSS}
        justify="space-between"
        align="center"
        p={4}
      >
        {showMenu ? (
          <Link to={PATH_MAIN}>
            <Logo />
          </Link>
        ) : (
          <Box as="span">
            <Logo />
          </Box>
        )}
        {showPageTitle && (
          <Text fontSize="20px" fontWeight={700} color="#ffffff">
            {showPageTitle}
          </Text>
        )}

        {initialized && showMenu && (
          <Menu placement="bottom-end">
            <Flex justify="flex-end">
              {/* {!isNil(setDarkModeTo) && (
                <Flex alignItems="center" mr={4}>
                  <Text color={colors.white} fontWeight="bold" mr={2}>
                    Switch To Dark Mode
                  </Text>
                  <Switch
                    colorScheme="teal"
                    size="lg"
                    isChecked={isDarkMode}
                    onChange={(e) => setisDarkMode(e.target.checked)}
                    data-testid="setDarkMode"
                  />
                </Flex>
              )} */}

              {isSupportDocumentationEnabled && !isCurrentPage('/support') && (
                <Menu>
                  <MenuIcon marginTop={1} as={Box} marginRight={4}>
                    <Link to="/support">
                      <IoMdHelpCircle size={32} color="#fff" />
                    </Link>
                  </MenuIcon>
                </Menu>
              )}

              {showManagementOverviewIcon && !isCurrentPage('/managementOverview') && isCurrentPage(PATH_ANALYTICS) && (
                <Menu>
                  <MenuIcon marginTop={1} as={Box} marginRight={4}>
                    <Link to="/managementOverview">
                      <MdInsertChart size={32} color="#fff" fill="#fff" />
                    </Link>
                  </MenuIcon>
                </Menu>
              )}

              {isPositionManagementFeatureEnabled &&
                !isCurrentPage('/administration') &&
                !isCurrentPage(PATH_HIRE_FORM) &&
                !isCurrentPage('/positions') && (
                  <Menu>
                    <MenuIcon marginTop={1} as={Box} marginRight={4}>
                      <Link to="/positions">
                        <IoMdFiling size={32} color="#fff" />
                      </Link>
                    </MenuIcon>
                  </Menu>
                )}

              {showAnalytics && !isCurrentPage('/analytics') && (
                <Menu>
                  <MenuIcon marginTop={1} as={Box} marginRight={4}>
                    <Link to="/analytics">
                      <IoMdAnalytics size={32} color="#fff" />
                    </Link>
                  </MenuIcon>
                </Menu>
              )}
              {isNewAnalyticEnabled && showPrototype && !isCurrentPage('/prototype') && (
                <Menu>
                  <MenuIcon marginTop={1} as={Box} marginRight={4}>
                    <Link to="/prototype">
                      <AiFillExperiment size={32} color="#fff" />
                    </Link>
                  </MenuIcon>
                </Menu>
              )}

              {!isCurrentPage('/candidateSearch') && (
                <Menu>
                  <MenuIcon as={Box} marginRight={4}>
                    <Link to="/candidateSearch">
                      <MdPersonSearch size={38} color="#fff" />
                    </Link>
                  </MenuIcon>
                </Menu>
              )}

              {enableTopCandidatesOfPosition && (
                <Menu>
                  <MenuIcon marginTop={1} as={Box} marginRight={4} onClick={() => onPositionMgmtForTopCandidateOpen()}>
                    <FaBriefcase size={32} color="#fff" />
                  </MenuIcon>
                </Menu>
              )}
              {!isCurrentPage('/candidates/') && (
                <Menu>
                  <MenuIcon marginTop={1} as={Box} marginRight={4}>
                    <Link to="/messaging">
                      <IoMdChatbubbles size={32} color="#fff" />
                    </Link>
                  </MenuIcon>
                </Menu>
              )}

              {showCalendar && (!isCurrentPage('/calendar') || isCurrentPage('/administration/calendar')) && (
                <Menu>
                  <MenuIcon marginTop={1} as={Box} marginRight={4}>
                    <Link to="/calendar">
                      <IconCalendar width={30} height={30} />
                    </Link>
                  </MenuIcon>
                </Menu>
              )}
              {showDashboard && !isCurrentPage('/dashboard') && (
                <Menu>
                  <MenuIcon marginTop={1} as={Box} marginRight={4}>
                    <Link to="/dashboard">
                      <IoIosListBox size={32} color="#fff" />
                    </Link>
                  </MenuIcon>
                </Menu>
              )}

              {enableNotificationFeature && (
                <Menu>
                  <MenuIcon as={Box} marginTop={1} marginRight={4} cursor="pointer" position="relative" onClick={onOpen}>
                    <Fragment>
                      <Box css={notificationCount}>{newNotificationCount}</Box>
                      <IoMdNotifications size={32} color="#fff" />
                    </Fragment>
                  </MenuIcon>
                </Menu>
              )}
              <Box zIndex={12}>
                <MenuButton as={Box} data-testid="ProfilePictureButton" trigger="hover" className="userProfilePic">
                  <Flex align="center">
                    <ProfilePicture
                      data-testid="UserProfilePicture"
                      name={currentUserProfile?.fullName}
                      style={{ backgroundColor: colors.blue.default, color: 'white' }}
                      src={recruiterProfileImage}
                      width="40px"
                      height="40px"
                    />
                  </Flex>
                </MenuButton>
                <MenuList backgroundColor="#ffffff" paddingLeft="10px" paddingRight="10px" css={menuItemCss}>
                  <Box paddingTop="10px" paddingBottom="10px">
                    <Flex align="center">
                      <ProfilePicture
                        name={currentUserProfile?.fullName}
                        style={{ backgroundColor: colors.blue.default, color: 'white' }}
                        src={recruiterProfileImage}
                        width="40px"
                        height="40px"
                      />
                      <Box pl={2} color="#000">
                        <Heading as="h4" fontSize="sm" marginTop={0} marginBottom={0} lineHeight={1.1}>
                          {currentUserProfile?.fullName}
                        </Heading>
                        <Text fontSize="xs" color="#a6a6a6">
                          {currentUserProfile?.email}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                  {enableUpdateProfileFeature && (
                    <MenuItem onClick={() => setOpenProfile(true)} className="menuItem">
                      {t('account.myProfile')}
                    </MenuItem>
                  )}
                  {!isNil(appOwnersId) &&
                    !isNil(appUserId) &&
                    appOwnersId?.includes(appUserId) &&
                    (location.pathname === '/administration' ? (
                      <Link to="/messaging" style={{ textDecoration: 'none' }}>
                        <MenuItem className="menuItem">{t('account.recruiter')}</MenuItem>
                      </Link>
                    ) : (
                      <Link to="/administration" style={{ textDecoration: 'none' }}>
                        <MenuItem className="menuItem">{t('account.administration')}</MenuItem>
                      </Link>
                    ))}
                  <MenuItem data-testid="LanguageChanger" onClick={updateLocaleHandler} className="menuItem">
                    {t('account.switchLang')}
                  </MenuItem>
                  <MenuItem data-testid="Logout" onClick={() => onConfirmationOpen()} className="menuItem">
                    <Flex alignItems="center" justifyContent="space-between" width="100%">
                      <Box>
                        <Text>{t('account.logout')}</Text>
                      </Box>
                      <Spacer />
                      <Box>
                        <IoIosLogOut color="#000" />
                      </Box>
                    </Flex>
                  </MenuItem>
                </MenuList>
              </Box>
            </Flex>
          </Menu>
        )}
        {isOpen && enableNotificationFeature && (
          <NotificationPanel isOpen={isOpen} onClose={onClose} unreadNotificationNumber={unreadNotificationNumber} />
        )}
        {openProfile && (
          <ProfileView open={openProfile} onCloseProfile={() => setOpenProfile(false)} picture={recruiterProfileImage} />
        )}
        {isPositionMgmtForTopCandidateOpen && (
          <JobPositionModalView isOpen={isPositionMgmtForTopCandidateOpen} onClose={onPositionMgmtForTopCandidateClose} />
        )}
        {isConfirmationOpen && (
          <AlertDialog isOpen={isConfirmationOpen} leastDestructiveRef={cancelRef} onClose={onConfirmationClose}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {t('logoutConfirmation.message')}
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <Button ref={cancelRef} colorScheme="blue" onClick={onConfirmationClose}>
                    {t('logoutConfirmation.cancel')}
                  </Button>
                  <Button
                    data-testid="confirmSignOutButton"
                    colorScheme="red"
                    onClick={() => signOut().finally(() => onConfirmationClose())}
                    ml={3}
                  >
                    {t('logoutConfirmation.logout')}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )}
      </Flex>
    </Fragment>
  );
};

Header.defaultProps = {
  showSearchSort: true,
  showPageTitle: '',
};
