export default {
  notAuthorized: {
    notAuthorizedMsg: `Vous n'êtes pas autorisé à voir cette page. Contactez votre administrateur.`,
    goToHomeBtn: ` Retourner à l'accueil`,
  },
  administrationSideBarMenuItems: {
    jobListingUrl: `URL pour l’affichage de postes d’emploi `,
    hireFormTemplates: `Formulaire d’embauche`,
    uploadCompanyLogo: `Télécharger le logo de l'entreprise`,
    companyLogo: `Logo de l'entreprise`,
    companyManagement: "Ressources de l'entreprise",
    certnIntegration: 'Intégration de Certn',
    positionManagement: 'Gestion des postes',
    lowAvailability: `Gestion des disponibilités de l'équipe`,
    recruiterManagement: 'Gestion des recruteurs',
    appointmentCalendarLabel: `Calendrier`,
    configuration: 'Configuration',
    unresponsivePeriod: 'Gestion des candidats qui ne répond pas\n',
    languageEvaluationPhrasesLabel: 'Évaluation de la langue',
    videoGallery: 'Galerie vidéo',
    webHookURL: 'Endpoint du webhook',
    imageGallery: "Galerie d'images",
    templates: 'Modèles',
    fitbandConfiguration: 'Configuration de match des disponibilités',
  },
  calendar: {
    sidebarTitle: 'Recruteurs',
    positionTitle: 'Postes',
    all: 'Tous',
    followed: 'Suivi',
    sidebarRecruiterSearchPlaceholder: 'Rechercher parmis les recruteurs',
    sidebarPositionSearchPlaceholder: 'Rechercher les postes',
    sidebarPositionClearAll: 'Réinitialiser tout',
    toolTipWarningMessage: `Pour voir les entrevues de {{recruiterName}}, veuillez décocher un autre recruteur.`,
    toolTipWarningMessageSnackBar: `Pour voir les entrevues, veuillez décocher un autre recruteur.`,
    backBtnText: 'Retour',
  },
  hireFormAPIKey: {
    label: 'Clés API',
    subDescription: 'Copy API key for hire form',
    copyKeyBtnLabel: 'Copy Key',
    generateNewAPIKey: 'Générer nouvelle clé API',
    copiedLabel: 'Clé API copiée',
    renewedSuccessMessage: 'New API key generated successfully',
    alertModal: {
      title: 'Générer nouvelle clé API',
      description:
        'Ceci va générer une nouvelle clé API. Cette clé API sera utilisée à travers votre intégration avec AppyHere. Souhaitez-vous continuer?',
      confirm: 'Confirmer',
      cancel: 'Annuler',
    },
    alertModalCopy: {
      title:
        "Copier et stocker cette clé API dans un emplacement sécurisé. Vous ne serez plus capable de l'accéder après la fermeture de cette fenêtre.",
      copyBtn: 'Copier clé API',
    },
  },
  modernHire: {
    sidebarTitle: 'Évaluation des compétences',
    form: {
      userName: `Nom d'utilisateur`,
      password: 'Mot de passe',
      modernHireSubDomain: 'Sous-domaine Modern Hire',
      submit: 'Soumettre',
    },
  },
  languageEvaluation: {
    cardTitle: 'Évaluation de la langue ',
    selectLanguage: 'Choisir la langue',
    french: 'Français',
    english: 'Anglais',
    phraseTitle: 'Phrase titre',
    phrase: 'Phrase',
    add: 'Ajouter',
    update: 'Mettre à jour',
    delete: 'Supprimer',
    deletePhraseTitle: 'Supprimer une phrase',
    deletePhraseDetail: 'Cette phrase sera définitivement supprimée. Cette action ne peut être annulée.',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    phraseLabelRequired: "L'étiquette de la phrase est requise",
    phraseDescriptionRequired: 'La phrase est obligatoire',
    template: 'Modèles',
    phrases: 'Phrases',
    templateHeader: "Modèles pour l'évaluation de la langue",
    showDefault: 'Montrer les modèles par défaut',
    addNewTemplate: 'Créer un nouveau modèle',
    create: 'Céer',
    duplicate: 'Dupliquer',
    edit: 'Modifier',
    name: 'Nom',
    created: 'Créé le',
    action: 'Actions',
    noData: 'Aucune donnée trouvée',
    systemDefault: '(Modèle par défaut)',
    showingResult: "Affiche présentement {{currentItems}} d'un total de {{totalItems}} modèles",
    templateModal: {
      createTemplateTitle: "Créer un nouveau modèle d'évaluation de la langue",
      editTemplateTitle: "Modifier le modèle d'évaluation de la langue",
      viewTemplate: "Voir les modèles de l'évaluation de la langue",
      testSettings: 'Je veux essayer ce modèle',
      templateName: 'Nom',
      templateNameError: 'Le nom du modèle est requis.',
      enterTemplateName: 'Entrer le nom du modèle',
      language: 'Langage',
      selectLanguage: 'Sélectionner le langage',
      languageError: 'Le langage est requis.',
      phrases: 'Phrase(s)',
      selectPhrases: 'Sélectionner les phrase(s)',
      note: 'Note:',
    },
    templateToastMessage: {
      nameExists: 'Ce nom de modèle existe déjà.',
      copyTemplateTitle: 'Modèle dupliqué',
      copyTemplateSuccess: 'Un dupliqué de {{templateName}} a été créé.',
      templateCreate: 'Modèle créé',
      templateCreateSuccess: 'Le modèle {{templateName}} a été créé avec succès.',
      templateEdit: 'Modèle modifié',
      templateEditSuccess: 'Le modèle {{templateName}} a été modifié avec succès.',
    },
    recording: {
      selectPhrase: 'Sélectionner une phrase',
      choosePhrase: 'Sélectionner une phrase',
      evaluateRecording: 'Tester votre modèle',
      startRecording: "Commencer l'enregistrement",
      clickRecordButton: "Cliquer le bouton pour débuter l'enregistrement.",
      evaluate: 'Évaluer',
      allowMicrophoneAccess: "Vous devez permettre l'accès à votre microphone pour continuer.",
      reRecord: 'Enregistrer de nouveau',
      audioEvaluationTitle: "Évaluation de l'audio",
      audioEvaluationSuccess: "L'évaluation de l'audio est réussie.",
    },
    band: {
      expert: 'Expert',
      competent: 'Compétent',
      limited: 'Limité',
      occasional: 'Minimal',
      expertTooltipText:
        'L’élocution du candidat est précise en tout temps. Il peut être compris sans effort tout au long de l’enregistrement.',
      competentTooltipText: `Le candidat parle sans hésitations, répétitions, ou corrections significatives. Il peut être compris sans grand effort.`,
      limitedTooltipText: `Le candidat ne peut pas parler sans des pauses visibles. Leur élocution pourrait être lente. Il prend un certain effort pour comprendre le candidat, et certaines sections de l’enregistrement pourraient ne pas être comprises.`,
      occasionalText:
        'L’élocution du candidat est complètement incohérente. Il peut occasionnellement produire des mots ou des phrases qu’ils sont reconnaissables. La majorité de l’enregistrement ne peut pas être comprise.',
    },
    bandFields: {
      accuracy: 'Justesse',
      completeness: 'Complétude',
      confidence: 'Confiance',
      fluency: 'Aisance',
      pronunciation: 'Prononciation',
    },
    evaluation: {
      expertText:
        "Cet enregistrement serait considéré niveau Expert d'après la configuration de votre modèle. Voici votre score détaillé:",
      competentText:
        "Cet enregistrement serait considéré niveau Compétent d'après la configuration de votre modèle. Voici votre score détaillé:",
      limitedText:
        "Cet enregistrement serait considéré niveau Limité d'après la configuration de votre modèle. Voici votre score détaillé:",
      occasionalText:
        "Cet enregistrement serait considéré niveau Minimal d'après la configuration de votre modèle. Voici votre score détaillé:",
      defaultText: 'Voici le score détaillé pour cet enregistrement:',
    },
    noteLabel: {
      accuracy: "Évalue comment la prononciation du parleur s'aligne avec celle d'un locuteur natif.",
      completeness: 'Évalue la proportion du texte que le parleur dit.',
      confidence: "Le niveau de confiance de l'évaluation automatisée.",
      fluency:
        "Évalue comment le parleur utilise des pauses entre les mots, et si cela s'aligne avec l'utilisation des pauses d'un locuteur natif.",
      pronunciation: "Évalue le niveau d'exactitude de prononciation du parleur.",
    },
  },
  fitbandConfiguration: {
    templateHeader: 'Modèles de match des disponibilités',
    showDefaults: 'Montrer les modèles par défaut',
    createNew: 'Créer un nouveau modèle',
    confirmChanges: 'Confirmer les modifications du modèle',
    areYouSureText: `Êtes-vous sûr de vouloir enregistrer vos modifications? Ces modifications seront appliquées à tous les postes utilisant ce modèle.`,
    cancelBtn: 'Annuler',
    okBtn: 'OK',
    duplicate: 'Dupliquer',
    edit: 'Modifier',
    saveBtn: 'Je comprends',
    systemDefault: '(Modèle par défaut)',
    noChanges: `Pas encore changé`,
    noDataFound: 'Aucune donnée trouvée',
    showingResult: "Affiche présentement {{currentItems}} d'un total de {{totalItems}} modèles",
    tableHeaders: {
      name: 'Nom',
      createdOn: 'Créé le',
      lastEdited: 'Dernière modification',
      action: 'Actions',
    },
    fitbandModal: {
      viewFitband: 'Modèle de match des disponibilités',
      editFitband: 'Modifier le modèle de match des disponibilités',
      createFitband: 'Créer un modèle de match des disponibilités',
      templateName: 'Nom du modèle',
      enterTemplateName: 'Saisir le nom du modèle',
      templateNameError: 'Le nom du modèle est requis.',
      goodFit: 'Bon',
      mediumFit: 'Moyen',
      mediumFitError: 'Le score moyen doit être inférieure à le bon score.',
      penalty: 'Ajustements des pénalités',
      requisition: 'Disponibilité manquante',
      extraAvailability: 'Disponibilité supplémentaire',
      days: 'Jours',
      hours: 'Heures',
      updateBtn: 'Mettre à jour',
      createBtn: 'Créer',
    },
    fitbandToastMessage: {
      nameExists: 'Ce nom de modèle existe déjà.',
      copyTemplateTitle: 'Modèle dupliqué',
      copyTemplateSuccess: 'Un duplicata de {{templateName}} a été créé.',
      templateCreate: 'Modèle créé',
      templateCreateSuccess: '{{templateName}} a été créé avec succès.',
      templateEdit: 'Modèle modifié',
      templateEditSuccess: '{{templateName}} a été modifié avec succès.',
    },
    penaltyTooltipText:
      'Vous pouvez ajuster ces valeurs pour déterminer la rigueur avec laquelle les candidats sont évalués en fonction de vos besoins de disponibilité de travail.',
    requisitionText: "Disponibilité manquante : Pénalise les candidats qui n'ont pas toute la disponibilité requise.",
    extraAvailabilityText:
      'Disponibilité supplémentaire : Pénalise les candidats qui offrent plus de disponibilité que nécessaire.',
  },
  webHookUrl: {
    title: 'Endpoint du webhook pour les candidats embauchés',
    subtitle:
      "Vous pouvez définir l'URL du endpoint pour le API des candidats embauchés ici. Ce endpoint sera où que nous allons envoyer les renseignements d'un candidat lorsqu'il est embauché.",
    inputPlaceHolder: "Entrer l'URL du endpoint",
    successMsg: "L'URL du webhook a été mise à jour correctement.",
    errorMsg: "L'URL du webhook ne pouvait pas être mise à jour.",
    validUrl: 'Seulement les formats de URL valides sont acceptés..',
  },
  imageGallery: {
    loading: 'Attendez quelques instants...',
    noImages: 'Aucune image trouvée',
    uploadError: "Le téléchargement de l'image a échoué.",
    imagePreviewTitle: "Apercu d'image",
  },
  imageGalleryModal: {
    triggerButtonLabel: 'Ajouter une image',
    title: "Galerie d'images",
    subtitle: 'Ajouter et gérer les images de cette organisation.',
    refreshButtonToolTip: 'Rafraîchir la galerie',
    uploadButtonLabel: 'Ajouter une image',
    uploadPreviewCancelButtonLabel: 'Annuler',
    uploadPreviewSaveButtonLabel: 'Sauvegarder',
    loadFailedToastTitle: "Incapable d'accéder la galerie.",
    loadFailedToastMessage: 'Une erreur est survenue. Veuillez essayer de nouveau.',
    invalidImageType: "Type d'image invalide. Seulement les PNG ou les JPG sont acceptés.",
    invalidImageSize: "Taille d'image invalide. Seulement les images de taille inférieure ou égale à 2MB sont acceptées.",
    sortAscending: 'Tri ascendant',
    sortDescending: 'Tri descendant',
    searchInputPlaceholder: 'Rechercher',
    errorLoadingImages: "Erreur de téléchargement de l'image",
  },
  companyResources: {
    companyLogo: "Logo de l'entreprise",
    documentSettings: "Paramètres du formulaire d'embauche",
    disclaimerText: 'Clause de non-responsabilité',
    disclaimerSubText:
      "Ceci est un modèle de clause de non-responsabilité standard affiché avant que le candidat commence leur formulaire d'embauche. Vous pouvez le modifier pour vos besoins. Assurez-vous que tous les détails légaux et de conformité sont inclus pour maintenir la transparence avec vos candidats.",
    cancel: 'Annuler',
    update: 'Mettre à jour',
    preview: 'Aperçu',
    devicePreview: "Aperçu de l'appareil",
    successMessage: 'Paramètres mis à jour',
    errorMessage: 'Echec du mise à jour des paramètres',
    emptyDisclaimerMessage:
      "La clause de non-responsabilité ne peut pas être vide. Assurez-vous qu'une clause de non-responsabilité valide est fournie avant de continuer.",
    emptyConsentMessage:
      "Le texte de consentement ne peut pas être vide. Assurez-vous qu'un texte de consentement valide est fourni avant de continuer.",
    editorPlaceholderText: 'Ajoutez des liens et votre texte de non-responsabilité ici.',
    consentButtonPlaceholder: 'Texte de confirmation',
  },
};
