export default {
  VIDEO: 'vidéo',
  PHONE: 'téléphone',
  INPERSON: 'en personne',
  APPOINTMENT_ASSESSMENT_POSITIVE: 'Évaluation positive',
  APPOINTMENT_ASSESSMENT_NEGATIVE: 'Évaluation négative',
  SEEKER_APPOINTMENT_REMINDER_SENT: 'Rappel d’entrevue envoyé au candidat',
  CANDIDATE_BOOKMARKED: 'Sauvegardé',
  CANDIDATE_CALLED: 'Appeler',
  CANDIDATE_DISMISSED: 'Rejeté',
  CANDIDATE_HIRED: 'Embauché',
  OFFER_MADE: 'Offre faite',
  OFFER_REJECTED: 'Offre révoquée',
  CANDIDATE_UNHIRED: 'Candidat non embauché',
  CANDIDATE_UNLOCKED: 'Interaction initiée',
  CONTACT_REQUEST_SENT: "Demande d'intérêt envoyée",
  CONTACT_REQUEST_ACCEPTED: 'Le candidat a confirmé son intérêt',
  CONTACT_REQUEST_DECLINED: 'Demande contact refusé',
  POSITION_LIKED: 'Appliqué',
  POSITION_UNLIKED: "Le candidat n'aime plus le poste",
  QUESTION_ANSWERED: 'Réponse à une question',
  INTEREST_REVOKED: `Pas intéressé`,
  SAVED_FROM_AUTO_DISMISSAL: 'Préservé du rejet',
  APPOINTMENT_PERIOD_CANCELLED: 'Plage horaire annulée',
  INVITED_FOR_SCREENING: 'Invitation à la vérification des antécédents',
  SCREENING_COMPLETED: 'Vérification des antécédents terminée',
  VIEW_CHECK_HISTORY_ONLY: "Visualiser uniquement l'historique de la vérification des antécédents",
  NO_BACKGROUND_HISTORY: "Aucun historique de vérification d'antécédents",
  UNKNOWN: 'Action inconnue',
  CALL: 'Appel',
  VIDEO_INTERVIEW_JOIN_FAILED: "Difficulté à se connecter à l'entrevue vidéo",
  VIDEO_INTERVIEW_JOINED: "Joint à l'entrevue vidéo",
  FREE_FORM_QUESTION_ANSWERED: 'Question de forme libre répondue',
  APPOINTMENT_REQUEST_ACCEPTED: 'Demande d’entrevue acceptée',
  APPOINTMENT_REQUEST_CANCELLED: 'Demande d’entrevue annulée',
  APPOINTMENT_REQUEST_CREATED: 'Demande d’entrevue créée',
  OFFER_ACCEPETED_BY_SEEKER: 'Offre acceptée',
  OFFER_REJECTED_BY_SEEKER: 'Offre rejetée',
  View_free_form_answers: 'Voir les réponses des questions libres',
  not_answered: 'Pas répondu',
  answered: 'Répondu',
  yes: 'OUI',
  no: 'NON',
  automation: 'Automatisation',
  HIRE_FORM_REQUESTED: "Formulaire d'embauche demandé",
  HIRE_FORM_COMPLETED: "Formulaire d'embauche rempli",
  HIRE_FORM_RESUBMISSION_REQUESTED: "Demande de resoumission du formulaire d'embauche",
  CANDIDATE_FORM_HELP_REQUESTED: "Aide demandée pour le formulaire d'embauche",
  backgroundcheck: 'Vérification des antécédents',
  hireForm: 'Documents',
  appointment: 'Entrevue',
  offer: "Offre d'emploi",
  candidate: 'Candidat',
  all: 'Tous',
  no_hire_form_history: 'No Documents History',
  no_appointement_history: "Aucun historique d'entrevue",
  no_offer_history: "Aucun historique d'offre d'emploi",
  no_candidate_history: 'Aucun historique de candidat',
  no_history: 'Aucun historique',
  MODERN_HIRE_ASSESSMENT_STARTED: 'Demandé',
  MODERN_HIRE_ASSESSMENT_IN_PROGRESS: 'En cours',
  MODERN_HIRE_ASSESSMENT_PASSED: 'Réussie',
  MODERN_HIRE_ASSESSMENT_FAILED: 'Échouée',
  modernHire: "Statut de l'évaluation des compétences",
  ASSESSMENT_STARTED: 'Demandé',
  CANDIDATE_APPROVED: 'Formulaire d’embauche approuvé',
  CANDIDATE_REJECTED: 'Formulaire d’embauche rejeté',
  languageEvaluation: 'Évaluation de la langue',
  CANDIDATE_RECORDING_UPLOADED: 'Langue audio disponible',
  CANDIDATE_LANGUAGE_EVALUATION_EXPERT: 'Évaluation de la langue complétée - Expert',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_EXPERT: 'Évaluation de la langue complétée - Expert',
  CANDIDATE_LANGUAGE_EVALUATION_COMPETENT: 'Évaluation de la langue complétée - Compétent',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_COMPETENT: 'Évaluation de la langue complétée - Compétent',
  CANDIDATE_LANGUAGE_EVALUATION_LIMITED: 'Évaluation de la langue complétée - Limité',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_LIMITED: 'Évaluation de la langue complétée - Limité',
  CANDIDATE_LANGUAGE_EVALUATION_OCCASIONAL: 'Évaluation de la langue complétée - Minimale',
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_OCCASIONAL: 'Évaluation de la langue complétée - Minimale',
  CANDIDATE_LANGUAGE_EVALUATION_NEEDS_REVIEW: "Évaluation de la langue complétée - Besoin d'entrevue",
  CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_NEEDS_REVIEW: "Évaluation de la langue complétée - Besoin d'entrevue",
  APPOINTMENT_REQUEST_DECLINED: 'Demande d’entrevue $t({{ appointmentType }}) refusée',
  OFFER_REVOKE: 'Offre retirée',
  close: `Fermer`,
  hireDetailsResubmissionRequestTitle: "Demande de resoumission pour formulaire d'embauche",
  hireDetailsResubmissionRequestSubTitle: 'Le candidat a été demandé de resoumettre les renseignements suivants:',
  view: 'Voir détails',
  CANDIDATE_LANGUAGE_EVALUATION_REQUESTED: 'Évaluation de la langue demandée',
  HIRE_FORM_IN_PROGRESS: `Formulaire d'embauche débuté`,
  HIRE_FORM_RESUBMISSION_IN_PROGRESS: `Resoumission du formulaire d'embauche débuté`,
  CANDIDATE_APPOINTMENT_EXPIRED: 'Demande d’entrevue expirée',
  CANDIDATE_LANGUAGE_EVALUATION_RE_REQUESTED: 'Évaluation de la langue redemandée',
  coInterviewerCandidateAssessment: `L'évaluation du candidat par le co-intervieweur`,
  COLLABORATOR_INTERVIEW_REVIEW: `L'évaluation du co-intervieweur`,
  viewReview: `Voir l'évaluation`,
  availability: 'Disponibilité',
  compact: 'Compact',
  detailed: 'Détaillé',
  expectedWorkHours: 'Heures maximum du candidat',
  NOT_LOOKING_FOR_WORK: "Ne cherche pas d'emploi",
  DEACTIVATED: 'Compte désactivé',
  OFFBOARDED: `Cessation d'emploi`,
  ONDEMAND_INTERVIEW_CREATED: 'Entrevue sur demande planifié',
  ONDEMAND_INTERVIEW_CANCELLED: 'Entrevue sur demande annulé',
  ONDEMAND_INTERVIEW_ACCEPTED: 'Entrevue sur demande accepté',
};
