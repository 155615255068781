import React from 'react';
import { Flex, Text, IconButton } from '@chakra-ui/core';
import { MdFirstPage, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineLastPage } from 'react-icons/md';
import colors from '../../styles/colors';

export type SearchPaginateProps = {
  dataCount: number;
  totalCount: number;
  handleFirstPage?: () => void;
  handleNextPage?: () => void;
  handlePreviousPage?: () => void;
  handleLastPage?: () => void;
  currentPage: number;
  isFetching?: boolean;
  showingResultsTranslation?: any;
};

export const SearchPagination = ({
  dataCount,
  totalCount,
  handleFirstPage,
  handleNextPage,
  handlePreviousPage,
  handleLastPage,
  currentPage,
  isFetching,
  showingResultsTranslation,
}: SearchPaginateProps): JSX.Element => {
  return (
    <>
      <Flex alignItems="center" w="100%" p={4} background={colors.white}>
        <Flex alignItems="center" w="100%" justifyContent="space-between">
          <Flex alignItems="center">
            <Text mr={3} data-testid="pagination-text">
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
              {showingResultsTranslation({
                currentItems: dataCount,
                totalItems: totalCount,
              })}
            </Text>
          </Flex>
          <Flex alignItems="center">
            <IconButton
              onClick={handleFirstPage}
              mr={3}
              isDisabled={currentPage <= 1}
              variant="outline"
              colorScheme="blue"
              aria-label="First"
              borderRadius="8px"
              h="28px"
              w="28px"
              minW="28px"
              borderColor="#718096"
              icon={<MdFirstPage />}
              data-testid="first-page-button"
            />
            <IconButton
              onClick={handlePreviousPage}
              mr={3}
              isDisabled={currentPage <= 1 || isFetching}
              variant="outline"
              colorScheme="blue"
              aria-label="Prev"
              borderRadius="8px"
              h="28px"
              w="28px"
              minW="28px"
              borderColor="#718096"
              icon={<MdKeyboardArrowLeft />}
              data-testid="prevPage-button"
            />
            <IconButton
              onClick={handleNextPage}
              mr={3}
              isDisabled={dataCount >= totalCount || isFetching}
              variant="outline"
              colorScheme="blue"
              aria-label="Next"
              borderRadius="8px"
              h="28px"
              w="28px"
              minW="28px"
              borderColor="#718096"
              icon={<MdKeyboardArrowRight />}
              data-testid="nextPage-button"
            />
            <IconButton
              onClick={handleLastPage}
              isDisabled={currentPage >= Math.ceil(totalCount / 10) || isFetching}
              variant="outline"
              colorScheme="blue"
              aria-label="Last"
              borderRadius="8px"
              h="28px"
              w="28px"
              minW="28px"
              borderColor="#718096"
              icon={<MdOutlineLastPage />}
              data-testid="last-page-button"
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
