import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { HStack, Stack } from '@chakra-ui/core';
import ButtonPreview from './ButtonPreview';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';
import { useTranslation } from 'react-i18next';

export default function ImageSchemaBuilder(props: SchemaBuilderProps) {
  const { t } = useTranslation('hrFormTemplate');

  return (
    <Stack>
      <HStack justify="space-between">
        <LabelSchemaBuilder {...props} />
        {props?.labelRightRenderer}
      </HStack>
      <FormElementTextBuilder {...props} placeholder={t('formBuilder.editorPlaceholder.image')} />
      <HStack>
        <ButtonPreview label={t('formBuilder.chooseAFile')} />
        <ButtonPreview label={t('formBuilder.openCamera')} />
      </HStack>
    </Stack>
  );
}
