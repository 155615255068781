import { Box, Checkbox, FormControl, FormErrorMessage, Grid, GridItem, Select, Spinner, Text } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoChevronDown } from 'react-icons/io5';
import axios, { AxiosError } from 'axios';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { HrFromTemplate } from '../../hireForm/formElements/formBuilderSchema';
import colors from '../../../../styles/colors';
import { clearErrorMessage, FormattedError, getErrorMessage, hasErrorMessage } from '../../../../utils/FormErrorUtils';

export const JobPositionActionEnableContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid alignItems="center" templateColumns="repeat(3, 1fr)" gap={4} autoRows="3rem">
      {children}
    </Grid>
  );
};

interface HireFormTemplateSelectProps {
  enableHireFormSolution: boolean;
  onHireFormSolutionChange: (value: boolean, fieldName: string, dataType: boolean) => void;
  hireFormTemplateId: string | null;
  onHireFormTemplateChange: (value: string | null, fieldName: string, dataType: boolean) => void;
  formErrors: FormattedError[];
  setFormErrors: (errors: FormattedError[]) => void;
}

function JobPositionHireForm({
  formErrors,
  setFormErrors,
  enableHireFormSolution,
  onHireFormSolutionChange,
  ...rest
}: HireFormTemplateSelectProps) {
  const { t } = useTranslation();
  const chekboxRef = React.useRef<HTMLInputElement>(null);
  const isHireFormTemplateEnabled = useStoreState((s) => s.app.accounts?.configuration?.hireFormTemplateEnabled);

  const [showTemplateSelect, setShowTemplateSelect] = React.useState(false);

  React.useEffect(
    () => {
      setShowTemplateSelect(enableHireFormSolution);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enableHireFormSolution],
  );

  React.useEffect(() => {
    onHireFormSolutionChange(showTemplateSelect, 'enableHireFormSolution', false);
    setFormErrors(clearErrorMessage(formErrors, 'enableHireFormSolution'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTemplateSelect]);

  const hasError = hasErrorMessage(formErrors, 'enableHireFormSolution');

  React.useEffect(() => {
    if (hasError && chekboxRef.current) {
      chekboxRef.current.focus();
      chekboxRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  return (
    <JobPositionActionEnableContainer>
      <GridItem>
        <FormControl isInvalid={hasError}>
          <Checkbox
            ref={chekboxRef}
            defaultChecked={showTemplateSelect}
            isChecked={showTemplateSelect}
            onChange={(e) => setShowTemplateSelect(e.target.checked)}
          >
            {t('createJobPosition:hireForm')}
          </Checkbox>
          <FormErrorMessage>{getErrorMessage(formErrors, 'enableHireFormSolution')}</FormErrorMessage>
        </FormControl>
      </GridItem>
      {isHireFormTemplateEnabled && showTemplateSelect && (
        <GridItem>
          <TemplateSelect {...rest} formErrors={formErrors} setFormErrors={setFormErrors} />
        </GridItem>
      )}
    </JobPositionActionEnableContainer>
  );
}
// eslint-disable-next-line react/display-name
export const TemplateSelect = React.memo(
  ({
    hireFormTemplateId,
    onHireFormTemplateChange,
    formErrors,
    setFormErrors,
  }: Omit<HireFormTemplateSelectProps, 'enableHireFormSolution' | 'onHireFormSolutionChange'>) => {
    const { t } = useTranslation('createJobPosition');
    const { t: tHrForm } = useTranslation('hrFormTemplate');
    const selectRef = React.useRef<HTMLSelectElement>(null);
    const { getTemplates } = useStoreActions((state) => state.hrFormTemplate);

    const { currentUserProfile } = useCurrentUserProfile();
    const accountId = currentUserProfile?.account ?? '';

    const [isLoading, setIsLoading] = React.useState(false);
    const [templates, setTemplates] = React.useState<HrFromTemplate[]>([]);

    const [selectedTemplate, setSelectedTemplate] = React.useState<HrFromTemplate | null>(null);

    React.useEffect(() => {
      if (hireFormTemplateId && !selectedTemplate) {
        return;
      }
      onHireFormTemplateChange(selectedTemplate?.id ?? null, 'hireFormTemplateId', false);
      setFormErrors(clearErrorMessage(formErrors, 'hireFormTemplateId'));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTemplate]);

    const handleGetTemplates = React.useCallback(() => {
      setIsLoading(true);
      getTemplates({ account: accountId })
        .then((res) => {
          setTemplates(res.templates);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          return setIsLoading(false);
        });
    }, [accountId, getTemplates]);

    React.useEffect(() => {
      handleGetTemplates();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(
      () => {
        if (templates.length === 0) {
          return;
        }

        setSelectedTemplate(templates.find((template) => template.id === hireFormTemplateId) ?? null);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [hireFormTemplateId, templates],
    );

    const hasError = hasErrorMessage(formErrors, 'hireFormTemplateId');

    React.useEffect(() => {
      if (hasError && selectRef.current) {
        selectRef.current.focus();
        selectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formErrors]);

    return (
      <FormControl isInvalid={hasError}>
        <Select
          className="filled"
          id="jobPositionHireFormTemplateSelect"
          value={selectedTemplate?.id}
          onChange={(e) => {
            setSelectedTemplate(templates.find((template) => template.id === e.target.value) ?? null);
          }}
          data-testid="SelectHireFormTemplate"
          isLoading={isLoading}
          isDisabled={isLoading}
          icon={isLoading ? <Spinner size="sm" /> : <IoChevronDown fontSize="sm" color={colors.black} />}
          _placeholder={{ color: colors.gray[400] }}
          ref={selectRef}
        >
          <option disabled selected>
            {/* {t('hireFormSelect.selectPlaceholder')}  */}
            {t('hireFormSelect.dollaramaDefault')}
          </option>
          {templates.map((template) => (
            <option key={template.id} value={template.id}>
              {template.name}
              {template?.type === 'default' && ` (${tHrForm('formBuilder.systemDefault')})`}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{getErrorMessage(formErrors, 'hireFormTemplateId')}</FormErrorMessage>
      </FormControl>
    );
  },
);

// eslint-disable-next-line react/display-name
const HireFormTemplateSelect = React.memo((props: HireFormTemplateSelectProps) => {
  const hireFormEnabled = useStoreState((state) => state.app.accounts?.configuration?.hireFormEnabled);
  if (!hireFormEnabled) return null;
  return (
    <Box mb={6} data-testid="job-position-hire-form-enabled">
      <JobPositionHireForm {...props} />
    </Box>
  );
});

interface GetSeletedHireFormTemplateProps {
  templateId: string | null;
}

export function GetSeletedHireFormTemplate({ templateId }: GetSeletedHireFormTemplateProps) {
  const { templates } = useStoreState((state) => state.hrFormTemplate);

  const selectedHireFormTemplate = templates?.templates?.find((template) => template.id === templateId) ?? null;
  if (!selectedHireFormTemplate) return null;
  return (
    <Text fontSize="sm" data-testid="preview-hire-form-template-name">
      {selectedHireFormTemplate.name}
    </Text>
  );
}

interface HandleHireFormErrorsProps {
  error: any;
}
export function handleHireFormErrors({ error }: HandleHireFormErrorsProps): FormattedError[] {
  const newError = error as AxiosError;
  if (axios.isAxiosError(newError) && newError.response?.status === 400) {
    const hireFormError = error as AxiosError<{
      title: string;
      errors: {
        hireFormTemplateId: string;
      };
    }>;
    if (hireFormError?.response?.data?.errors?.hireFormTemplateId) {
      return [
        {
          label: 'hireFormTemplateId',
          message: hireFormError?.response?.data?.title,
        },
      ];
    }
  }
  return [];
}

export default HireFormTemplateSelect;
