/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  Center,
  Stack,
  InputGroup,
  InputLeftElement,
  Input,
  FormControl,
  FormLabel,
  Checkbox,
  Image,
  MenuButton,
  Portal,
  MenuList,
  Button,
  Menu,
} from '@chakra-ui/core';
import { Column } from 'react-table';
import { MdPersonSearch } from 'react-icons/md';
import { SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { Header } from '../../app/topBar/Header';
import colors from '../../styles/colors';
import CandidateSearchTableView from './CandidateSearchTableView';
import { CandidateSearchResponseType, CandidateStatus } from './CandidateSearchTypes';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { SearchPagination } from './SearchPagination';

export const CandidateSearchView = (): JSX.Element => {
  const { t } = useTranslation('candidateSearch');
  const accountId = useStoreState((state) => state.app.accounts?.id);
  const isHireFormEnabled = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabled);
  const isRequisitionEnabled = useStoreState((s) => s.app.accounts?.configuration?.requisitionEnabled);

  const [candidateName, setCandidateName] = useState<string | undefined>('');
  const [phone, setPhone] = useState<string | undefined>('');
  const [email, setEmail] = useState<string | undefined>('');
  const [hireId, setHireId] = useState<string | undefined>('');
  const [requisitionId, setRequisitionId] = useState<string | undefined>('');
  const [locationId, setLocationId] = useState<string | undefined>('');
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const [candidatesList, setCandidatesList] = useState<CandidateSearchResponseType[]>([]);
  const [hasData, setHasData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentCount, setCurrentCount] = useState<number>(0);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);

  const { searchCandidates } = useStoreActions((actions) => actions.candidateSearch);

  const searchHeaderFromTranslation = {
    name: t('tableHeader.candidateName'),
    location: t('tableHeader.location'),
    phone: t('tableHeader.phone'),
    status: t('tableHeader.status'),
    lastUpdate: t('tableHeader.lastUpdate'),
    hireID: t('tableHeader.hireId'),
  };

  const searchListTableColumns: Column[] = [
    {
      Header: searchHeaderFromTranslation.name,
      accessor: 'fullName',
    },
    {
      Header: searchHeaderFromTranslation.location,
      accessor: 'location',
    },
    {
      Header: searchHeaderFromTranslation.phone,
      accessor: 'phone',
    },
    {
      Header: searchHeaderFromTranslation.status,
      accessor: 'status',
    },
    {
      Header: searchHeaderFromTranslation.lastUpdate,
      accessor: 'lastUpdatedAt',
    },
    ...(isHireFormEnabled
      ? [
          {
            Header: searchHeaderFromTranslation.hireID,
            accessor: 'seekerHireDetailId',
          },
        ]
      : []),
  ];

  const hasSearchCriteria =
    candidateName || phone || email || hireId || locationId || requisitionId || selectedStatuses.length > 0;

  // function to get search candidates
  const loadSearchCandidates = async (page = 1) => {
    // istanbul ignore next
    if (!hasSearchCriteria) {
      setHasData(false);
    }

    setIsFetching(true);

    const searchPayload = {
      page,
      limit: 10,
      location: !_isEmpty(locationId) ? /* istanbul ignore next */ locationId : null,
      fullName: !_isEmpty(candidateName) ? candidateName : null,
      email: !_isEmpty(email) ? email : null,
      phone: !_isEmpty(phone) ? phone : null,
      positionName: null,
      requisitionId: !_isEmpty(requisitionId) ? requisitionId : null,
      seekerHireDetailId: !_isEmpty(hireId) ? hireId : null,
      seekerId: null,
      status: !_isEmpty(selectedStatuses) ? /* istanbul ignore next */ selectedStatuses : [],
    };

    if (accountId && hasSearchCriteria) {
      setIsLoading(true);
      try {
        const response = await searchCandidates({ accountId, searchData: searchPayload });
        setCandidatesList(response?.data);
        setCurrentPage(response?.page);
        setCurrentCount((page - 1) * 10 + response?.data.length);
        setTotalDataCount(response?.total);
        setHasData(true);
      } finally {
        setIsFetching(false);
        setIsLoading(false);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCandidateSearch = useCallback(
    debounce(() => {
      loadSearchCandidates();
    }, 300),
    [candidateName, phone, email, hireId, locationId, requisitionId],
  );

  // istanbul ignore next
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchCandidate = useCallback(
    debounce(() => {
      loadSearchCandidates();
    }, 300),
    [selectedStatuses],
  );

  useEffect(() => {
    debouncedSearchCandidate();

    return () => {
      debouncedSearchCandidate.cancel();
    };
  }, [debouncedSearchCandidate]);

  // istanbul ignore next
  const handleCandidateStatusSelection = (status: CandidateStatus) => {
    setSelectedStatuses((prevSelected) => {
      if (prevSelected.includes(status)) {
        return prevSelected.filter((s) => s !== status);
      }
      return [...prevSelected, status];
    });
  };

  // istanbul ignore next
  const handleNextPage = async () => {
    if (currentCount < totalDataCount && !isFetching) {
      await loadSearchCandidates(currentPage + 1);
    }
  };

  // istanbul ignore next
  const handlePreviousPage = async () => {
    if (currentPage > 1 && !isFetching) {
      await loadSearchCandidates(currentPage - 1);
    }
  };

  // istanbul ignore next
  const handleFirstPage = async () => {
    if (currentPage > 1 && !isFetching) {
      await loadSearchCandidates(1);
    }
  };

  // istanbul ignore next
  const handleLastPage = async () => {
    const lastPage = Math.ceil(totalDataCount / 10);
    if (currentPage !== lastPage && !isFetching) {
      await loadSearchCandidates(lastPage);
    }
  };

  return (
    <Box height="100vh" overflow="hidden">
      <Header showMenu showPageTitle={t('searchTitle')} />
      <Box mt={3} p={5} height="calc(100vh - 80px)" display="flex" flexDirection="column">
        <Box position="sticky" top={0} bg="white" zIndex={10} pb={4}>
          <Flex justifyContent="space-between" flexWrap="wrap" gap={4} width="100%">
            <InputGroup flex={{ base: '1 1 100%', md: '1 1 48%', lg: '1 1 28%' }} mb={2} mr={2}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color={colors.gray[300]} />
              </InputLeftElement>
              <Input
                data-testid="searchNameInput"
                type="text"
                size="md"
                placeholder={t('name')}
                maxLength={100}
                value={candidateName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const trimmedName = e.target.value
                    .replace(/[^a-zA-Z0-9\s.]/g, '')
                    .replace(/\s+/g, ' ')
                    .trimStart();

                  setCandidateName(trimmedName);
                }}
                style={{ borderRadius: '40px' }}
                backgroundColor={colors.input.background}
                _placeholder={{ color: colors.gray[400], fontSize: '14px' }}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleCandidateSearch();
                  }
                }}
              />
            </InputGroup>

            <InputGroup flex={{ base: '1 1 100%', md: '1 1 48%', lg: '1 1 23%' }} mb={2} mr={2}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color={colors.gray[300]} />
              </InputLeftElement>
              <Input
                data-testid="searchPhoneInput"
                type="tel"
                size="md"
                placeholder={t('tableHeader.phone')}
                maxLength={12}
                value={phone}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, '');
                  setPhone(numericValue);
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (!/[0-9]/.test(e.key) && e.key !== 'Enter') {
                    e.preventDefault();
                  }
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  if (e.key === 'Enter') handleCandidateSearch();
                }}
                style={{ borderRadius: '40px' }}
                backgroundColor={colors.input.background}
                _placeholder={{ color: colors.gray[400], fontSize: '14px' }}
              />
            </InputGroup>

            <InputGroup flex={{ base: '1 1 100%', md: '1 1 48%', lg: '1 1 23%' }} mb={2} mr={2}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color={colors.gray[300]} />
              </InputLeftElement>
              <Input
                data-testid="searchEmailInput"
                type="text"
                placeholder={t('email')}
                maxLength={100}
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const trimmedEmail = e.target.value
                    .replace(/[^a-zA-Z0-9\s.@]/g, '')
                    .replace(/\s+/g, ' ')
                    .trimStart();

                  setEmail(trimmedEmail);
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleCandidateSearch();
                  }
                }}
                style={{ borderRadius: '40px' }}
                backgroundColor={colors.input.background}
                _placeholder={{ color: colors.gray[400], fontSize: '14px' }}
              />
            </InputGroup>

            {isHireFormEnabled && (
              <InputGroup flex={{ base: '1 1 100%', md: '1 1 48%', lg: '1 1 23%' }} mb={2} mr={2}>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color={colors.gray[300]} />
                </InputLeftElement>
                <Input
                  data-testid="searchHireIDInput"
                  type="text"
                  placeholder={t('enterHireId')}
                  maxLength={100}
                  value={hireId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHireId(e.target.value)}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      handleCandidateSearch();
                    }
                  }}
                  style={{ borderRadius: '40px' }}
                  backgroundColor={colors.input.background}
                  _placeholder={{ color: colors.gray[400], fontSize: '14px' }}
                />
              </InputGroup>
            )}
          </Flex>

          <Flex mt={5} justifyContent="space-between" flexWrap="wrap" gap={4} width="100%">
            <FormControl flex={{ base: '1 1 100%', md: '1 1 48%', lg: '1 1 32%' }} mr={2} mb={2}>
              <FormLabel fontSize="14px" fontWeight={400}>
                {t('tableHeader.status')}
              </FormLabel>

              <Menu closeOnSelect={false}>
                <MenuButton
                  variant="outline"
                  as={Button}
                  pr={2}
                  width="100%"
                  borderRadius="6px"
                  backgroundColor="gray.150"
                  padding="0.4rem 1rem"
                  display="flex"
                  fontSize="14px"
                  fontWeight="normal"
                  justifyContent="space-between"
                  textAlign="left"
                  style={{ height: '40px' }}
                  _focus={{ outline: 'none' }}
                  rightIcon={<ChevronDownIcon style={{ height: '20px', width: '20px' }} />}
                >
                  {selectedStatuses.length > 0
                    ? /* istanbul ignore next */ t('statusSelected', { statusCount: selectedStatuses.length })
                    : t('selectStatus')}
                </MenuButton>
                <Portal>
                  <MenuList minWidth="240px" borderColor="gray.50" maxHeight="300px" overflowY="auto">
                    <Box>
                      <Stack p={2} spacing={3} direction="column">
                        {Object.values(CandidateStatus).map((status) => {
                          return (
                            <Checkbox
                              key={status}
                              isChecked={selectedStatuses.includes(status)}
                              onChange={/* istanbul ignore next */ () => handleCandidateStatusSelection(status)}
                              colorScheme="blue"
                              px={3}
                              fontSize="14px"
                              fontWeight={400}
                              data-testid={`candidateStatus-${status}`}
                            >
                              {t(`candidateStatus.${status}`)}
                            </Checkbox>
                          );
                        })}
                      </Stack>
                    </Box>
                  </MenuList>
                </Portal>
              </Menu>
            </FormControl>

            <FormControl flex={{ base: '1 1 100%', md: '1 1 48%', lg: '1 1 32%' }} mr={2} mb={2}>
              <FormLabel fontSize="14px" fontWeight={400}>
                {t('tableHeader.location')}
              </FormLabel>
              <Input
                data-testid="searchLocationInput"
                placeholder={t('enterLocationId')}
                fontSize="14px"
                value={locationId}
                maxLength={100}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLocationId(e.target.value)}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleCandidateSearch();
                  }
                }}
                backgroundColor={colors.input.background}
                _placeholder={{ color: colors.gray[400] }}
              />
            </FormControl>

            {isRequisitionEnabled && (
              <FormControl flex={{ base: '1 1 100%', md: '1 1 48%', lg: '1 1 32%' }} mb={2} mr={2}>
                <FormLabel fontSize="14px" fontWeight={400}>
                  {t('requisitionId')}
                </FormLabel>
                <Input
                  data-testid="searchRequisition"
                  placeholder={t('enterRequisition')}
                  fontSize="14px"
                  value={requisitionId}
                  maxLength={100}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRequisitionId(e.target.value)}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      handleCandidateSearch();
                    }
                  }}
                  backgroundColor={colors.input.background}
                  _placeholder={{ color: colors.gray[400] }}
                />
              </FormControl>
            )}
          </Flex>
        </Box>

        <Box flex="1" overflowY="auto">
          {/* eslint-disable-next-line no-nested-ternary */}
          {!hasData ? (
            <Box position="relative" mt={20}>
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                py={5}
                width="370px"
                mx="auto"
              >
                <Center>
                  <MdPersonSearch size={100} color={colors.gray[400]} />
                </Center>
                <Center>
                  <Text fontSize="24px" fontWeight={700}>
                    {t('exploreProfiles')}
                  </Text>
                </Center>
                <Box mt={2}>
                  <Text fontSize="15px" fontWeight={400} color={colors.gray[300]}>
                    {t('enterDetailsText')}
                  </Text>
                </Box>
              </Flex>
            </Box>
          ) : /* istanbul ignore next */ candidatesList.length > 0 ? (
            <Box mt={5}>
              <CandidateSearchTableView columns={searchListTableColumns} data={candidatesList} isLoading={isLoading} />

              <SearchPagination
                currentPage={currentPage}
                dataCount={currentCount}
                totalCount={totalDataCount}
                handleFirstPage={handleFirstPage}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
                handleLastPage={handleLastPage}
                isFetching={isFetching}
                showingResultsTranslation={/* istanbul ignore next */ (variable: string) => t('showingPageResults', variable)}
              />
            </Box>
          ) : (
            // istanbul ignore next
            <Box position="relative" mt={20}>
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                py={5}
                width="370px"
                mx="auto"
              >
                <Center>
                  <Image src="/seaching_icon.png" alt="No search Icon" />
                </Center>
                <Center>
                  <Text fontSize="24px" fontWeight={700} data-testid="noMatchFound">
                    {t('noMatchFound')}
                  </Text>
                </Center>
                <Box mt={2}>
                  <Text fontSize="15px" fontWeight={400} color={colors.gray[300]} data-testid="noMatchFoundText">
                    {t('noMatchFoundText')}
                  </Text>
                </Box>
              </Flex>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
