/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  Flex,
  Checkbox,
  Box,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  FormErrorMessage,
  Tooltip,
  Portal,
} from '@chakra-ui/core';
import { MdOutlineInfo } from 'react-icons/md';
import { ChevronDownIcon } from '@chakra-ui/icons';
import axios, { AxiosError } from 'axios';
import Joi from 'joi';
import _some from 'lodash/some';
import _isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import isNaN from 'lodash/isNaN';
import { v4 } from 'uuid';
import colors from '../../../../styles/colors';
import { EvaluationScreen } from './EvaluationScreen';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { LanguageEvaluationPhrases } from '../../../../firebase/firestore/documents/languageEvaluationPhrases';
import { Band, BandHeaders, LanguageEvaluationTemplateResponseType, LanguageEvaluationType } from '../LanguageEvaluationTypes';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
} from '../../../../utils/FormErrorUtils';

export type LanguageEvaluationTemplateProps = {
  isOpen: boolean;
  onClose: () => void;
  templateToEdit: LanguageEvaluationTemplateResponseType | null;
  templateToView: LanguageEvaluationTemplateResponseType | null;
  setTemplateView: React.Dispatch<React.SetStateAction<LanguageEvaluationTemplateResponseType | null>>;
  languagePhrases: LanguageEvaluationPhrases[];
  setTemplateEdit: React.Dispatch<React.SetStateAction<LanguageEvaluationTemplateResponseType | null>>;
  templateData: LanguageEvaluationType;
  setTemplateData: React.Dispatch<React.SetStateAction<LanguageEvaluationType>>;
  handleLanguageEvaluationPhrase: (checked: boolean, language: string, phrases: LanguageEvaluationPhrases) => void;
  handleLanguageChange: () => void;
  getLETemplates: () => void;
  formErrors: FormattedError[];
  setFormErrors: React.Dispatch<React.SetStateAction<FormattedError[]>>;
  isDisabled: boolean;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export type LanguageEvaluationTemplate = {
  id?: string;
  templateName?: string;
  language?: string;
  phraseId?: string;
  languageEvaluationTemplates?: {
    language: string;
    phrase: string;
    phraseId: string;
  }[];
};

const languageEvaluationBandLabels: string[] = ['accuracy', 'completeness', 'confidence', 'fluency', 'pronunciation'];

const MAX_VALUE = 100;
const MIN_VALUE = 0;

export const LanguageEvaluationCreateModal = ({
  isOpen,
  onClose,
  templateToEdit,
  setTemplateEdit,
  templateToView,
  setTemplateView,
  templateData,
  setTemplateData,
  getLETemplates,
  languagePhrases,
  handleLanguageEvaluationPhrase,
  handleLanguageChange,
  formErrors,
  setFormErrors,
  isDisabled,
  setIsDisabled,
}: LanguageEvaluationTemplateProps): JSX.Element => {
  const toast = useToast();
  const { t } = useTranslation('administration');
  const accountId = useStoreState((s) => s.app.accounts?.id);
  const { createLanguageEvaluationTemplate, updateLanguageEvaluationTemplate } = useStoreActions(
    (actions) => actions.languageEvaluationTemplate,
  );

  const [phraseLanguage, setPhraseLanguage] = useState<string>('');
  const [isFromSubmitted, setIsFromSubmitted] = useState<boolean>(false);
  const [isRecordingOpen, setIsRecordingOpen] = useState<boolean>(false);
  const [bands, setBands] = useState<{
    [key: string]: Band;
  }>({
    EXPERT: { accuracy: 0, completeness: 0, confidence: 0, fluency: 0, pronunciation: 0 },
    COMPETENT: { accuracy: 0, completeness: 0, confidence: 0, fluency: 0, pronunciation: 0 },
    LIMITED: { accuracy: 0, completeness: 0, confidence: 0, fluency: 0, pronunciation: 0 },
    OCCASIONAL: { accuracy: 0, completeness: 0, confidence: 0, fluency: 0, pronunciation: 0 },
  });

  // istanbul ignore next
  const transformBands = (template: LanguageEvaluationTemplateResponseType) => {
    return template?.bands
      ? Object.entries(bands).reduce((acc, [key, defaultValues]) => {
          if (template?.bands && template?.bands[key]) {
            acc[key] = {
              ...template.bands[key],
              confidence: Math.round(template.bands[key].confidence * 100),
            };
          } else {
            acc[key] = defaultValues;
          }
          return acc;
        }, {} as { [key: string]: Band })
      : bands;
  };

  const getUpdateTemplateData = (template: LanguageEvaluationTemplateResponseType) => {
    if (!_isNil(template)) {
      const transformedBands = transformBands(template);
      setBands(transformedBands);

      setPhraseLanguage(template.language || '');

      const selectedPhrases = languagePhrases.filter(
        (phrase) => (phrase.language === template.language && template.phraseIds?.includes(String(phrase.id))) ?? false,
      );

      setTemplateData((prevData) => ({
        ...prevData,
        languageEvaluationTemplates: selectedPhrases.map((phr) => ({
          id: phr.id || '',
          language: phr.language || '',
          phrase: phr.description || '',
          phraseId: phr.phraseId || '',
        })),
        templateName: template.templateName || prevData.templateName,
        bands,
      }));
    }
  };

  React.useEffect(() => {
    if (templateToEdit) {
      getUpdateTemplateData(templateToEdit);
    } else if (templateToView) {
      getUpdateTemplateData(templateToView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateToEdit, templateToView, setTemplateData]);

  const handleBandInputChange = (band: string, field: string, value: string) => {
    const numericValue = Math.min(MAX_VALUE, Math.max(MIN_VALUE, Number(value)));
    if (!isNaN(numericValue)) {
      setBands((prevBands) => ({
        ...prevBands,
        [band]: {
          ...prevBands[band],
          [field]: numericValue,
        },
      }));
    }
  };

  const bandFieldSchema = Joi.object({
    accuracy: Joi.number().integer().min(1).max(100).required(),
    completeness: Joi.number().integer().min(1).max(100).required(),
    confidence: Joi.number().integer().min(1).max(100).required(),
    fluency: Joi.number().integer().min(1).max(100).required(),
    pronunciation: Joi.number().integer().min(1).max(100).required(),
  });

  const bandSchema = Joi.object({
    EXPERT: bandFieldSchema,
    COMPETENT: bandFieldSchema,
    LIMITED: bandFieldSchema,
    OCCASIONAL: bandFieldSchema,
  });

  const templateSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      templateName: Joi.string()
        .trim(true)
        .required()
        .messages({
          'string.empty': t('languageEvaluation.templateModal.templateNameError'),
        }),
      languageEvaluationTemplates: Joi.array()
        .allow(null)
        .min(1)
        .messages({
          'array.min': t('createJobPosition:templateRequiredError'),
        }),
      phraseLanguage: Joi.string()
        .valid('en', 'fr')
        .required()
        .messages({
          'any.only': t('languageEvaluation.templateModal.languageError'),
        }),
      bands: bandSchema.required().messages({
        'any.required': 'All bands are required.',
      }),
    });

  const formValidation = (formData: LanguageEvaluationType, scheme: Joi.ObjectSchema) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = scheme.validate(formData);
    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }
    setFormErrors([]);
    return true;
  };

  const handleInputChange = (value: string, fieldName: string) => {
    setTemplateData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  const resetFromData = () => {
    setTemplateData({
      templateName: '',
      languageEvaluationTemplates: [],
    });
    setTemplateEdit(null);
  };

  // function to handle template creation and edit
  const onTemplateCreate = async () => {
    setIsFromSubmitted(true);
    const templateDataToVerify = { ...templateData, phraseLanguage, bands };
    const validate = formValidation(templateDataToVerify, templateSchema);

    if (!validate) return;

    const phraseIds = languagePhrases
      .filter(
        (phrase) =>
          templateData &&
          templateData.languageEvaluationTemplates &&
          templateData?.languageEvaluationTemplates.some((template) => template.id === phrase.id),
      )
      .map((phrase) => phrase.id);

    const filteredBands = Object.keys(bands).reduce((result, bandName) => {
      const filteredFields = Object.entries(bands[bandName])
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, value]) => value > 0)
        .reduce((obj, [key, value]) => {
          obj[key as keyof Band] = key === 'confidence' ? Math.min(1, Math.max(0, value / 100)) : value;
          return obj;
        }, {} as Band);

      if (Object.keys(filteredFields).length > 0) {
        result[bandName] = filteredFields;
      }
      return result;
    }, {} as { [key: string]: Band });

    const payload: any = {
      templateName: templateData.templateName,
      language: phraseLanguage,
      phraseIds,
      bands: filteredBands,
    };

    if (!templateToEdit) {
      try {
        if (accountId) {
          await createLanguageEvaluationTemplate({ accountId, templateData: payload });

          toast({
            title: t('languageEvaluation.templateToastMessage.templateCreate'),
            description: t('languageEvaluation.templateToastMessage.templateCreateSuccess', {
              templateName: templateData.templateName,
            }),
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          resetFromData();
          onClose();
          getLETemplates();
        }
      } catch (err) {
        console.error('Error creating template', err);
        let defaultMessage = t('languageEvaluation.templateToastMessage.nameExists');
        const newError = err as AxiosError<{ message: string }>;
        if (axios.isAxiosError(newError)) {
          defaultMessage = newError.response?.data?.message || defaultMessage;
        }
        toast({
          description: defaultMessage,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
        throw err;
      }
    } else {
      try {
        if (templateToEdit.id) {
          payload.account = accountId;
          await updateLanguageEvaluationTemplate({ templateId: templateToEdit.id, templateData: payload });
          toast({
            title: t('languageEvaluation.templateToastMessage.templateEdit'),
            description: t('languageEvaluation.templateToastMessage.templateEditSuccess', {
              templateName: templateData.templateName,
            }),
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          resetFromData();
          onClose();
          getLETemplates();
        }
      } catch (err) {
        console.error('Error editing template', err);
        let defaultMessage = 'Could not edit template at this time.';
        const newError = err as AxiosError<{ message: string }>;
        if (axios.isAxiosError(newError)) {
          defaultMessage = newError.response?.data?.message || defaultMessage;
        }
        toast({
          description: defaultMessage,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
        throw err;
      }
    }
  };

  const handleCancel = () => {
    setIsRecordingOpen(false);
    setTemplateData((prevData) => ({
      ...prevData,
      templateName: '',
      languageEvaluationTemplates: [],
    }));
    setBands({});
    setFormErrors([]);
    setTemplateEdit(null);
    setTemplateView(null);
    setIsDisabled(false);
    onClose();
  };

  const renderBandForm = (band: string, bandLabel: string, tooltipLabel: string) => {
    return (
      <Box mt={5} width="80%">
        <Flex justifyContent="flex-start" alignItems="center" mb={1}>
          <Text fontSize="14px" fontWeight={700} mr={1}>
            {bandLabel}
          </Text>
          <Tooltip hasArrow placement="right" label={tooltipLabel}>
            <Box>
              <MdOutlineInfo size={14} />
            </Box>
          </Tooltip>
        </Flex>
        <Flex>
          {(languageEvaluationBandLabels as Array<keyof Band>).map((field) => {
            return (
              <FormControl key={field} isInvalid={bands[band][field] === 0 && isFromSubmitted} isRequired={!templateToView}>
                <FormLabel fontSize="14px" fontWeight={400}>
                  {t(`languageEvaluation.bandFields.${field}`)}
                </FormLabel>
                <Input
                  type="tel"
                  width="50px"
                  size="sm"
                  placeholder="50"
                  maxLength={3}
                  value={bands[band][field] === 0 ? '' : Number(bands[band][field])}
                  onChange={(e) => handleBandInputChange(band, field, e.target.value)}
                  disabled={isDisabled}
                  _disabled={{ color: 'black' }}
                />
              </FormControl>
            );
          })}
        </Flex>
      </Box>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={isRecordingOpen ? '6xl' : '4xl'}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        data-testid="languageEvaluationTemplateModal"
        maxHeight="97vh"
        overflow="auto"
        display="flex"
        flexDirection="column"
      >
        <ModalBody display="flex" flexDirection="row" flex="1" height="100%">
          <Box flex={isRecordingOpen ? '0 0 55%' : '1'} padding={3}>
            <ModalHeader pl={0} pr={0} pb={2} borderBottom="1px solid #e2e8f0">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Text fontSize="20px" fontWeight={700}>
                  {isDisabled
                    ? t('languageEvaluation.templateModal.viewTemplate')
                    : templateToEdit
                    ? t('languageEvaluation.templateModal.editTemplateTitle')
                    : t('languageEvaluation.templateModal.createTemplateTitle')}
                </Text>
                {!isRecordingOpen && !isDisabled && (
                  <Box
                    onClick={() => setIsRecordingOpen(true)}
                    cursor="pointer"
                    fontSize="sm"
                    data-testid="settingsTest"
                    fontWeight={400}
                    color={colors.blue[500]}
                  >
                    {t('languageEvaluation.templateModal.testSettings')}
                  </Box>
                )}
              </Box>
            </ModalHeader>
            <Flex alignItems="baseline" pt={4}>
              <FormControl isInvalid={hasErrorMessage(formErrors, 'templateName')} isRequired={!templateToView}>
                <FormLabel fontSize="14px" fontWeight={700}>
                  {t('languageEvaluation.templateModal.templateName')}
                </FormLabel>
                <Input
                  data-testid="templateIdField"
                  value={templateData.templateName}
                  onChange={(e) => handleInputChange(e.target.value, 'templateName')}
                  placeholder={t('languageEvaluation.templateModal.enterTemplateName')}
                  fontSize="14px"
                  backgroundColor="#EDF2F7"
                  _placeholder={{ color: '#A0AEC0' }}
                  style={{ height: '40px' }}
                  disabled={isDisabled}
                  _disabled={{ color: 'black' }}
                />
                <FormErrorMessage>{getErrorMessage(formErrors, 'templateName')}</FormErrorMessage>
              </FormControl>

              <FormControl ml={4} isRequired={!templateToView} isInvalid={hasErrorMessage(formErrors, 'phraseLanguage')}>
                <FormLabel fontSize="14px" fontWeight={700} htmlFor="phrase-language">
                  {t('languageEvaluation.templateModal.language')}
                </FormLabel>
                <Select
                  value={phraseLanguage}
                  data-testid="phraseLanguageChange"
                  id="phrase-language"
                  onChange={(e) => {
                    setPhraseLanguage(e.target.value);
                    setFormErrors(clearErrorMessage(formErrors, 'phraseLanguage'));
                    handleLanguageChange();
                  }}
                  fontSize="14px"
                  backgroundColor="#EDF2F7"
                  _placeholder={{ color: '#A0AEC0' }}
                  // placeholder={t('languageEvaluation.templateModal.selectLanguage')}
                  style={{ height: '40px' }}
                  disabled={isDisabled}
                  _disabled={{ color: 'black' }}
                >
                  <option value="" selected disabled>
                    {t('languageEvaluation.templateModal.selectLanguage')}
                  </option>
                  <option value="en">{t('languageEvaluation.english')}</option>
                  <option value="fr">{t('languageEvaluation.french')}</option>
                </Select>
                <FormErrorMessage>{getErrorMessage(formErrors, 'phraseLanguage')}</FormErrorMessage>
              </FormControl>

              {languagePhrases && languagePhrases.length > 0 && (
                <FormControl
                  ml={4}
                  isRequired={!templateToView}
                  isInvalid={hasErrorMessage(formErrors, 'languageEvaluationTemplates')}
                >
                  <Box alignItems="center">
                    <FormLabel fontSize="14px" fontWeight={700} htmlFor="phrase">
                      {t('languageEvaluation.templateModal.phrases')}
                    </FormLabel>

                    <Menu closeOnSelect={false}>
                      <MenuButton
                        variant="outline"
                        as={Button}
                        pr={2}
                        width="100%"
                        borderRadius="6px"
                        backgroundColor="gray.150"
                        padding="0.4rem 1rem"
                        display="flex"
                        fontSize="14px"
                        fontWeight="normal"
                        justifyContent="space-between"
                        textAlign="left"
                        style={{ height: '40px' }}
                        _focus={{ outline: 'none' }}
                        rightIcon={<ChevronDownIcon style={{ height: '20px', width: '20px' }} />}
                        disabled={isDisabled}
                        _disabled={{ color: 'black' }}
                      >
                        {t('languageEvaluation.templateModal.selectPhrases')}
                      </MenuButton>
                      <Portal>
                        <MenuList minWidth="240px" borderColor="gray.50" key={v4()}>
                          <Box>
                            <Stack p={2} spacing={3} direction="column">
                              {languagePhrases
                                .filter((langEvalPhrase: LanguageEvaluationPhrases) => langEvalPhrase.language === phraseLanguage)
                                .map((phrase: LanguageEvaluationPhrases) => (
                                  <Checkbox
                                    defaultIsChecked={_some(templateData.languageEvaluationTemplates, {
                                      id: phrase.id,
                                    })}
                                    colorScheme="blue"
                                    px={3}
                                    key={phrase.id}
                                    value={phrase.id}
                                    data-testid={`phraseCheckBox-${phrase.id}`}
                                    onChange={(e) => handleLanguageEvaluationPhrase(e.target.checked, phraseLanguage, phrase)}
                                  >
                                    {phrase.phraseId}
                                  </Checkbox>
                                ))}
                            </Stack>
                          </Box>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Box>
                  <FormErrorMessage>{getErrorMessage(formErrors, 'languageEvaluationTemplates')}</FormErrorMessage>
                </FormControl>
              )}
            </Flex>

            {languagePhrases &&
              languagePhrases.length > 0 &&
              templateData.languageEvaluationTemplates?.map((template) => (
                <Box key={template.id} mb={2} backgroundColor="#FFFFF0" padding="16px" borderRadius="6px" mt={2}>
                  <FormLabel htmlFor="phrase" fontWeight="bold" fontSize="14px">
                    {
                      languagePhrases.filter((langPhrase: LanguageEvaluationPhrases) => langPhrase.id === template.id)[0]
                        ?.phraseId
                    }
                  </FormLabel>
                  <Box fontSize="14px">{template.phrase}</Box>
                </Box>
              ))}

            {renderBandForm(
              BandHeaders.EXPERT,
              t('languageEvaluation.band.expert'),
              t('languageEvaluation.band.expertTooltipText'),
            )}
            {renderBandForm(
              BandHeaders.COMPETENT,
              t('languageEvaluation.band.competent'),
              t('languageEvaluation.band.competentTooltipText'),
            )}
            {renderBandForm(
              BandHeaders.LIMITED,
              t('languageEvaluation.band.limited'),
              t('languageEvaluation.band.occasionalText'),
            )}
            {renderBandForm(
              BandHeaders.OCCASIONAL,
              t('languageEvaluation.band.occasional'),
              t('languageEvaluation.band.occasionalText'),
            )}

            <Box mt={5}>
              <Text fontSize="sm" fontWeight={700} mb={2}>
                {t('languageEvaluation.templateModal.note')}
              </Text>
              {languageEvaluationBandLabels.map((key) => (
                <Flex key={key}>
                  <Text fontSize="12px" fontWeight={700} color="#718096" lineHeight="16px" mb={1} mr={1}>
                    {t(`languageEvaluation.bandFields.${key}`)}
                    {`${':'}`}
                  </Text>
                  <Text fontSize="12px" color="#718096" lineHeight="16px" mb={1}>
                    {t(`languageEvaluation.noteLabel.${key}`)}
                  </Text>
                </Flex>
              ))}
            </Box>
            <ModalFooter pr={0}>
              <Button data-testid="cancelBtn" colorScheme="red" onClick={handleCancel}>
                {t('languageEvaluation.cancel')}
              </Button>
              {!isDisabled && (
                <Button data-testid="createTemplateBtn" colorScheme="blue" ml={3} onClick={onTemplateCreate}>
                  {templateToEdit ? t('languageEvaluation.update') : t('languageEvaluation.create')}
                </Button>
              )}
            </ModalFooter>
          </Box>

          {isRecordingOpen && (
            <Box flex="0 0 45%" ml={6} padding={3} borderLeft="1px solid #e2e8f0">
              <EvaluationScreen
                setIsRecordingOpen={setIsRecordingOpen}
                templateData={templateData}
                language={phraseLanguage}
                bands={bands}
              />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
