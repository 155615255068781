export default {
  searchTitle: 'Recherche de candidat',
  name: 'Nom',
  email: 'Adresse courriel',
  selectStatus: 'Choisir un statut',
  enterHireId: "ID de l'embauche",
  requisitionId: 'Réquisition',
  enterRequisition: 'Entrer une réquisition',
  enterLocationId: `Entrer l'identifiant ou le nom du lieu`,
  statusSelected: `{{statusCount}} status sélectionné(s)`,
  tableHeader: {
    candidateName: 'Nom du candidat',
    location: 'Emplacement',
    phone: 'Téléphone',
    status: 'Statut',
    lastUpdate: 'Dernièr changement',
    hireId: `ID de l'embauche`,
  },
  showingPageResults: 'Affiche présentement {{currentItems}} de {{totalItems}} candidats',
  exploreProfiles: 'Explorer les profils de vos candidats',
  enterDetailsText: `Il semble que vous n'ayez pas encore recherché quelque chose. Entrez vos critères ci-dessus pour trouver les candidats pour lequel vous cherchez!`,
  noMatchFound: 'Aucun candidat trouvé',
  noMatchFoundText: `Il n'y a pas de candidats qui correspondent à vos critères. Essayez d'ajuster vos filtres ou mots-clés pour avoir un meilleur résultat.`,
  candidateStatus: {
    DISMISSED: 'Rejeté',
    NOT_LOOKING_FOR_WORK: 'Pas à la recherche de travail',
    HIRED: 'Embauché',
    HIRE_IN_REVIEW: 'Embauche en cours',
    HIRED_ON_ANOTHER_POSITION: 'Embauché sur un autre poste',
    UNHIRED: 'Désembauché',
    OFFER_MADE: 'Offre faite',
    OFFER_REJECTED: 'Offre refusée',
    OFFER_REVOKED: 'Offre révoquée',
    CONTACT_REQUESTED: 'Contact demandé',
    CONTACT_REQUEST_ACCEPTED: 'Demande de contact acceptée',
    CONTACT_REQUEST_DECLINED: 'Demande de contact refusée',
    APPOINTMENT_REQUESTED: 'Entrevue demandée',
    APPOINTMENT_ACCEPTED: 'Entrevue acceptée',
    APPOINTMENT_DECLINED: 'Entrevue refusée',
    APPOINTMENT_CANCELLED: 'Entrevue annulée',
    APPOINTMENT_RESCHEDULING_NEEDED: "Besoin de replanifier l'entrevue",
    BOOKMARKED: 'Sauvegardé',
    LIKED: 'Appliqué',
    BLACKLISTED: 'Bloqué',
    UNKNOWN: 'Inconnue',
    INTEREST_REVOKED: `Candidature retirée`,
    APPOINTMENT_REQUEST_MODIFIED: 'Demande de rendez-vous modifiée',
    HIRE_FORM_REQUESTED: "Formulaire d'embauche demandé",
    HIRE_FORM_IN_PROGRESS: "Formulaire d'embauche en cours",
    HIRE_FORM_HELP_REQUESTED: "Formulaire d'embauche aide demandée",
  },
  copiedHireId: `L'identifiant a été copié`,
};
