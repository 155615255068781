import React from 'react';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import Joi from 'joi';
import isEqual from 'lodash/isEqual';
import { Language, languages } from './formElements/formBuilderSchema';
import take from 'lodash/take';
import { useTranslation } from 'react-i18next';

export enum validationType {
  LINK = 'link',
  TEXT = 'text',
  LABEL = 'label',
  SECTION_NAME = 'section_name',
  TEMPLATE_NAME = 'template_name',
  DOCUMENT_PREVIEW_CONFIRM_TEXT = 'document_preview_confirm_text',
  DOCUMENT_PREVIEW_FILE = 'document_preview_file',
  SECTION = 'section',
}
export type ValidationType =
  | 'link'
  | 'template_name'
  | 'label'
  | 'text'
  | 'document_preview_confirm_text'
  | 'document_preview_file'
  | 'section_name';

type ValueType =
  | string
  | {
      file: {
        mimetype: string;
        size: number;
      };
    };

export interface ErrorWrapperProps {
  children: React.ReactNode;
  hasError: boolean;
  error: string;
}

interface useErrorHandlingProps<T> {
  path: (string | number)[];
  valdationFor: ValidationType;
  onValidation: (value: ValueType) => T;
  onFocus?: () => void;
}

export default function useErrorHandling<T>({ path, valdationFor, onValidation, onFocus }: useErrorHandlingProps<T>) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { t } = useTranslation('hrFormTemplate');
  const { errors } = useStoreState((state) => state.hrFormTemplate);
  const { setErrors } = useStoreActions((state) => state.hrFormTemplate);
  const [currentErrors, setCurrentErrors] = React.useState<Joi.ValidationErrorItem>();

  const serverError = errors?.find((error) => isEqual(path, take(error.path, path.length)));
  const hasError = React.useMemo(() => !!serverError || !!currentErrors, [serverError, currentErrors]);

  React.useEffect(() => {
    if (hasError) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      onFocus?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, errors, currentErrors]);

  const validationSchemas = (languages: readonly Language[], valdationFor: ValidationType) => {
    const schemas = {
      [validationType.LINK]: Joi.alternatives().try(
        Joi.object().pattern(
          Joi.string().valid(...languages),
          Joi.string()
            .max(100)
            .allow('', null)
            .optional()
            .messages({
              'string.max': t('validation.linkMaxLength', { limit: 100 }),
            }),
        ),
      ),
      [validationType.TEMPLATE_NAME]: Joi.string()
        .max(50)
        .allow('', null)
        .optional()
        .messages({
          'string.max': t('validation.templateNameMaxLength', { limit: 50 }),
          'string.empty': t('validation.templateNameRequired'),
        }),
      [validationType.LABEL]: Joi.string()
        .max(50)
        .allow('', null)
        .messages({
          'string.max': t('validation.labelMaxLength', { limit: 50 }),
          'string.empty': t('validation.labelRequired'),
          'string.required': t('validation.labelRequired'),
        }),
      [validationType.TEXT]: Joi.alternatives().try(
        Joi.object().pattern(
          Joi.string().valid(...languages),
          Joi.string()
            .max(500)
            .allow('', null)
            .optional()
            .messages({
              'string.max': t('validation.textMaxLength', { limit: 500 }),
              'string.empty': t('validation.textRequired'),
              'string.required': t('validation.textRequired'),
            }),
        ),
      ),
      [validationType.DOCUMENT_PREVIEW_CONFIRM_TEXT]: Joi.string()
        .max(30)
        .messages({
          'string.max': t('validation.confirmTextMaxLength', { limit: 30 }),
          'string.empty': t('validation.confirmTextRequired'),
          'string.required': t('validation.confirmTextRequired'),
        }),
      [validationType.DOCUMENT_PREVIEW_FILE]: Joi.object({
        file: Joi.object({
          mimetype: Joi.string()
            .valid('application/pdf')
            .required()
            .messages({
              'any.only': t('validation.fileAccept'),
              'any.required': t('validation.fileRequired'),
            }),
          size: Joi.number()
            .max(2 * 1024 * 1024) // 2MB in bytes
            .required()
            .messages({
              'number.max': t('validation.fileMaxSize', { limit: 2 }),
              'any.required': t('validation.fileRequired'),
            }),
        })
          .required()
          .messages({
            'any.required': t('validation.fileRequired'),
          }),
      }),
      [validationType.SECTION_NAME]: Joi.string()
        .max(50)
        .required()
        .messages({
          'string.max': t('validation.sectionMaxLength', { limit: 50 }),
          'string.empty': t('validation.sectionRequired'),
          'any.required': t('validation.sectionRequired'),
        }),
    };

    return schemas[valdationFor];
  };

  const schema = validationSchemas(languages, valdationFor);

  function validateError(
    value: ValueType,
    onSuccess?: (value: ValueType) => void,
    onError?: (error: Joi.ValidationErrorItem) => void,
  ) {
    const { error } = schema.validate(onValidation(value), { abortEarly: false });
    const index = errors?.findIndex((error) => isEqual(error.path, path));
    if (error) {
      const validationError = { ...error?.details?.[0], path };
      onError?.(validationError);
      setCurrentErrors(validationError);
      // const newErrors = [...errors];
      // if (index !== -1) {
      //   // newErrors.splice(index, 1, validationError);
      //   setCurrentErrors(validationError);
      // } else {
      //   setCurrentErrors([...newErrors, validationError]);
      // }
    } else {
      onSuccess?.(value);
      setCurrentErrors(undefined);
      const newErrors = [...errors];
      if (newErrors.length > 0) {
        if (index !== -1) {
          newErrors.splice(index, 1);
          setErrors(newErrors);
        } else {
          setErrors([]);
        }
      }
    }
  }

  return { validateError, error: currentErrors || serverError, hasError, inputRef };
}
