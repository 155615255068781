import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { HStack } from '@chakra-ui/core';
import FormElementTextBuilder from './FormElementTextBuilder';
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

export default function CheckBoxSchemaBuilder({ ...props }: SchemaBuilderProps) {
  const { t } = useTranslation('hrFormTemplate');

  return (
    <HStack align="flex-start">
      <MdOutlineCheckBoxOutlineBlank fontSize={20} />
      <FormElementTextBuilder {...props} placeholder={t('formBuilder.editorPlaceholder.text')} />
    </HStack>
  );
}
