import { css } from '@emotion/core';
import colors from '../../../../styles/colors';

export const EvaluationContainerStyles = css`
  canvas {
    width: 100%;
  }

  .record-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: ${colors.neutral[200]}; /* Light gray background for the button and timer */
    border-radius: 50px; /* Rounded edges for the background */
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    width: max-content;
  }

  .record-button {
    width: 24px !important;
    height: 24px;
    border-radius: 50%;
    background-color: ${colors.red[500]}; /* Red for recording */
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.neutral[200]};
    font-size: 20px;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }

  .timer {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.black}; /* Darker gray for the text */
  }
`;
