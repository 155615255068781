import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Checkbox,
  Text,
  Box,
  Flex,
} from '@chakra-ui/core';
import Bugsnag from '@bugsnag/js';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { UserProfileType } from '../../modules/profile/ProfileTypes';
import colors from '../../styles/colors';

export enum ComplianceContext {
  POSITION = 'position',
  OFFER = 'offer',
}

export type PositionComplianceReminderProps = {
  isOpen: boolean;
  onClose: () => void;
  context: ComplianceContext;
  onConfirm?: () => void;
  userDidSomeAction?: boolean;
};

export const PositionComplianceReminder = ({
  isOpen,
  onClose,
  context,
  onConfirm,
  userDidSomeAction,
}: PositionComplianceReminderProps): JSX.Element | null => {
  const [dontRemindAgain, setDontRemindAgain] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const user = useStoreState((s) => s.app.user);
  const { t } = useTranslation('jobPosition');
  const offerComplianceCheckedDate = useStoreState((s) => s.app.user?.offerComplianceCheckedDate);
  const updateProfile = useStoreActions((actions) => actions.profileManagement.updateProfile);

  const showAcknowledgementNotSelectedError = isNil(offerComplianceCheckedDate) && userDidSomeAction;

  const onUpdateProfile = async (userId: string, updatedUserProfile: UserProfileType) => {
    try {
      await updateProfile({ userID: userId, userProfile: updatedUserProfile });
    } catch (e) {
      Bugsnag.notify(e);
    }
  };

  const updatePositionCompliance = async (dontRemindAgainChecked: boolean) => {
    try {
      if (user) {
        setIsUpdating(true);
        const updatedUserProfile: UserProfileType = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          filterCandidateByDays: user.filterCandidateByDays,
          needCompletedTodo: user.needCompletedTodo,
          [context === ComplianceContext.OFFER ? 'offerComplianceChecked' : 'positionComplianceChecked']: dontRemindAgainChecked,
        };

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        await onUpdateProfile(user.id, { ...updatedUserProfile });
      }
    } catch (error) {
      // istanbul ignore next line
      console.error('Error updating profile:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleProceed = async (isDoNotReminderChecked: boolean) => {
    await updatePositionCompliance(isDoNotReminderChecked);
  };

  const handleComplianceSubmit = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    dontRemindAgain && (await handleProceed(dontRemindAgain));
    if (onConfirm) {
      onConfirm();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    context === ComplianceContext.POSITION && onClose();
  };

  const renderComplianceReminderContent = () => {
    const isComplianceForOffer = context === ComplianceContext.OFFER;
    return (
      <Flex direction="column" mb={isComplianceForOffer ? 6 : 'unset'} data-testid={`compliance_${context}`}>
        <Box
          display="flex"
          flexDirection="column"
          backgroundColor={isComplianceForOffer ? colors.brightBlue : colors.white}
          padding={isComplianceForOffer ? 4 : 'unset'}
          borderRadius="6px"
          border={showAcknowledgementNotSelectedError ? `1px solid ${colors.red[500]}` : 'unset'}
        >
          {isComplianceForOffer && (
            <Text color={colors.persianBlue} fontSize={16} fontWeight={700} mb={2}>
              {t('complianceModal.heading')}
            </Text>
          )}
          <Text color={colors.gray[450]}>{t('complianceModal.bodyText')}</Text>
          <Checkbox
            width="max-content"
            mt={4}
            data-testid="doNotShowAgainCheckbox"
            isChecked={dontRemindAgain}
            onChange={(e) => {
              setDontRemindAgain(e.target.checked);
            }}
          >
            <Text color={colors.darkBg}>{t('complianceModal.dontRemindText')}</Text>
          </Checkbox>

          <Box padding={0} mt={2} textAlign="right">
            <Button
              colorScheme="blue"
              onClick={() => handleComplianceSubmit()}
              ml={3}
              isLoading={isUpdating}
              isDisabled={isUpdating}
              data-testid="proceedButton"
            >
              {t('complianceModal.proceed')}
            </Button>
          </Box>
        </Box>
        {showAcknowledgementNotSelectedError && (
          <Text color={colors.red[500]} mt={2} data-testid="complianceErrorMessage">
            {t('complianceModal.errorMessage')}
          </Text>
        )}
      </Flex>
    );
  };

  return context === ComplianceContext.OFFER ? (
    <Box>{renderComplianceReminderContent()}</Box>
  ) : (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent padding={4}>
        <ModalHeader padding={0} mb={4}>
          {t('complianceModal.heading')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={0}>{renderComplianceReminderContent()}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
