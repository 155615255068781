import React from 'react';
import { Box, Button, Checkbox, HStack, Menu, MenuButton, MenuList, Stack, VStack, Text } from '@chakra-ui/core';
import { FieldSchema, Language, OptionSchema, SchemaBuilderProps } from './formBuilderSchema';
import ButtonPreview from './ButtonPreview';
import FormElementTextBuilder from './FormElementTextBuilder';
import FollowUpSchemaBuilder from './followUpQuestions/FollowUpSchemaBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MdAdd, MdOutlineClose } from 'react-icons/md';
import { useStoreState } from '../../../../models/hooks';
import { useTranslation } from 'react-i18next';

export default function YesNoSchemaBuilder(props: SchemaBuilderProps) {
  const { followUpLabel } = props;
  const { i18n } = useTranslation();
  const { t } = useTranslation('hrFormTemplate');

  const selectedLanguage = i18n.language as Language;
  return (
    <Stack spacing={2}>
      <LabelSchemaBuilder {...props} />
      <FormElementTextBuilder {...props} placeholder={t('formBuilder.editorPlaceholder.yesNo')} />
      <HStack direction={props.defaultValue?.optionList?.some((item) => item?.tag) ? 'column' : 'row'}>
        {props.defaultValue?.optionList?.map((option, index) => {
          return (
            <ButtonPreview
              key={index}
              label={option.text?.[selectedLanguage]!}
              // rightContent={
              //   <SelectTag
              //     followUpOptionId={option.id}
              //     option={option}
              //     parentDefaultValue={props.defaultValue}
              //     onChange={props.onChange}
              //     isAdvanced={props.isAdvanced || false}
              //   />
              // }
            />
          );
        })}
      </HStack>

      <FollowUpSchemaBuilder {...props} followUpLabel={(followUpLabel || 0) + 1} />
    </Stack>
  );
}

interface SelectTagProps {
  followUpOptionId: string;
  option: OptionSchema;
  parentDefaultValue: FieldSchema;
  onChange: (field: FieldSchema) => void;
  isAdvanced: boolean;
}
export function SelectTag({ followUpOptionId, parentDefaultValue, onChange, isAdvanced }: SelectTagProps) {
  const { tags } = useStoreState((state) => state.hrFormTemplate);
  const [showTagSelect, setShowTagSelect] = React.useState(false);

  const optionTag = React.useMemo(
    () => parentDefaultValue?.optionList?.find((item) => item?.id === followUpOptionId)?.tag,
    // eslint-disable-next-line
    [parentDefaultValue],
  );

  React.useEffect(() => {
    if (optionTag) setShowTagSelect(true);
  }, [optionTag]);
  if (isAdvanced) return null;

  function handleTagSelect(tag: string | null) {
    const newFormSchema = { ...parentDefaultValue };
    newFormSchema.optionList?.forEach((item) => {
      if (item?.id === followUpOptionId) {
        item.tag = tag;
      }
    });
    onChange(newFormSchema);
  }

  function handleTagAction() {
    setShowTagSelect(!showTagSelect);
    handleTagSelect(null);
  }

  return (
    <HStack spacing={0}>
      {showTagSelect && (
        <MultiSelect
          options={tags.map((tag) => ({ label: tag, value: `[${tag}]` }))}
          value={optionTag || ''}
          onChange={(e) => handleTagSelect(e.join(' '))}
        />
      )}
      <Button variant="link" size="xs" onClick={handleTagAction} _focus={{ outline: 'none' }}>
        {showTagSelect ? <MdOutlineClose size="12" /> : <MdAdd size="12" />}
      </Button>
    </HStack>
  );
}

interface MultiSelectOption {
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: MultiSelectOption[];
  value: string;
  onChange: (selected: string[]) => void;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, value, onChange }) => {
  const selectedValues = React.useMemo(() => value.split(' '), [value])?.filter((item) => item !== '');
  const handleToggle = (value: string) => {
    if (selectedValues.includes(value)) {
      onChange(selectedValues.filter((item) => item !== value));
    } else {
      onChange([...selectedValues, value]);
    }
  };

  return (
    <Box>
      <Menu closeOnSelect={false}>
        <MenuButton
          sx={{ '& + div': { zIndex: 100 } }}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          variant="ghost"
          fontSize="xs"
          size="xs"
          colorScheme="gray"
        >
          {selectedValues.length > 0 ? (selectedValues.length > 2 ? `${selectedValues.length} tags` : value) : 'Select tags'}
        </MenuButton>
        <MenuList>
          <VStack align="start" spacing={2} p={2}>
            {options.map((option) => (
              <Checkbox
                key={option.value}
                isChecked={selectedValues.includes(option.value)}
                onChange={() => handleToggle(option.value)}
                variant="outline"
              >
                <Text fontSize="xs">{option.label}</Text>
              </Checkbox>
            ))}
          </VStack>
        </MenuList>
      </Menu>
    </Box>
  );
};
