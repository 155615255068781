import React from 'react';
import { useHireFormContext } from '../../HireForm.context';
import { Box, Divider, HStack, Stack, SystemStyleObject } from '@chakra-ui/core';

type DropPositionT = 'top' | 'bottom';
export type DropTargetT = { index: number; position: DropPositionT };

interface DroppableElementProps {
  children: React.ReactNode;
  isDisabled?: boolean;
  isLastElement?: boolean;
  onDrop?: (type: string, position?: DropPositionT) => void;
  rightContent?: React.ReactNode;
  style?: React.CSSProperties;
  sx?: SystemStyleObject;
  hideDivider?: boolean;
  currentType?: string;
  acceptedTypes: string[];
  index: number; // Added for accurate drop index
  disableDropZone?: boolean;
}

export default function DroppableElement({
  children,
  isDisabled = false,
  isLastElement = false,
  onDrop,
  rightContent,
  style,
  sx,
  hideDivider = false,
  currentType,
  acceptedTypes = [],
  index,
  disableDropZone = false,
}: DroppableElementProps) {
  const [dropTarget, setDropTarget] = React.useState<DropTargetT | null>(null);
  const elementRef = React.useRef<HTMLDivElement>(null);
  const { draggedElement } = useHireFormContext();
  const lastCursorY = React.useRef(0);
  const lastDropPosition = React.useRef<DropPositionT | null>(null);

  // Check if the dragged item is an accepted type
  const isAcceptedType = acceptedTypes.length === 0 || acceptedTypes.includes(draggedElement?.panel || '');

  React.useEffect(() => {
    if (!draggedElement) {
      setDropTarget(null);
      lastDropPosition.current = null;
    }
  }, [draggedElement]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (isDisabled || !isAcceptedType) return;
    event.preventDefault();
    event.stopPropagation();

    const currentTarget = event.currentTarget;
    if (!currentTarget) return;

    const rect = currentTarget.getBoundingClientRect();
    const cursorY = event.clientY;
    if (Math.abs(cursorY - lastCursorY.current) < 5) return;
    const midpointY = rect.top + rect.height / 2;
    const newPosition: DropPositionT = cursorY < midpointY ? 'top' : 'bottom';

    const relatedTarget = event.relatedTarget as HTMLElement | null;
    const isEnteringChild = relatedTarget && currentTarget.contains(relatedTarget);

    // If entering a child, clear the dropTarget to prevent parent interference
    if (isEnteringChild) {
      setDropTarget(null);
      return;
    }

    // Prevent redundant state updates
    if (!dropTarget || lastDropPosition.current !== newPosition || dropTarget.index !== index) {
      setDropTarget({ index, position: newPosition });
      lastDropPosition.current = newPosition;
    }

    lastCursorY.current = cursorY;
  };
  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    if (isDisabled || !isAcceptedType) return;

    const relatedTarget = event.relatedTarget as HTMLElement | null;
    const currentTarget = event.currentTarget;

    // Delay checking to allow time for re-entering
    setTimeout(() => {
      if (!currentTarget.contains(relatedTarget)) {
        setDropTarget(null);
        lastDropPosition.current = null;
      }
    }, 50);
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isDisabled && isAcceptedType) {
      const type = event.dataTransfer.getData('type');
      onDrop?.(type, dropTarget?.position);
      setDropTarget(null);
    }
  };

  return (
    <Box
      ref={elementRef}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      role="group"
      style={style}
      sx={sx}
      className="hire-form-element-drop-container"
    >
      <HStack justify="space-between" align="flex-start">
        <Box flex={1}>
          {!isDisabled && !disableDropZone && dropTarget?.position === 'top' && (
            <Stack spacing={4} marginBottom={4}>
              <DropPlaceholder />
              {!hideDivider && <Divider />}
            </Stack>
          )}
          <Stack pb={dropTarget?.position === 'bottom' ? 4 : 0} spacing={4}>
            {children}
            {!isLastElement && !hideDivider && <Divider />}
          </Stack>
          {!isDisabled && !disableDropZone && dropTarget?.position === 'bottom' && (
            <Stack spacing={4} marginTop={4}>
              {isLastElement && !hideDivider && <Divider />}
              <DropPlaceholder />
              {!isLastElement && !hideDivider && <Divider />}
            </Stack>
          )}
        </Box>
        {rightContent}
      </HStack>
    </Box>
  );
}

function DropPlaceholder() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 10,
        backgroundColor: 'blue.100',
        border: '2px dashed',
        borderColor: 'blue.300',
        borderRadius: 'md',
      }}
      onDragOver={(e) => e.preventDefault()}
    />
  );
}
