import { thunk, Thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import HttpClientWithRetry from '../../../../http/client/HttpClientWithRetry';
import { HttpStoreModel } from '../../../../http/models/httpModel';
import { FitbandTemplateData, FitbandTemplateList, FitbandTemplateType } from '../FitbandConfigurationTypes';
import { FitbandConfigurationController } from '../../../../controllers/fitbandConfigurationController';

export type FitbandConfigurationStoreModel = {
  fitbandTemplate: FitbandConfigurationModel;
};

export interface FitbandConfigurationModel {
  createFitbandTemplate: Thunk<
    FitbandConfigurationModel,
    { accountId: string; templateData: FitbandTemplateData },
    void,
    HttpStoreModel,
    Promise<AxiosResponse>
  >;
  getFitbandTemplates: Thunk<
    FitbandConfigurationModel,
    { accountId: string; params: { after?: string | null; before?: string | null } },
    void,
    HttpStoreModel,
    Promise<FitbandTemplateList>
  >;
  copyFitbandTemplate: Thunk<
    FitbandConfigurationModel,
    { accountId: string; templateId: string },
    void,
    HttpStoreModel,
    Promise<AxiosResponse>
  >;
  updateFitbandTemplate: Thunk<
    FitbandConfigurationModel,
    { accountId: string; templateId: string; templateData: FitbandTemplateData },
    void,
    HttpStoreModel,
    Promise<AxiosResponse>
  >;
  getFitbandTemplateDetail: Thunk<
    FitbandConfigurationModel,
    { accountId: string; templateId: string },
    void,
    HttpStoreModel,
    Promise<FitbandTemplateType>
  >;
}

export const fitbandConfigurationModel: FitbandConfigurationModel = {
  createFitbandTemplate: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new FitbandConfigurationController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.createFitbandTemplate(payload.accountId, payload.templateData);
    return response;
  }),

  getFitbandTemplates: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new FitbandConfigurationController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getFitbandTemplates(payload.accountId, payload.params);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response?.data as FitbandTemplateList;
  }),

  copyFitbandTemplate: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new FitbandConfigurationController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.copyFitbandTemplate(payload.accountId, payload.templateId);
    return response;
  }),

  updateFitbandTemplate: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new FitbandConfigurationController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updateFitbandTemplate(payload.accountId, payload.templateId, payload.templateData);
    return response;
  }),

  getFitbandTemplateDetail: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new FitbandConfigurationController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getFitbandTemplateDetail(payload.accountId, payload.templateId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response?.data?.fitBandTemplate as FitbandTemplateType;
  }),
};
