import {
  Box,
  Flex,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  Heading,
  AvatarBadge,
  Checkbox,
  Textarea,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { TopCandidateDataType } from './TopCandidateDataType';
import { useStoreState, useStoreActions } from '../../../models/hooks';
import colors from '../../../styles/colors';
import { ProfilePicture } from '../../common';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import { useTopCandidatesContext } from './TopCandidatesContext';
import useTokenThresholdForAutomation from '../../../app/hooks/useTokenThresholdForAutomation';

type ContactRequestProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedTopCandidate: TopCandidateDataType;
};

export const ContactRequestModalView = ({ isOpen, onClose, selectedTopCandidate }: ContactRequestProps): JSX.Element => {
  const { t } = useTranslation('businessManagement');
  const [profileURL, setProfileURl] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDespEditable, setIsDespEditable] = useState<boolean>(false);
  const { positionDocument } = useTopCandidatesContext();
  const [currentRecruiterNotes, setCurrentRecruiterNotes] = useState<string>('');
  const [recruiterNotes, setRecruiterNotes] = useState<string>('');
  const seekersProfilePictures = useStoreState((state) => state.messaging.seekersProfilePictures);
  const userId = useStoreState((state) => state.app.user?.id);
  const minutesBeforeInactivity = useStoreState((state) => state.remoteConfig[RemoteConfigKey.MINUTES_BEFORE_INACTIVITY]);
  const { updateContactRequestNoteOfPosition } = useStoreActions((action) => action.positionManagement);
  const accountStore = useStoreState((s) => s.app.accounts);
  const tokenThresholdForLowToken = useTokenThresholdForAutomation();

  const positionDescription = `${positionDocument?.description ? `${positionDocument.description}\n` : ''}`;

  const greetingNote = `${t('topCandidate.greetingHello')} ${selectedTopCandidate.seeker.firstName}!\n`;

  const recentlyActive =
    moment(selectedTopCandidate.seeker.lastActivity).toDate() > moment().subtract(minutesBeforeInactivity, 'minute').toDate();
  // const positions = usePosition(selectedPositionIdForFilter);

  const currentNotes = isDespEditable && recruiterNotes ? recruiterNotes : currentRecruiterNotes;
  const slicedUserNotes = currentNotes.slice(greetingNote.length);

  const handleContactRequest = async () => {
    if (positionDocument?.id && recruiterNotes) {
      setIsLoading(true);
      await updateContactRequestNoteOfPosition({
        positionId: positionDocument.id,
        seekerId: selectedTopCandidate.seeker.id,
        recruiterNotes: slicedUserNotes,
      }).finally(() => {
        setIsLoading(false);
        onClose();
      });
    }
  };

  const handleRecruiterNotes = (value: string) => {
    if (!value.startsWith(greetingNote)) {
      setRecruiterNotes(greetingNote);
    } else {
      setRecruiterNotes(value);
    }
  };

  useEffect(() => {
    if (selectedTopCandidate) {
      const url = seekersProfilePictures[selectedTopCandidate.seeker?.id];
      setProfileURl(url);
    }
  }, [selectedTopCandidate, seekersProfilePictures]);

  useEffect(() => {
    let myNote = '';
    if (positionDocument?.recruiterNotes) {
      const notesIndex = positionDocument?.recruiterNotes?.findIndex((noteItem) => noteItem?.id === userId);
      if (notesIndex > -1) {
        myNote = positionDocument?.recruiterNotes[notesIndex].notes;
      } else {
        myNote = positionDocument?.recruiterNotes?.filter((noteItem) => noteItem?.id === 'default')[0]?.notes;
      }
      if (isEmpty(myNote)) {
        myNote = positionDescription;
      }
    } else {
      myNote = 'We think you’d be a great fit for this position.';
    }

    myNote = `${greetingNote}${myNote}`;
    setCurrentRecruiterNotes(myNote);
    setRecruiterNotes(myNote);
  }, [positionDocument, userId, greetingNote, positionDescription]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" size="xl">
      <ModalOverlay />
      <ModalContent maxW="45rem" px={8} py={6}>
        <Heading as="h3" fontSize="md" lineHeight={1.6} mb={2}>
          {t('topCandidate.contactCandidate')}
        </Heading>
        <Text fontSize="sm" mb={4}>
          {t('topCandidate.contactNote')}
        </Text>

        {tokenThresholdForLowToken &&
          accountStore?.monetizationEnabled &&
          !isNil(accountStore?.remainingCredits) &&
          accountStore?.remainingCredits < tokenThresholdForLowToken && (
            <Alert status="info" borderRadius="8px" marginTop={3} mb={3}>
              <AlertIcon height={20} width="auto" pr={4} />
              {t('topCandidate.lowTokenWarningMessage')}
            </Alert>
          )}
        <Flex border="#E5E7EB 1px solid" borderLeft={0} borderRight={0} py={4} px={1} mb={4}>
          <Box mr={2}>
            <ProfilePicture
              ml="10px"
              src={profileURL}
              style={{ backgroundColor: colors.orange[500], color: 'white' }}
              name={`${selectedTopCandidate.seeker.firstName} ${selectedTopCandidate.seeker.lastName}`}
              bg="transparent"
            >
              {recentlyActive && <AvatarBadge width="14px" height="14px" bg={colors.green['200']} />}
            </ProfilePicture>
          </Box>
          <Box>
            <Text>{`${selectedTopCandidate.seeker.firstName} ${selectedTopCandidate.seeker.lastName}`}</Text>
            <Text>
              {selectedTopCandidate.workExperience > 10 && t('topCandidate.moreThan10years')}
              {selectedTopCandidate.workExperience < 10 &&
                selectedTopCandidate.workExperience > 0 &&
                t('topCandidate.experience', { years: selectedTopCandidate.workExperience })}
              {selectedTopCandidate.workExperience === 0 && `--`}
            </Text>
          </Box>
        </Flex>
        <Box mb={3}>
          <Checkbox mb={3} onChange={(e) => setIsDespEditable(e.target.checked)}>
            {t('topCandidate.sendCustomMessage')}
          </Checkbox>
          <Textarea
            isDisabled={!isDespEditable}
            mb={4}
            height="135px"
            borderColor="#E2E8F0"
            color="#4B5563"
            onChange={(e) => handleRecruiterNotes(e.target.value.trimStart())}
            value={isDespEditable ? recruiterNotes || '' : currentRecruiterNotes}
          />
          <Flex alignItems="center" justifyContent="flex-end">
            <Button colorScheme="red" variant="solid" mr={3} onClick={() => onClose()}>
              {t('topCandidate.cancel')}
            </Button>
            <Button
              isLoading={isLoading}
              isDisabled={slicedUserNotes.trim() === ''}
              loadingText="Sending"
              colorScheme="blue"
              variant="solid"
              onClick={() => handleContactRequest()}
            >
              {t('topCandidate.sendContactRequest')}
            </Button>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
};
