export default {
  createBusiness: {
    businessCreation: `Création de site`,
    smbBusinessCreation: "Création d'emplacement",
    businessCreationDetail: `Recherchez l'emplacement de votre entreprise par nom. Les emplacements les plus proches de vous afficheront en premier.`,
    smbBusinessCreationDetail: 'Définir un emplacement précis ou que vos postes seront situés.',
    businessName: `Nom du site`,
    businessId: `Identifiant de l'emplacement`,
    address: `Adresse`,
    city: `Ville`,
    state: `État / Province / Région`,
    zip: `ZIP / Code postal`,
    country: `Pays`,
    canada: 'Canada',
    usa: 'USA',
    url: 'URL',
    phone: `Numéro de téléphone`,
    create: 'Créer',
    businessNameRequired: `Le nom de l'entreprise est requis`,
    cityNameRequired: `Le nom de la ville est requis`,
    addressRequired: `L'adresse de l'entreprise est requise`,
    countryRequired: 'Le pays est requis',
    zipRequired: 'Le ZIP ou code postal est requis',
    webSiteRequired: 'Le site Web est requis',
    websiteInvalid: 'Le site Web doit avoir un URL valide',
    stateRequired: `L'état ou la province ou la région est requise`,
    phoneRequired: `Le numéro de téléphone est requis`,
    phoneMin: `Le numéro de téléphone est requis`,
    latitudeRequired: 'La latitude est requise',
    longitudeRequired: 'La longitude est requise',
    placeIdRequired: 'Place Id  is required',
    positionRequired: 'Position  is required',
    viewBusinessList: 'Voir liste des sites',
    update: 'Mettre à jour',
    successMsg: 'Le site a été créé avec succès',
    successMsgBusinessUpdate: 'Le site a été mis à jour avec succès',
    businessAlreadyExists: 'Il existe déjà une entreprise à cet endroit dans AppyHere',
    group: 'Numéro de groupe',
    smbBusinessOnBoardingModal: {
      businessTitle: 'Quelle est la suite?',
      businessHeading: 'Création de votre emplacement',
      businessDesc:
        "Pour trouver les candidats les mieux situés pour travailler dans votre entreprise, nous avons besoin de plusieurs détails, comme l'adresse exacte de l'emplacement. Ceci serait l'adresse ou les candidats embauchés travailleront.",
      positionTitle: 'Quelle est la suite?',
      positionHeading: 'Création de votre poste',
      positionDesc:
        "Allons créer le poste pour lequel vos candidats vont postuler. Assurez-vous de garder les détails les plus précis possibles, pour être certain d'attirer les candidats idéals au poste.",
      of: 'de ',
      skip: 'Ignorer',
      next: 'Suivant',
    },
  },
  businessList: {
    searchBusiness: `Rechercher pas nom de l'emplacement ou son identifiant`,
    myLocations: `Mes sites`,
    allLocations: `Tous les sites`,
    business: 'Sites',
    create: 'Créer',
    listOfBusiness: `Liste des sites`,
    loadMore: ` Voir plus`,
    job: 'poste',
    jobs: 'postes',
    openPositions: `Poste ouvert`,
    noBusinessCreated: `Pour créer des postes et voir des candidats, vous devez d'abord ajouter un site à l'entreprise.`,
  },
  topCandidate: {
    appliedFilterLabels: {
      salary: 'Salaire',
      salaryRange: 'Échelle salariale',
      otherFilterParams: 'Autres filtres',
      experienceRange: 'Expérience',
      lastActiveDays: 'Dernière activité',
    },
    filters: 'Filtres',
    appliedFilters: 'Filtres appliqués',
    resetFilters: 'Réinitialiser les filtres',
    salary: 'Salaire',
    withInRange: `À l'interieur de l'echelle salariale`,
    aboveRange: 'Au dessus de l`echelle salariale',
    salaryRange: 'Echelle salariale (taux horaire)',
    top: 'Meilleurs',
    bookmarked: 'Sauvegardé',
    active: 'Actifs',
    searchCandidates: 'Rechercher parmis les candidats',
    minExperience: 'Expérience minimum',
    years: ' Années',
    lastActive: 'Dernière activité',
    reset: 'Réinitialiser',
    lessThanAWeek: `moins d'une semaine`,
    oneMonth: '1 mois',
    sixMonths: '6 mois',
    lessThanSixMonth: 'moins de 6 mois',
    oneYear: '1 an',
    sixYears: '6 ans',
    flexTransport: 'Accès à un transport flexible',
    seekerIsStudent: 'Est un étudiant',
    emptyAvailabilities: "Ce candidat n'a pas réglé ses disponibilités de travail",
    otherApplication: 'Autres candidatures',

    contactCandidate: `Envoyer une demande de contact?`,
    contactNote: `L'envoi d'une demande de contact indique au candidat que vous êtes intéressé par son profil. Il pourra voir le poste et postuler. Vous allez également pouvoir voir leur profil complet s'il est préqualifié pour le poste.`,
    moreThan10years: `Plus de 10 années d'expérience directe`,
    experience: ` {{years}} années d'expérience directe`,
    addNotes: 'Nous trouvons que tu es un candidat idéal pour notre poste.',
    cancel: 'Annuler',
    sendContactRequest: 'Envoyer demande de contact',
    sendCustomMessage: 'Envoyer une demande de contact personnalisée',

    hasApplied: 'Postulé',
    accepted: 'Acceptées',
    requested: 'Demandé',
    dismissCandidate: 'Rejeter ce candidat',
    communicationCandidate: 'Envoyer un message à ce candidat',

    engageCandidateModal: {
      header: 'Vous utulisez des jetons pour ce compte',
      bodyText: '{{name}} semble être un bon candidat',
      useToken: 'Le serveur traite votre achat...',
      engage: 'Engager',
    },
    engageBtnLabel: 'Engager un candidat',
    sendAgainBtnLabel: 'Renvoyer',
    engageErrorText: `Vous n'êtes pas autorisé à faire cette action.`,
    sendAgainTooltipText: `Votre demande d'intérêt a été envoyée. ⏱ En attente de la réponse du candidat...`,
    engageDisableMsg: `Le chercheur n'a pas encore accepté votre demande de contact !`,
    noBookmarkedCandidates: `Vous n'avez pas de candidats sauvegardés.`,
    noTopCandidates: `Il n'a pas de candidats pour ce poste.`,
    noActiveCandidates: "Ce poste n'a pas de candidats actifs.",
    moreThan10Years: `Plus de 10 années d'expérience directe`,
    directExperience: `années d'expérience directe`,
    seekerProfile: {
      days: 'Jours',
      weeks: 'Semaines',
      immediately: 'Immédiatement',
    },
    lowTokenWarningMessage:
      'Envoyer une demande de contact utilise un de vos jetons. Les jetons sont requis pour rejoindre vos candidats, alors soyez sélectifs pour maximiser vos chances.',
    greetingHello: 'Bonjour',
  },
};
