/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Center,
  Spinner,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Select,
  Text,
  Textarea,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Switch,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  Button,
  Stack,
} from '@chakra-ui/core';
import { ChevronDownIcon } from '@chakra-ui/icons';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _some from 'lodash/some';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { clearErrorMessage, FormattedError, getErrorMessage, hasErrorMessage } from '../../../../utils/FormErrorUtils';
import { IndustryRadiosStyle, ReactCustomSelectStyles } from './CreateJobPositionStyle';
import { getTranslatedStringValue } from '../../../../utils/localizedNumber';
import { getTranslatedValue } from '../../../../utils/localizedString';
import { BusinessSelectType, IndustryType, JobPosition, JobType, OccupationType } from '../PositionType';
import iconTransportation from '../../../../assets/img/icon-transportation.svg';
import iconFood from '../../../../assets/img/icon-food.svg';
import iconHospitality from '../../../../assets/img/icon-hospitality.svg';
import iconRetail from '../../../../assets/img/icon-retail.svg';
import iconServices from '../../../../assets/img/icon-services.svg';
import iconManufacturing from '../../../../assets/img/icon-manufacturing.svg';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import useLanguagePhrasesByAccountId from '../../../../app/hooks/useLanguagePhrasesByAccountId';
import { LanguageEvaluationPhrases } from '../../../../firebase/firestore/documents/languageEvaluationPhrases';
import { LanguageEvaluationRating } from '../../../../firebase/firestore/documents/candidate';
import { DialogFlowAgent } from '../../../../firebase/firestore/documents/accounts';
import { Lookups } from '../../../../firebase/firestore/documents/lookups';
import { MultiSelectDropdown } from '../../../common/MultiSelectDropDown/MultiSelectDropDown';
import useBusinessLocations from '../useBusinessLocations';
import HireFormTemplateSelect from './HireFormTemplateSelect';
import { LanguageEvaluationTemplateDataType } from '../../languageEvaluation/LanguageEvaluationTypes';

export enum IconIndustry {
  TRANSPORTATION = 'Transportation',
  RETAIL = 'Retail',
  FOOD_AND_BEVERAGE = 'Food & Beverage Services',
  HOSPITALITY = 'Hospitality',
  SERVICES = 'Services',
  MANUFACTURING = 'Manufacturing',
}

export enum JobTypes {
  FULL_TIME = 'full_time',
  PART_TIME = 'part_time',
}

const loadIcon = (industryType: string) => {
  switch (industryType) {
    case IconIndustry.TRANSPORTATION:
      return iconTransportation;
    case IconIndustry.FOOD_AND_BEVERAGE:
      return iconFood;
    case IconIndustry.HOSPITALITY:
      return iconHospitality;
    case IconIndustry.RETAIL:
      return iconRetail;
    case IconIndustry.SERVICES:
      return iconServices;
    case IconIndustry.MANUFACTURING:
      return iconManufacturing;
    default:
      return iconServices;
  }
};

export type JobPositionDetailProps = {
  jobPositionData: JobPosition;
  industries: IndustryType[];
  occupationList: OccupationType[];
  jobList: JobType[];
  formErrors: FormattedError[];
  setFormErrors: (errors: FormattedError[]) => void;
  salaryErrors: FormattedError[];
  audioEvaluationOptions?: Lookups[];
  positionDataToEdit: boolean;
  handleJobChange: (value: string, fieldName: string) => void;
  handleChange: (value: string | Date | number | boolean | null, fieldName: string, dataType: boolean) => void;
  handleSalaryChange: (value: string, fieldName: string) => void;
  handleLanguageEvaluationPhrase: (checked: boolean, language: string, phrases: LanguageEvaluationPhrases) => void;
  handleLanguageEvaluation: (checked: boolean) => void;
  handleDialogFlowCheckbox?: (checked: boolean) => void;
  handleLanguageChange: () => void;
  handleDialogFlowAgents?: (agent: DialogFlowAgent) => void;
  isSmbRecruiter?: boolean;
  setJobPositionData?: React.Dispatch<React.SetStateAction<JobPosition>>;
  isCopyPostion?: boolean;
};

export const JobPositionDetailCard = ({
  jobPositionData,
  industries,
  occupationList,
  jobList,
  formErrors,
  setFormErrors,
  salaryErrors,
  positionDataToEdit,
  handleJobChange,
  handleChange,
  handleSalaryChange,
  handleLanguageEvaluationPhrase,
  handleLanguageEvaluation,
  handleDialogFlowCheckbox,
  handleLanguageChange,
  handleDialogFlowAgents,
  audioEvaluationOptions,
  isSmbRecruiter,
  setJobPositionData,
  isCopyPostion,
}: JobPositionDetailProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [phraseLanguage, setPhraseLanguage] = useState<string>('fr');
  const [selectedJobType, setSelectedJobType] = useState<JobTypes>(JobTypes.FULL_TIME);
  const enableNewUiForCreatePosition: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_NEW_UI_FOR_CREATE_POSITION];
  });

  const isLETemplateEnabled = useStoreState((s) => s.app.accounts?.configuration?.languageEvaluationTemplateEnabled);

  const { getLanguageEvaluationTemplates } = useStoreActions((actions) => actions.languageEvaluationTemplate);
  const [languageEvaluationTemplateList, setLanguageEvaluationTemplateList] = useState<LanguageEvaluationTemplateDataType[]>([]);

  // filter occupations and jobs based on selected industry
  const filterOccupations = (industryId: string) =>
    occupationList
      // eslint-disable-next-line radix
      .filter((j) => j.industryRef === parseInt(industryId))
      .sort((o1, o2) => (/* istanbul ignore next */ o1.name.en.toUpperCase() < o2.name.en.toUpperCase() ? -1 : 1));

  const filterJobs = (industryId: string, occupationId: string) =>
    jobList
      // eslint-disable-next-line radix
      .filter((j) => j.occupation === parseInt(occupationId))
      .sort((j1, j2) => (/* istanbul ignore next */ j1.jobTitle.en.toUpperCase() < j2.jobTitle.en.toUpperCase() ? -1 : 1));

  const accountId = useStoreState((state) => state.app.accounts?.id);
  const isModernHireEnable = useStoreState((s) => s.app.accounts?.configuration?.modernHireEnable);
  const isScheduleMatchEnabled = useStoreState((s) => s.app.accounts?.configuration?.scheduleMatchEnabled);
  const isRequsitionEnabled = useStoreState((s) => s.app.accounts?.configuration?.requisitionEnabled);
  const isVirtualRecruiterEnabled = useStoreState((s) => s.app.accounts?.configuration?.virtualRecruiterEnabled);
  const modernHireTemplateIdList = useStoreState((s) => s.app.accounts?.configuration?.modernHireTemplateIdList);
  const isLanguageEvaluationEnabled = useStoreState((s) => s.app.accounts?.configuration?.languageEvaluationEnabled);
  const isResumeEnabled = useStoreState((s) => s.app.accounts?.configuration?.resumeEnabled);
  const dialogFlowAgents = useStoreState((s) => s.app.accounts?.dialogFlowAgents);
  const accountDialogFlowEnabled = useStoreState((s) => s.app.accounts?.configuration?.dialogFlowEnabled);
  const languageEvaluationPhrases = useLanguagePhrasesByAccountId(accountId ?? '');

  /* istanbul ignore next */
  const handleJobLocationChange = (businessLocation: BusinessSelectType | null) => {
    /* istanbul ignore next */
    if (!_isNil(businessLocation) && !_isNil(setJobPositionData)) {
      setJobPositionData((prevData) => ({
        ...prevData,
        business: businessLocation.id,
        businessName: businessLocation.value,
        businessAddress: businessLocation.label,
      }));
    }
    /* istanbul ignore next */
    setFormErrors?.(clearErrorMessage(formErrors, 'business'));
  };
  const businessLocations = useBusinessLocations();

  useEffect(() => {
    if (jobPositionData.languageEvaluationTemplates && jobPositionData.languageEvaluationTemplates.length > 0) {
      setPhraseLanguage(jobPositionData.languageEvaluationTemplates[0].language);
    }
  }, [jobPositionData.languageEvaluationTemplates]);

  const onGetLETemplates = async () => {
    const params: { after?: string; before?: string; default?: boolean; limit?: number } = {
      default: true,
    };
    try {
      if (accountId) {
        const res = await getLanguageEvaluationTemplates({ accountId, params });
        setLanguageEvaluationTemplateList(res?.languageEvaluationTemplates);
      }
    } catch (err) {
      console.error('LE templates error', err);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    onGetLETemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPositionData?.languageEvaluationEnable]);

  useEffect(() => {
    if (jobPositionData?.fullTime === false) {
      setSelectedJobType(JobTypes.PART_TIME);
    } else {
      setSelectedJobType(JobTypes.FULL_TIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPositionData.fullTime]);

  const jobTypes = Object.entries(JobTypes);

  const getJobTypeText = (value: JobTypes): string => {
    switch (value) {
      case JobTypes.FULL_TIME:
        return t('createJobPosition:jobTypedesc.fullTime');
      case JobTypes.PART_TIME:
        return t('createJobPosition:jobTypedesc.partTime');
      default:
        return '';
    }
  };

  const showFeatureEverywhereExceptOnSmbCopy = !isSmbRecruiter || (isSmbRecruiter && !isCopyPostion);

  const renderNumberOfOpenings = () => {
    return (
      <Flex w={isSmbRecruiter ? '50%' : '30%'} pr={isSmbRecruiter ? '0' : '6'}>
        <Box w="100%">
          <FormControl isInvalid={hasErrorMessage(formErrors, 'noOfOpenings')} isRequired={isSmbRecruiter}>
            <FormLabel htmlFor="numberOfOpening">{t('createJobPosition:noOfOpenings')}</FormLabel>
            <NumberInput
              variant="filled"
              id="numberOfOpening"
              min={0}
              max={999}
              value={!_isNil(jobPositionData.noOfOpenings) ? jobPositionData.noOfOpenings : ''}
              onChange={(value) => handleChange(value, 'noOfOpenings', true)}
              data-testid="NumberOfOpeningField"
            >
              <NumberInputField />
              <NumberInputStepper borderColor="#dee1e3">
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormErrorMessage>{getErrorMessage(formErrors, 'noOfOpenings')}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
    );
  };

  const showTemplateBasedEvaluation =
    (!positionDataToEdit ||
      (positionDataToEdit && (jobPositionData?.leTemplateId || _isNil(jobPositionData?.languageEvaluationTemplates)))) &&
    isLETemplateEnabled;

  return (
    <Box backgroundColor="white" borderRadius={5} mb={6}>
      <Box
        p={/* istanbul ignore next */ enableNewUiForCreatePosition ? 0 : 6}
        paddingTop={/* istanbul ignore next */ enableNewUiForCreatePosition ? 6 : '1.5rem'}
      >
        <Box>
          {isCopyPostion && (
            <Flex w="100%" mt={6} mb={3}>
              <FormControl isInvalid={hasErrorMessage(formErrors, 'business')} isRequired>
                <FormLabel htmlFor="jobLocation" fontWeight="bold">
                  {t('createJobPosition:businessLocation')}
                </FormLabel>
                <Box css={ReactCustomSelectStyles} data-testid="JobLocationChangeBtn">
                  <MultiSelectDropdown
                    placeholder={t('createJobPosition:businessLocationPlaceholder')}
                    id="jobLocation"
                    handleChange={handleJobLocationChange}
                    options={businessLocations}
                    value={
                      /* istanbul ignore next */ jobPositionData.businessAddress
                        ? {
                            id: jobPositionData.business,
                            label: jobPositionData.businessName,
                            value: jobPositionData.businessAddress,
                          }
                        : null
                    }
                    data-testid="JobLocationField"
                  />
                </Box>
                <FormErrorMessage>{getErrorMessage(formErrors, 'business')}</FormErrorMessage>
              </FormControl>
            </Flex>
          )}

          {showFeatureEverywhereExceptOnSmbCopy && (
            <FormControl isInvalid={hasErrorMessage(formErrors, 'industry')} mb={8} isRequired>
              <FormLabel>{t('createJobPosition:industry')}</FormLabel>
              {industries.length <= 0 && (
                <Center p={4}>
                  <Spinner color="blue.500" size="sm" />
                </Center>
              )}
              <RadioGroup
                name="industry"
                id="industryLabel"
                css={IndustryRadiosStyle}
                colorScheme="blue"
                data-testid="industryRadio"
                value={jobPositionData.industry}
              >
                <Flex direction="row" wrap="wrap" justify="flex-start">
                  {industries.map((i) => (
                    <Radio
                      onChange={(e) => handleChange(e.target.value, 'industry', false)}
                      mr={2}
                      mt={{ base: 2, sm: 2, md: 2, lg: 2, xl: 0 }}
                      key={i.id}
                      value={String(i.id)}
                      id={String(i.id)}
                      data-testid={`radio-${String(i.id)}`}
                    >
                      <Box>
                        <img src={loadIcon(i.name.en)} alt={getTranslatedValue(i.name, i18n.language)} />
                      </Box>
                      {getTranslatedValue(i.name, i18n.language)}
                    </Radio>
                  ))}
                </Flex>
              </RadioGroup>
              <FormErrorMessage>{getErrorMessage(formErrors, 'industry')}</FormErrorMessage>
            </FormControl>
          )}

          <Flex w="100%" mb={6} flexDirection={isSmbRecruiter ? 'column' : 'row'}>
            {showFeatureEverywhereExceptOnSmbCopy && (
              <Flex w={isSmbRecruiter ? '100%' : '66%'}>
                <Box w="50%" pr={6} mb={isSmbRecruiter ? 6 : 0}>
                  <FormControl isInvalid={hasErrorMessage(formErrors, 'occupation')} isRequired>
                    <FormLabel>{t('createJobPosition:occupation')}</FormLabel>
                    <Select
                      className="filled"
                      name="occupation"
                      id="occupationLabel"
                      value={jobPositionData.occupation}
                      disabled={_isEmpty(jobPositionData.industry)}
                      data-testid="occupationSelect"
                      onChange={(e) => handleChange(e.target.value, 'occupation', false)}
                    >
                      <option value="">{t('createJobPosition:selectOccupationPlaceholder')}</option>
                      {filterOccupations(jobPositionData.industry).map((o) => (
                        <option key={o.id} value={o.id} data-testid={`occupation-${o.id}`}>
                          {getTranslatedValue(o.name, i18n.language)}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{getErrorMessage(formErrors, 'occupation')}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w="50%" pr={6}>
                  <FormControl isInvalid={hasErrorMessage(formErrors, 'job')} isRequired>
                    <FormLabel>{t('createJobPosition:jobType')}</FormLabel>
                    <Select
                      className="filled"
                      name="job"
                      id="jobLabel"
                      value={jobPositionData.job}
                      disabled={_isEmpty(jobPositionData.occupation)}
                      data-testid="jobSelect"
                      onChange={(e) => handleJobChange(e.target.value, 'job')}
                    >
                      <option value="">{t('createJobPosition:selectJobTypePlaceholder')}</option>
                      {filterJobs(jobPositionData.industry, jobPositionData.occupation).map((j) => (
                        <option key={j.id} value={j.id} data-testid={`job-${j.id}`}>
                          {getTranslatedValue(j.jobTitle, i18n.language)}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{getErrorMessage(formErrors, 'job')}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Flex>
            )}
            <Flex justifyContent="space-between" w={isSmbRecruiter ? '100%' : '33%'}>
              <Box w={isSmbRecruiter ? '50%' : '100%'} pr={isSmbRecruiter ? '6' : 'unset'}>
                <FormControl isInvalid={hasErrorMessage(formErrors, 'jobCustomName')} isRequired>
                  <FormLabel htmlFor="jobTitle">{t('createJobPosition:jobTitle')}</FormLabel>
                  <Input
                    variant="filled"
                    id="jobTitle"
                    type="text"
                    value={jobPositionData.jobCustomName}
                    onChange={(e) => handleChange(e.target.value, 'jobCustomName', false)}
                    data-testid="JobCustomNameField"
                  />
                  <FormErrorMessage>{getErrorMessage(formErrors, 'jobCustomName')}</FormErrorMessage>
                </FormControl>
              </Box>

              {isSmbRecruiter && renderNumberOfOpenings()}
            </Flex>
          </Flex>
          <Flex mb={6}>
            {!isSmbRecruiter && renderNumberOfOpenings()}

            {isSmbRecruiter && (
              <Box w="50%" pr={6}>
                <FormControl isRequired>
                  <FormLabel htmlFor="employmentType">{t('createJobPosition:smbEmploymentType')}</FormLabel>
                  <RadioGroup value={selectedJobType} height={8} display="flex" alignItems="flex-end">
                    <Stack spacing={4} direction="row">
                      {jobTypes.map(([key, eachJobType]) => {
                        return (
                          <Radio
                            key={key}
                            value={eachJobType}
                            data-testid={`jobType-${eachJobType}`}
                            onChange={(e) => {
                              if (e.target.value === JobTypes.FULL_TIME) {
                                handleChange(true, 'fullTime', false);
                              } else {
                                handleChange(false, 'fullTime', false);
                              }
                              setSelectedJobType(e.target.value as JobTypes);
                            }}
                          >
                            {getJobTypeText(eachJobType)}
                          </Radio>
                        );
                      })}
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Box>
            )}

            <Flex w={isSmbRecruiter ? '50%' : '100%'}>
              <Box w="20%" mr={4}>
                <FormControl isRequired={isSmbRecruiter}>
                  <FormLabel htmlFor="salary">{t('createJobPosition:salary')}</FormLabel>
                  <Select
                    className="filled"
                    id="salary"
                    value={jobPositionData.proposedSalary.unit}
                    onChange={(e) => handleChange(e.target.value, 'proposedSalary.unit', false)}
                    data-testid="SalaryUnitField"
                  >
                    <option value="annual">{t('createJobPosition:proposedSalaryUnitOptions.annual')}</option>
                    <option value="hourly">{t('createJobPosition:proposedSalaryUnitOptions.hourly')}</option>
                    <option value="custom">{t('createJobPosition:proposedSalaryUnitOptions.custom')}</option>
                  </Select>
                </FormControl>
              </Box>

              <Box w="20%" mr={4}>
                <FormControl isInvalid={hasErrorMessage(salaryErrors, 'minValue')}>
                  <FormLabel>&nbsp;</FormLabel>
                  <InputGroup>
                    <InputLeftAddon>$</InputLeftAddon>
                    <Input
                      type="text"
                      variant="filled"
                      placeholder={jobPositionData.proposedSalary.unit === 'annual' ? '2000' : '20'}
                      value={
                        /* istanbul ignore next */
                        !_isNil(jobPositionData.proposedSalary.minValue)
                          ? getTranslatedStringValue(jobPositionData.proposedSalary.minValue, i18n.language)
                          : ''
                      }
                      onChange={(e) => handleSalaryChange(e.target.value, 'minValue')}
                      data-testid="MinValueField"
                    />
                  </InputGroup>
                  <FormErrorMessage>{getErrorMessage(salaryErrors, 'minValue')}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box mr={4}>
                <FormControl>
                  <FormLabel>&nbsp;</FormLabel>
                  <Text mt={5}>{t('createJobPosition:to')}</Text>
                </FormControl>
              </Box>
              <Box w="20%" mr={4}>
                <FormControl isInvalid={hasErrorMessage(salaryErrors, 'maxValue')}>
                  <FormLabel>&nbsp;</FormLabel>
                  <InputGroup>
                    <InputLeftAddon>$</InputLeftAddon>
                    <Input
                      type="text"
                      variant="filled"
                      placeholder={jobPositionData.proposedSalary.unit === 'annual' ? '5000' : '50'}
                      value={
                        /* istanbul ignore next */
                        !_isNil(jobPositionData.proposedSalary.maxValue)
                          ? getTranslatedStringValue(jobPositionData.proposedSalary.maxValue, i18n.language)
                          : ''
                      }
                      onChange={(e) => handleSalaryChange(e.target.value, 'maxValue')}
                      data-testid="MaxValueField"
                    />
                  </InputGroup>
                  <FormErrorMessage>{getErrorMessage(salaryErrors, 'maxValue')}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box w="15%">
                {jobPositionData.proposedSalary.unit === 'custom' && (
                  <FormControl isInvalid={hasErrorMessage(formErrors, 'proposedSalary.customUnit')}>
                    <FormLabel htmlFor="per">{t('createJobPosition:per')}</FormLabel>
                    <Input
                      variant="filled"
                      id="per"
                      type="text"
                      placeholder={t('createJobPosition:unit')}
                      value={
                        /* istanbul ignore next */ !_isNil(jobPositionData.proposedSalary.customUnit)
                          ? jobPositionData.proposedSalary.customUnit
                          : undefined
                      }
                      onChange={(e) => handleChange(e.target.value, 'proposedSalary.customUnit', false)}
                      data-testid="CustomUnitTypeField"
                    />
                    {}
                    <FormErrorMessage>{getErrorMessage(formErrors, 'proposedSalary.customUnit')}</FormErrorMessage>
                  </FormControl>
                )}
              </Box>
            </Flex>
          </Flex>
          <Flex mb={6}>
            {showFeatureEverywhereExceptOnSmbCopy && (
              <Box w={isSmbRecruiter ? '100%' : '40%'}>
                <Box>
                  <FormControl isInvalid={hasErrorMessage(formErrors, 'jobDescription')} isRequired={isSmbRecruiter}>
                    <FormLabel htmlFor="jobDescription">{t('createJobPosition:jobDescription')}</FormLabel>
                    <Textarea
                      variant="filled"
                      id="jobDescription"
                      placeholder={t('createJobPosition:jobDescriptionPlaceholder')}
                      value={
                        /* istanbul ignore next */ !_isNil(jobPositionData.jobDescription)
                          ? jobPositionData.jobDescription
                          : undefined
                      }
                      onChange={(e) => handleChange(e.target.value, 'jobDescription', false)}
                      data-testid="JobDescriptionField"
                    />
                    <FormErrorMessage>{getErrorMessage(formErrors, 'jobDescription')}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            )}
            {!isSmbRecruiter && (
              <Box w="60%">
                <Flex marginLeft="10px">
                  <Box w="40%" mr={4}>
                    <FormControl>
                      <FormLabel htmlFor="experience">{t('createJobPosition:experience')}</FormLabel>
                      <Select
                        className="filled"
                        id="experience"
                        value={!_isNil(jobPositionData.experienceFilterType) ? jobPositionData.experienceFilterType : undefined}
                        onChange={(e) => handleChange(e.target.value, 'experienceFilterType', false)}
                        data-testid="ExperienceFilterTypeField"
                      >
                        <option value="" disabled selected>
                          {t('createJobPosition:selectFilterByType')}
                        </option>
                        <option value="preferred">{t('createJobPosition:experienceFilterTypeOptions.preferred')}</option>
                        <option value="maximum">{t('createJobPosition:experienceFilterTypeOptions.maximum')}</option>
                        <option value="minimum">{t('createJobPosition:experienceFilterTypeOptions.minimum')}</option>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box w="60%">
                    <FormControl>
                      <FormLabel>&nbsp;</FormLabel>
                      <Flex alignItems="center">
                        <NumberInput
                          min={0}
                          max={100}
                          variant="filled"
                          id="experienceYears"
                          type="number"
                          w="40%"
                          value={jobPositionData.requiredExperience ? jobPositionData.requiredExperience : ''}
                          onChange={(value) => handleChange(value, 'requiredExperience', true)}
                          data-testid="RequiredExperienceField"
                        >
                          <NumberInputField />
                          <NumberInputStepper borderColor="#dee1e3">
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <Text ml={4} w="60%">
                          {t('createJobPosition:yearsOfExperience')}
                        </Text>
                      </Flex>
                    </FormControl>
                  </Box>
                </Flex>
              </Box>
            )}
          </Flex>
          {!isSmbRecruiter && (
            <Fragment>
              <Flex mb={6} mt={3}>
                {/* {hireFormEnabled && (
                  <Box w="30%" pr="6">
                    <Text>{t('createJobPosition:hireForm')}</Text>
                    <FormControl>
                      <Switch
                        size="sm"
                        isChecked={jobPositionData?.enableHireFormSolution}
                        onChange={(e) => handleChange(e.target.checked, 'enableHireFormSolution', false)}
                        data-testid="enableHireForm"
                      />
                    </FormControl>
                  </Box>
                )} */}
                {isScheduleMatchEnabled && isRequsitionEnabled && (
                  <Box w="30%" pr="6">
                    <Text>{t('createJobPosition:scheduleMatch')}</Text>
                    <FormControl>
                      <Switch
                        size="sm"
                        isChecked={jobPositionData?.enableScheduleMatch}
                        onChange={(e) => handleChange(e.target.checked, 'enableScheduleMatch', false)}
                        data-testid="enableScheduleMatch"
                      />
                    </FormControl>
                  </Box>
                )}
                {isVirtualRecruiterEnabled && (
                  <Box w="30%" pr="6">
                    <Text>{t('createJobPosition:virtualRecruiter')}</Text>
                    <FormControl>
                      <Switch
                        size="sm"
                        isChecked={jobPositionData?.virtualRecruiterEnabled}
                        onChange={(e) => handleChange(e.target.checked, 'virtualRecruiterEnabled', false)}
                        data-testid="virtualRecruiterEnabled"
                      />
                    </FormControl>
                  </Box>
                )}
                {isResumeEnabled && (
                  <Box w="30%" pr="6">
                    <Text>{t('createJobPosition:resume')}</Text>
                    <FormControl>
                      <Switch
                        size="sm"
                        isChecked={jobPositionData?.resumeEnabled}
                        onChange={(e) => handleChange(e.target.checked, 'resumeEnabled', false)}
                        data-testid="resumeEnabled"
                      />
                    </FormControl>
                  </Box>
                )}
              </Flex>
              {/* <Flex mb={6} mt={3}>
                {isModernHireEnable && (
                  <Box w="30%" pr="6">
                    <Text mb="0.5rem">{t('createJobPosition:modernHire')}</Text>
                    <FormControl>
                      <Switch
                        size="sm"
                        isChecked={jobPositionData?.modernHireEnable}
                        onChange={(e) => handleChange(e.target.checked, 'modernHireEnable', false)}
                        data-testid="modernHire"
                      />
                    </FormControl>
                  </Box>
                )}
                {isModernHireEnable && (
                  <Box w="30%" pr="6">
                    <FormControl isInvalid={hasErrorMessage(formErrors, 'modernHirePassThreshold')}>
                      <Text mb="0.5rem">{t('createJobPosition:modernHirePassThreshold')}</Text>
                      <NumberInput
                        min={0}
                        max={100}
                        variant="filled"
                        id="experienceYears"
                        type="number"
                        w="40%"
                        isDisabled={!(isModernHireEnable && jobPositionData?.modernHireEnable)}
                        value={jobPositionData?.modernHirePassThreshold ? jobPositionData.modernHirePassThreshold : ''}
                        onChange={(value) => handleChange(value, 'modernHirePassThreshold', true)}
                        data-testid="modernHirePassThreshold"
                      >
                        <NumberInputField />
                        <NumberInputStepper borderColor="#dee1e3">
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <FormErrorMessage>{getErrorMessage(formErrors, 'modernHirePassThreshold')}</FormErrorMessage>
                    </FormControl>
                  </Box>
                )}
                {isModernHireEnable && (
                  <Box w="30%" pr="6">
                    <FormControl isInvalid={hasErrorMessage(formErrors, 'modernHireTemplateId')}>
                      <FormLabel>{t('createJobPosition:templateID')}</FormLabel>
                      <Select
                        className="filled"
                        name="job"
                        id="jobLabel"
                        value={jobPositionData?.modernHireTemplateId}
                        disabled={!(isModernHireEnable && jobPositionData?.modernHireEnable)}
                        data-testid="modernHireTemplateId"
                        onChange={(e) => {
                          handleChange(e.target.value, 'modernHireTemplateId', false);
                        }}
                      >
                        <option value="">{t('createJobPosition:templateID')}</option>
                        {modernHireTemplateIdList?.map((template) => (
                          <option key={template} value={template} data-testid="template-template">
                            {template}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{getErrorMessage(formErrors, 'modernHireTemplateId')}</FormErrorMessage>
                    </FormControl>
                  </Box>
                )}
              </Flex> */}

              {isModernHireEnable && (
                <Flex mb={6} justifyContent="space-between" alignItems="baseline" width="100%">
                  <Checkbox
                    mb={3}
                    isChecked={jobPositionData?.modernHireEnable}
                    onChange={(e) => handleChange(e.target.checked, 'modernHireEnable', false)}
                    data-testid="modernHire"
                    width="33%"
                    mr={4}
                  >
                    {t('createJobPosition:modernHire')}
                  </Checkbox>

                  {jobPositionData?.modernHireEnable && (
                    <Fragment>
                      <Box w="33%" pr="6">
                        <FormControl isInvalid={hasErrorMessage(formErrors, 'modernHirePassThreshold')}>
                          {/* <Text mb="0.5rem">{t('createJobPosition:modernHirePassThreshold')}</Text> */}
                          <NumberInput
                            min={0}
                            max={100}
                            variant="filled"
                            id="experienceYears"
                            type="number"
                            w="100%"
                            isDisabled={!(isModernHireEnable && jobPositionData?.modernHireEnable)}
                            value={jobPositionData?.modernHirePassThreshold ?? ''}
                            onChange={(value) => handleChange(value, 'modernHirePassThreshold', true)}
                            placeholder="Evaluation Pass Threshold"
                            data-testid="modernHirePassThreshold"
                          >
                            <NumberInputField />
                            <NumberInputStepper borderColor="#dee1e3">
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                          <FormErrorMessage>{getErrorMessage(formErrors, 'modernHirePassThreshold')}</FormErrorMessage>
                        </FormControl>
                      </Box>

                      <Box w="33%">
                        <FormControl isInvalid={hasErrorMessage(formErrors, 'modernHireTemplateId')}>
                          {/* <FormLabel>{t('createJobPosition:templateID')}</FormLabel> */}
                          <Select
                            className="filled"
                            name="job"
                            id="jobLabel"
                            value={jobPositionData?.modernHireTemplateId}
                            disabled={!(isModernHireEnable && jobPositionData?.modernHireEnable)}
                            data-testid="modernHireTemplateId"
                            onChange={(e) => {
                              handleChange(e.target.value, 'modernHireTemplateId', false);
                            }}
                            backgroundColor="#EDF2F7"
                          >
                            <option value="" selected disabled>
                              {t('createJobPosition:templateID')}
                            </option>
                            {modernHireTemplateIdList?.map((template) => (
                              <option key={template} value={template} data-testid="template-template">
                                {template}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>{getErrorMessage(formErrors, 'modernHireTemplateId')}</FormErrorMessage>
                        </FormControl>
                      </Box>
                    </Fragment>
                  )}
                </Flex>
              )}
            </Fragment>
          )}

          <HireFormTemplateSelect
            enableHireFormSolution={jobPositionData?.enableHireFormSolution || false}
            hireFormTemplateId={jobPositionData?.hireFormTemplateId || null}
            onHireFormSolutionChange={handleChange}
            onHireFormTemplateChange={handleChange}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />

          {accountDialogFlowEnabled && !isSmbRecruiter && (
            <Flex mb={6} justifyContent="space-between" alignItems="baseline" width="100%">
              <Checkbox
                mb={3}
                isChecked={jobPositionData?.dialogFlowEnabled}
                onChange={(e) => handleDialogFlowCheckbox?.(e.target.checked)}
                data-testid="enableLanguageEvaluationField"
                width="33%"
                mr={4}
              >
                {t('createJobPosition:dialogflow')}
              </Checkbox>

              {jobPositionData?.dialogFlowEnabled && (
                <Fragment>
                  <FormControl w="70%" isRequired isInvalid={hasErrorMessage(formErrors, 'dialogFlowAgents')}>
                    <Flex w="100%">
                      <FormControl w="50%" mr="4%" isRequired>
                        {/* <FormLabel mb={0} htmlFor="phrase-language">
                          {t('createJobPosition:selectEnglishAgent')}
                        </FormLabel> */}
                        <Select
                          value={jobPositionData?.dialogFlowAgents?.find((agent) => agent.defaultLanguageCode === 'en')?.agentId}
                          name="languageEvaluationPassThreshold"
                          id="languageEvaluationPassThreshold-label"
                          data-testid="languageThresholdChange"
                          onChange={(e) => {
                            const foundAgent = dialogFlowAgents?.find(
                              (agent) => agent.agentId === e.target.value,
                            ) as DialogFlowAgent;
                            handleDialogFlowAgents?.(foundAgent);
                          }}
                        >
                          <option value="" selected disabled>
                            {t('createJobPosition:selectEnglishAgent')}
                          </option>
                          {dialogFlowAgents
                            ?.filter((option) => option.defaultLanguageCode === 'en')
                            .map((option) => (
                              <option key={option.agentId} value={option.agentId}>
                                {option.displayName}
                              </option>
                            ))}
                        </Select>
                      </FormControl>

                      <FormControl w="50%" isRequired>
                        {/* <FormLabel mb={0} htmlFor="languageEvaluationPassThreshold-label">
                          {t('createJobPosition:selectFrenchAgent')}
                        </FormLabel> */}
                        <Select
                          value={jobPositionData?.dialogFlowAgents?.find((agent) => agent.defaultLanguageCode === 'fr')?.agentId}
                          name="languageEvaluationPassThreshold"
                          id="languageEvaluationPassThreshold-label"
                          data-testid="languageThresholdChange"
                          onChange={(e) => {
                            const foundAgent = dialogFlowAgents?.find(
                              (agent) => agent.agentId === e.target.value,
                            ) as DialogFlowAgent;
                            handleDialogFlowAgents?.(foundAgent);
                          }}
                        >
                          <option value="" selected disabled>
                            {t('createJobPosition:selectFrenchAgent')}
                          </option>
                          {dialogFlowAgents
                            ?.filter((option) => option.defaultLanguageCode === 'fr')
                            .map((option) => (
                              <option key={option.agentId} value={option.agentId}>
                                {option.displayName}
                              </option>
                            ))}
                        </Select>
                      </FormControl>
                    </Flex>
                    <FormErrorMessage>{getErrorMessage(formErrors, 'dialogFlowAgents')}</FormErrorMessage>
                  </FormControl>
                </Fragment>
              )}
            </Flex>
          )}
          {isLanguageEvaluationEnabled && !isSmbRecruiter && (
            <>
              {showTemplateBasedEvaluation ? (
                <Flex mb={6} justifyContent="space-between" alignItems="baseline" width="100%">
                  <Checkbox
                    mb={3}
                    isChecked={jobPositionData?.languageEvaluationEnable}
                    // onChange={(e) => handleLanguageEvaluation(e.target.checked)}
                    onChange={(e) => handleChange(e.target.checked, 'languageEvaluationEnable', false)}
                    data-testid="enableLanguageEvaluationField"
                    width="33%"
                    mr={4}
                  >
                    {t('createJobPosition:languageEvaluation')}
                  </Checkbox>

                  {jobPositionData?.languageEvaluationEnable && (
                    <Fragment>
                      <FormControl w="33%" mr="4%" isRequired isInvalid={hasErrorMessage(formErrors, 'leTemplateId')}>
                        <Select
                          className="filled"
                          name="job"
                          id="jobLabel"
                          value={jobPositionData?.leTemplateId}
                          disabled={!(isLanguageEvaluationEnabled && jobPositionData?.languageEvaluationEnable)}
                          data-testid="languageEvaluationTemplate"
                          onChange={(e) => {
                            handleChange(e.target.value, 'leTemplateId', false);
                          }}
                          backgroundColor="#EDF2F7"
                        >
                          <option value="" selected disabled>
                            {t('createJobPosition:selectTemplate')}
                          </option>

                          {languageEvaluationTemplateList?.map((template) => (
                            <option key={template.id} value={template.id} data-testid="template-template">
                              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                              {template.templateName}{' '}
                              {template.default ? `${t('administration:languageEvaluation.systemDefault')}` : ''}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'leTemplateId')}</FormErrorMessage>
                      </FormControl>

                      <FormControl w="33%" isRequired isInvalid={hasErrorMessage(formErrors, 'languageEvaluationPassThreshold')}>
                        <Flex>
                          <Select
                            value={jobPositionData?.languageEvaluationPassThreshold}
                            // isDisabled={!jobPositionData.languageEvaluationTemplates.length}
                            name="languageEvaluationPassThreshold"
                            id="languageEvaluationPassThreshold-label"
                            data-testid="languageThresholdChange"
                            onChange={(e) => {
                              handleChange(Number(e.target.value), 'languageEvaluationPassThreshold', false);
                            }}
                          >
                            <option value="" selected disabled>
                              {t('createJobPosition:selectPassThreshold')}
                            </option>
                            {audioEvaluationOptions
                              ?.filter((option) => option.id !== LanguageEvaluationRating.NEEDS_REVIEW)
                              .map((option) => (
                                <option key={option.order} value={option.order}>
                                  {getTranslatedValue(option.label, i18n.language)}
                                </option>
                              ))}
                          </Select>
                        </Flex>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'languageEvaluationPassThreshold')}</FormErrorMessage>
                      </FormControl>
                    </Fragment>
                  )}
                </Flex>
              ) : (
                <Box mb={6} pb={3}>
                  <Checkbox
                    mb={3}
                    isChecked={!_isNil(jobPositionData.languageEvaluationTemplates)}
                    onChange={(e) => handleLanguageEvaluation(e.target.checked)}
                    data-testid="enableLanguageEvaluationField"
                  >
                    {t('createJobPosition:languageEvaluation')}
                  </Checkbox>
                  {!_isNil(jobPositionData.languageEvaluationTemplates) && (
                    <Box>
                      <Flex mb={2} alignItems="flex-start">
                        <FormControl d="flex" alignItems="center" justifyContent="normal" w="48%" mr="4%">
                          <FormLabel mb={0} htmlFor="phrase-language">
                            {t('createJobPosition:selectLanguage')}
                          </FormLabel>
                          <Select
                            value={phraseLanguage}
                            w="60%"
                            data-testid="phraseLanguageChange"
                            id="phrase-language"
                            onChange={(e) => {
                              setPhraseLanguage(e.target.value);
                              handleLanguageChange();
                            }}
                          >
                            <option value="en">{t('createJobPosition:english')}</option>
                            <option value="fr">{t('createJobPosition:french')}</option>
                          </Select>
                        </FormControl>
                        {languageEvaluationPhrases && languageEvaluationPhrases.length > 0 ? (
                          <FormControl
                            d="flex"
                            flexDirection="column"
                            alignItems="baseline"
                            justifyContent="space-between"
                            w="48%"
                            marginRight="4%"
                            isRequired
                            isInvalid={hasErrorMessage(formErrors, 'languageEvaluationTemplates')}
                          >
                            <Flex alignItems="center">
                              <FormLabel mb={0} htmlFor="phrase">
                                {t('createJobPosition:phraseId')}
                              </FormLabel>

                              <Menu closeOnSelect={false}>
                                <MenuButton
                                  variant="outline"
                                  as={Button}
                                  colorScheme="gray"
                                  pr={2}
                                  borderColor="gray.150"
                                  backgroundColor="gray.150"
                                  boxShadow="0 0 0 1px #F5F8FA"
                                  height="3rem"
                                  padding="0.4rem 1rem"
                                  display="flex"
                                  fontWeight="normal"
                                  justifyContent="space-between"
                                  textAlign="left"
                                  rightIcon={<ChevronDownIcon style={{ height: '20px', width: '20px' }} />}
                                >
                                  {t('createJobPosition:selectPhrase')}
                                </MenuButton>
                                <MenuList minWidth="240px" borderColor="gray.50">
                                  <Box>
                                    <Stack p={2} spacing={3} direction="column">
                                      {languageEvaluationPhrases
                                        .filter(
                                          (langEvalPhrase: LanguageEvaluationPhrases) =>
                                            langEvalPhrase.language === phraseLanguage,
                                        )
                                        .map((phrase: LanguageEvaluationPhrases) => (
                                          <Checkbox
                                            defaultIsChecked={_some(jobPositionData.languageEvaluationTemplates, {
                                              phraseId: phrase.id,
                                            })}
                                            colorScheme="blue"
                                            px={3}
                                            key={phrase.id}
                                            value={phrase.id}
                                            data-testid={`phraseCheckBox-${phrase.id}`}
                                            onChange={(e) =>
                                              handleLanguageEvaluationPhrase(e.target.checked, phraseLanguage, phrase)
                                            }
                                          >
                                            {phrase.phraseId}
                                          </Checkbox>
                                        ))}
                                    </Stack>
                                  </Box>
                                </MenuList>
                              </Menu>
                            </Flex>
                            <FormErrorMessage>{getErrorMessage(formErrors, 'languageEvaluationTemplates')}</FormErrorMessage>
                          </FormControl>
                        ) : (
                          /* istanbul ignore next */
                          <Flex alignItems="center">
                            <AiOutlineInfoCircle style={{ width: '36px' }} />
                            <Text ml="6px" fontStyle="italic">
                              {t('createJobPosition:noPhrasetext')}
                            </Text>
                          </Flex>
                        )}

                        <FormControl
                          d="flex"
                          alignItems="center"
                          flexDirection="column"
                          justifyContent="normal"
                          w="48%"
                          mr="4%"
                          isRequired
                          isInvalid={hasErrorMessage(formErrors, 'languageEvaluationPassThreshold')}
                        >
                          <Flex>
                            <FormLabel mb={0} htmlFor="languageEvaluationPassThreshold-label">
                              {t('createJobPosition:selectLanguageEvaluationPassThreshold')}
                            </FormLabel>
                            <Select
                              value={jobPositionData?.languageEvaluationPassThreshold}
                              isDisabled={!jobPositionData.languageEvaluationTemplates.length}
                              w="60%"
                              name="languageEvaluationPassThreshold"
                              id="languageEvaluationPassThreshold-label"
                              data-testid="languageThresholdChange"
                              onChange={(e) => {
                                handleChange(Number(e.target.value), 'languageEvaluationPassThreshold', false);
                              }}
                            >
                              <option value="" selected disabled>
                                {t('createJobPosition:selectPassThreshold')}
                              </option>
                              {audioEvaluationOptions
                                ?.filter((option) => option.id !== LanguageEvaluationRating.NEEDS_REVIEW)
                                .map((option) => (
                                  <option key={option.order} value={option.order}>
                                    {getTranslatedValue(option.label, i18n.language)}
                                  </option>
                                ))}
                            </Select>
                          </Flex>
                          <FormErrorMessage>{getErrorMessage(formErrors, 'languageEvaluationPassThreshold')}</FormErrorMessage>
                        </FormControl>
                      </Flex>
                      {languageEvaluationPhrases &&
                        languageEvaluationPhrases.length > 0 &&
                        jobPositionData.languageEvaluationTemplates?.map((template) => (
                          <Box key={template.phraseId} mb={3}>
                            <FormLabel htmlFor="phrase" fontWeight="bold" fontSize="14px">
                              {
                                languageEvaluationPhrases.filter(
                                  (langPhrase: LanguageEvaluationPhrases) => langPhrase.id === template.phraseId,
                                )[0]?.phraseId
                              }
                            </FormLabel>
                            <Box fontSize="14px" border="1px solid" borderColor="gray.50" borderRadius={3} p={3}>
                              {template.phrase}
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
          {/* Todo: will be uncomment in next release */}
          {/* <Box w="100%"> */}
          {/*  <Box> */}
          {/*    <FormControl> */}
          {/*      <FormLabel htmlFor="jobDescription" fontWeight="bold"> */}
          {/*        {t('createJobPosition:recruiterNotes')} */}
          {/*      </FormLabel> */}
          {/*      <Textarea */}
          {/*        variant="filled" */}
          {/*        id="recruiterNote" */}
          {/*        placeholder={t('createJobPosition:recruiterNotePlaceholder')} */}
          {/*        value={ */}
          {
            /*          /* istanbul ignore next */
            //! _isNil(jobPositionData.recruiterNote) ? jobPositionData.recruiterNote : undefined} */
          }
          {/*        } */}
          {/*        onChange={(e) => handleChange(e.target.value, 'recruiterNote', false)} */}
          {/*        data-testid="recruiterNote" */}
          {/*      /> */}
          {/*    </FormControl> */}
          {/*  </Box> */}
          {/* </Box> */}
        </Box>
      </Box>
    </Box>
  );
};
