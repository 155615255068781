export default {
  formBuilder: {
    title: 'Constructeur de formulaire',
    save: 'Sauvegarder',
    clearAll: 'Effacer tout',
    cancel: 'Annuler',
    loadingText: 'Nous contruisons votre modèle...',
    templateNameInputPlaceholder: 'Entrer le nom du modèle',
    signatureSwapError: 'Le composant signature doit demeurer à la fin du modèle.',
    dragBoxInfo: 'Déplacez la souris ou cliquez sur le menu Composants à la gauche pour commencer à construire votre modèle.',
    sectionInputPlaceholder: 'Entrer le nom de la section',
    sectionNameExistsMessage: 'Ce nom de section existe déjà. Vous devez choisir un autre nom.',
    deletePopoverMessage: 'Êtes-vous certain de vouloir supprimer ceci?',
    clearAllPopoverMessage: 'Êtes-vous certain de vouloir supprimer tous les éléments du formulaire?',
    yes: 'Oui',
    no: 'Non',
    selectAField: 'Choisir un champ',
    sectionSortDrawer: {
      title: 'Modifier la section',
      save: 'Sauvegarder',
      cancel: 'Annuler',
    },
    languageReviewModal: {
      title: 'Avez-vous vérifié ce modèle dans tous ses langues?',
      message: 'Vous devrez vérifier le contenu de ce modèle dans tous les langues applicables avant de continuer.',
      confirmText: "J'ai vérifié le contenu de ce modèle dans tous les langues applicables.",
      confirmButton: 'Confirmer',
      finalReviewSignature: {
        label: 'Nécessiter une signature de la part du candidat',
        description:
          "Activer cette option pour nécessiter que le candidat fournisse une signature avant de soumettre leur formulaire d'embauche.",
      },
    },
    preview: {
      title: 'Aperçu du formulaire',
      publish: 'Publier',
      deviceView: "Vue dans l'appareil",
      listView: 'Vue en liste',
      mobilePreview: {
        newHire: 'Nouvelle embauche',
        help: 'Aide',
        getStarted: 'Commencer',
        chooseFile: 'Choisir un fichier',
        openCamera: 'Ouvrir la caméra',
        selectDate: 'Choisir une date',
        selectTime: 'Choisir une heure',
        reviewDocument: 'Consulter le document',
        signHere: 'Signe ici',
        showMe: 'Montre-moi',
        findAddress: "Trouver l'adresse",
      },
    },
    componentSidebar: {
      tabs: {
        basic: 'Simple',
        advanced: 'Advancé',
        tags: 'Étiquettes',
      },
      searchPlaceholder: 'Rechercher les composants',
      pageElement: 'Élements du formulaire',
      components: {
        text: 'Texte',
        info: 'Instructions',
        email: 'Courriel',
        phone: 'Téléphone',
        link: 'Lien',
        date: 'Date',
        time: 'Heure',
        number: 'Nombre',
        radio: 'Choix unique',
        checkbox: 'Choix multiple',
        gender: 'Genre',
        yesNo: 'Oui/Non',
        address: 'Adresse',
        image: 'Ajouter une image',
        file: 'Fichier',
        document: 'Aperçu de document',
        singleCheckbox: 'Case à cocher',
        button: 'Bouton',
        correspondenceLanguage: 'Langue de corréspondance',
        signature: 'Signature',
        section: 'Section',
        sin: 'NAS',
        ssn: 'NSS',
        finalSinature: 'Dernière signature',
        documentPreview: 'Aperçu de document',
        canadianBank: 'Détails de banque canadienne',
        americanBank: 'Détails de banque americaine',
        earliestStartDate: 'Première date de début',
        addressValidator: "Validateur d'addresse",
        emergencyContact: "Contact en cas d'urgence",
        miscellaneous: 'Divers',
        bankAccountNumber: 'Numéro de compte de banque',
        routingNumber: "Numéro d'acheminement",
        branchTransitNumber: "Numéro de l'unité de la succursale",
        financialInstitutionNumber: "Numéro d'institution financière",
        checkboxDisabled: 'Case à cocher désactivé',
        fullname: 'Nom et prénom',
      },
      advancedOnceInsertMessage: '{{component}} peut seulement être inséré une fois dans un modèle.',
      onlyOneBankInsertMessage: 'Seulement un de {{component1}} ou {{component2}} peut être inséré une fois dans un modèle.',
    },
    signHere: 'Signe ici',
    previewDocument: 'Afficher un document',
    showNextQuestion: 'Afficher la question suivante sur',
    inputHere: 'Saisir ici',
    inputField: 'Champ de saisie',
    systemDefault: 'Paramètre par défaut',
    addFollowUp: 'Ajouter un suivi',
    emptyFollowUpPlaceholder: 'Glisser-déposer les éléments à gauche pour ajouter un suivi.',
    editorPlaceholder: {
      text: 'Saisir le texte ici',
      yesNo: 'Saisir le texte ici pour une réponse oui/non',
      date: 'Saisir le texte ici pour une date',
      number: 'Saisir le texte ici pour un nombre',
      radio: 'Saisir le texte ici pour un choix unique',
      checkbox: 'Saisir le texte ici pour un choix multiple',
      image: 'Saisir le texte ici pour une image',
      file: 'Saisir le texte ici pour un fichier',
      document: 'Saisir le texte ici pour un document',
      address: 'Saisir le texte ici pour une adresse',
      email: 'Saisir le texte ici pour un courriel',
      phone: 'Saisir le texte ici pour un numéro de téléphone',
      link: 'Saisir le texte ici pour un lien',
      time: 'Saisir le texte ici pour une heure',
    },
    chooseAFile: 'Choisir un fichier',
    openCamera: 'Ouvrir la caméra',
    addLabel: 'Ajouter une étiquette',
    showMe: 'Montre-moi',
    documentReview: {
      description1: 'Cliquez sur le bouton ci-dessous pour télécharger votre document PDF.',
      description2:
        "Veuillez vous assurer que le fichier est au format PDF, d'une taille maximale de 2 Mo et d'une résolution idéale pour la visualisation sur un appareil mobile.",
      chooseFiles: 'Choisir les fichiers',
    },
    characterLimit: {
      title: 'Limite de caractères',
      message:
        "Définissez le nombre minimum et maximum de caractères autorisés dans ce champ. L'utilisateur doit saisir entre {{min}} et {{max}} caractères lorsqu'il répond à cette question.",
      min: 'Minimum',
      max: 'Maximum',
      reset: 'Remise à zéro',
      cancel: 'Annuler',
      done: 'Terminé',
    },
  },
  templateListView: {
    title: 'Modèles de formulaire',
    showDefault: 'Afficher les modèles par défaut',
    addTemplate: 'Créer un nouveau modèle',
    tableHeaders: {
      name: 'Nom',
      createdDate: 'Date de création',
      editHistory: 'Dernière modification',
      actions: 'Actions',
    },
    tableActions: {
      edit: 'Modifier',
      delete: 'Supprimer',
      copy: 'Copier',
      deleteSuccessMessage: '{{template}} a été supprimé.',
      deleteErrorMessage: 'Une erreur est survenue en essayant de supprimer {{template}}. SVP essayez de nouveau.',
    },
    paginationText: {
      showingResult: 'Affiche présentement {{currentItems}} de {{totalItems}} modèles',
    },
  },
  validation: {
    templateNameMaxLength: 'La valeur ne peut pas dépasser la longueur maximale autorisée.',
    templateNameRequired: 'Ce champ est obligatoire.',
    linkMaxLength: 'La valeur ne peut pas dépasser la longueur maximale autorisée.',
    labelMaxLength: 'La valeur ne peut pas dépasser la longueur maximale autorisée.',
    labelRequired: 'Ce champ est obligatoire.',
    textMaxLength: 'La valeur ne peut pas dépasser la longueur maximale autorisée.',
    textRequired: 'Ce champ est obligatoire.',
    confirmTextMaxLength: 'La valeur ne peut pas dépasser la longueur maximale autorisée.',
    confirmTextRequired: 'Ce champ est obligatoire.',
    fileAccept: 'Seuls certains types de fichiers sont autorisés.',
    fileRequired: 'Ce champ est obligatoire.',
    fileMaxSize: 'La taille du fichier dépasse la limite maximale autorisée.',
    sectionMaxLength: 'La valeur ne peut pas dépasser la longueur maximale autorisée.',
    sectionRequired: 'Ce champ est obligatoire.',
    fieldRequired: 'Ce champ est obligatoire.',
    requiredInEnglish: 'Ce champ est obligatoire pour l’anglais.',
    validUrlInEnglish: 'Veuillez saisir une URL valide pour l’anglais.',
    urlRequiredInEnglish: 'Veuillez télécharger un document pour l’anglais.',
    validUrlInFrench: 'Veuillez saisir une URL valide pour le français.',
    urlRequiredInFrench: 'Veuillez télécharger un document pour le français.',
    urlRequiredForBoth: 'Les documents en anglais et en français sont obligatoires.',
    invalidFollowUp: 'Configuration de question de suivi invalide.',
    invalidFollowUpOption: 'Option de question de suivi invalide.',
    duplicateOptions: 'Options en double trouvées.',
    duplicateQuestionId: 'Identifiants en double trouvés.',
  },
};
