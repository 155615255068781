import isArray from 'lodash/isArray';
import { useHireFormContext } from '../HireForm.context';
import { DropTargetT } from './formElementBuilder/DroppableElement';
import { FieldSchema, formBuilderType, isFieldSchema, mapField, SectionSchema } from './formBuilderSchema';
import { v4 as uuidv4 } from 'uuid';
import useHandleAddSignature from './useHandleAddSignature';

export type IDropOnType = 'parent' | 'section' | 'follow-up';
interface UseHandleDropProps {
  // dropOnType: IDropOnType;
  sectionIndex: number;
  fieldIndex?: number;
}

export default function useHandleDrop({ sectionIndex, fieldIndex }: UseHandleDropProps) {
  const { draggedElement, formSchema, setFormSchema } = useHireFormContext();

  const { checkAddSignature } = useHandleAddSignature();

  /**
   * Handle the drop event to the section.
   *
   * @param {dropTarget: DropTargetT} - The drop target object.
   * @returns {void}
   */
  function handleDropToSection({ dropTarget }: { dropTarget: DropTargetT['position'] | undefined }): void {
    if (!draggedElement || !formSchema || !formSchema[sectionIndex]) return;
    const { defaultValue: value, type } = draggedElement;
    const defaultValue = checkAddSignature({
      defaultValue: value,
      type,
    });
    const dropType = draggedElement.panel;
    if (dropType === 'page-element') return;

    const isAdvancedSection = Boolean(dropType === 'advanced');
    const newFormSchema = [...formSchema];
    const currentSection = newFormSchema[sectionIndex];

    const insertIndex = dropTarget === 'bottom' ? currentSection.fields.length : fieldIndex ?? 0;

    if (isArray(defaultValue)) {
      if (isAdvancedSection) {
        const newFields = defaultValue?.map((field) => mapField(field, currentSection.section));
        const newFieldsSchema: SectionSchema = {
          section: draggedElement.section || `section${newFormSchema.length + 1}`,
          isAdvanced: true,
          fields: newFields,
          id: uuidv4(),
        };
        if (!(newFields?.length > 0)) {
          console.error(`Error: The dragged element could not be mapped to a valid field. Field: ${defaultValue}`);
          return;
        }
        currentSection.fields.splice(insertIndex, 0, newFieldsSchema);
      }
    } else {
      const newField = mapField(defaultValue);

      if (newField === null) {
        console.error(`Error: The dragged element could not be mapped to a valid field. Field: ${defaultValue}`);
        return;
      }

      currentSection.fields.splice(insertIndex, 0, newField);
    }

    setFormSchema(newFormSchema);
  }

  function handleDropToParent({ dropTarget }: { dropTarget: DropTargetT['position'] | undefined }) {
    if (!draggedElement || !formSchema) return;

    const { panel: dropPanel, type: inputType, defaultValue: value, section: sectionName } = draggedElement;
    const defaultValue = checkAddSignature({
      defaultValue: value,
      type: inputType,
    });
    if (defaultValue === null) {
      console.error(`Error: The dragged element could not be mapped to a valid field. Field: ${defaultValue}`);
      return;
    }

    const isLastSection = sectionIndex === formSchema.length - 1;
    const insertIndex = isLastSection ? sectionIndex + 1 : dropTarget === 'bottom' ? sectionIndex + 1 : sectionIndex;

    const newFormSchema = [...formSchema];
    const newSection: SectionSchema = { section: 'section' + (formSchema.length + 1), fields: [], id: uuidv4() };

    const hasSignature = formSchema
      .flatMap((section) => section.fields)
      .some((field) => isFieldSchema(field) && field.inputType === formBuilderType.signatureAdvanced);

    // Insert the NEW SECTION
    if (dropPanel === 'page-element') {
      if (hasSignature) {
        newFormSchema.splice(newFormSchema.length - 1, 0, newSection);
      } else {
        newFormSchema.splice(insertIndex, 0, newSection);
      }
      setFormSchema(newFormSchema);
      return;
    }

    // Insert the new ADVANCED SECTION
    if (dropPanel === 'advanced' && isArray(defaultValue)) {
      if (sectionName) {
        newSection.section = sectionName;
      }
      newSection.isAdvanced = true;
      newSection.fields = defaultValue.map((field) => mapField(field));
      if (newSection.fields === null) {
        console.error(`Error: The dragged element could not be mapped to a valid field. Field: ${defaultValue}`);
        return;
      }

      if (hasSignature) {
        newFormSchema.splice(newFormSchema.length - 1, 0, newSection);
      } else if (
        inputType === formBuilderType.signatureAdvanced ||
        (newFormSchema.length === 1 && newFormSchema[0].fields.length === 0)
      ) {
        newFormSchema.push(newSection);
      } else {
        newFormSchema.splice(insertIndex, 0, newSection);
      }
      setFormSchema(newFormSchema);
      return;
    }

    // Insert the new BASIC ELEMENT
    newSection.fields = [mapField(defaultValue as FieldSchema)];
    if (newSection.fields === null) {
      console.error(`Error: The dragged element could not be mapped to a valid field. Field: ${defaultValue}`);
      return;
    }

    if (hasSignature) {
      newFormSchema.splice(newFormSchema.length - 1, 0, newSection);
    } else {
      newFormSchema.splice(insertIndex, 0, newSection);
    }
    setFormSchema(newFormSchema);
  }

  return { handleDropToSection, handleDropToParent };
}
