import {
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  useDisclosure,
  Text,
  Box,
} from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';

interface DeletePopoverProps {
  popoverTrigger: React.ReactNode;
  onYes: () => void;
  open?: boolean;
  handleClose?: () => void;
}

export default function DeletePopover({ popoverTrigger, onYes, open = false, handleClose }: DeletePopoverProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { t } = useTranslation('hrFormTemplate');

  React.useEffect(() => {
    if (open) {
      onOpen();
    }
    // eslint-disable-next-line
  }, [open]);
  const id = uuidV4();

  function handleOnClose() {
    if (handleClose) {
      handleClose();
    }
    onClose();
  }
  return (
    <Box position="relative" key={id}>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={handleOnClose}
        closeOnBlur
        closeOnEsc
        autoFocus={false}
        placement="left-start"
        isLazy
      >
        <PopoverTrigger>{popoverTrigger}</PopoverTrigger>
        <Portal>
          <PopoverContent key={id}>
            <PopoverCloseButton />
            <PopoverBody>
              <Text>{t('formBuilder.deletePopoverMessage')}</Text>
            </PopoverBody>
            <PopoverFooter display="flex" alignItems="center" justifyContent="flex-end">
              <ButtonGroup size="sm" variant="outline">
                <Button
                  colorScheme="red"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleOnClose();
                    onYes();
                  }}
                >
                  {t('formBuilder.yes')}
                </Button>
                <Button onClick={handleOnClose}>{t('formBuilder.no')}</Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
}
