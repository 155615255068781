import { useDisclosure, useToast, Box, Button } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { PATH_HIRE_FORM_COPY, PATH_ADMINISTRATION_TEMPLATES } from '../../../../routes/constants';
import useHandleTemplateValidation from '../errorHandling/hireFormValidation.schema';
import { generateFormSchema } from '../formElements/formBuilderSchema';
import { useHireFormContext } from '../HireForm.context';
import LanguageReviewModal from './LanguageReviewModal';
import { useStoreActions, useStoreState } from '../../../../models/hooks';

interface SaveTemplateProps {
  templateName: string;
  needFinalSignature: boolean;
}
const SaveTemplate = React.memo(({ templateName, needFinalSignature }: SaveTemplateProps) => {
  const { t } = useTranslation('hrFormTemplate');
  const { onClose, isOpen, onOpen } = useDisclosure();
  const toast = useToast();
  const { formSchema, language, setShowExitPrompt } = useHireFormContext();

  const { isUpdating, currentTemplate } = useStoreState((state) => state.hrFormTemplate);
  const { createTemplate, updateTemplate, setErrors } = useStoreActions((state) => state.hrFormTemplate);
  const { currentUserProfile } = useCurrentUserProfile();

  const templateId = useParams<{ templateId?: string }>()?.templateId ?? undefined;
  const isDuplicate = useRouteMatch(PATH_HIRE_FORM_COPY);
  const accountId = currentUserProfile?.account ?? '';
  const history = useHistory();

  const { TemplateSchema } = useHandleTemplateValidation();

  React.useEffect(() => {
    const hasChanged = templateName?.trim()?.length > 0 && templateName !== currentTemplate?.name;
    setShowExitPrompt(hasChanged);
  }, [templateName, currentTemplate, setShowExitPrompt]);

  async function handleSaveTemplate() {
    try {
      setShowExitPrompt(false);
      const questions = generateFormSchema(formSchema, language);

      const validation = TemplateSchema.validate(
        { name: templateName, questions: generateFormSchema(formSchema, language, true) },
        { abortEarly: false },
      );
      if (validation.error) {
        setErrors(validation.error.details);
      } else {
        // let response: HrFromCreateTemplateResponse | undefined = undefined;
        if (templateId && !isDuplicate) {
          await updateTemplate({
            account: accountId,
            id: templateId,
            payload: { name: templateName?.trim(), questions: questions, needFinalSignature },
          });
        } else {
          await createTemplate({
            account: accountId,
            payload: { name: templateName?.trim(), questions: questions, needFinalSignature },
          });
        }

        toast({
          title: `Template ${templateId ? 'updated' : 'created'}`,
          status: 'success',
          isClosable: true,
        });

        setErrors([]);
        setShowExitPrompt(false);
        setTimeout(() => {
          history?.replace(PATH_ADMINISTRATION_TEMPLATES);
        }, 0);
        // if (!isDuplicate) {

        // }
        // if (isDuplicate) {
        //   history.replace(PATH_HIRE_FORM_EDIT.replace(':templateId', response?.data?.templateId ?? (templateId || '')));
        // }
      }
    } catch (error) {
      toast({
        title: `Error ${templateId ? 'updating' : 'creating'} template`,
        status: 'error',
        isClosable: true,
      });
    }
  }

  function handleOnConfirm() {
    onClose();
    handleSaveTemplate();
  }
  return (
    <Box>
      <Button
        colorScheme="blue"
        isDisabled={
          formSchema.length === 0 || (formSchema.length === 1 && formSchema?.[0]?.fields?.length === 0) || isUpdating
          // errors.length > 0
        }
        onMouseDown={(e) => e.preventDefault()}
        isLoading={isUpdating}
        onClick={onOpen}
        data-testid="save-template-button"
      >
        {t('formBuilder.save')}
      </Button>
      <LanguageReviewModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => {
          setTimeout(() => {
            handleOnConfirm();
          }, 0);
        }}
      />
    </Box>
  );
});

export default SaveTemplate;
