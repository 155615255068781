/* eslint-disable @typescript-eslint/no-floating-promises */
/* istanbul ignore file */
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ChangeEvent, Fragment, memo, useCallback, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enGB, fr } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import _includes from 'lodash/includes';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Joi from 'joi';
import { MdHelpOutline } from 'react-icons/md';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import { confirmationModalHeaderCSS } from './ConfirmationModal.styles';
import useSeekerNotes from '../../candidate/notes/useSeekerNotes';
import { getTranslatedNumber, getTranslatedStringValue } from '../../../../../utils/localizedNumber';
import { SeekersPresenter } from '../../../sidebar/seekerList/seekersPresenter';
import SeekerListSortType from '../../../sidebar/filters/seekerListSortType';
import SeekerListGroupType from '../../../sidebar/filters/seekerListGroupType';
import { MESSAGING_CANDIDATE_TABS, MESSAGING_TABS } from '../../../../../routes/constants';
import { SeekerContainerTabItems, useSeekerContainerContext } from '../../SeekerContainerContext';
import { useMessagingViewContext } from '../../../MessagingViewContext';
import { reactDatePickerCSS } from '../../../../calendar/scheduleSidebar/ReactDatePicker.Style';
import { drawerCSS } from '../../../../calendar/CalendarView.Style';
import { ReactComponent as _IconCalendar } from '../../../../../assets/img/icon-calendar-blue.svg';
import { getTranslatedValue } from '../../../../../utils/localizedString';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
} from '../../../../../utils/FormErrorUtils';
import useBusinessLocations from '../../../../administration/positionManagement/useBusinessLocations';
import { DateAndTimePickerCoustomCSS } from '../../../../administration/positionManagement/createJobs/CreateJobPositionStyle';
import { BusinessSelectType, PositionSelectType } from '../../../../administration/positionManagement/PositionType';
import colors from '../../../../../styles/colors';
import { Business, HireFormErrorResponse, HireFormType, HireFormTypeEnum, RequestHireDetailForm } from './HireFormTypeEnum';
import { CandidateStatus } from '../../../../../firebase/firestore/documents/candidate';
import { usePositionDocumentSnapshot } from './usePositionDocumentSnapshot';
import { hireFormContractWording } from './hireFormContractWording';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';
import { SelectRequisition } from '../SelectRequisition';
import { Requisition } from '../../../../../firebase/firestore/documents/requisition';
import { isCandidatePage } from '../../../../../utils/candidatesUtils';
import useHistory from '../../candidate/history/useHistory';
import { DismissConfirmationModal } from './DismissConfirmationModal';

const IconCalendar = memo(_IconCalendar);
registerLocale('fr', enGB);

registerLocale('fr', fr);

export type HireRejectConfirmationModalProps = {
  isOpen: boolean;
  disabled: boolean;
  onClose: () => void;
  warningMessage: boolean;
  messageModalOpen: () => void;
};

export type HireInfo = {
  businessLocation: string;
  jobPosition: string;
  joinDate?: Date | null;
  notes: string | null;
};

export type NoteDetail = {
  salary: string | number;
  salaryUnit?: string;
  customSalaryUnit?: string;
};

const DefaultHireInfo: HireInfo = {
  joinDate: null,
  jobPosition: '',
  businessLocation: '',
  notes: '',
};
const maximumSalary = 999999;

const maxHoursPerWeek = 150;

export const HireRejectConfirmationModal = ({
  isOpen,
  warningMessage,
  messageModalOpen,
  disabled,
  onClose,
}: HireRejectConfirmationModalProps): JSX.Element => {
  const { t, i18n } = useTranslation();

  const hireFormTypes = [
    { value: HireFormTypeEnum.NEW_HIRE, label: t('messaging:actions.hire.confirmationModal.startNewHire') },
    { value: HireFormTypeEnum.SEASONAL, label: t('messaging:actions.hire.confirmationModal.startSeasonalContractor') },
  ];

  const seekerList: SeekersPresenter | undefined = useStoreState((s) => s.candidate.allCandidates);
  const { histories } = useHistory();
  const offeredSalary = histories?.getRecentOfferMadeStatus()?.[0].offeredSalary;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updatedPositionId, setUpdatedPositionId] = useState<string | undefined>(undefined);
  const isHireFormEnabled = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabled);
  const hireFormEnabledPositions = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabledPositions);
  const seasonalContractPeriod = useStoreState((s) => s.app.accounts?.configuration?.seasonalContractPeriod);
  const [radioValue, setRadioValue] = useState<string>('hired');
  const { selectedCandidate, setSelectedCandidate } = useSeekerContainerContext();
  const { setSelectedEngagedCandidate, setSelectedEngagedSeeker } = useMessagingViewContext();
  const shouldAllowHireForm = () => {
    if (isHireFormEnabled) {
      if (updatedPositionId) {
        return _includes(hireFormEnabledPositions, updatedPositionId);
      }
      if (selectedCandidate?.newHireData) {
        return _includes(hireFormEnabledPositions, selectedCandidate?.newHireData?.positionId);
      }
      return _includes(hireFormEnabledPositions, selectedCandidate?.position);
    }
    return false;
  };
  const earliestAvailabilityDate = selectedCandidate?.earliestAvailabilityDate;
  const [noteContent, setNoteContent] = useState<string | null>(
    shouldAllowHireForm() ? null : `${t('messaging:actions.hire.hireDetailsDesc1')}`,
  );
  const { isOpen: isDismissModalOpen, onOpen: onDismissModalOpen, onClose: onDismissModalClose } = useDisclosure();

  const [salary, setSalary] = useState<string>('');
  const [unit, setUnit] = useState<string>('hourly');
  const [customUnit, setCustomUnit] = useState<string | null>('');
  const [disabledButton, setButtonDisabled] = useState<boolean>(true);
  const [joinDate, setJoinDate] = useState<Date | null | undefined>(
    earliestAvailabilityDate ? new Date(earliestAvailabilityDate) : undefined,
  );
  const [partTimeHours, setPartTimeHours] = useState<string>('0.00');
  const [previousData, setPreviousData] = useState<string>('');
  const [endDate, setEndDate] = useState<Date | null>();
  const [fullTime, setFullTime] = useState<string>('fullTime');
  const [activeBusiness, setActiveBusiness] = useState<Business>({});
  const [hireInfo, setHireInfo] = useState<HireInfo>(DefaultHireInfo);
  const [hireFormType, setHireFormType] = useState<HireFormType | undefined>(undefined);
  const [updatedBusinessId, setUpdatedBusinessId] = useState<string | undefined>(undefined);

  const [fromHireReject, setFromHireReject] = useState<boolean>(true);

  const [previousRequisitionID, setPreviousRequisitionID] = useState<string | undefined>(selectedCandidate?.requisitionId);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const businessLocations = useBusinessLocations();
  const positionDocument = usePositionDocumentSnapshot(selectedCandidate?.position);
  const [dismissedCandidateAfterRejection, setDismissedCandidateAfterRejection] = useState<boolean>(true);
  const [selectedRequisition, setSelectedRequisition] = useState<Requisition | undefined>(undefined);
  const selectedBusinessLocation = businessLocations.find((businessLocation) =>
    selectedCandidate?.newHireData?.businessId
      ? businessLocation.id === selectedCandidate.newHireData.businessId
      : businessLocation.id === positionDocument?.business,
  );
  const [businessLocation, setBusinessLocation] = useState<BusinessSelectType | null>(null);
  const { selectedSeeker } = useMessagingViewContext();
  const [contractDetailsWordings, setContractDetailsWordings] = useState<string>(hireFormContractWording(selectedSeeker?.locale));
  const { isOpen: isOpenConformationModal, onOpen: onOpenConformationModal, onClose: onCloseConformationModal } = useDisclosure();

  const requisitionEnabled = useStoreState((state) => state.app.accounts?.configuration?.requisitionEnabled);

  const { currentUserProfile } = useCurrentUserProfile();

  const seekerNotes = useSeekerNotes();

  const hireCandidate = useStoreActions((actions) => actions.messaging.hireCandidate);

  const onRequestHireDetails = useStoreActions((actions) => actions.messaging.requestHireDetails);

  const saveOfferRejectedNote = useStoreActions((actions) => actions.account.saveOfferRejectedNote);
  const dismissCandidate = useStoreActions((actions) => actions.messaging.dismissCandidate);

  const toast = useToast();

  const accountLocale = useStoreState((s) => s.app.user?.locale);

  let noteDetail: NoteDetail | undefined;
  /* eslint-disable no-return-assign */
  seekerNotes.presenter.positionNotes
    .filter((position) => position.positionId === selectedCandidate?.position)
    .map((notepositon) => {
      return notepositon.notes.map((n) => {
        if (n.recruiterIdInNote === currentUserProfile?.id) {
          return Array.isArray(n.note)
            ? n.note.reverse().map((note) => (note.type === 'OFFER_DETAILS' ? (noteDetail = note.additionalContextData) : ''))
            : '';
        }
        return '';
      });
    });

  /* eslint-disable no-return-assign */

  const wageSchema = Joi.object().keys({
    unit: requisitionEnabled
      ? Joi.string().optional()
      : Joi.string()
          .required()
          .messages({
            'string.empty': t('messaging:actions.hire.confirmationModal.unitRequired'),
          }),
    value: requisitionEnabled
      ? Joi.string().optional()
      : Joi.string()
          .required()
          .messages({
            'string.empty': t('messaging:actions.hire.confirmationModal.valueRequired'),
          }),
    customUnit: Joi.string().allow(null).optional(),
  });

  const businessSchema = Joi.object().keys({
    name: Joi.string()
      .required()
      .messages({
        'string.empty': t('messaging:actions.hire.confirmationModal.businessNameRequired'),
      }),
    country: Joi.string()
      .required()
      .messages({
        'string.empty': t('messaging:actions.hire.confirmationModal.countryRequired'),
      }),
    address: Joi.string().allow('').allow(null).optional(),
    locationId: Joi.string().allow('').allow(null).optional(),
  });

  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      type: Joi.string()
        .required()
        .messages({
          'any.required': t('messaging:actions.hire.confirmationModal.formTypeRequired'),
        }),
      positionName: Joi.string()
        .required()
        .messages({
          'string.empty': t('messaging:actions.hire.confirmationModal.positionNameRequired'),
        }),
      note: Joi.string().allow('').allow(null).optional(),
      updatedBusinessId: Joi.string().allow(null).optional(),
      updatedPositionId: Joi.string().allow(null).optional(),
      wage: wageSchema,
      business: businessSchema,
      fullTime:
        hireFormType && hireFormType.value === HireFormTypeEnum.SEASONAL
          ? Joi.boolean()
              .required()
              .messages({
                'any.required': t('messaging:actions.hire.confirmationModal.candidateStatusRequired'),
              })
          : Joi.boolean().allow(null).optional(),
      partTimeHours:
        fullTime === 'partTime'
          ? Joi.string().required().messages({
              'any.required': 'Hours are required',
              'string.empty': 'Hours are required',
            })
          : Joi.string().optional(),
      startingDate: requisitionEnabled
        ? Joi.date().optional()
        : Joi.date()
            .required()
            .messages({
              'any.required':
                hireFormType && hireFormType.value === HireFormTypeEnum.SEASONAL
                  ? t('messaging:actions.hire.confirmationModal.contractStartDateRequired')
                  : t('messaging:actions.hire.confirmationModal.startDateRequired'),
            }),
      endDate:
        hireFormType && hireFormType.value === HireFormTypeEnum.SEASONAL
          ? Joi.date()
              .required()
              .messages({
                'any.required': t('messaging:actions.hire.confirmationModal.contractEndDateRequired'),
              })
          : Joi.date().allow(null).optional(),
      contractDetailsWordings:
        hireFormType && hireFormType.value === HireFormTypeEnum.SEASONAL
          ? Joi.string()
              .required()
              .messages({
                'string.empty': t('messaging:actions.hire.confirmationModal.contractDetailsWordingRequired'),
              })
          : Joi.string().allow(null).optional(),
      requisitionId: requisitionEnabled ? Joi.string().required() : Joi.string().allow('').optional(),
    });
  const formValidation = (formData: RequestHireDetailForm) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (requisitionEnabled) return true;
    const { error } = schema.validate(formData);
    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }
    setFormErrors([]);
    return true;
  };
  const updateNoteContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteContent(e.target.value);
  };
  const handleCustomUnitChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCustomUnit(e.target.value);
  };
  const checkLengthOfStringSeparatedByDotOrComma = (givenString: string, stringLength: number) => {
    let allowInput = true;
    const spliterator = givenString?.includes('.') ? givenString?.split('.') : givenString?.split(',');
    if (spliterator[1]) {
      if (spliterator[1].length > stringLength) {
        allowInput = false;
      }
    }
    return allowInput;
  };
  const handleSalaryChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!checkLengthOfStringSeparatedByDotOrComma(e.target.value, 2)) {
      return;
    }

    setSalary(e.target.value);
    setFormErrors(clearErrorMessage(formErrors, 'wage.value'));
  };
  const handlePartTimeHoursChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!checkLengthOfStringSeparatedByDotOrComma(e.target.value, 2)) {
      return;
    }
    setPartTimeHours(e.target.value);
    setFormErrors(clearErrorMessage(formErrors, 'partTimeHours'));
  };
  const handleUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUnit(e.target.value);
    if (e.target.value !== 'custom') {
      setCustomUnit(null);
    }
    setFormErrors(clearErrorMessage(formErrors, 'note'));
  };

  const submitNote = async (dismissNote?: string, dismissNoteArea?: string) => {
    onCloseConformationModal();
    if (!selectedCandidate || !currentUserProfile) return;

    try {
      setIsLoading(true);
      await saveOfferRejectedNote({
        positionId: selectedCandidate.position,
        seekerId: selectedCandidate.seeker,
        offerRejectedDetails: noteContent,
      });
      if (dismissedCandidateAfterRejection) {
        await dismissCandidate({
          positionId: selectedCandidate.position,
          seekerId: selectedCandidate.seeker,
          recruiterId: currentUserProfile.id,
          dismissalReason: dismissNote === 'Others' ? dismissNoteArea : dismissNote,
        });
      }
      onClose();
    } finally {
      setIsLoading(false);
      setFromHireReject(false);
      toast({
        title: t('notes:noteSuccessMsg.title'),
        description: t('notes:noteSuccessMsg.description'),
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const messageModalOpenHandler = () => {
    if (warningMessage) {
      messageModalOpen();
    }
  };
  /* eslint-disable */
  const changeCandidateOnTheLeftPanelHandler = () => {
    if (!seekerList || !seekerList.engagedCandidates) return;
    const activeListLength = seekerList.getSeekersList(
      i18n.language,
      SeekerListSortType.ALPHABETICAL,
      '',
      SeekerListGroupType.NONE,
    ).length;
    // istanbul ignore else
    if (activeListLength > 0) {
      const { candidate } = seekerList.getSeekersList(
        i18n.language,
        SeekerListSortType.ALPHABETICAL,
        '',
        SeekerListGroupType.NONE,
      )[0].item;
      const { id } = seekerList.getSeekersList(
        i18n.language,
        SeekerListSortType.ALPHABETICAL,
        '',
        SeekerListGroupType.NONE,
      )[0].item.candidate;
      setSelectedCandidate(candidate);
      setSelectedEngagedCandidate(candidate);
      setSelectedEngagedSeeker(candidate && { seekerId: candidate.seeker, candidateId: candidate.id });
      if (isCandidatePage()) {
        window.history.pushState(null, '', MESSAGING_CANDIDATE_TABS(id, SeekerContainerTabItems.Messaging));
      } else {
        window.history.pushState(null, '', MESSAGING_TABS(id, SeekerContainerTabItems.Messaging));
      }
    }
  };

  /* eslint-enable */
  const onReviewHire = () => {
    hireInfo.joinDate = joinDate;
    hireInfo.jobPosition = selectedCandidate?.jobTitle ? getTranslatedValue(selectedCandidate?.jobTitle, i18n.language) : '';
    hireInfo.businessLocation = selectedCandidate?.businessName || '';
    hireInfo.notes = noteContent;
    setHireInfo({ ...hireInfo });
  };
  const handleCreateAppointmentRequest = async () => {
    onCloseConformationModal();
    if (!selectedCandidate) {
      return;
    }
    let updatedSalary: string;
    if (salary.includes(',')) {
      updatedSalary = salary.toString().replace(',', '.');
    } else {
      updatedSalary = salary.toString();
    }
    try {
      setIsLoading(true);
      await hireCandidate({
        positionId: selectedCandidate.position,
        seekerId: selectedCandidate.seeker,
        hireDetails: noteContent,
        salary: updatedSalary,
        salaryUnit: unit,
        dateOfJoining: joinDate === null ? /* istanbul ignore next */ undefined : joinDate,
        customSalaryUnit: customUnit,
        requisitionId: requisitionEnabled ? selectedRequisition?.requisitionId : '',
        updatedBusinessId: updatedBusinessId || selectedCandidate?.newHireData?.businessId,
        updatedPositionId: updatedPositionId || selectedCandidate?.newHireData?.positionId,
      });
      onClose();
    } finally {
      setIsLoading(false);
      setFromHireReject(false);
      messageModalOpenHandler();
      // changeCandidateOnTheLeftPanelHandler();
    }
  };
  const onHandleRequestHireDetails = async () => {
    onCloseConformationModal();
    let updatedSalary: string;
    if (salary.includes(',')) {
      updatedSalary = salary.toString().replace(',', '.');
    } else {
      updatedSalary = salary.toString();
    }

    const requisitionId = selectedRequisition?.requisitionId;

    const requestHireDetailFormFields = {
      type: hireFormType?.value,
      fullTime: fullTime === 'fullTime',
      partTimeHours: fullTime !== 'fullTime' ? partTimeHours : undefined,
      positionName: hireInfo.jobPosition,
      note: noteContent,
      startingDate: joinDate === null ? /* istanbul ignore next */ undefined : joinDate,
      wage: {
        unit,
        value: updatedSalary,
        customUnit: customUnit || null,
      },
      business: activeBusiness,
      updatedBusinessId: updatedBusinessId || selectedCandidate?.newHireData?.businessId,
      updatedPositionId: updatedPositionId || selectedCandidate?.newHireData?.positionId,
      requisitionId: requisitionEnabled ? requisitionId : '',
    };

    const fieldsForSeasonalContractor = {
      endDate,
      contractDetailsWordings,
    };
    if (
      formValidation(
        hireFormType && hireFormType.value !== HireFormTypeEnum.SEASONAL
          ? requestHireDetailFormFields
          : { ...requestHireDetailFormFields, ...fieldsForSeasonalContractor },
      ) &&
      hireFormType
    ) {
      try {
        setIsLoading(true);
        const hireRequestFormResponse = await onRequestHireDetails({
          candidateId: selectedCandidate?.id ? selectedCandidate?.id : '',
          requestHireDetailFormFields:
            hireFormType.value !== HireFormTypeEnum.SEASONAL
              ? requestHireDetailFormFields
              : { ...requestHireDetailFormFields, ...fieldsForSeasonalContractor },
        });
        if (hireRequestFormResponse !== '') {
          const { title } = hireRequestFormResponse as HireFormErrorResponse;
          toast({
            title,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        } else {
          onClose();
        }
        if (!shouldAllowHireForm()) {
          changeCandidateOnTheLeftPanelHandler();
        }
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleRadioValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    // istanbul ignore else
    if (e.target.value === 'rejected') {
      setNoteContent('');
    } else if (e.target.value === 'hired') {
      // istanbul ignore next
      setNoteContent(shouldAllowHireForm() ? '' : `${t('messaging:actions.hire.hireDetailsDesc1')}`);
    }
    setRadioValue(e.target.value);
  };
  const handleJoinDateChange = (date: Date) => {
    setJoinDate(date);
  };

  const CustomDatePickerInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <ButtonGroup className="customDatepicker" size="md" isAttached variant="outline" onClick={props.onClick} ref={ref}>
      <Button mr="-px" style={{ width: '100%' }}>
        {props.value}
      </Button>
      <IconButton style={{ padding: '0.2rem' }} aria-label="calendar" icon={<IconCalendar width={20} height={20} />} />
    </ButtonGroup>
  );
  const handleJobLocationChange = (businessLocationItem: BusinessSelectType | null) => {
    /* istanbul ignore next */
    if (!_isNil(businessLocationItem)) {
      setHireInfo((prevData) => ({
        ...prevData,

        businessLocation: businessLocationItem.value,
        jobPosition: '',
      }));
      setBusinessLocation(businessLocationItem);
      setActiveBusiness({
        name: businessLocationItem?.value,
        country: businessLocationItem?.country,
        address: businessLocationItem?.completeAddress,
        locationId: businessLocationItem?.locationId,
      });
      setSelectedRequisition(undefined);
      setPreviousRequisitionID(undefined);
      if (selectedCandidate?.newHireData) {
        setUpdatedBusinessId(businessLocationItem.id);
      } else {
        setUpdatedBusinessId(businessLocationItem.id === selectedBusinessLocation?.id ? undefined : businessLocationItem.id);
      }
      setUpdatedPositionId(undefined);
      setJoinDate(earliestAvailabilityDate ? new Date(earliestAvailabilityDate) : undefined);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
    }
    /* istanbul ignore next */
    setFormErrors(clearErrorMessage(formErrors, 'businessName'));
  };
  const handleNullValue = (feild: string, value: string | number | null) => {
    if (!_isNil(value)) {
      if (feild === 'salary') {
        return (parseInt(value as string, 10) / 100).toFixed(2);
      }
      return value.toString();
    }
    switch (feild) {
      case 'salary':
        return '0.00';
      case 'unit':
        return 'hourly';
      default:
        return '';
    }
  };
  const handleJobChange = (jobPositionItem: PositionSelectType | null) => {
    let givenSalary = noteDetail?.salary;
    if (_isUndefined(givenSalary)) {
      givenSalary = '';
    }
    /* istanbul ignore next */
    if (!_isNil(jobPositionItem)) {
      setHireInfo((prevData) => ({
        ...prevData,

        jobPosition: jobPositionItem.value,
      }));
      if (selectedCandidate?.newHireData) {
        setUpdatedPositionId(jobPositionItem.id);
      } else {
        setUpdatedPositionId(jobPositionItem.id === selectedCandidate?.position ? undefined : jobPositionItem.id);
      }
      if (jobPositionItem.id !== selectedCandidate?.position) {
        setSalary(
          !_isNil(jobPositionItem?.proposedSalary)
            ? handleNullValue('salary', jobPositionItem?.proposedSalary?.minValue)
            : '0.00',
        );
        setUnit(
          !_isNil(jobPositionItem?.proposedSalary) ? handleNullValue('unit', jobPositionItem?.proposedSalary?.unit) : 'hourly',
        );
        setCustomUnit(
          !_isNil(jobPositionItem?.proposedSalary)
            ? handleNullValue('customUnit', jobPositionItem?.proposedSalary?.customUnit)
            : '',
        );
      } else {
        setSalary(getTranslatedNumber(Number(givenSalary).toFixed(2).toString(), i18n.language) as any);
        setUnit(noteDetail?.salaryUnit ?? 'hourly');
        setCustomUnit(noteDetail?.customSalaryUnit ?? '');
      }
      setJoinDate(earliestAvailabilityDate ? new Date(earliestAvailabilityDate) : null);
    }
    /* istanbul ignore next */
    setFormErrors(clearErrorMessage(formErrors, 'positionName'));
    setSelectedRequisition(undefined);
  };

  const fullTimeRadioFeild = () => (
    <Radio colorScheme="blue" value="fullTime">
      {t('messaging:actions.hire.confirmationModal.fullTime')}
    </Radio>
  );
  // eslint-disable-next-line consistent-return
  const showFullTimeField = () => {
    if (hireFormType === undefined) {
      return fullTimeRadioFeild();
    }
    if (hireFormType.value === HireFormTypeEnum.NEW_HIRE) {
      return fullTimeRadioFeild();
    }
  };

  React.useEffect(
    () => () => {
      setIsLoading(false);
      setNoteContent('');
      setSalary('0');
      setUnit('hourly');
      setCustomUnit('');
      setJoinDate(earliestAvailabilityDate ? new Date(earliestAvailabilityDate) : null);
      setButtonDisabled(true);
    },
    [earliestAvailabilityDate],
  );

  React.useEffect(() => {
    if (shouldAllowHireForm()) {
      onReviewHire();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHireFormEnabled, hireFormEnabledPositions]);

  React.useEffect(() => {
    let givenSalary = noteDetail?.salary;
    if (_isUndefined(givenSalary)) {
      givenSalary = '';
    }

    setSalary(getTranslatedNumber(Number(givenSalary).toFixed(2).toString(), i18n.language) as any);
    setUnit(noteDetail?.salaryUnit ?? 'hourly');
    setCustomUnit(noteDetail?.customSalaryUnit ?? '');
  }, [noteDetail, i18n.language]);

  React.useEffect(() => {
    if (
      (radioValue === 'hired' && (!/^(?:\d*[.|,]\d{1,2}|\d+)$/.exec(salary) || salary === '')) ||
      (radioValue === 'hired' && (salary as any)) <= -1 ||
      (fullTime === 'partTime' && parseInt(partTimeHours, 10) <= 0)
    ) {
      setButtonDisabled(true);
    } else if (radioValue === 'hired' && unit === 'custom' && customUnit === '') {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [noteContent, unit, salary, customUnit, radioValue, joinDate, fullTime, partTimeHours]);

  React.useEffect(() => {
    setContractDetailsWordings(hireFormContractWording(selectedSeeker?.locale));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeeker, businessLocation, joinDate, endDate]);

  React.useEffect(() => {
    if (_isNil(businessLocation) && !_isNil(selectedBusinessLocation) && !_isNil(selectedBusinessLocation.id)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setBusinessLocation(selectedBusinessLocation);
      setActiveBusiness({
        name: selectedBusinessLocation?.value,
        country: selectedBusinessLocation?.country,
        address: selectedBusinessLocation?.completeAddress,
        locationId: selectedBusinessLocation?.locationId,
      });
    }
  }, [businessLocation, selectedBusinessLocation]);

  const onHandelRequsitionSelect = (requisition?: Requisition) => {
    setEndDate(requisition?.endDate);
    if (requisition?.seasonal) {
      setHireFormType((prevData) => ({
        ...prevData,
        value: HireFormTypeEnum.SEASONAL,
        label: t('messaging:actions.hire.confirmationModal.startSeasonalContractor'),
      }));
    } else {
      setHireFormType((prevData) => ({
        ...prevData,
        value: HireFormTypeEnum.NEW_HIRE,
        label: t('messaging:actions.hire.confirmationModal.startNewHire'),
      }));
    }
    setFullTime(requisition?.fullTime ? 'fullTime' : 'partTime');
    setCustomUnit(requisition?.wage?.customUnit ? requisition?.wage?.customUnit : null);
    setUnit(offeredSalary?.salaryUnit || '');
    setSalary(Number(offeredSalary?.salary).toFixed(2).toString() || '');
    setPartTimeHours((requisition?.partTimeHours || '').toString());

    setSelectedRequisition(requisition);
  };

  const onHandleSubmitButtonWithOutHireForm = async (note?: string, noteTextArea?: string) => {
    if (radioValue === 'hired') {
      if (updatedBusinessId || updatedPositionId) {
        onOpenConformationModal();
      } else {
        await handleCreateAppointmentRequest();
      }
    } else {
      await submitNote(note, noteTextArea);
    }
  };
  const onHandleSubmitButtonWithHireForm = async (note?: string, noteTextArea?: string) => {
    if (radioValue === 'hired') {
      if (updatedBusinessId || updatedPositionId) {
        onOpenConformationModal();
      } else {
        await onHandleRequestHireDetails();
      }
    } else if (radioValue === 'rejected') {
      await submitNote(note, noteTextArea);
    }
  };

  const onShouldAllowHireFormSaveClick = (note?: string, noteTextArea?: string) => {
    if (requisitionEnabled && radioValue !== 'rejected') {
      if (joinDate) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onHandleSubmitButtonWithHireForm(note, noteTextArea);
      } else {
        const selectorId = `#elementID_earliest_start_date`;
        const section = document.querySelector(selectorId);
        section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setFormErrors([{ label: 'startingDate', message: t('messaging:actions.hire.confirmationModal.startDateRequired') }]);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onHandleSubmitButtonWithHireForm(note, noteTextArea);
    }
  };

  const onDismissSuccess = async (note?: string, noteTextArea?: string) => {
    if (!shouldAllowHireForm()) {
      await onHandleSubmitButtonWithOutHireForm(note, noteTextArea);
    } else {
      onShouldAllowHireFormSaveClick(note, noteTextArea);
    }
  };

  return (
    <Box>
      <Modal
        id="HireRejectConfirmationModal"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size={requisitionEnabled || isHireFormEnabled ? '4xl' : '2xl'}
        scrollBehavior="inside"
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader css={confirmationModalHeaderCSS} className="modal-header" style={{ color: 'black' }}>
              {!shouldAllowHireForm() ? (
                t('messaging:actions.hire.rejectModal.header')
              ) : (
                <Box display="flex">
                  {t('messaging:actions.hire.confirmationModal.candidateHiringForm')}
                  <Text color={colors.blue.default} ml="8px">
                    {hireFormType ? `(${hireFormType.label})` : ''}
                  </Text>
                </Box>
              )}
            </ModalHeader>
            <ModalBody css={[drawerCSS, reactDatePickerCSS]}>
              {
                <Fragment>
                  {selectedCandidate?.computedStatus?.status === CandidateStatus.OfferMade && (
                    <Fragment>
                      <Text color="#455A63" mb="1rem">
                        {t('messaging:actions.hire.rejectModal.body')}
                      </Text>
                      <RadioGroup mb="2rem">
                        <Stack spacing={5} direction="row">
                          <Radio
                            isChecked
                            colorScheme="blue"
                            data-testid="Hired"
                            value="hired"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleRadioValueChange(e)}
                          >
                            {t('messaging:actions.hire.rejectModal.hired')}
                          </Radio>
                          <Radio
                            colorScheme="blue"
                            data-testid="Rejected"
                            value="rejected"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleRadioValueChange(e)}
                          >
                            {t('messaging:actions.hire.rejectModal.rejected')}
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </Fragment>
                  )}

                  {radioValue === 'hired' && (
                    <Fragment>
                      <Flex alignItems="baseline">
                        {!requisitionEnabled && shouldAllowHireForm() && (
                          <Box w="50%" mr={3}>
                            <FormControl isInvalid={hasErrorMessage(formErrors, 'type')} isRequired>
                              <FormLabel htmlFor="jobLocation" fontWeight="bold">
                                {t('messaging:actions.hire.confirmationModal.hireFormType')}
                              </FormLabel>
                              <Menu fixed={false}>
                                <MenuButton
                                  className="menu-button-with-icon-and-dropdown"
                                  as={Button}
                                  rightIcon={<ChevronDownIcon />}
                                  w="100%"
                                  textAlign="left"
                                  fontWeight="normal"
                                  bg="gray.150"
                                  border="2px"
                                  borderColor="gray.150"
                                  disabled={requisitionEnabled}
                                  borderRadius="5px"
                                  h="48px"
                                  _hover={{ bg: 'gray.150' }}
                                  _active={{ bg: 'gray.150', borderColor: 'blue.500' }}
                                  _focus={{ bg: 'gray.150', borderColor: 'blue.500' }}
                                >
                                  {hireFormType
                                    ? hireFormType.label
                                    : t('messaging:actions.hire.confirmationModal.selectHireFormType')}
                                </MenuButton>
                                <MenuList>
                                  {hireFormTypes.map((type) => (
                                    <MenuItem
                                      key={type.value}
                                      onClick={() => {
                                        setHireFormType(type);
                                        setFullTime(type.value === HireFormTypeEnum.NEW_HIRE ? 'fullTime' : 'partTime');
                                        setFormErrors(clearErrorMessage(formErrors, 'type'));
                                      }}
                                    >
                                      {type.label}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </Menu>
                              <FormErrorMessage>{getErrorMessage(formErrors, 'type')}</FormErrorMessage>
                            </FormControl>
                          </Box>
                        )}
                        {isHireFormEnabled && (
                          <Box
                            mt="12px"
                            data-testid="DateOfJoining"
                            w="49%"
                            mr={3}
                            css={DateAndTimePickerCoustomCSS}
                            id="elementID_earliest_start_date"
                          >
                            <FormControl isInvalid={hasErrorMessage(formErrors, 'startingDate')} isRequired>
                              <FormLabel mb="8px" fontWeight="bold">
                                {t('messaging:actions.hire.dateOfJoining')}
                              </FormLabel>
                              <DatePicker
                                selected={joinDate}
                                customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                                locale={i18n.language}
                                dateFormat="P"
                                shouldCloseOnSelect
                                minDate={
                                  requisitionEnabled
                                    ? selectedRequisition?.effectiveDate && new Date(selectedRequisition?.effectiveDate)
                                    : null
                                }
                                onChange={
                                  /* istanbul ignore next */ (date) => {
                                    handleJoinDateChange(date as Date);
                                    setFormErrors(clearErrorMessage(formErrors, 'startingDate'));
                                  }
                                }
                              />
                              <FormErrorMessage>{getErrorMessage(formErrors, 'startingDate')}</FormErrorMessage>
                            </FormControl>
                          </Box>
                        )}
                      </Flex>
                      <Box mt="12px">
                        <SelectRequisition
                          positionId={updatedPositionId || selectedCandidate?.position || ''}
                          requisitionId={previousRequisitionID}
                          onRequisitionChanged={(requisition) => onHandelRequsitionSelect(requisition)}
                          handleJobLocationChange={(businessLocationItem: BusinessSelectType | null) =>
                            handleJobLocationChange(businessLocationItem)
                          }
                          handleJobChange={(jobPositionItem: PositionSelectType | null) => handleJobChange(jobPositionItem)}
                          isOpenConformationModal={isOpenConformationModal}
                          onHandleConformationModal={async (conformation: boolean) => {
                            if (conformation) {
                              if (radioValue === 'hired') {
                                if (shouldAllowHireForm()) await onHandleRequestHireDetails();
                                else await handleCreateAppointmentRequest();
                              } else {
                                await submitNote();
                              }
                            } else {
                              onCloseConformationModal();
                            }
                          }}
                          showIcon
                          updatedWageOfRequisition={getTranslatedStringValue(salary, i18n.language).toString()}
                          // setUpdatedWageOfRequisition={}
                        />
                      </Box>
                      {shouldAllowHireForm() ? (
                        <Fragment>
                          <Flex pt="15px">
                            {!requisitionEnabled && (
                              <Box mb="1rem" mr={3} data-testid="HiredDiv" w="40%">
                                <Text mb="8px" fontWeight="bold">
                                  {` ${t('messaging:actions.hire.confirmationModal.salary')} ($) : `}
                                </Text>
                                <Box display="inline-flex" style={{ width: '100%' }}>
                                  <Select
                                    onChange={(e: ChangeEvent<HTMLSelectElement>) => handleUnitChange(e)}
                                    data-testid="UnitValue"
                                    size="sm"
                                    width="35%"
                                    mr={3}
                                    style={{ height: '40px', background: `${colors.gray['150']}`, border: 'none' }}
                                    value={unit}
                                  >
                                    <option value="hourly">{t('messaging:actions.hire.confirmationModal.hourly')}</option>
                                    <option value="annual">{t('messaging:actions.hire.confirmationModal.annually')}</option>
                                    <option value="custom">{t('messaging:actions.hire.confirmationModal.custom')}</option>
                                  </Select>
                                  <Box w="30%" mr={3}>
                                    <FormControl isInvalid={hasErrorMessage(formErrors, 'wage.value')} isRequired>
                                      <NumberInput
                                        max={
                                          // istanbul ignore next
                                          (i18n.language === 'en' && maximumSalary) || undefined
                                        }
                                        precision={2}
                                        min={1}
                                        value={getTranslatedStringValue(salary, i18n.language)}
                                      >
                                        <NumberInputField
                                          style={{
                                            height: '40px',
                                            width: '100%',
                                            background: `${colors.gray['150']}`,
                                            border: 'none',
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                            textAlign: 'left',
                                          }}
                                          data-testid="SalaryValue"
                                          onChange={(e: ChangeEvent<HTMLInputElement>) => handleSalaryChange(e)}
                                          onClick={() => {
                                            setPreviousData(salary);
                                            setSalary('');
                                          }}
                                          onBlur={() => {
                                            if (salary === '') {
                                              setSalary(previousData);
                                            }

                                            setSalary((salaryVal) =>
                                              Number(salaryVal.replace(',', '.')) > maximumSalary
                                                ? maximumSalary.toString()
                                                : Number(salaryVal.replace(',', '.')).toFixed(2).toString(),
                                            );
                                          }}
                                          placeholder={t('messaging:actions.hire.confirmationModal.salaryValue')}
                                        />
                                      </NumberInput>
                                      <FormErrorMessage>{getErrorMessage(formErrors, 'wage.value')}</FormErrorMessage>
                                    </FormControl>
                                  </Box>
                                  {unit === 'custom' && (
                                    <Box w="35%">
                                      <Input
                                        style={{ height: '40px', background: `${colors.gray['150']}`, border: 'none' }}
                                        value={customUnit ?? ''}
                                        data-testid="CustomUnitValue"
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleCustomUnitChange(e)}
                                        placeholder={t('messaging:actions.hire.confirmationModal.customUnit')}
                                        size="sm"
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            )}
                            {!requisitionEnabled && (
                              <Box w="45%" alignItems="top" display="flex">
                                <Box w="70%">
                                  <Text mb="8px">&nbsp;</Text>
                                  <Flex alignItems="center" display="flex">
                                    <RadioGroup w="100%" value={fullTime}>
                                      <Stack
                                        spacing={5}
                                        direction="row"
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFullTime(e.target.value)}
                                      >
                                        {showFullTimeField()}
                                        <Radio colorScheme="blue" value="partTime">
                                          {t('messaging:actions.hire.confirmationModal.partTime')}
                                        </Radio>
                                      </Stack>
                                    </RadioGroup>
                                  </Flex>
                                </Box>
                                <Box w="30%" height="40px">
                                  {fullTime !== 'fullTime' && (
                                    <FormControl isInvalid={hasErrorMessage(formErrors, 'partTimeHours')} isRequired>
                                      <FormLabel mr={0} fontSize="xs" fontWeight="bold">
                                        {t('messaging:actions.hire.confirmationModal.partTimeHoursPerWeek')}
                                      </FormLabel>

                                      <NumberInput
                                        max={
                                          // istanbul ignore next
                                          (i18n.language === 'en' && maxHoursPerWeek) || undefined
                                        }
                                        precision={2}
                                        min={1}
                                        value={getTranslatedStringValue(partTimeHours, i18n.language)}
                                      >
                                        <NumberInputField
                                          style={{
                                            height: '40px',
                                            width: '100%',
                                            background: `${colors.gray['150']}`,
                                            border: 'none',
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                            textAlign: 'left',
                                          }}
                                          data-testid="SalaryValue"
                                          onChange={(e: ChangeEvent<HTMLInputElement>) => handlePartTimeHoursChange(e)}
                                          onClick={() => {
                                            setPreviousData(partTimeHours);
                                            setPartTimeHours('');
                                          }}
                                          onBlur={() => {
                                            if (partTimeHours === '') {
                                              setPartTimeHours(previousData);
                                            }

                                            setPartTimeHours((partTimeVal) =>
                                              Number(partTimeVal.replace(',', '.')) > maxHoursPerWeek
                                                ? maxHoursPerWeek.toString()
                                                : Number(partTimeVal.replace(',', '.')).toFixed(2).toString(),
                                            );
                                          }}
                                          placeholder="hours/week"
                                        />
                                      </NumberInput>
                                      <FormErrorMessage>{getErrorMessage(formErrors, 'partTimeHours')}</FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Flex>

                          {hireFormType && hireFormType.value === HireFormTypeEnum.SEASONAL && (
                            <Fragment>
                              <Flex>
                                {!requisitionEnabled && (
                                  <Box w="49%" mr={3}>
                                    <Box data-testid="ContactEndDate" css={DateAndTimePickerCoustomCSS}>
                                      <FormControl isInvalid={hasErrorMessage(formErrors, 'endDate')} isRequired>
                                        <FormLabel my="8px" fontWeight="bold">
                                          {t('messaging:actions.hire.confirmationModal.contractEndDate')}
                                        </FormLabel>
                                        <DatePicker
                                          selected={endDate}
                                          customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                                          locale={i18n.language}
                                          dateFormat="P"
                                          shouldCloseOnSelect
                                          minDate={joinDate}
                                          maxDate={moment(joinDate)
                                            .add(seasonalContractPeriod || 90, 'days')
                                            .toDate()}
                                          onChange={
                                            /* istanbul ignore next */ (date) => {
                                              setEndDate(date as Date);
                                              setFormErrors(clearErrorMessage(formErrors, 'endDate'));
                                            }
                                          }
                                        />
                                        <FormErrorMessage>{getErrorMessage(formErrors, 'endDate')}</FormErrorMessage>
                                      </FormControl>
                                    </Box>
                                  </Box>
                                )}
                              </Flex>

                              {!_isNil(joinDate) && !_isNil(endDate) && (
                                <Box mt={4}>
                                  <FormControl isInvalid={hasErrorMessage(formErrors, 'contractDetailsWordings')} isRequired>
                                    <Text my="8px" fontWeight="bold" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                      {t('messaging:actions.hire.confirmationModal.contractDetailsWording')}
                                      <Tooltip
                                        shouldWrapChildren
                                        label={t('messaging:actions.hire.confirmationModal.contractDetailsWordingTooltip')}
                                        fontSize="md"
                                      >
                                        <MdHelpOutline style={{ marginLeft: '5px' }} />
                                      </Tooltip>
                                    </Text>
                                    {selectedSeeker?.locale !== accountLocale && (
                                      <FormHelperText mt={0} mb={3}>
                                        {t('messaging:actions.hire.confirmationModal.contractWordingHintMessage')}
                                      </FormHelperText>
                                    )}
                                    <Textarea
                                      style={{
                                        height: '285px',
                                        width: '100%',
                                        background: `${colors.gray['150']}`,
                                        border: 'none',
                                      }}
                                      value={contractDetailsWordings}
                                      data-testid="ContractDetailsWordingContent"
                                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                        setContractDetailsWordings(e.target.value);
                                        setFormErrors(clearErrorMessage(formErrors, 'contractDetailsWordings'));
                                      }}
                                      focusBorderColor="gray"
                                      placeholder={t(
                                        'messaging:actions.hire.confirmationModal.contractDetailsWordingPlaceholder',
                                      )}
                                    />
                                    <FormErrorMessage>{getErrorMessage(formErrors, 'contractDetailsWordings')}</FormErrorMessage>
                                  </FormControl>
                                </Box>
                              )}
                            </Fragment>
                          )}
                          <Box mt={4}>
                            <FormControl isInvalid={hasErrorMessage(formErrors, 'note')} isRequired>
                              <Text color="#455A63" mb="0.5rem" fontWeight="bold">
                                {t('messaging:actions.hire.modalMessage')}
                              </Text>
                              <Textarea
                                style={{ height: '85px', width: '100%', background: `${colors.gray['150']}`, border: 'none' }}
                                value={noteContent || ''}
                                data-testid="HireNoteContent"
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                  updateNoteContent(e);
                                  setFormErrors(clearErrorMessage(formErrors, 'note'));
                                }}
                                focusBorderColor="gray"
                                placeholder={t('messaging:actions.hire.rejectModal.seenToCandidatePlaceholder')}
                              />
                              <FormErrorMessage>{getErrorMessage(formErrors, 'note')}</FormErrorMessage>
                            </FormControl>
                          </Box>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {!requisitionEnabled && (
                            <Box display="flex" mb="1rem" data-testid="HiredDiv" mt="26px">
                              <Text mb="8px" fontWeight="bold" style={{ width: unit === 'custom' ? '33%' : '23%' }}>
                                {` ${t('messaging:actions.hire.confirmationModal.salary')} ($) : `}
                              </Text>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                style={{ marginTop: '-6px', marginLeft: '12px', width: unit === 'custom' ? '103%' : '74%' }}
                              >
                                <Select
                                  onChange={(e: ChangeEvent<HTMLSelectElement>) => handleUnitChange(e)}
                                  data-testid="UnitValue"
                                  size="sm"
                                  style={{ height: '40px', background: `${colors.gray['150']}`, border: 'none' }}
                                  value={unit}
                                >
                                  <option value="hourly">{t('messaging:actions.hire.confirmationModal.hourly')}</option>
                                  <option value="annual">{t('messaging:actions.hire.confirmationModal.annually')}</option>
                                  <option value="custom">{t('messaging:actions.hire.confirmationModal.custom')}</option>
                                </Select>
                                {unit === 'custom' && (
                                  <Input
                                    style={{
                                      marginLeft: '15px',
                                      height: '40px',
                                      background: `${colors.gray['150']}`,
                                      border: 'none',
                                    }}
                                    value={customUnit ?? ''}
                                    data-testid="CustomUnitValue"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleCustomUnitChange(e)}
                                    placeholder={t('messaging:actions.hire.confirmationModal.customUnit')}
                                    size="sm"
                                  />
                                )}
                                <Input
                                  style={{
                                    marginLeft: '15px',
                                    height: '40px',
                                    width: '90%',
                                    background: `${colors.gray['150']}`,
                                    border: 'none',
                                  }}
                                  value={getTranslatedStringValue(salary, i18n.language)}
                                  data-testid="SalaryValue"
                                  // onChange={(e: ChangeEvent<HTMLInputElement>) => handleSalaryChange(e)}
                                  placeholder={t('messaging:actions.hire.confirmationModal.salaryValue')}
                                  size="sm"
                                  // onBlur={() => {
                                  //   if (salary === '') {
                                  //     setSalary(previousData);
                                  //   }

                                  //   setSalary((salaryVal) =>
                                  //     Number(salaryVal.replace(',', '.')) > maximumSalary
                                  //       ? maximumSalary.toString()
                                  //       : Number(salaryVal.replace(',', '.')).toFixed(2).toString(),
                                  //   );
                                  // }}
                                />
                              </Box>
                            </Box>
                          )}
                          {!requisitionEnabled && (
                            <Box display="flex" data-testid="DateOfJoining" mb={2} id="elementID_earliest_start_date">
                              <FormControl>
                                <FormLabel mt="8px" fontWeight="bold" style={{ width: '100%' }}>
                                  {t('messaging:actions.hire.dateOfJoining')}
                                </FormLabel>
                                <Box css={DateAndTimePickerCoustomCSS}>
                                  <DatePicker
                                    selected={joinDate}
                                    customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                                    locale={i18n.language}
                                    dateFormat="Pp"
                                    data-testid="JoinDate"
                                    showTimeInput
                                    timeInputLabel="Time:"
                                    shouldCloseOnSelect
                                    onChange={/* istanbul ignore next */ (date) => handleJoinDateChange(date as Date)}
                                  />
                                </Box>
                              </FormControl>
                            </Box>
                          )}
                          <Box mb="1rem" mt="12px">
                            <Text color="#455A63" mb="0.5rem" fontWeight="bold">
                              {t('messaging:actions.hire.modalMessage')}
                            </Text>
                            <Textarea
                              style={{ height: '65px', width: '100%', background: `${colors.gray['150']}`, border: 'none' }}
                              value={noteContent || ''}
                              data-testid="HireNoteContent"
                              resize="none"
                              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => updateNoteContent(e)}
                              focusBorderColor="gray"
                              placeholder={t('messaging:actions.hire.rejectModal.seenToCandidatePlaceholder')}
                            />
                          </Box>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                  {radioValue === 'rejected' && (
                    <Box>
                      <Textarea
                        value={noteContent || ''}
                        resize="none"
                        data-testid="NoteContent"
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => updateNoteContent(e)}
                        focusBorderColor="gray"
                        style={{
                          height: '160px',
                          background: `${colors.gray['150']}`,
                          border: 'none',
                        }}
                        placeholder={t('messaging:actions.hire.rejectModal.placeholder')}
                      />
                      <Checkbox
                        mt={6}
                        mb={6}
                        isChecked={dismissedCandidateAfterRejection}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setDismissedCandidateAfterRejection(e.target.checked)}
                      >
                        {t('messaging:actions.dismiss.confirmationModal.header')}
                      </Checkbox>
                    </Box>
                  )}
                </Fragment>
              }
            </ModalBody>
            <ModalFooter justifyContent={selectedCandidate?.newHireData ? 'space-between' : 'flex-end'}>
              {selectedCandidate?.newHireData && (
                <Text fontStyle="italic" color={colors.orange} w="70%">
                  {t('requisition:locationAndPositionChanged')}
                </Text>
              )}
              <Flex>
                <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={() => onClose()} disabled={isLoading}>
                  {t('messaging:actions.hire.confirmationModal.cancelButton')}
                </Button>

                {!shouldAllowHireForm() ? (
                  <Button
                    data-testid="ConfirmButton"
                    colorScheme="blue"
                    onClick={async () => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      dismissedCandidateAfterRejection && radioValue === 'rejected'
                        ? onDismissModalOpen()
                        : await onHandleSubmitButtonWithOutHireForm();
                    }}
                    disabled={
                      requisitionEnabled && radioValue === 'hired'
                        ? !selectedRequisition || isEmpty(offeredSalary?.salary.toString())
                        : disabledButton || disabled || isLoading || (updatedBusinessId ? !updatedPositionId : false)
                    }
                    isLoading={isLoading}
                  >
                    {t('messaging:actions.hire.confirmationModal.saveButton')}
                  </Button>
                ) : (
                  <Button
                    disabled={
                      requisitionEnabled && radioValue === 'hired'
                        ? !selectedRequisition || isEmpty(offeredSalary?.salary.toString()) || isLoading
                        : disabledButton ||
                          disabled ||
                          isLoading ||
                          parseInt(salary, 10) < 1 ||
                          (updatedBusinessId ? !updatedPositionId : false)
                    }
                    isLoading={isLoading}
                    colorScheme="blue"
                    onClick={() =>
                      dismissedCandidateAfterRejection && radioValue === 'rejected'
                        ? onDismissModalOpen()
                        : onShouldAllowHireFormSaveClick()
                    }
                  >
                    {t('messaging:actions.hire.confirmationModal.submit')}
                  </Button>
                )}
              </Flex>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      {isDismissModalOpen && (
        <DismissConfirmationModal
          isOpen={isDismissModalOpen}
          disabled={false}
          onClose={onDismissModalClose}
          isMessagingView
          onDismissSuccess={onDismissSuccess}
          isFromHireReject={fromHireReject}
        />
      )}
    </Box>
  );
};
