/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { RiInformationLine } from 'react-icons/ri';
import moment from 'moment-timezone';
import { AiOutlineLaptop, AiOutlineMobile } from 'react-icons/ai';
import isNil from 'lodash/isNil';
import { drawerCSS } from '../CalendarView.Style';
import { reactDatePickerCSS } from '../scheduleSidebar/ReactDatePicker.Style';
import { calendarCreateEvent } from '../events/CalendarCreateEvent.Style';
import {
  AppointmentAvailability,
  AvailableBookingWithPosition,
} from '../../../firebase/firestore/documents/appointmentAvaiability';
import colors from '../../../styles/colors';
import { ScheduleListView } from '../scheduleList/ScheduleListView';
import { AppointmentType } from '../../../firebase/firestore/documents/position';
import { Recruiter } from '../../../firebase/firestore/documents/recruiter';
import { ConfirmationDialog } from '../../common/AlertDialog/ConfirmationDialog';
import { TeamMemberAction } from '../../administration/team/TeamType';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';

export type CalendarAvailabilityDetailProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedAvailability: AppointmentAvailability;
  bookingList: AvailableBookingWithPosition[];
  recruiterInfo: Recruiter;
};

export const CalendarAvailabilityDetail = ({
  isOpen,
  onClose,
  selectedAvailability,
  bookingList,
  recruiterInfo,
}: CalendarAvailabilityDetailProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('calendar');
  const {
    isOpen: isConfirmDeleteAvailabilityModalOpen,
    onOpen: onConfirmDeleteAvailabilityModalOpen,
    onClose: onConfirmDeleteAvailabilityModalClose,
  } = useDisclosure();
  const { currentUserProfile } = useCurrentUserProfile();

  const { deleteSchedule } = useStoreActions((actions) => actions.calendar);

  const confirmDeleteAvailability = async () => {
    setIsLoading(true);
    await deleteSchedule({ id: selectedAvailability.id });
    setIsLoading(false);
    onClose();
  };

  const appUserId = useStoreState((s) => s.app.user?.id);
  const appOwnersId = useStoreState((s) => s.app.accounts?.owners);

  const isAdministrationAccount = !isNil(appOwnersId) && !isNil(appUserId) && appOwnersId?.includes(appUserId);

  const allowSuperUsersToDeleteAvailability: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ALLOW_SUPER_USERS_TO_DELETE_AVAILABILITY];
  });
  const hasDatePassed = (givenDate: Date) => {
    const dateToCheck = givenDate;
    const currentDate = new Date();
    return dateToCheck < currentDate;
  };

  // istanbul ignore next
  const getWarningMessage = () => {
    if (hasDatePassed(selectedAvailability.fromDate)) {
      return t('availabilityDetail.waringMessageWhenUserTriesToDeletePassedAvailability');
    }
    if (isAdministrationAccount && currentUserProfile?.id !== selectedAvailability.userId) {
      return t('availabilityDetail.warningMessageToDeleteAvailabilityWithOutBookings');
    }
    return '';
  };

  // istanbul ignore next
  const showDeleteBtn = () => {
    if (allowSuperUsersToDeleteAvailability && isAdministrationAccount) {
      return true;
    }
    return currentUserProfile?.id === selectedAvailability.userId;
  };

  const getAlertMessage = () => {
    if (hasDatePassed(selectedAvailability.fromDate)) {
      return '';
    }
    if (bookingList.length <= 0) {
      return t('availabilityDetail.confirmationMessageToDeleteAvailabilityWithOutBookings');
    }

    return t('availabilityDetail.confirmationMessageToDeleteAvailabilityWithBookings');
  };

  return (
    <Fragment>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm" data-testid="AvailabilityDetailDrawer">
        <DrawerOverlay>
          <DrawerContent css={[drawerCSS, reactDatePickerCSS, calendarCreateEvent]}>
            <DrawerCloseButton />
            <DrawerHeader borderColor="#EBEDF3" px={4} borderBottomWidth="1px">
              <Heading
                as="h4"
                size="sm"
                color="blue.500"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', whiteSpace: 'pre-line' }}
              >
                {t('availabilityDetail.drawerTitle')}
              </Heading>
            </DrawerHeader>
            <DrawerBody p="0" bg="#fbfbfb">
              <Box className="availability-detail" p={4} bg="white" borderBottom={`1px solid ${colors.gray['50']}`}>
                <Box mb={3}>
                  <Text d="inline-flex" alignItems="center" as="label" fontSize="md" fontWeight="bold">
                    <Text mr={1}>{`${recruiterInfo?.firstName} ${recruiterInfo?.lastName}`}</Text>
                    {selectedAvailability.bookingCapacity ? <AiOutlineLaptop /> : <AiOutlineMobile />}
                  </Text>
                  <Text fontSize="sm">{moment(selectedAvailability.fromDate).format('ll')}</Text>
                  <Text fontSize="sm">
                    {`${moment(selectedAvailability.fromDate).format('hh:mm A')} - ${moment(selectedAvailability.toDate).format(
                      'hh:mm A',
                    )}`}
                  </Text>
                </Box>
                <Box mb={3}>
                  <Text as="label" fontSize="sm" fontWeight="bold">
                    {t('availabilityDetail.interviewType')}
                  </Text>
                  <Text fontSize="sm">
                    {selectedAvailability.appointmentTypes.map((type, i, { length }) => (
                      <Box as="span" mr={2} key={`${type}`}>
                        {t(`${type}`)}
                        {selectedAvailability?.adhoc && (
                          <Text as="span" fontStyle="italic" color={colors.gray[500]} ml={1}>
                            {t('availabilityDetail.onDemand')}
                          </Text>
                        )}
                        {i + 1 !== length ? ',' : ''}
                      </Box>
                    ))}
                  </Text>
                </Box>
                {selectedAvailability.appointmentTypes.length === 1 &&
                  selectedAvailability.appointmentTypes[0] === AppointmentType.inPerson && (
                    <Box>
                      <Text as="label" fontSize="sm" d="inline-flex" alignItems="center" fontWeight="bold">
                        <Text as="span" mr={1}>
                          {t('availabilityDetail.bookingCapacity')}
                        </Text>
                        <Tooltip label={t('availabilityDetail.bookingCapacityHelpText')} fontSize="md" shouldWrapChildren>
                          <RiInformationLine />
                        </Tooltip>
                      </Text>
                      <Text fontSize="sm">{selectedAvailability.bookingCapacity ?? 0}</Text>
                    </Box>
                  )}
              </Box>
              <Heading
                px={4}
                py={3}
                lineHeight={1}
                as="h4"
                fontSize="md"
                color="blue.500"
                borderBottom={`1px solid ${colors.gray['50']}`}
              >
                {t('availabilityDetail.bookedInterviews')}
              </Heading>
              {bookingList.length <= 0 && (
                <Box className="booking-list" p={4}>
                  <Box px={4} py={2} bg="white" borderRadius="md" boxShadow="sm">
                    {t('availabilityDetail.noInterviewsMessage')}
                  </Box>
                </Box>
              )}
              <ScheduleListView listBooking={bookingList} />
            </DrawerBody>
            <Flex justify="flex-end" py={3} borderTop={`1px solid ${colors.gray['50']}`}>
              {showDeleteBtn() && (
                <Button
                  variant="outline"
                  borderColor={`${colors.gray['50']}`}
                  ml={2}
                  borderRadius={3}
                  mr={1}
                  onClick={onConfirmDeleteAvailabilityModalOpen}
                  colorScheme="red"
                  data-testid="DeleteAvailabilityBtn"
                >
                  {t('availabilityDetail.deleteAvailability')}
                </Button>
              )}

              <Button
                variant="outline"
                borderColor={`${colors.gray['50']}`}
                ml={6}
                borderRadius={3}
                mr={3}
                onClick={onClose}
                data-testid="CancelDrawerBtn"
              >
                {t('eventForm.cancel')}
              </Button>
            </Flex>
            <ConfirmationDialog
              isLoading={isLoading}
              isOpen={isConfirmDeleteAvailabilityModalOpen}
              onClose={onConfirmDeleteAvailabilityModalClose}
              message={getAlertMessage()}
              warningMessage={getWarningMessage()}
              action={hasDatePassed(selectedAvailability.fromDate) ? null : TeamMemberAction.DELETE}
              handleConfirm={confirmDeleteAvailability}
            />
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Fragment>
  );
};
