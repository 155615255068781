/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import { Box, Image, Text, Center } from '@chakra-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '../../app/topBar/Header';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { PATH_POSITION_TABS } from '../../routes/constants';
import { SelectedTab } from '../administration/topCandidates/TopCandidatesContext';

export const PaymentSuccessfulView = (): JSX.Element => {
  const { t } = useTranslation('tokenPayment');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const positionId = queryParams.get('positionId');

  const accountId = useStoreState((s) => s.app.accounts?.id);
  const { getReceipt } = useStoreActions((actions) => actions.product);

  // function that fetches tokens/products
  const handleReceipt = async () => {
    if (accountId) {
      const receiptResponse = await getReceipt({ accountId });

      /* istanbul ignore next */
      if (receiptResponse.transactionReceipt && receiptResponse.transactionReceipt !== undefined) {
        window.open(receiptResponse.transactionReceipt, '_blank');
      } else {
        console.error('No receipt url found');
      }
    }
  };

  return (
    <React.Fragment>
      <Header showMenu />
      <Box display="flex" flexDirection="column" justifyContent="center" height="100vh">
        <Center>
          <Image src="/successful.png" alt="success-image" height={{ base: '150px', md: '200px', lg: '250px' }} />
        </Center>
        <Center py={5}>
          <Text fontSize="24px" fontWeight={400} color="#A0AEC0" data-testid="payment-success">
            {t('paymentSuccessful')}
          </Text>
        </Center>
        <Center py={3}>
          <Text fontSize="24px" fontWeight={700}>
            {t('congrats')}
          </Text>
        </Center>
        <Center flexDirection="row" justifyContent="center" alignItems="center">
          <Text fontSize="16px" fontWeight={400}>
            {t('orderDetails')}
          </Text>
          <Box cursor="pointer" data-testid="clickHere" onClick={() => handleReceipt()}>
            <Text fontSize="16px" fontWeight={700} paddingLeft="5px" textDecoration="underline">
              {t('here')}
            </Text>
          </Box>
        </Center>
        <Center paddingTop="120px" cursor="pointer">
          {positionId && (
            /* istanbul ignore next */
            <Link to={PATH_POSITION_TABS(positionId, SelectedTab.BOOKMARKED)}>
              <Text fontSize="20px" fontWeight={400} color="blue.500" data-testid="dashboard">
                {t('goHome')}
              </Text>
            </Link>
          )}
        </Center>
      </Box>
    </React.Fragment>
  );
};
